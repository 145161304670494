import React, { useState, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  useTheme,
  Typography,
  Container,
  Stack,
  Button,
  TextField,
} from '@mui/material'
import { equals } from 'ramda'
import { QuestionDetailsModal } from '../../Modals/QuestionDetails'
import {
  getSubjectOptions,
  getGradeOptions,
  getInitialValues,
  getQuestionsToDelete,
  getQuestionsToChangePosition,
  getQuestionsToAdd,
  getQuestionsPositions,
} from './helpers'
import { getStyles } from './styles'
import { SelectForm } from './SelectForm'
import { SelectGroupList } from './SelectGroupList'
import { QuestionList } from './QuestionList'
import { AddQuestionsModal } from './Modals/AddQuestions'
import { SuccessSubmitModal } from './Modals/SuccessSubmit'
import { useManageBookletFilterExam } from '~data/manageBooklets/form/filterExam'
import { useFeedback } from '~contexts/Feedback'

export const ExamForm = ({
  entityId,
  entityType,
  focoExamEditionId,
  requestEndpoint,
  name,
  subjectId,
  gradeId,
  studentsByClassroom,
  initialSelectedQuestions = [],
  requestOnSubmit,
  resetOnSuccessSubmit = false,
  resetInitialValuesOnSubmit = false,
}) => {
  const { data } = useManageBookletFilterExam({
    entityId,
    entityType,
    focoExamEditionId,
  })
  const queryClient = useQueryClient()
  const { showMessage } = useFeedback()
  const formRef = useRef()
  const currentValues = formRef?.current?.values
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const [isAddQuestionModalOpen, setIsAddQuestionModalOpen] = useState(false)
  const [isQuestionDetailsModalOpen, setIsQuestionDetailsModalOpen] =
    useState(false)
  const [selectedQuestionIds, setSelectedQuestionIds] = useState(
    initialSelectedQuestions.map(({ foco_question_id }) => foco_question_id)
  )
  const [selectedQuestionId, setSelectedQuestionId] = useState(null)

  const theme = useTheme()
  const styles = getStyles(theme)

  if (!data || !data?.foco_exam_editions.length) return null

  const examEdition = data?.foco_exam_editions[0]

  const initialValues = {
    ...getInitialValues({
      examEdition,
      name,
      subjectId,
      gradeId,
      studentsByClassroom,
    }),
  }

  const handleSubjectChange = (value, setFieldValue) => {
    const firstGradeOption = getGradeOptions(examEdition, value)[0].id
    setFieldValue('subject', value)
    setFieldValue('grade', firstGradeOption)
    setFieldValue('studentsByClassroom', [])
  }

  const handleGradeChange = (value, setFieldValue) => {
    setFieldValue('grade', value)
    setFieldValue('studentsByClassroom', [])
  }

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    requestOnSubmit({
      endpoint: requestEndpoint,
      name: values.name,
      examId: examEdition.id,
      subjectId: values.subject,
      gradeId: values.grade,
      studentProfileIds: normalizeStudentProfileIds(values.studentsByClassroom),
      questionIds: normalizeQuestions(
        initialSelectedQuestions,
        selectedQuestionIds
      ),
    }).then(([error]) => {
      setSubmitting(false)
      if (error) {
        showMessage({
          type: 'error',
          text: error?.message ?? `Status: ${error?.status}`,
        })
      } else {
        setIsModalSuccessOpen(true)
        queryClient.invalidateQueries('useBookletsList')
        if (resetOnSuccessSubmit) {
          resetForm()
          setSelectedQuestionIds([])
        }
      }
    })
  }

  const handleOpenAddQuestionModal = () => {
    setIsAddQuestionModalOpen(true)
  }

  const handleOpenQuestionDetailsModal = (questionId) => {
    setIsQuestionDetailsModalOpen(true)
    setSelectedQuestionId(questionId)
  }

  const handleFormValidate = (values) => {
    const errors = {}
    if (values['name'] == '') {
      errors['name'] = 'Obrigatório'
    }

    if (
      values['studentsByClassroom'].some(
        ({ studentIds }) => studentIds.length == 0
      )
    ) {
      errors['studentsByClassroom'] = 'Obrigatório'
    }

    return errors
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={handleFormValidate}
        innerRef={formRef}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          isSubmitting,
          errors,
          values,
        }) => (
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ ...styles.form }}>
              <Typography variant="h5" component="h2">
                Detalhes do caderno
              </Typography>
              <Divider sx={{ my: 2 }} />

              <Container>
                <Grid
                  container
                  spacing={{ xs: 0, lg: 2 }}
                  sx={{ pl: 2, pr: 6 }}
                >
                  <Grid item xs={12} lg={6}>
                    <TextField
                      variant="standard"
                      size="small"
                      value={examEdition.name ?? '-'}
                      label="Avaliação"
                      fullWidth
                      disabled
                      sx={{
                        '& .MuiFormLabel-root': {
                          color: 'inherit !important',
                        },
                      }}
                    />

                    <TextField
                      variant="standard"
                      size="small"
                      value={values.name}
                      onChange={handleChange}
                      id="input-name"
                      name="name"
                      required
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      label="Nome do caderno"
                      fullWidth
                    />

                    <SelectForm
                      id="subject"
                      label="Disciplina"
                      value={values.subject}
                      disabled={
                        getSubjectOptions(examEdition).length <= 1 ||
                        selectedQuestionIds.length > 0
                      }
                      onChange={(event) => {
                        handleSubjectChange(event.target.value, setFieldValue)
                      }}
                    >
                      {getSubjectOptions(examEdition).map(({ id, name }) => (
                        <MenuItem value={id} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </SelectForm>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SelectForm
                      id="grade"
                      label="Série"
                      value={values.grade}
                      disabled={
                        getGradeOptions(examEdition, values.subject).length <= 1
                      }
                      onChange={(event) => {
                        handleGradeChange(event.target.value, setFieldValue)
                      }}
                    >
                      {getGradeOptions(examEdition, values.subject).map(
                        ({ id, name }) => (
                          <MenuItem value={id} key={id}>
                            {name}
                          </MenuItem>
                        )
                      )}
                    </SelectForm>

                    <SelectGroupList
                      schoolId={entityId}
                      gradeId={values.grade}
                      setFieldValue={setFieldValue}
                      studentsByClassroom={values.studentsByClassroom}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mt: { xs: 2, lg: 0 } }}>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight={700}
                      >
                        Adicionar questões
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={handleOpenAddQuestionModal}
                        sx={{ height: 36, textTransform: 'none' }}
                      >
                        Banco de questões
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>

              <QuestionList
                selectedQuestionIds={selectedQuestionIds}
                setSelectedQuestionIds={setSelectedQuestionIds}
                handleOpenQuestionDetailsModal={handleOpenQuestionDetailsModal}
              />
            </Box>

            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <Button
                variant="neutral"
                size="small"
                sx={{ minWidth: 160 }}
                component={Link}
                to={`/gerenciar-avaliacoes/school/${entityId}`}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="small"
                sx={{ minWidth: 160 }}
                disabled={
                  isSubmitting ||
                  Object.keys(errors).length > 0 ||
                  (equals(values, initialValues) &&
                    handleQuestionChangeValidate(
                      initialSelectedQuestions,
                      selectedQuestionIds
                    ))
                }
              >
                Salvar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>

      {isModalSuccessOpen && (
        <SuccessSubmitModal
          isOpen={isModalSuccessOpen}
          setIsOpen={setIsModalSuccessOpen}
          basePath={`/gerenciar-avaliacoes/school/${entityId}`}
          resetInitialValuesOnSubmit={resetInitialValuesOnSubmit}
        />
      )}

      {isQuestionDetailsModalOpen && selectedQuestionId && (
        <QuestionDetailsModal
          isOpen={isQuestionDetailsModalOpen}
          setIsOpen={setIsQuestionDetailsModalOpen}
          questionId={selectedQuestionId}
        />
      )}

      {isAddQuestionModalOpen && (
        <AddQuestionsModal
          isOpen={isAddQuestionModalOpen}
          setIsOpen={setIsAddQuestionModalOpen}
          selectedQuestionIds={selectedQuestionIds}
          setSelectedQuestionIds={setSelectedQuestionIds}
          handleOpenQuestionDetailsModal={handleOpenQuestionDetailsModal}
          subject={
            examEdition
              ? getSubjectOptions(examEdition).find(
                  ({ id }) => id === currentValues?.subject
                )
              : {}
          }
          bookletName={formRef?.current?.values?.name ?? ''}
        />
      )}
    </>
  )
}

const handleQuestionChangeValidate = (initialQuestions, newQuestionIds) => {
  const initialQuestionIds = initialQuestions.map(
    ({ foco_question_id }) => foco_question_id
  )

  return equals(initialQuestionIds, newQuestionIds)
}

const normalizeStudentProfileIds = (studentsByClassroom) =>
  studentsByClassroom
    .map(({ studentIds }) => studentIds)
    .reduce((acc, studentIds) => [...acc, ...studentIds])

const normalizeQuestions = (initialQuestions, newQuestionIds) => {
  const newQuestions = getQuestionsPositions(newQuestionIds)

  const questionsToDelete = getQuestionsToDelete(initialQuestions, newQuestions)
  const questionsToChangePosition = getQuestionsToChangePosition(
    initialQuestions,
    newQuestions
  )
  const questionsToAdd = getQuestionsToAdd(initialQuestions, newQuestions)

  return [...questionsToAdd, ...questionsToChangePosition, ...questionsToDelete]
}
