import { styled } from '@mui/material/styles'

export const Aside = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: 'calc(100% - 70px)',
  height: '100%',
  overflowY: 'auto',
  top: 0,
  zIndex: '999',
  left: '-100%',
  padding: theme.spacing(2, 3),
  background: theme.palette.common.white,
  transition: theme.transitions.create('all'),
  scrollBehavior: 'smooth',
  '&[data-is-open="true"]': {
    left: 0,
  },
  [theme.breakpoints.up('lg')]: {
    position: 'relative',
    left: 'initial',
    width: '23%',
    padding: theme.spacing(0, 7.5, 0, 0),
    overflowY: 'initial',
    '&:before': {
      display: 'none',
    },
  },
}))

export const Overlay = styled('div')(({ theme }) => ({
  transition: theme.transitions.create('opacity'),
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: '991',
  background: theme.palette.primary.dark,
  opacity: 0,
  pointerEvents: 'none',
  left: 0,
  top: 0,
  '&[data-is-open="true"]': {
    opacity: 0.8,
    pointerEvents: 'all',
  },
}))

export const Content = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, -2),

  [theme.breakpoints.up('lg')]: {
    margin: 0,
    width: '77%',
  },
}))
