import React, { useState } from 'react'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import {
  Stack,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Link,
  Collapse,
} from '@mui/material'
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { RootStyle } from './styles'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import useResponsive from '~hooks/useResponsive'

const VIEW_OPTIONS = [
  {
    value: 'timeGridDay',
    label: 'Dia',
  },
  {
    value: 'timeGridWeek',
    label: 'Semana',
  },
]

const CalendarToolbar = ({
  date,
  view,
  onNextDate,
  onPrevDate,
  onChangeView,
  onChangeCalendar,
  filterIsOpen,
  setFilterIsOpen,
}) => {
  const isDesktop = useResponsive({ query: 'up', key: 'lg' })

  const { entityId } = useParams()
  const { search } = useLocation()

  const currentDay = dayjs(date).format('MMMM YYYY')

  const [calendarIsOpen, setCalendarIsOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RootStyle>
        <Stack
          direction={{ md: 'row', lg: 'row-reverse' }}
          alignItems="center"
          justifyContent={{
            xs: 'space-between',
            sm: 'space-between',
            md: 'space-between',
            lg: 'flex-end',
          }}
          spacing={2}
          sx={{
            flex: '1',
            width: { xs: '100%', sm: '100%', md: '100%', lg: 'initial' },
          }}
        >
          {isDesktop ? (
            <Typography variant="h6" component="span" sx={{ fontWeight: 400 }}>
              {currentDay}
            </Typography>
          ) : (
            <Stack direction="row" spacing={0} alignItems="center">
              <Button
                variant="text"
                sx={{ minWidth: 40 }}
                disableRipple
                onClick={() => {
                  setFilterIsOpen(!filterIsOpen)
                }}
              >
                <SVGIcon name="handleFilterOpen" size={2} color="linkColor" />
              </Button>
              <Button
                variant="text"
                disableRipple
                onClick={() => {
                  setCalendarIsOpen(!calendarIsOpen)
                }}
                data-active={calendarIsOpen}
              >
                <Typography variant="h6" component="span" sx={{ mr: 1 }}>
                  {currentDay}
                </Typography>
                <SVGIcon name="commonArrow" size={2} color="linkColor" />
              </Button>
            </Stack>
          )}

          <Box>
            <IconButton onClick={onPrevDate}>
              <SVGIcon name="previous" color="linkColor" size={3} />
            </IconButton>

            <IconButton onClick={onNextDate}>
              <SVGIcon name="next" color="linkColor" size={3} />
            </IconButton>
          </Box>
        </Stack>

        {!isDesktop && (
          <Stack
            sx={{
              width: { xs: '100%', sm: '100%', md: '100%', lg: 'initial' },
            }}
          >
            <Collapse in={calendarIsOpen} timeout="auto" unmountOnExit>
              <DateCalendar
                views={['day']}
                defaultValue={dayjs()}
                value={dayjs(date)}
                variant="fullWidthCustom"
                onChange={onChangeCalendar}
                sx={{
                  mt: 2,
                  '& .MuiPickersCalendarHeader-root': {
                    display: 'none',
                  },
                }}
              />
            </Collapse>
          </Stack>
        )}

        {isDesktop && (
          <Stack direction="row" spacing={2} alignItems="center">
            <FormControl
              fullWidth
              variant="inline"
              sx={{ flex: '1', alignItems: 'center' }}
            >
              <InputLabel id="select-vizualization-label">
                Visualizar por
              </InputLabel>
              <Select
                labelId="select-vizualization-label"
                value={view}
                size="small"
                variant="standard"
                onChange={(e) => {
                  onChangeView(e.target.value)
                }}
                sx={{ minWidth: 120 }}
              >
                {VIEW_OPTIONS.map((viewOption, index) => (
                  <MenuItem
                    value={viewOption.value}
                    selected={viewOption.value === view}
                    key={index}
                  >
                    {viewOption.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Link
              component={RouterLink}
              to={`/curriculo/school/${entityId || ''}${search}`}
              color="secondary"
              variant="body2"
            >
              Voltar para o currículo
            </Link>
          </Stack>
        )}
      </RootStyle>
    </LocalizationProvider>
  )
}

export default CalendarToolbar
