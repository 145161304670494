import { Typography, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { deleteExam } from './fetch'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import Modal from '~primitives/Modal'
import DeleteCheck from '~assets/svg/delete_check.svg'
import SuccessCheck from '~assets/svg/success_check.svg'

import { useFeedback } from '~contexts/Feedback'

export const DeleteModal = ({ isOpen, setIsOpen, selectedExam }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const { showMessage } = useFeedback()

  const queryClient = useQueryClient()

  const { mutate } = useMutation(() => deleteExam(selectedExam?.id), {
    onSuccess: () => {
      queryClient.invalidateQueries('useManageExamsList')
      setIsSuccess(true)
    },
    onError: (error) => {
      showMessage({ type: 'error', text: error.message })
    },
  })

  const handleClose = () => {
    setIsOpen(false)
    setIsSuccess(false)
  }

  const handleConfirm = () => {
    if (isSuccess) {
      handleClose()
    } else {
      mutate()
    }
  }

  return (
    <Modal
      onClose={handleClose}
      onConfirm={handleConfirm}
      closeButton={!isSuccess}
      width="500px"
      confirmButtonText={isSuccess ? 'Voltar para a lista' : 'Confirmar'}
      isOpen={isOpen}
    >
      <>
        <Typography variant="h4" margin="15px 0">
          <UnderlinedHeading as="span">
            <strong> Deletar avaliação</strong>
          </UnderlinedHeading>
        </Typography>

        <Stack variant="modalBox">
          {!isSuccess && (
            <React.Fragment>
              <img src={DeleteCheck} alt="Ícone de deletar" height="90px" />
              <Typography variant="h5" component="h3">
                Atenção!
              </Typography>
              <Typography variant="body2" textAlign="center">
                Deseja realmente deletar a avaliação {selectedExam?.name}
              </Typography>
            </React.Fragment>
          )}
          {isSuccess && (
            <React.Fragment>
              <img src={SuccessCheck} alt="Ícone de sucesso" height="90px" />
              <Typography variant="h5" component="h3">
                Avaliação excluída com sucesso!
              </Typography>
              <Typography variant="body2" textAlign="center">
                A avaliação {selectedExam?.name} foi deletada com sucesso.
              </Typography>
            </React.Fragment>
          )}
        </Stack>
      </>
    </Modal>
  )
}
