import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Box, Typography, Divider } from '@mui/material'
import { ImportModal } from '../ImportModal'
import { StudentsTable } from './Table'
import { Search } from './Search'
import { AddModal } from '~pages/ManageStudents/AddModal'
import { EditModal } from '~pages/ManageStudents/EditModal'
import { DeleteModal } from '~pages/ManageStudents/DeleteModal'
import RouteWithProps from '~routes/RouteWithProps'
import { Breadcrumbs } from '~primitives/Breadcrumbs'
import { Header } from '~components/ManageStudents/Header'
import FilterBar from '~components/Common/FilterBar'
import useEntity from '~data/entity'

import useFilters from '~hooks/useFilters'

export const Content = () => {
  const [modalEditProps, setModalEditProps] = useState(null)
  const [modalDeleteProps, setModalDeleteProps] = useState(null)
  const [modalImportIsOpened, setModalImportIsOpened] = useState(false)
  const { filters } = useFilters()
  const { entityId } = useParams()

  const history = useHistory()
  const { search } = useLocation()

  const handleOpenEditModal = (props) => {
    const { schoolId } = props
    setModalEditProps(props)
    if (schoolId) {
      history.push({
        pathname: `/gerenciar-alunos/school/${schoolId}/edit`,
        search,
      })
    }
  }

  const handleOpenDeleteModal = (props) => {
    const { schoolId } = props
    setModalDeleteProps(props)
    if (schoolId) {
      history.push({
        pathname: `/gerenciar-alunos/school/${props.schoolId}/delete`,
        search,
      })
    }
  }

  const { data } = useEntity('school', String(entityId))

  return (
    <Box sx={{ px: 8 }}>
      <Breadcrumbs
        margin="0"
        items={[
          { id: 'students', text: 'Alunos', pathname: '/' },
          {
            id: 'manage-students',
            text: 'Gerenciar alunos',
            pathname: `/manage-student/${entityId || 0}`,
          },
        ]}
      />
      <Typography variant="h5" sx={{ my: 1 }}>
        {data && entityId ? `${data?.name} (${entityId})` : 'Carregando...'}
      </Typography>

      <Divider sx={{ my: 1 }} />

      <Header openImportModal={setModalImportIsOpened} />

      <ImportModal
        isOpened={modalImportIsOpened}
        setIsOpened={setModalImportIsOpened}
      />

      <FilterBar contained margin="20px 0" />

      <Typography variant="body1" sx={{ my: 1 }}>
        <strong>Lista de alunos •</strong>{' '}
        {filters?.classroom_id?.selected.name}
      </Typography>

      <Search />

      <StudentsTable
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
      />

      {entityId && modalEditProps && (
        <RouteWithProps
          props={modalEditProps}
          path={`/gerenciar-alunos/school/${entityId}/edit`}
          component={EditModal}
        />
      )}

      {entityId && (
        <RouteWithProps
          props={{ schoolId: entityId }}
          path={`/gerenciar-alunos/school/${entityId}/add`}
          component={AddModal}
        />
      )}

      {entityId && modalDeleteProps && (
        <RouteWithProps
          props={modalDeleteProps}
          path={`/gerenciar-alunos/school/${entityId}/delete`}
          component={DeleteModal}
        />
      )}
    </Box>
  )
}
