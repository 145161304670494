import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = (id) => `/api/v3/foco_exams/${id || 0}/foco_exam_result`

export const useFocoExamResultQuestions = (params) => {
  const { data, loading, error } = useResource({
    key: 'useFocoExamResultQuestions',
    resource: getFocoExamResultQuestions,
    params,
    enabled: Boolean(params.examId && params.classroomId),
  })

  return { data, loading, error }
}

const getFocoExamResultQuestions = (params) =>
  fetchWithRoot('get')(endpoint(params.examId), {
    params: {
      entity_type: 'school',
      entity_id: params.schoolId,
      classroom_id: params.classroomId,
      include: [{ questions: [{ foco_question: ['curriculum_skill'] }] }],
      fields: [
        {
          questions: [
            'id',
            'hit_rate',
            {
              foco_question: [
                'id',
                { curriculum_skill: ['id', 'name', 'description'] },
              ],
            },
          ],
        },
      ],
    },
  })
