import { fetchWithRoot } from '~helpers/fetch'

export const delete_lesson_plan_endpoint = ({ baseCurriculumId, planId }) =>
  `/api/v3/base_curriculums/${baseCurriculumId}/base_events/${planId}`

export const deleteCurriculum = ({ baseCurriculumId, planId }) => {
  if (!planId) return false
  return fetchWithRoot('delete')(
    delete_lesson_plan_endpoint({ baseCurriculumId, planId })
  )
}
