import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { Typography, Box, Button } from '@mui/material'
import { ToggleWrapper, Container, LinkStyled } from './styles'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { track } from '~resources/ahoy'

const ToggleHome = () => {
  const isShowingNewHome = useSelector((state) => state.user.showNewHome)
  const dispatch = useDispatch()

  const handleClick = () => {
    track(`Home / ${isShowingNewHome ? 'Disable' : 'Enable'} new home`)
    dispatch({ type: 'TOGGLE_NEW_HOME' })
  }

  return (
    <Box>
      {isShowingNewHome ? (
        <LinkStyled id="toggle-home" onClick={handleClick}>
          Ver versão anterior da página inicial
        </LinkStyled>
      ) : (
        <ToggleWrapper>
          <Container>
            <Box item xs={1}>
              <SVGIcon name="regularResult" color="#FDA534" size={8} />
            </Box>
            <Box item xs={10}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                O Módulo de Devolutivas Pedagógicas vai ganhar uma cara nova
              </Typography>
              <Typography variant="body2">
                Em breve, a Foco terá uma nova página inicial, facilitando o seu
                acesso aos conteúdos mais relevantes da plataforma. Clique no
                botão e mude para a nova versão!
              </Typography>
            </Box>
            <Box item xs={1}>
              <Button variant="primary" id="toggle-home" onClick={handleClick}>
                Mudar agora
              </Button>
            </Box>
          </Container>
        </ToggleWrapper>
      )}
    </Box>
  )
}

export default ToggleHome
