import React from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import { Table, TableBody, TableContainer } from '@mui/material'
import Row from '~components/FocoExams/Teacher/SchoolContent/List/Table/Row'
import Header from '~components/FocoExams/Teacher/SchoolContent/List/Table/Header'

const ListTable = ({ list }) => {
  const history = useHistory()
  const query = qs.parse(useLocation().search, { ignoreQueryPrefix: true })

  const setOrder = (orderBy, order) => {
    history.push({
      search: qs.stringify({ ...query, order, orderBy }),
    })
  }

  return (
    <TableContainer>
      <Table
        variant="borderless"
        sx={{
          minWidth: 650,
          color: 'grey.800',
          borderSpacing: '0 2px',
          borderCollapse: 'separate',
        }}
      >
        <Header setOrder={setOrder} />
        <TableBody data-testid="students-table-body">
          {list.map((item) => (
            <Row
              key={item.id}
              id={item.student_profile.id}
              name={item.student_profile.user.name}
              classroom={item.student_profile.classroom.name}
              answered={item.answered}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ListTable
