import React from 'react'
import { useHistory } from 'react-router-dom'
import { StudentFormModal } from '~components/Students/FormModal'
import { patchStudentProfile } from '~data/manageStudents/studentProfile'

export const EditModal = (props) => {
  const history = useHistory()

  const handleClose = () => {
    history.push({
      pathname: `/gerenciar-alunos/school/${props.schoolId}`,
    })
  }
  const requiredFields = [
    'id',
    'name',
    'registration_number',
    'email',
    'classroom',
    'grade',
    'schoolId',
  ]

  if (requiredFields.every((field) => !props[field])) return null

  return (
    <StudentFormModal
      title="Editar aluno"
      requestOnSubmit={patchStudentProfile}
      closeOnSuccessSubmit={true}
      handleClose={handleClose}
      grade={{ id: 1, name: 'Série' }}
      classroom={{ id: 1, name: 'Turma' }}
      id={props.id}
      name={props.name}
      registration_number={props.registration_number}
      email={props.email}
      isActive={props.isActive}
      schoolId={props.schoolId}
    />
  )
}
