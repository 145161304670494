import React from 'react'
import { Stack, Typography } from '@mui/material'
import SuccessCheck from '~assets/svg/success_check.svg'
import Warning from '~assets/svg/warning_form.svg'

const iconSources = { success: SuccessCheck, warning: Warning }

export const FormFeedback = ({ type = 'success', title, text }) => (
  <Stack justifyContent="center" alignItems="center">
    <img src={iconSources[type]} alt={title} width="90px" />
    <Typography align="center" variant="h2" fontWeight={700}>
      {title}
    </Typography>
    <Typography align="center" variant="body1">
      {text}
    </Typography>
  </Stack>
)
