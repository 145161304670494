import axios from 'axios'
import { fetchWithRoot, fetchWithoutRoot } from '../helpers/fetch'

export const endpoints = {
  createUserWithProfiles: 'api/v2/users',
  getProfiles: '/api/v2/users/profiles',
  getFeatures: '/api/v2/menus',
  getStudentFeatures: '/api/v3/foco_exam_editions',
  getUser: '/api/v2/user',
  profileData: '/api/v2/users/profile',
  userData: '/api/v2/users/user',
}

const getProfiles = () =>
  axios
    .get(endpoints.getProfiles)
    .then((response) => [null, response.data])
    .catch((error) => {
      if (error.response?.status === 401) {
        return [{ status: 401, logout: true }, null]
      } else {
        return [{ status: error.response?.status, logout: false }, null]
      }
    })

const getFeatures = () =>
  axios
    .get(endpoints.getFeatures)
    .then((response) => [null, response.data])
    .catch((error) => {
      if (error.response?.status === 401) {
        return [{ status: 401, logout: true }, null]
      } else {
        return [{ status: error.response?.status, logout: false }, null]
      }
    })

const getStudentFeatures = () =>
  fetchWithRoot('get')(endpoints.getStudentFeatures)

const patchProfile = (data) =>
  fetchWithoutRoot('patch')(endpoints.profileData, { data })

const patchUser = (data) =>
  fetchWithoutRoot('patch')(endpoints.userData, { data })

const getUser = () => fetchWithRoot('get')(endpoints.getUser)

const createUserWithProfiles = (user) =>
  fetchWithRoot('post')(endpoints.createUserWithProfiles, { data: user })

export default {
  createUserWithProfiles,
  getProfiles,
  getFeatures,
  getStudentFeatures,
  getUser,
  endpoints,
  patchProfile,
  patchUser,
}
