import { NavLink } from 'react-router-dom'
import React from 'react'
import { styled } from '@mui/material/styles'

const List = styled('ul')`
  margin: ${({ margin }) => margin || '20px 0'};
  display: flex;
  align-items: center;
  font-size: 10px;

  li {
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.foco.textLight};

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 1px 1px ${theme.palette.foco.textLight}`};
      }
    }
    .disabled {
      cursor: not-allowed;
    }

    &:last-of-type a {
      color: ${({ theme }) => theme.palette.button.secondary};
    }

    &:not(:last-of-type) a:after {
      content: '';
      display: inline-block;
      border: solid black;
      border-width: 0 1px 1px 0;
      border-color: ${({ theme }) => theme.palette.foco.textLight};
      padding: 2px;
      margin: 0 8px 1.5px;
      transform: rotate(-45deg);
      vertical-align: middle;
    }
  }
`

export const Breadcrumbs = ({ items, margin }) => (
  <List margin={margin}>
    {items.map(({ id, pathname, text }, index) => (
      <li key={id ?? index}>
        <NavLink data-testid={id} to={pathname ?? ''}>
          {text}
        </NavLink>
      </li>
    ))}
  </List>
)
