export const statusIcons = {
  registered: { color: 'success', name: 'checkBage' },
  planned: { color: 'success', name: 'checkBage' },
  unregistered: { color: 'error', name: 'warning' },
  unplanned: { color: 'warning', name: 'warning' },
  unplanned_late: { color: 'error', name: 'warning' },
}

export const getStatusIcons = (status, endTime) => {
  const isLate = Date.now() > new Date(endTime)
  if (isLate && status === 'unplanned') {
    return statusIcons.unplanned_late
  }
  return statusIcons[status]
}
