import React, { useEffect } from 'react'
import isEqual from 'react-fast-compare'
import usePrevious from './usePrevious'
import { trackView } from '~resources/ahoy'

const useAhoy = (name, properties) => {
  const prevProperties = usePrevious(properties)

  useEffect(() => {
    if (prevProperties && isEqual(prevProperties, properties)) {
      return
    }

    trackView(name, properties)
  }, [name, prevProperties, properties])
}

export default useAhoy
