import { config } from '../../config'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

import { getFieldsAndIncludes } from '~data/helpers'

const { endpoint, key } = config

const getEndpoint = (entityType, entityId, academicYearId) =>
  `${endpoint(entityType, entityId)}/${String(academicYearId)}`

export const useAcademicYear = (params = {}) => {
  const { loading, error, data } = useResource({
    key,
    resource: getAcademicYear,
    params,
  })

  return { loading, error, data }
}

const extensionsConfig = {
  default: {
    fields: ['id', 'year', 'initial_date', 'end_date'],
  },
  schoolDetails: {
    fields: ['expected_total_days', 'curriculum_period_details_count'],
  },
  districtDetails: {
    fields: ['expected_total_days', 'schools_count'],
  },
  curriculumPeriods: {
    include: ['curriculum_periods'],
    fields: [{ curriculum_periods: ['id', 'name', 'start_date', 'end_date'] }],
  },
  curriculumPeriodDays: {
    include: ['curriculum_periods'],
    fields: [{ curriculum_periods: ['total_days'] }],
  },
  parent: {
    include: ['parent_academic_year'],
    fields: [{ parent_academic_year: ['id', 'year'] }],
  },
}

const getAcademicYear = ({
  entityType,
  entityId,
  academicYearId,
  extensions = [],
}) => {
  return fetchWithRoot('get')(
    getEndpoint(entityType, entityId, academicYearId),
    {
      params: { ...getFieldsAndIncludes(extensionsConfig, extensions) },
      bypassLoader: true,
    }
  )
}
