import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  Grid,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Divider,
  MenuItem,
} from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { generateCurriculum } from './fetch'
import Modal from '~primitives/Modal'
import { useFeedback } from '~contexts/Feedback'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { useAcademicYearsBySchool } from '~data/curriculum/school/academicYears'
import { useCurriculumPeriodsBySchool } from '~data/curriculum/school/curriculumPeriods'
import { useGradesByProfile } from '~data/curriculum/gradesByProfile'
import { useBaseCurriculumsByDistrict } from '~data/curriculum/school/baseCurriculumsByDistrict'
import SuccessCheck from '~assets/svg/success_check.svg'
import Info from '~assets/svg/info_warning.svg'

const CreateModal = ({ schoolId, isOpen, setIsOpen }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null)
  const [selectedCurriculumPeriodId, setSelectedCurriculumPeriodId] =
    useState(null)
  const [selectedGradeId, setSelectedGradeId] = useState(null)
  const [selectedBaseCurriculumIds, setSelectedBaseCurriculumIds] = useState([])
  const [successDataStatuses, setSuccessDataStatuses] = useState([])

  const { showMessage } = useFeedback()
  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    () =>
      generateCurriculum({
        curriculumPeriodId: selectedCurriculumPeriodId,
        baseCurriculumIds: selectedBaseCurriculumIds,
        schoolId,
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('useManagementBaseCurriculums')
        setSuccessDataStatuses(data.statuses)
        setIsSuccess(true)
      },
      onError: (error) => {
        showMessage({ type: 'error', text: error.message })
      },
    }
  )

  const filtersIsNotNull =
    selectedAcademicYear !== null &&
    selectedCurriculumPeriodId !== null &&
    selectedGradeId !== null

  const {
    data: academicYearsData,
    loading: academicYearsLoading,
    error: academicYearsError,
  } = useAcademicYearsBySchool({ schoolId })

  const {
    data: curriculumPeriodsData,
    loading: curriculumPeriodsLoading,
    error: curriculumPeriodsError,
  } = useCurriculumPeriodsBySchool({ schoolId })

  const {
    data: gradesData,
    loading: gradesLoading,
    error: gradesError,
  } = useGradesByProfile()

  const { data: baseCurriculumsData } = useBaseCurriculumsByDistrict({
    schoolId,
    gradeId: selectedGradeId,
    curriculumPeriodId: selectedCurriculumPeriodId,
  })

  const handleChangeSelectedAcademicYear = (event) => {
    setSelectedAcademicYear(event.target.value)
    setSelectedBaseCurriculumIds([])
  }

  const handleChangeCurriculumPeriodId = (event) => {
    setSelectedCurriculumPeriodId(event.target.value)
    setSelectedBaseCurriculumIds([])
  }

  const handleChangeSelectedGradeId = (event) => {
    setSelectedGradeId(event.target.value)
    setSelectedBaseCurriculumIds([])
  }

  const handleChangeSelectedBaseCurriculumIds = (event) => {
    const value = Number(event.target.value)

    if (selectedBaseCurriculumIds.includes(value)) {
      setSelectedBaseCurriculumIds(
        selectedBaseCurriculumIds.filter((id) => id !== value)
      )
    } else {
      setSelectedBaseCurriculumIds([...selectedBaseCurriculumIds, value])
    }
  }

  const handleConfirm = () => {
    if (!isSuccess) {
      mutate()
    } else {
      setIsOpen(false)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      width="671px"
      height="90vh"
      closeButton={!isSuccess}
      disableConfirm={!selectedBaseCurriculumIds?.length}
      confirmButtonText={isSuccess ? 'Ver lista de currículos' : 'Salvar'}
      onConfirm={handleConfirm}
      onClose={handleClose}
      contentPadding="16px"
      showFooterDivider={false}
    >
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Criar Currículo</strong>
        </UnderlinedHeading>
      </Typography>

      <Stack variant="modalBox" sx={{ display: 'block' }}>
        {!isSuccess && (
          <React.Fragment>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1">
                Inclua os dados do currículo
              </Typography>
              <FormControl variant="inline">
                <InputLabel id="year-label" required>
                  Ano letivo
                </InputLabel>
                <Select
                  id="select-year"
                  name="year"
                  label="year-label"
                  value={selectedAcademicYear}
                  onChange={handleChangeSelectedAcademicYear}
                  disabled={
                    academicYearsLoading ||
                    academicYearsError ||
                    academicYearsData?.academic_years?.length === 0
                  }
                  variant="standard"
                  size="small"
                  MenuProps={{
                    style: { zIndex: 1000001 },
                  }}
                >
                  {academicYearsData?.academic_years?.map(({ year, id }) => (
                    <MenuItem key={id} value={id}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Divider sx={{ my: 2.5 }} />
            <Stack direction="row" gap={1.5}>
              <FormControl fullWidth>
                <InputLabel id="grade-label" required>
                  Série
                </InputLabel>
                <Select
                  id="select-grade"
                  name="grade"
                  label="grade-label"
                  value={selectedGradeId}
                  onChange={handleChangeSelectedGradeId}
                  disabled={
                    gradesLoading ||
                    gradesError ||
                    gradesData?.grades?.length === 0
                  }
                  variant="standard"
                  size="small"
                  MenuProps={{
                    style: { zIndex: 1000001 },
                  }}
                >
                  {gradesData?.grades?.map(({ name, id }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="period-label" required>
                  Período
                </InputLabel>
                <Select
                  id="select-period"
                  name="period"
                  label="period-label"
                  value={selectedCurriculumPeriodId}
                  onChange={handleChangeCurriculumPeriodId}
                  disabled={
                    curriculumPeriodsLoading ||
                    curriculumPeriodsError ||
                    curriculumPeriodsData?.curriculum_periods?.length === 0
                  }
                  variant="standard"
                  size="small"
                  MenuProps={{
                    style: { zIndex: 1000001 },
                  }}
                >
                  {curriculumPeriodsData?.curriculum_periods?.map(
                    ({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Stack>

            {filtersIsNotNull && baseCurriculumsData && (
              <Box mt={2}>
                <InputLabel required>Disciplina</InputLabel>

                <Grid container spacing={0} mx={1.5}>
                  {baseCurriculumsData?.base_curriculums?.map(
                    ({ id, subject }) => {
                      const { name } = subject
                      const isChecked = selectedBaseCurriculumIds.includes(id)

                      return (
                        <Grid item xs={12} sm={6} md={4} key={id} my={1}>
                          <FormControlLabel
                            control={<Checkbox variant="form" />}
                            labelPlacement="end"
                            label={name}
                            value={id}
                            checked={isChecked}
                            onChange={handleChangeSelectedBaseCurriculumIds}
                          />
                        </Grid>
                      )
                    }
                  )}
                </Grid>
              </Box>
            )}
          </React.Fragment>
        )}
        {isSuccess && (
          <Stack alignItems="center" gap={2}>
            {successDataStatuses.map((item, index) => {
              if (item.status === 'success') {
                return (
                  <Stack
                    key={index}
                    direction="row"
                    gap={1}
                    alignItems="center"
                    width="100%"
                  >
                    <img src={SuccessCheck} alt="" width="30px" />

                    <Typography
                      variant="body1"
                      fontWeight={400}
                      key={`success-${index}`}
                      fontSize={'16px'}
                    >
                      O currículo da <strong>{item.grade.name}</strong> de{' '}
                      <strong>{item.subject.name}</strong> foi criado com
                      sucesso
                    </Typography>
                  </Stack>
                )
              }

              if (item.status === 'error') {
                return (
                  <Stack
                    key={item.index}
                    direction="row"
                    gap={1}
                    alignItems="center"
                    width="100%"
                  >
                    <img src={Info} alt="" width="30px" />
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      key={`error-${index}`}
                    >
                      O currículo da <strong>{item.grade.name}</strong> de{' '}
                      <strong>{item.subject.name}</strong> já estava cadastrado.
                      Verifique a lista de currículos
                    </Typography>
                  </Stack>
                )
              }
            })}
          </Stack>
        )}
      </Stack>
    </Modal>
  )
}

export default CreateModal
