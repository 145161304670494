export default function InputLabel(theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          fontWeight: theme.typography.bold,
          transform: 'none',
          position: 'relative',
          color: theme.palette.primary.main,
          marginBottom: 0,
        },
      },
    },
  }
}
