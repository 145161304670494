import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { ExamDetails } from './Details'
import { Form } from './Form'
import { updateFocoExamStart } from './Form/ModalSubmit/fetch'
import { useFeedback } from '~contexts/Feedback'

export const Content = ({
  examEditionName,
  subjectName,
  gradeName,
  examStart,
  examEnd,
  timeLimit,
  instructions,
  answers,
  questions,
}) => {
  const [formOpened, setFormOpened] = useState(false)
  const { id } = useParams()
  const { showMessage } = useFeedback()

  const queryClient = useQueryClient()
  const [selectedAnswers, setSelectedAnswers] = useState(
    normalizeAnswers(answers)
  )

  const answersByQuestions = normalizeAnswersByQuestions(answers, questions)
  const amountOfQuestions = answersByQuestions.length

  const handleNewSelectOptionId = (answerId, optionId) => {
    setSelectedAnswers(
      selectedAnswers.map((answer) =>
        answer.id === answerId
          ? { ...answer, selectedOptionId: optionId }
          : answer
      )
    )
  }

  const { mutate } = useMutation(
    () =>
      updateFocoExamStart({
        id: Number(id),
        data: { answered: false, started: true },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useStudentFocoExamsEditions')
        setFormOpened(true)
      },
      onError: (error) => {
        setFormOpened(false)
        showMessage({ type: 'error', text: error.message })
      },
    }
  )

  const handleOpenForm = () => {
    mutate()
  }

  const handleCloseForm = () => {
    setFormOpened(false)
  }

  return (
    <>
      {!formOpened && (
        <ExamDetails
          examEditionName={examEditionName}
          subjectName={subjectName}
          gradeName={gradeName}
          examStart={examStart}
          examEnd={examEnd}
          timeLimit={timeLimit}
          instructions={instructions}
          amountOfQuestions={amountOfQuestions}
          handleOpenForm={handleOpenForm}
        />
      )}
      {formOpened && (
        <Form
          handleCloseForm={handleCloseForm}
          examEditionName={examEditionName}
          subjectName={subjectName}
          gradeName={gradeName}
          selectedAnswers={selectedAnswers}
          answersByQuestions={answersByQuestions}
          amountOfQuestions={amountOfQuestions}
          handleNewSelectOptionId={handleNewSelectOptionId}
        />
      )}
    </>
  )
}

const normalizeAnswers = (answers) =>
  answers.map((answer) => ({
    id: answer.id,
    questionId: answer.foco_question_id,
    selectedOptionId: answer.selected_foco_question_option_id,
  }))

const normalizeAnswersByQuestions = (answers, questions) => {
  const questionIdsOnAnswers = answers.map(
    ({ foco_question_id }) => foco_question_id
  )
  const filteredQuestions = questions.filter((question) =>
    questionIdsOnAnswers.includes(question.id)
  )

  const questionsByAnswers = filteredQuestions.map((question) => {
    const currentAnswer = answers.find(
      (answer) => answer.foco_question_id === question.id
    )

    return {
      ...question,
      selectedOptionId: currentAnswer?.selected_foco_question_option_id ?? null,
      answerId: currentAnswer?.id,
    }
  })

  return questionsByAnswers
}
