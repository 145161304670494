import React from 'react'
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'

export const Placeholder = () => (
  <React.Fragment>
    <Typography variant="h4" margin="15px 0">
      <UnderlinedHeading as="span">
        <strong>Registro da aula</strong>
      </UnderlinedHeading>
    </Typography>
    <Stack variant="modalBox" style={{ display: 'block' }}>
      <Stack direction="row" justifyContent="space-between" gap={2} my={1.5}>
        <Skeleton variant="text" height={14} width="100%" />
        <Skeleton variant="text" height={14} width="100%" />
        <Skeleton variant="text" height={14} width="100%" />
      </Stack>

      <Divider sx={{ my: 1 }} />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        my={3.5}
      >
        <Skeleton variant="text" height={14} width="100%" />
        <Skeleton variant="text" height={14} width="100%" />
      </Stack>

      <Divider sx={{ my: 1 }} />

      <Box width={1}>
        <Stack sx={{ my: 1.5 }} gap={1}>
          <Skeleton variant="text" height={14} width={150} />
          <Skeleton variant="text" height={14} width={110} />
        </Stack>
        <Stack sx={{ my: 1.5 }} gap={1}>
          <Skeleton variant="text" height={14} width={150} />
          <Skeleton variant="text" height={14} width="100%" />
          <Skeleton variant="text" height={14} width="100%" />
          <Skeleton variant="text" height={14} width="50%" />
        </Stack>

        <Divider sx={{ my: 1 }} />

        <Stack
          sx={{ my: 1.5 }}
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Skeleton variant="text" height={14} width={130} />
          <Skeleton variant="circular" height={34} width={34} />
        </Stack>

        <Divider sx={{ my: 1 }} />

        <Stack sx={{ my: 2 }} gap={1}>
          <Skeleton variant="text" height={14} width={150} />
          <Skeleton variant="text" height={14} width="90%" />
          <Skeleton variant="text" height={14} width="100%" />
          <Skeleton variant="text" height={14} width="50%" />
          <Skeleton variant="text" height={14} width="80%" />
        </Stack>

        <Stack sx={{ my: 2 }} gap={1}>
          <Skeleton variant="text" height={14} width={150} />
          <Skeleton variant="text" height={14} width="90%" />
          <Skeleton variant="text" height={14} width="100%" />
        </Stack>
      </Box>
    </Stack>
  </React.Fragment>
)
