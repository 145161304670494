import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Slide, IconButton, Typography, Snackbar, Paper } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { getSelectedProfile } from '~selectors/profile'
import { getPath } from '~helpers/paths'

import { track } from '~resources/ahoy'

const TransitionLeft = (props) => {
  return <Slide {...props} direction="left" />
}

const NotificationMessage = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'left',
  height: 80,
  padding: '10px 25px',
  borderLeft: `solid 3px ${theme.palette.error.main}`,
  boxShadow: theme.customShadows?.dropdown,
}))

export default function Notification({ notification, handleUpdate, index }) {
  const [read, setAsRead] = useState(true)
  const theme = useTheme()
  const calcTopPosition = Number(76 + index * 85)
  const timeoutOnEnter = index * 200 + 300
  const notificationDate = dayjs().to(dayjs(notification.created_at))

  const linkHref = useLinkHref(notification)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAsRead(false)
    track('Notifications / close notification')
    setTimeout(() => {
      handleUpdate(notification.id)
    }, 300)
  }

  const NotificationLink = ({ children, notification, target }) =>
    notification.url ? (
      <a
        href={linkHref}
        target={target}
        onClick={() => {
          setAsRead(false)
          track('Notifications / Click notification')
          setTimeout(() => {
            handleUpdate(notification.id)
          }, 300)
        }}
      >
        {children}
      </a>
    ) : (
      <Link
        to={linkHref}
        onClick={() => {
          setAsRead(false)
          track('Notifications / Click notification')
          setTimeout(() => {
            handleUpdate(notification.id)
          }, 300)
        }}
      >
        {children}
      </Link>
    )

  return (
    <Snackbar
      open={read}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
      TransitionProps={{
        timeout: {
          enter: timeoutOnEnter,
          exit: 300,
        },
      }}
      key={notification.id}
      sx={{
        position: 'absolute',
        top: { xs: calcTopPosition },
        transition: 'all .3s ease-in-out',
        displayPrint: 'none',
      }}
    >
      <NotificationMessage sx={{ width: '360px' }}>
        <NotificationLink
          notification={notification}
          target={notification.new_tab ? '_blank' : '_self'}
        >
          <Typography
            variant="body1"
            sx={{
              pr: 5,
              color: 'text.primary',
              '&:hover': {
                color: '#F05E09',
              },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: notification.message }} />
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: 'text.disabled',
              textAlign: 'left',
              mt: theme.spacing(0.5),
            }}
          >
            {notificationDate}
          </Typography>
        </NotificationLink>
        <IconButton
          onClick={handleClose}
          id={notification.id}
          variant="grey"
          sx={{
            position: 'absolute',
            zIndex: 99,
            top: '50%',
            right: theme.spacing(2),
            transform: 'translate(0,-50%)',
          }}
        >
          <SVGIcon name="close" color={theme.palette.text.primary} size={3} />
        </IconButton>
      </NotificationMessage>
    </Snackbar>
  )
}

const useLinkHref = ({ url, exam_info }) => {
  const profile = useSelector(getSelectedProfile)
  if (url) return url

  if (exam_info) {
    return getPath({
      feature: exam_info.feature,
      resource: exam_info.resource,
      examType: exam_info.exam_type,
    })(exam_info.exam_result_id, profile)
  }

  return ''
}
