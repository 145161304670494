import { useSelector } from 'react-redux'
import React, { lazy, Suspense } from 'react'
import { Thumbs } from '../styles'
import { Banner } from '~primitives/Banner'
import { getHomeWalkthrough } from '~walkthrough/home'
import { getSelectedProfile } from '~selectors/profile'
import Thumb from '~components/Home/Thumb'
import TitleUpdater from '~connected/TitleUpdater'
import ToggleHome from '~components/Home/ToggleHome'
import Walkthrough from '~components/Common/Walkthrough'
import useAhoy from '~hooks/useAhoy'
import useCustomization from '~hooks/useCustomization'
import useProfileData from '~hooks/useProfileData'

import svgENEM from '~assets/svg/home_enem.svg'
import svgIndicators from '~assets/svg/home_indicators.svg'
import svgSchool from '~assets/svg/home_school.svg'
import svgStudents from '~assets/svg/home_students.svg'

const LoadableSchoolDashboard = lazy(() => import('~pages/Dashboard/School'))

const items = [
  { id: 'students-thumb', name: 'prospective', icon: svgStudents },
  { id: 'school-thumb', name: 'retrospective', icon: svgSchool },
  { id: 'indicators-thumb', name: 'indicators', icon: svgIndicators },
  { id: 'enem-thumb', name: 'enem', icon: svgENEM },
]

const TeacherHome = () => {
  const user = useSelector(({ user }) => user)
  const profile = useSelector(getSelectedProfile)
  const showNewHome = profile.kind === 'school' && user.showNewHome

  useAhoy('Home')
  useAhoy(`${showNewHome ? 'New' : 'Old'} Home`)

  const customization = useCustomization()
  const { profileHasFeature } = useProfileData()

  const { features } = user
  const showToggleHome =
    profile.kind === 'school' && profileHasFeature('indicators', 'dashboard')

  return (
    <>
      {showToggleHome && <ToggleHome />}

      {showNewHome ? (
        <Suspense fallback={null}>
          <LoadableSchoolDashboard schoolId={profile.personable.id} />
        </Suspense>
      ) : (
        <>
          <TitleUpdater
            htmlTitle="Bem-vindo"
            templateTitle={customization.applicationName}
          />

          <Banner image id="home-banner">
            <h2>
              <strong>Olá,</strong> {user.name}
            </h2>
            <p>
              O <strong>{customization.applicationName}</strong> apoia o
              professor no planejamento pedagógico, acompanhamento e prática na
              sala de aula
            </p>
          </Banner>

          <Thumbs>
            {items.map((feature) => {
              if (
                features[feature.name] &&
                features[feature.name].resources.length
              ) {
                return (
                  <Thumb
                    key={feature.name}
                    id={feature.id}
                    title={features[feature.name].label}
                    text={features[feature.name].description}
                    icon={feature.icon}
                    buttons={features[feature.name].resources}
                    feature={feature.name}
                  />
                )
              }
            })}
          </Thumbs>

          <Walkthrough
            name="home"
            steps={getHomeWalkthrough({
              applicationName: customization.applicationName,
            })}
          />
        </>
      )}
    </>
  )
}

export default TeacherHome
