import React from 'react'
import { Stack, Box, Typography, Skeleton } from '@mui/material'
import { Insights } from './Insights'

import { pxToRem } from '~helpers/getFontValue'
import useFilters from '~hooks/useFilters'

export const Content = () => {
  const { filters } = useFilters()

  return (
    <React.Fragment>
      <Box my={2}>
        <Typography sx={{ fontSize: pxToRem(20), fontWeight: 700 }}>
          Visão geral
        </Typography>

        {filters ? (
          <>
            <Typography variant="body1">
              {`${filters?.curriculum_period_id?.selected?.name}  • `}

              {` ${filters?.subject_id?.selected?.name} • `}

              {`${filters?.grade_id?.selected?.name}  • `}

              {filters?.classroom_id?.selected?.name}
            </Typography>
          </>
        ) : (
          <LegendPlaceholder />
        )}
      </Box>
      <Box my={2}>
        <Insights />
      </Box>
    </React.Fragment>
  )
}

const LegendPlaceholder = () => (
  <Stack direction="row" gap={2} alignItems="center" height={24}>
    <Skeleton variant="text" height={16} width="100px" />
    <Skeleton variant="text" height={16} width="100px" />
    <Skeleton variant="text" height={16} width="100px" />
    <Skeleton variant="text" height={16} width="100px" />
  </Stack>
)
