import { createReducer } from 'reduxsauce'

export const initialState = {
  visit: null,
  visitor: null,
  visitExpires: null,
  visitorExpires: null,
}

const updateVisit = (state, { payload }) => ({
  ...state,
  ...payload,
})

const handlers = { UPDATE_AHOY_VISIT: updateVisit }

export default createReducer(initialState, handlers)
