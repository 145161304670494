import React from 'react'
import { Button, Stack } from '@mui/material'
import { times, omit } from 'ramda'
import {
  CloseButton,
  TooltipBody,
  TooltipTitle,
  TooltipContent,
  TooltipFooter,
  Navigation,
  NavigationItem,
  NavigationButton,
} from './styles'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import useWalkthrough from '~hooks/useWalkthrough'

const Tooltip = ({
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => {
  const { state, setState } = useWalkthrough()

  const setStep = (step) => setState({ ...state, step })

  const nextButtonProps = omit('onClick', { ...primaryProps })
  const prevButtonProps = omit('onClick', { ...backProps })

  return (
    <div {...tooltipProps}>
      <TooltipBody id="walkthrough-tooltip" data-testid="walkthrough-tooltip">
        <CloseButton
          id="walkthrough-close-button"
          data-testid="walkthrough-close-button"
          {...closeProps}
        >
          <SVGIcon name="close" color="neutralLight" size={3} />
        </CloseButton>
        {!!step?.title && (
          <TooltipTitle data-testid="walkthrough-title">
            {step?.title}
          </TooltipTitle>
        )}
        <TooltipContent>{step.content}</TooltipContent>
        <TooltipFooter>
          <Navigation>
            {times(
              (index) => (
                <NavigationItem key={index}>
                  <NavigationButton
                    active={index + 1 === state.step}
                    disabled={index + 1 === state.step}
                    onClick={() => setStep(index + 1)}
                    data-testid={`navigation-button-${index + 1}`}
                  />
                </NavigationItem>
              ),
              size - 1
            )}
          </Navigation>

          <Stack direction="row" spacing={4}>
            {index > 1 && (
              <Button
                variant="transparent"
                size="small"
                id="walkthrough-prev-button"
                data-testid="walkthrough-prev-button"
                onClick={() => setStep(state.step - 1)}
                {...prevButtonProps}
              >
                Anterior
              </Button>
            )}

            <Button
              variant="primary"
              size="small"
              id="walkthrough-next-button"
              data-testid="walkthrough-next-button"
              onClick={() => setStep(state.step + 1)}
              {...nextButtonProps}
            >
              {index < size - 1 ? 'Próximo' : 'Fechar'}
            </Button>
          </Stack>
        </TooltipFooter>
      </TooltipBody>
    </div>
  )
}

export default Tooltip
