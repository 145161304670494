import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpointProfileClassrom = '/api/v3/profiles/grades'

const endpoints = {
  put: (id) => `/api/v3/foco_exam_editions/${id || ''}`,
  post: '/api/v3/foco_exam_editions',
}

export const useProfileClassrooms = () => {
  const { data, loading, error } = useResource({
    key: 'useProfileClassrooms',
    resource: getProfileClassrooms,
  })
  return { data, loading, error }
}

const getProfileClassrooms = () =>
  fetchWithRoot('get')(endpointProfileClassrom, {
    params: {
      fields: [
        {
          grades: ['id', 'name', { subjects: ['id', 'name'] }],
        },
      ],
      include: [{ grades: ['subjects'] }],
    },
  })

export const postManageExam = async ({ data, entity_id }) => {
  const [error] = await fetchWithRoot('post')(endpoints.post, {
    data: {
      entity_type: 'school',
      entity_id: entity_id,
      foco_exam_edition: data,
    },
  })

  if (error) {
    throw new Error(error?.message ?? error?.status)
  }
}

export const putManageExam = async ({ data, entity_id }) => {
  const [error] = await fetchWithRoot('put')(endpoints.put(data?.id), {
    data: {
      entity_type: 'school',
      entity_id: entity_id,
      foco_exam_edition: data,
    },
  })

  if (error) {
    throw new Error(error?.message ?? error?.status)
  }
}
