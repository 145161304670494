import { Formik } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import React, { useState } from 'react'
import { Typography, Stack, Button, Link } from '@mui/material'
import { Form } from '~primitives/Input/Form'
import { TextInput } from '~primitives/Input/Text'
import { NotificationBox } from '~primitives/Messages/NotificationBox'
import { onlyNumbers } from '~helpers/formatting/numbers'
import { track } from '~resources/ahoy'
import { validateCPF } from '~helpers/validators'
import Register from '~resources/register'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import useAhoy from '~hooks/useAhoy'
import useCustomization from '~hooks/useCustomization'

const initialValues = { cpf: '' }

const FormCPF = ({ onSuccess, onOpenModal }) => {
  const [cpfError, setCpfError] = useState(undefined)
  const [submitted, setSubmitted] = useState(false)
  const [userCpf, setUserCpf] = useState('')

  useAhoy('Signup / Number')
  const { custom_preregister_message } = useCustomization()

  const validate = (formValues) => {
    setSubmitted(false)

    if (!validateCPF(formValues.cpf)) {
      return { cpf: 'CPF inválido' }
    }

    return {}
  }

  const errorTexts = {
    [404]: {
      title: 'CPF não cadastrado',
      children: custom_preregister_message ? (
        <div dangerouslySetInnerHTML={{ __html: custom_preregister_message }} />
      ) : (
        <p>
          Seu CPF ainda não foi autorizado pela Secretaria. Envie seus dados
          para pedirmos autorização para seu acesso.
        </p>
      ),
    },
    [403]: {
      title: 'Você já tem um cadastro na Foco',
      children: (
        <p>
          Caso não tenha senha ou não se lembre dela,{' '}
          <Link
            component={RouterLink}
            to={`/recuperar-senha/${userCpf}`}
            data-testid="remember-password"
            id="remember-password"
          >
            clique aqui.
          </Link>
        </p>
      ),
    },
    unknown: {
      title: 'Houve um erro inesperado',
      children: (
        <p>
          Tente novamente, ou entre em contato com o suporte se o erro
          persistir.
        </p>
      ),
    },
  }

  const getErrorText = (status) => errorTexts[status] || errorTexts.unknown

  const handleFormSubmit = (formValues, { setErrors }) => {
    const cpf = onlyNumbers(formValues.cpf)
    setCpfError(undefined)
    setSubmitted(true)
    setUserCpf(cpf)

    Register.validateDocument(cpf).then(([error, data]) => {
      if (error) {
        track('Signup / Number / Error', {
          error_code: error.status,
          error_message: error.message,
        })
        setCpfError(error.status)
        setErrors({ cpf: getErrorText(error.status).title })
      }

      if (data) {
        onSuccess({ name: data.name, cpf })
      }
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleFormSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isValid,
      }) => (
        <Form onSubmit={handleSubmit}>
          <header>
            <Typography component="h2" variant="h2">
              Bem-vindo
            </Typography>
            <Typography component="span" variant="body1">
              Informe seu <strong>CPF</strong>
            </Typography>
          </header>

          <fieldset>
            <ul>
              <li>
                <TextInput
                  type="text"
                  name="cpf"
                  label="CPF"
                  placeholder="Digite seu CPF"
                  mask="000.000.000-00"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cpf}
                  error={errors.cpf}
                  helperText={errors.cpf}
                  variant="outlined"
                />
              </li>

              {!!cpfError && (
                <li>
                  <NotificationBox
                    name="cpf-error"
                    danger
                    {...getErrorText(cpfError)}
                  />
                </li>
              )}
            </ul>

            <footer className="navigation-footer">
              <Stack
                direction={{
                  sm: 'row-reverse',
                  xs: 'column',
                }}
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ flex: 1 }}
              >
                {(![403, 404].includes(cpfError) || !submitted) && (
                  <Button
                    type="submit"
                    id="submit-form"
                    data-testid="submit-form"
                    disabled={!isValid || (cpfError && submitted)}
                    variant="primary"
                    sx={{ width: { xs: 1, sm: 220 } }}
                  >
                    Avançar
                  </Button>
                )}

                {cpfError === 404 && submitted && (
                  <Button
                    onClick={onOpenModal}
                    variant="primary"
                    sx={{ width: { xs: 1, sm: 220 } }}
                  >
                    Pedir acesso à Foco
                  </Button>
                )}

                {cpfError === 403 && submitted && (
                  <Button
                    component={RouterLink}
                    to="/login"
                    variant="primary"
                    sx={{ width: { xs: 1, sm: 220 } }}
                  >
                    Fazer login
                  </Button>
                )}

                <Link component={RouterLink} to="/login">
                  <SVGIcon name="previous" color="neutral" size={1} /> Voltar
                </Link>
              </Stack>
            </footer>
          </fieldset>
        </Form>
      )}
    </Formik>
  )
}

export default FormCPF
