import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

const PrintSkillMap = lazy(() => import('~pages/Print/SkillMap'))

const LoggedInRoutes = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route
        path={[
          '/imprimir/resultados/:feature/:resource',
          '/print/habilities-map/:feature/:resource',
        ]}
        component={PrintSkillMap}
      />
    </Switch>
  </Suspense>
)

export default LoggedInRoutes
