import React from 'react'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Header from '~components/Common/Header'

import { FormJourney } from '~components/Journeys/Form'

export default function JourneysFormPage() {
  const { entityId, id } = useParams()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ px: { xs: 2, sm: 4, md: 8 } }}
      >
        <Header
          title="Cadastrar turno"
          breadcrumbs={[
            {
              text: 'Cadastrar',
              pathname: `/gerenciar-calendario/school/${entityId ?? ''}/turnos`,
            },
            {
              text: 'Turno',
              pathname: `/gerenciar-calendario/school/${entityId ?? ''}/turnos`,
            },
          ]}
        />

        {entityId && (
          <FormJourney entityId={Number(entityId)} journeyId={Number(id)} />
        )}
      </Container>
    </LocalizationProvider>
  )
}
