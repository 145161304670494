export const getCurriculumLink = (profile, resource) => {
  const basePath = `/curriculo/${profile.kind}/${profile.personable.id}`

  if (resource === 'curriculums_management') return basePath + '/listar'

  if (profile.kind === 'school') {
    if (resource === 'calendar') return basePath + '/calendario'
    if (resource === 'events_management') return basePath
  } else {
    if (resource === 'events_management') return basePath + '/gestao'
  }

  return ''
}
