export const getSkillMapLink = (feature, resource, exam_id, profile) =>
  !!profile && profile.kind === 'school'
    ? `/resultados/${feature}/${resource}?exam_id=${exam_id}&resultable_id=${
        profile.personable.id
      }&resultable_type=school`
    : `/selecao/${feature}/${resource}/${exam_id}`

export const getEnemMapLink = (profile) =>
  !!profile && profile.kind === 'school'
    ? `/enem/mapa?resultable_type=school&resultable_id=${profile.personable.id}`
    : '/selecao/enem/map'
