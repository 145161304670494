import React from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import useCurriculumSkills from '~data/curriculum/skills'
import useCurriculumCompetencies from '~data/curriculum/competencies'
import useFilters from '~hooks/useFilters'

export const SkillToPlan = ({
  id,
  status,
  handleSetValues,
  plannedCompetencyId,
  plannedSkillId,
}) => {
  const { filters } = useFilters()

  const {
    data: competenciesData,
    error: competenciesError,
    loading: competenciesLoading,
  } = useCurriculumCompetencies({
    gradeId: filters?.grade_id?.selected?.id,
    subjectId: filters?.subject_id?.selected?.id,
  })

  const {
    data: skillsData,
    error: skillsError,
    loading: skillsLoading,
  } = useCurriculumSkills({
    gradeId: filters?.grade_id?.selected?.id,
    subjectId: filters?.subject_id?.selected?.id,
    competencyId: plannedCompetencyId,
  })

  const handleChangeCurriculumCompetency = (event) => {
    handleSetValues(id, { competencyId: event.target.value, skillId: null })
  }

  const handleChangeCurriculumSkill = (event) => {
    handleSetValues(id, { skillId: event.target.value })
  }

  return (
    <Stack gap={1.5}>
      <Typography variant="body2" fontWeight={700}>
        Selecione a unidade temática e a habilidades que deseja planejar*
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="select-competency-label">Unidade temática</InputLabel>
        <Select
          labelId="select-competency-label"
          id="select-competency"
          name="competency"
          variant="standard"
          value={plannedCompetencyId}
          label="Unidade temática"
          size="small"
          disabled={
            competenciesLoading || competenciesError || status === 'registered'
          }
          onChange={handleChangeCurriculumCompetency}
          MenuProps={{
            style: { zIndex: 9999999 },
            autoFocus: true,
          }}
        >
          {competenciesData?.curriculum_competencies?.length === 0 ? (
            <MenuItem disabled>
              <Typography variant="body2" overflow="hidden">
                Nenhuma unidade temática disponível
              </Typography>
            </MenuItem>
          ) : (
            competenciesData?.curriculum_competencies?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                <Typography variant="body2" overflow="hidden">
                  {name}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="select-skill-label">Habilidade do currículo</InputLabel>
        <Select
          labelId="select-skill-label"
          id="select-skill"
          name="skill"
          variant="standard"
          value={plannedSkillId}
          label="Habilidade do currículo"
          size="small"
          disabled={
            skillsLoading ||
            skillsError ||
            !plannedCompetencyId ||
            status === 'registered'
          }
          onChange={handleChangeCurriculumSkill}
          MenuProps={{
            style: { zIndex: 9999999 },
            autoFocus: true,
          }}
        >
          {skillsData?.curriculum_skills?.length === 0 ? (
            <MenuItem disabled>
              <Typography variant="body2" overflow="hidden">
                Nenhuma habilidade de currículo disponível
              </Typography>
            </MenuItem>
          ) : (
            skillsData?.curriculum_skills?.map(({ id, name, description }) => (
              <MenuItem key={id} value={id}>
                <Typography variant="body2" my={1} overflow="hidden">
                  <strong>({name})</strong> {description}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Stack>
  )
}
