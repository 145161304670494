import React from 'react'
import { Skeleton, Stack, Typography } from '@mui/material'
import { AssessmentLevelLabel } from './Label'

export const AssessmentLevel = ({ level, loading }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      height: { xs: 50, md: 80 },
      borderRadius: 2,
      bgcolor: 'grey.0',
      px: 2.5,
    }}
  >
    {loading ? (
      <React.Fragment>
        <Stack>
          <Skeleton variant="text" width={120} height={14} />
          <Skeleton variant="text" width={100} height={12} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Skeleton variant="circular" width={12} height={12} />
          <Skeleton variant="text" width={44} height={16} />
        </Stack>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Stack>
          <Typography variant="body2" fontWeight={700}>
            Nível da avaliação
          </Typography>
          <Typography variant="caption">(por taxa de acerto)</Typography>
        </Stack>
        {level && <AssessmentLevelLabel level={level} />}
      </React.Fragment>
    )}
  </Stack>
)
