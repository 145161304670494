import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Container, Paper, Grid, Stack, Box, Link } from '@mui/material'
import { AuthSection, ResponsivePicture } from './styles'
import AuthRoutes from '~routes/Auth'
import useLoggedOutGuards from '~hooks/useLoggedOutGuards'
import AuthIllustration from '~assets/svg/illustrations/auth-illustration.svg'
import AuthIllustrationMobile from '~assets/svg/illustrations/auth-illustration-mobile.svg'

const AuthTemplate = () => {
  useLoggedOutGuards()

  return (
    <AuthSection>
      <Container maxWidth="lg">
        <Paper
          elevation={20}
          sx={{
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          <Grid
            container
            spacing={2}
            flexWrap={{ md: 'nowrap' }}
            sx={{ maxHeight: { md: '660px', lg: '660px' } }}
          >
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <Link to="/" component={RouterLink}>
                <ResponsivePicture>
                  <source
                    media="(max-width: 979px)"
                    srcSet={AuthIllustrationMobile}
                  />
                  <img src={AuthIllustration} alt="Sigae" loading="lazy" />
                </ResponsivePicture>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={7}
              py={3}
              sx={{ overflowY: { md: 'auto' } }}
            >
              <Stack justifyContent="center" sx={{ minHeight: '100%' }}>
                <Box
                  sx={{
                    px: { lg: 15, md: 5, sm: 2, xs: 2 },
                  }}
                >
                  <AuthRoutes />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </AuthSection>
  )
}

export default AuthTemplate
