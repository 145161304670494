import { mapObjIndexed, path, omit, keys, indexOf, splitAt } from 'ramda'
import React, { useState, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import Tooltip from '@mui/material/Tooltip'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import { Container, Grid, FilterWrapper, ToggleFilter } from './styles'
import useFilters from '~hooks/useFilters'
import { FlashMessage } from '~primitives/Messages/FlashMessage'
import FiltersPlaceholder from '~assets/placeholders/filters-placeholder.svg'
import usePrevious from '~hooks/usePrevious'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const getSelected = mapObjIndexed(path(['selected', 'id']))

const getOmitList = (key, selection) => {
  const list = keys(selection)
  const index = indexOf(key, list)
  if (index >= 0) return splitAt(index + 1, list)[1]
  return []
}

const FilterBar = ({
  onChange = () => {},
  margin = 0,
  maxWidth = '960px',
  contained = false,
  headingText,
  wrapperProps = {},
  reloadOnChange = true,
}) => {
  const { loading, error, filters, updateParams, requestFilters } = useFilters()
  const [selected, setSelected] = useState(getSelected(filters))
  const [[bufferError, bufferOptions], setBuffer] = useState([null, null])
  const [bufferLoading, setBufferLoading] = useState(false)
  const [isModified, setIsModified] = useState(false)
  const disableInputs = loading || bufferLoading
  const [displayFilters, setDisplayFilters] = useState(false)
  const [filtersToOmit, setFiltersToOmit] = useState([])

  const bufferDefaults = mapObjIndexed(
    (options = []) => options[0]?.id,
    bufferOptions
  )

  const prevFilters = usePrevious(filters)

  useEffect(() => {
    if (!isEqual(filters, prevFilters)) {
      setSelected(getSelected(filters))
      setBuffer([null, null])
      setFiltersToOmit([])
    }
  }, [filters, prevFilters])

  const getUpdatedSelection = (selection, updated) => {
    if (reloadOnChange) {
      const keysToOmit = getOmitList(updated.name, selection)
      return { ...omit(keysToOmit, selection), [updated.name]: updated.value }
    }

    return { ...selection, [updated.name]: updated.value }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setIsModified(true)
    const newSelection = getUpdatedSelection(selected, { name, value })
    setSelected(newSelection)

    if (reloadOnChange) {
      setBufferLoading(true)
      requestFilters(newSelection).then((response) => {
        setBuffer(response)
        setBufferLoading(false)
        if (response[1])
          setFiltersToOmit(
            Object.entries(response[1])
              .filter((item) => item[1] === undefined)
              .map((item) => item[0])
          )
      })
    }
  }

  const handleSubmit = () => {
    setDisplayFilters(false)
    setIsModified(false)
    updateParams({ ...bufferDefaults, ...selected })
    onChange(selected)
  }

  if (error || bufferError) {
    return (
      <FlashMessage color="danger">
        Houve um erro ao buscar os filtros para sua seleção
      </FlashMessage>
    )
  }

  if (!filters) {
    return (
      <Container
        id="filter-bar"
        data-testid="filter-bar"
        contained={contained}
        margin={margin}
      >
        <Stack
          spacing={2}
          sx={{
            display: { xs: displayFilters ? 'flex' : 'none', md: 'grid' },
            width: contained ? '100%' : maxWidth,
            margin: '0 auto',
            paddingBottom: '20px',
          }}
        >
          {headingText && (
            <Typography variant="body2" sx={{ padding: { xs: 2, md: 0 } }}>
              {headingText}
            </Typography>
          )}
          <Grid maxWidth={contained ? '100%' : maxWidth}>
            <img
              data-testid="filters-placeholder"
              src={FiltersPlaceholder}
              alt="Carregando informações de filtros"
            />
          </Grid>
        </Stack>
      </Container>
    )
  }

  const filterList = Object.entries(filters).filter(
    (select) => !filtersToOmit.includes(select[0])
  )

  const toggleFilters = () => {
    displayFilters ? setDisplayFilters(false) : setDisplayFilters(true)
  }

  return (
    <Container
      id="filter-bar"
      data-testid="filter-bar"
      contained={contained}
      margin={margin}
    >
      <ToggleFilter>
        <Typography variant="body2">Filtrar por resultados</Typography>
        <button
          type="button"
          onClick={toggleFilters}
          className={displayFilters ? 'active' : ''}
        >
          <SVGIcon
            name="options"
            color={displayFilters ? 'hoverColor' : 'linkColor'}
          />
        </button>
      </ToggleFilter>
      <Stack
        spacing={2}
        sx={{
          display: { xs: displayFilters ? 'flex' : 'none', md: 'grid' },
          maxWidth: contained ? '100%' : maxWidth,
          margin: '0 auto',
        }}
      >
        {headingText && (
          <Typography variant="body2" sx={{ padding: { xs: 2, md: 0 } }}>
            {headingText}
          </Typography>
        )}

        <Grid>
          {filterList.map(([name, filter]) => {
            const options = bufferOptions?.[name] || filter.options

            const formattedOptions = options.map(({ id, name, disabled }) => ({
              id,
              name: String(name),
              disabled,
            }))

            const selectedFilter = formattedOptions.find(
              (option) => String(option.id) === String(selected[name])
            )

            const value = selectedFilter ? selectedFilter.id : options[0]?.id

            return (
              <FilterWrapper
                key={name}
                filtersLength={filterList.length}
                data-filtersLength={filterList.length}
                {...wrapperProps}
              >
                <Tooltip
                  arrow={true}
                  title={
                    options.length <= 1 &&
                    name === 'classroom_id' &&
                    selected.year === '99'
                      ? 'Para filtrar por turma, por favor selecione um ano específico no filtro'
                      : ''
                  }
                >
                  <FormControl
                    key={name}
                    sx={{ width: 1 }}
                    variant="standard"
                    size="small"
                  >
                    <InputLabel>{filter.label}</InputLabel>
                    <Select
                      data-testid={`select-${name}`}
                      disabled={disableInputs || options.length <= 1}
                      value={value}
                      name={name}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': filter.label }}
                    >
                      {options.map(({ name, id }) => (
                        <MenuItem
                          key={id}
                          value={id}
                          teste={`Name-${name}-Id-${id}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Tooltip>
              </FilterWrapper>
            )
          })}
          <Button
            variant="primary"
            size="small"
            data-testid="submit-filters"
            onClick={handleSubmit}
            disabled={!isModified || disableInputs}
          >
            Filtrar
          </Button>
        </Grid>
      </Stack>
    </Container>
  )
}

export default FilterBar
