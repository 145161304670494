import React from 'react'
import qs from 'qs'
import { useLocation } from 'react-router-dom'

import useFilters from '~hooks/useFilters'
import useEntity from '~data/entity'
import useSchoolList from '~data/focoExams/teacher/schoolList'
import { Insights } from '~components/FocoExams/Teacher/Insights'
import ListHeader from '~components/FocoExams/Teacher/SectorContent/List/Header'
import ListTable from '~components/FocoExams/Teacher/SectorContent/List/Table'
import { Pagination } from '~primitives/molecules/Pagination'

const Content = ({ entityType, entityId }) => {
  const { filters } = useFilters()
  const { data: entityData } = useEntity(entityType, String(entityId))
  const search = qs.parse(useLocation().search)
  const page = search.page || search['?page']
  const order = search.order || search['?order']
  const orderBy = search.orderBy || search['?orderBy']
  const { error, data, examData } = useSchoolList({
    entityId: Number(entityId),
    gradeId: filters?.grade_id.selected?.id,
    subjectId: filters?.subject_id.selected?.id,
    districtId: filters?.foco_exam_edition_id?.selected.id,
    page: page ? Number(page) : 1,
    order,
    orderBy,
  })

  if (error) return `Erro! ${error.message || ''}`

  if (!data || !examData) return 'Carregando...'

  return (
    <>
      <Insights
        focoExamId={examData?.foco_exams[0]?.id}
        entityType={entityType}
        entityId={Number(entityId)}
      />

      {entityData && (
        <ListHeader
          entityData={entityData}
          listLength={data?.schools?.length}
        />
      )}

      <ListTable list={data?.schools} />
      <Pagination
        totalPages={data?.meta?.total_pages || 1}
        currentPage={page ? Number(page) : 1}
      />
    </>
  )
}

export default Content
