import {
  Avatar,
  Box,
  Stack,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useFeedback } from '~contexts/Feedback'
import { patchAnswer } from '~data/focoExams/student/patchAnswer'

const imgStyles = {
  width: '100%',
  height: 'auto',
  margin: '16px 0',
}

export const Question = ({
  statement,
  command,
  reference,
  supportImagePath,
  initialOptionId,
  options,
  answerId,
  handleNewSelectOptionId,
}) => {
  const { showMessage } = useFeedback()

  const [selectedOptionId, setSelectedOptionId] = useState(initialOptionId)

  const handleChangeOption = (event) => {
    const value = Number(event.target.value)
    setSelectedOptionId(value)
    handleNewSelectOptionId(answerId, value)
    patchAnswer({
      answerId,
      optionId: value,
    }).catch((err) => {
      showMessage({ text: err.message, type: 'error' })
    })
  }

  return (
    <React.Fragment>
      <Box mt={3} mb={6}>
        {statement && <Typography variant="body2">{statement}</Typography>}
        {supportImagePath && (
          <img
            src={`${ENV_VARS.FOCO_API_HOST}/${supportImagePath}`}
            alt="Imagem representativa da questão"
            style={imgStyles}
          />
        )}

        {reference && <Typography variant="body2">{reference}</Typography>}

        {command && <Typography variant="body2">{command}</Typography>}
      </Box>
      <FormControl fullWidth>
        <RadioGroup
          onChange={handleChangeOption}
          aria-labelledby="question-radio-buttons-group-label"
          name="question-radio-buttons-group"
          sx={{ gap: 1 }}
        >
          {options.map((option) => (
            <Stack
              key={option.id}
              direction="row"
              alignItems="center"
              gap={2}
              pl={1.5}
              minHeight={46}
              border={'solid 1px'}
              borderColor="grey.300"
            >
              <Avatar
                sx={{
                  height: 28,
                  width: 28,
                  fontSize: 14,
                  fontWeight: 700,
                  bgcolor: 'grey.800',
                }}
              >
                {option.alternative}
              </Avatar>
              <FormControlLabel
                checked={option.id === selectedOptionId}
                value={option.id}
                control={<Radio />}
                label={option.text}
              />
            </Stack>
          ))}
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  )
}
