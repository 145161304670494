import React, { createContext, useContext, useState } from 'react'
import { FeedbackSnackbar } from '~primitives/atoms/FeedbackSnackbar'

export const FeedbackContext = createContext({
  showMessage: () => {},
})

export const FeedbackProvider = ({ children }) => {
  const [messages, setMessages] = useState([])

  const handleCloseMessage = (deleteId) => {
    setMessages((currentMessages) =>
      currentMessages.filter(({ id }) => id !== deleteId)
    )
  }

  const showMessage = (message) => {
    const id = Date.now().toString()
    setMessages((currentMessages) => [...currentMessages, { ...message, id }])
  }

  return (
    <FeedbackContext.Provider value={{ showMessage }}>
      {children}
      {messages.map(({ type, text, id }, i) => (
        <FeedbackSnackbar
          key={id}
          id={id}
          type={type}
          text={text}
          onClose={handleCloseMessage}
          isOpen={messages.length - 1 === i}
        />
      ))}
    </FeedbackContext.Provider>
  )
}

export const useFeedback = () => useContext(FeedbackContext)
