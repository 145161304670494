import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

const getEndpoint = (schoolId) =>
  `api/v3/schools/${schoolId}/curriculum_periods`

export const useCurriculumPeriodsBySchool = ({ schoolId }) => {
  const { error, data, loading } = useResource({
    resource: getCurriculumPeriodsBySchool,
    params: { schoolId },
    key: 'useCurriculumPeriodsBySchool',
  })

  return { error, data, loading }
}

const getCurriculumPeriodsBySchool = ({ schoolId }) =>
  fetchWithRoot('get')(getEndpoint(String(schoolId)), {
    params: {
      include: ['curriculum_periods'],
      fields: ['id', 'name'],
    },
  })
