export default function ListItemButton(theme) {
  return {
    MuiListItemButton: {
      variants: [
        {
          props: { variant: 'outlinedSecondary' },
          style: {
            backgroundColor: theme.palette.grey[0],
            border: `solid 1px ${theme.palette.secondary.main}`,
            minWidth: 34,
            height: 34,
            justifyContent: 'center',
            borderRadius: '50%',
            padding: 0,
            '& .MuiListItemIcon-root': {
              margin: 0,
            },
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.grey[0],
            },
            '& .MuiListItemIcon-root > i': {
              transition: 'background-color 150ms',
            },
            '&:hover .MuiListItemIcon-root > i': {
              backgroundColor: theme.palette.grey[0],
            },
            '&.Mui-disabled': {
              border: `solid 1px ${theme.palette.action.disabled}`,
              '& > i': {
                backgroundColor: theme.palette.action.disabled,
              },
            },
          },
        },
      ],
    },
  }
}
