export default function Stack(theme) {
  return {
    MuiStack: {
      variants: [
        {
          props: { variant: 'modalBox' },
          style: {
            backgroundColor: theme.palette.grey[200],
            borderRadius: theme.shape.borderRadius * 5,
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(2.5),
          },
        },
      ],
    },
  }
}
