import React from 'react'
import { Box } from '@mui/material'

import filters from '~resources/filters'
import { FiltersProvider } from '~contexts/Filters'
import Content from '~components/FocoExams/Student/Results/Content'

const ExamResults = () => {
  return (
    <FiltersProvider
      resource={filters.getJourneyFiltersResults}
      config={{
        foco_exam_edition_id: {
          filter: 'foco_exam_editions',
          label: 'Tipo de avaliação',
        },
        subject_id: { filter: 'subjects', label: 'Disciplina' },
      }}
    >
      <Box sx={{ padding: '0px 70px' }}>
        <Content />
      </Box>
    </FiltersProvider>
  )
}

export default ExamResults
