import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const StatusBar = ({
  examEditionName,
  subjectName,
  gradeName,
  currentQuestionIndex,
  amountOfQuestions,
  handleOpenSubmitModal,
}) => {
  const { palette } = useTheme()

  return (
    <Stack
      bgcolor="grey.200"
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      borderRadius={2}
      p={2}
      my={2}
    >
      <Box>
        <Typography variant="h5" component="h3" fontWeight={400}>
          <strong>
            {examEditionName} - {subjectName} -
          </strong>{' '}
          {gradeName}
        </Typography>
      </Box>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack direction="row" alignItems="center" gap={1}>
          <SVGIcon name="gridDots" color={palette.secondary.lighter} size={0} />
          <Typography variant="body2">
            {currentQuestionIndex + 1}/{amountOfQuestions}
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          onClick={handleOpenSubmitModal}
          sx={{
            height: 36,
            minWidth: 200,
            gap: 1,
          }}
        >
          <SVGIcon name="paperPlaneFilled" color={palette.secondary.main} />
          Finalizar avaliação
        </Button>
      </Stack>
    </Stack>
  )
}
