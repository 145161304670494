import { fetchWithRoot } from '~helpers/fetch'

export const endpoints = {
  home: '/api/v2/home',
  state: '/api/v2/state',
}

export const getHome = () =>
  fetchWithRoot('get')(endpoints.home, { bypassLoader: true })

export const getProfile = () =>
  fetchWithRoot('get')(endpoints.state, { bypassLoader: true })
