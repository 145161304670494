export const getManagementLink = (profile, resource) => {
  const entityType = profile.kind
  const entityId = profile.personable.id

  if (resource === 'academic_years') {
    return `/gerenciar-calendario/${entityType}/${entityId}/anos-letivos`
  }

  if (resource === 'classrooms') {
    return `/gerenciar-turmas/${entityType}/${entityId}`
  }

  if (resource === 'journeys') {
    return `/gerenciar-calendario/${entityType}/${entityId}/turnos`
  }

  return ''
}
