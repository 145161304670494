import React, { useState } from 'react'
import Balloon from '~primitives/Tooltip/Balloon'
import { formatDecimalNumber } from '~helpers/formatting/numbers'

const usePlotlines = ({
  lines: plotLines,
  getPosition = (value) => value - 0.5,
  format = formatDecimalNumber(1),
}) => {
  const [tooltip, setTooltip] = useState({ show: false, x: 0, y: 0, value: 0 })

  const series = plotLines.map(({ name, color }) => ({
    name,
    color,
    grouping: false,
  }))

  const lines = plotLines.map(({ value, ...props }) => ({
    ...props,
    value: getPosition(value),
    tooltipText: format(value),
    width: 3,
    events: {
      mouseover: function (event) {
        !tooltip.show &&
          setTooltip({ value, show: true, x: event.layerX, y: event.layerY })
      },
      mouseout: () => {
        setTooltip({ show: false, x: 0, y: 0, value: 0 })
      },
    },
  }))

  const Tooltip = () =>
    tooltip.show && (
      <Balloon
        arrow="left"
        padding="4px 8px"
        width="55px"
        style={{
          left: tooltip.x + 15,
          top: tooltip.y - 18,
          textAlign: 'center',
          fontSize: '14px',
        }}
      >
        {format(tooltip.value)}
      </Balloon>
    )

  return { series, lines, Tooltip }
}

export default usePlotlines
