import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const getEndpoint = (questionId) => `api/v3/foco_questions/${questionId}`

export const useQuestion = (params) => {
  const { data, loading, error } = useResource({
    key: 'useQuestion',
    params,
    enabled: !!params?.questionId,
    resource: getQuestion,
  })

  return { data, loading, error }
}

const getQuestion = (params) =>
  fetchWithRoot('get')(getEndpoint(params?.questionId), {
    params: {
      include: ['curriculum_skill'],
      fields: [
        'text',
        'id',
        'code',
        { curriculum_skill: ['name', 'description', 'id'] },
      ],
    },
  })
