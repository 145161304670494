import { Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import _ from 'lodash'
import { options } from '../../helpers'
import { getStyles } from './styles'

export const Option = ({ name, value, selected, setSelected }) => {
  const theme = useTheme()
  const isActive = name === selected
  const color = options[name].color
  const styles = getStyles(theme, color, isActive)

  const handleClick = () => {
    if (isActive) {
      setSelected('')
      return
    }
    setSelected(name)
  }

  return (
    <Button
      disableRipple
      data-testid={`filter-button-${_.kebabCase(name)}`}
      onClick={handleClick}
      sx={{ ...styles.container }}
    >
      <Typography component="label" variant="body2" sx={{ ...styles.title }}>
        {options[name].title}
      </Typography>
      <Typography component="span" sx={{ ...styles.value }}>
        {value ?? '-'}
      </Typography>
    </Button>
  )
}
