import React from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { Scribble } from '~primitives/Typography/Underline'
import { pxToRem } from '~helpers/getFontValue'
import Modal from '~primitives/Modal'
import { config } from '~data/api/v3/studentProfiles/config'
import SuccessCheck from '~assets/svg/success_check.svg'

export const StudentModalDelete = ({
  schoolId,
  requestOnSubmit,
  selectedRows = [],
  handleClose,
}) => {
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const queryClient = useQueryClient()
  const { key } = config

  const { mutate } = useMutation(
    () =>
      requestOnSubmit({
        studentProfileId: selectedRows.join(','),
        params: {
          entityType: 'school',
          entityId: schoolId,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key)
        setIsSuccess(true)
        setIsSubmitting(false)
      },
      onError: () => {
        setIsSubmitting(false)
      },
    }
  )

  const handleSubmitDelete = () => {
    setIsSubmitting(true)
    mutate()
  }

  return (
    <Modal
      width="520px"
      height="50vh"
      confirmButtonText="Deletar"
      overflowContent={true}
      contentPadding="15px"
      showFooter={false}
      footerPadding="20px 35px 30px"
      onClose={handleClose}
    >
      <Typography
        sx={{ fontSize: pxToRem(24), fontWeight: 700, my: pxToRem(6) }}
      >
        <Scribble>Deletar alunos</Scribble>
      </Typography>

      {!isSuccess && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f4f4f4',
              borderRadius: '10px',
            }}
          >
            <Typography
              sx={{ fontSize: pxToRem(14), fontWeight: 500, my: pxToRem(35) }}
            >
              Tem certeza que você deseja deletar{' '}
              <strong>
                {selectedRows?.length} {' aluno(s)'}
              </strong>{' '}
              da sua lista?{' '}
            </Typography>
          </Box>
          <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
            <Button onClick={handleClose} variant="neutral">
              Cancelar
            </Button>
            <Button
              onClick={handleSubmitDelete}
              variant="primary"
              sx={{ maxWidth: 170, width: 1 }}
              disabled={isSubmitting}
            >
              Excluir
            </Button>
          </Stack>
        </>
      )}

      {isSuccess && (
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{ bgcolor: 'grey.200', px: 4, height: 85 }}
          >
            <img src={SuccessCheck} alt="" width="24px" />
            <Typography variant="body2">
              {'Aluno(s) deletado(s) com sucesso'}
            </Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
            <Button
              variant="primary"
              onClick={handleClose}
              sx={{ maxWidth: 170, width: 1 }}
            >
              Voltar para a lista
            </Button>
          </Stack>
        </Box>
      )}
    </Modal>
  )
}
