import dayjs from 'dayjs'
import React from 'react'
import { Box, Grid, Stack, Typography, Divider, useTheme } from '@mui/material'
import { ExamCard } from './ExamCard'
import { Scribble } from '~primitives/Typography/Underline'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const EditionCard = ({ title, startsAt, endsAt, exams, id }) => {
  const theme = useTheme()

  return (
    <Box
      component="section"
      data-testid={`edition-card-${id}`}
      sx={{ px: 3, py: 2, bgcolor: 'grey.200', mb: 5, borderRadius: '4px' }}
    >
      <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
        <Scribble>{title}</Scribble>
      </Typography>

      <Box sx={{ mt: 4, px: 3, py: 2, bgcolor: 'grey.0' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <SVGIcon name="date" color={theme.palette.primary.light} />
          <Typography fontSize={14} lineHeight={1}>
            <strong>Período de realização:</strong> de{' '}
            <strong>{dayjs(startsAt).format('DD/MM')}</strong> (
            {dayjs(startsAt).format('HH:mm')}) até{' '}
            <strong>{dayjs(endsAt).format('DD/MM')}</strong> (
            {dayjs(endsAt).format('HH:mm')})
          </Typography>
        </Stack>

        <Divider sx={{ my: 1 }} />

        <Grid container spacing={1.5} alignItems="stretch">
          {exams?.map(
            ({
              id,
              foco_exam_student_profile_id,
              name,
              subject_name,
              available,
              answered,
              started,
              answered_count,
              questions_count,
              estimated_time,
              expires_soon,
            }) => (
              <Grid item xs={12} sm={6} md={4} key={id}>
                <ExamCard
                  id={foco_exam_student_profile_id}
                  name={name}
                  subject={subject_name}
                  available={available && !answered}
                  started={started && !answered && available}
                  answered={answered}
                  expiresSoon={expires_soon && !answered}
                  unperformed={new Date(endsAt) < new Date() && !answered}
                  estimatedTime={estimated_time}
                  answeredCount={answered_count}
                  questionsCount={questions_count}
                />
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </Box>
  )
}
