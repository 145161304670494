import React from 'react'
import { Stack, Typography, useTheme } from '@mui/material'

export const Placeholder = () => {
  const { palette, shape } = useTheme()
  const styles = getStyles(palette, shape)

  return (
    <Stack justifyContent="center" sx={{ ...styles.placeholder }}>
      <Typography variant="body2" color="action.disabled">
        Nenhuma questão adicionada
      </Typography>
    </Stack>
  )
}

const getStyles = (palette, shape) => ({
  placeholder: {
    height: 105,
    borderRadius: shape.borderRadius,
    bgcolor: palette.common.white,
    pl: 3,
  },
})
