import React from 'react'
import { useTheme } from '@mui/material'
import { ColumnChart } from '~primitives/Charts/Column'

export const Chart = ({ proficiency }) => {
  const { palette } = useTheme()

  const { insufficient, below_basic, basic, proficient, advanced } = proficiency
  const totalStudents =
    insufficient + below_basic + basic + proficient + advanced

  return (
    <ColumnChart
      series={[
        {
          data: [
            { name: 'Insuficiente', y: insufficient },
            { name: 'Abaixo do básico', y: below_basic },
            { name: 'Básico', y: basic },
            { name: 'Proficiente', y: proficient },
            { name: 'Avançado', y: advanced },
          ],
          color: palette.resultables.school,
        },
      ]}
      maximum={totalStudents}
      height={150}
      alignLegend="left"
      tooltipDecimalPlaces={0}
      noLegend
      labelColumns
      noGrouping
      seriesAnimation
    />
  )
}
