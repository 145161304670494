import React, { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { deleteCurriculum } from './fetch'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import SuccessCheck from '~assets/svg/success_check.svg'
import DeleteCheck from '~assets/svg/delete_check.svg'
import { useFeedback } from '~contexts/Feedback'

function DeleteModal({ handleCloseModal, curriculumId, entityId, entityType }) {
  const [isSuccess, setIsSuccess] = useState(false)
  const queryClient = useQueryClient()
  const { showMessage } = useFeedback()

  const { mutate } = useMutation(
    () => deleteCurriculum({ curriculumId, entityId, entityType }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useManagementBaseCurriculums')
        setIsSuccess(true)
      },
      onError: (error) => {
        showMessage({ type: 'error', text: `Erro: ${error.message}` })
      },
    }
  )

  const handleConfirm = () => {
    if (isSuccess) {
      handleCloseModal()
    } else {
      mutate()
    }
  }

  return (
    <Modal
      width="600px"
      height="98vh"
      overflowContent={true}
      onClose={handleCloseModal}
      closeButton={!isSuccess}
      onConfirm={handleConfirm}
      confirmButtonText={isSuccess ? 'Voltar para a lista' : 'Confirmar'}
      showFooterDivider={false}
      footerPadding="0 35px 25px"
    >
      <>
        <Typography variant="h4" margin="15px 0">
          <UnderlinedHeading as="span">
            <strong>Deletar currículo</strong>
          </UnderlinedHeading>
        </Typography>

        <Box>
          <Stack variant="modalBox">
            {isSuccess ? (
              <>
                <img src={SuccessCheck} alt="" width="90px" />
                <Typography variant="h5">
                  Currículo deletado com sucesso!
                </Typography>
              </>
            ) : (
              <>
                <img src={DeleteCheck} alt="" width="90px" />
                <Typography variant="h5">
                  Tem certeza que deseja deletar este currículo?
                </Typography>
              </>
            )}
          </Stack>
        </Box>
      </>
    </Modal>
  )
}

export default DeleteModal
