import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Stack, Typography } from '@mui/material'
import { FiltersProvider } from '~contexts/Filters'
import FilterBar from '~components/Common/FilterBar'
import filters from '~resources/filters'
import DistrictContent from '~components/FocoExams/Teacher/DistrictContent'

const FocoExamDistrict = () => {
  const { entityType, entityId } = useParams()

  return (
    <Container maxWidth={false} sx={{ maxWidth: '920px' }}>
      <FiltersProvider
        resource={filters.getFocoExamsFilters}
        config={{
          foco_exam_edition_id: {
            filter: 'foco_exam_editions',
            label: 'Avaliação',
          },
          subject_id: { filter: 'subjects', label: 'Disciplina' },
          grade_id: { filter: 'grades', label: 'Série' },
        }}
        additionalParams={{
          entityType: entityType,
          entityId: entityId,
        }}
      >
        <Stack flexDirection="column" alignItems="center">
          <Typography
            variant="h6"
            component="h1"
            sx={{ color: 'grey.800', mb: 1 }}
          >
            Painel de avaliações
          </Typography>
          <Typography sx={{ color: 'grey.800', mb: 2.5 }}>
            Veja todas as avaliações da sua rede
          </Typography>
        </Stack>

        <FilterBar contained />

        <DistrictContent entityType={entityType} entityId={entityId} />
      </FiltersProvider>
    </Container>
  )
}

export default FocoExamDistrict
