import { Button } from '@mui/material'
import React from 'react'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const AbortButton = ({ onClick }) => {
  return (
    <Button variant="transparent" onClick={onClick}>
      <SVGIcon name="close" color="neutralLight" size={5} />
    </Button>
  )
}
