import React, { useState } from 'react'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import {
  Stack,
  Typography,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
  IconButton,
  ToggleButton,
  Button,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { Scribble } from '~primitives/Typography/Underline'
import { pxToRem } from '~helpers/getFontValue'
import useResponsive from '~hooks/useResponsive'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import CreateCurriculum from '~components/Curriculum/Modals/CreateCurriculum'

const CalendarAside = ({
  filtersGrade,
  setFiltersGrade,
  onChangeCalendar,
  date,
  filtersIdsGrade,
  setFilterIsOpen,
  view,
  onChangeView,
}) => {
  const handleChangeGrade = (index) => {
    const prev = [...filtersGrade]
    prev[index].checked = !prev[index].checked

    setFiltersGrade(prev)
  }

  const handleChangeAllGrade = (e) => {
    const prev = filtersGrade.map((item) => {
      return { id: item.id, name: item.name, checked: e.target.checked }
    })
    setFiltersGrade(prev)
  }

  const isDesktop = useResponsive({ query: 'up', key: 'lg' })

  const { entityId } = useParams()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {!isDesktop && (
          <IconButton
            onClick={() => {
              setFilterIsOpen(false)
            }}
            aria-label="fechar"
            sx={{ position: 'absolute', right: 10, top: 10 }}
          >
            <SVGIcon name="close" size={5} color="text" />
          </IconButton>
        )}
        <Stack direction="column" spacing={2}>
          <Typography
            sx={{ fontSize: pxToRem(24), fontWeight: 700, my: pxToRem(6) }}
          >
            <Scribble>Calendário</Scribble>
          </Typography>

          <Button
            onClick={() => {
              setModalIsOpen(true)
            }}
            variant="primary"
            size="small"
            startIcon={<SVGIcon name="addCircle" color="white" />}
            sx={{
              minWidth: '215px',
              width: '215px',
              display: 'flex',
              float: { xs: 'none', sm: 'right' },
            }}
          >
            Adicionar plano de aula
          </Button>

          {isDesktop ? (
            <DateCalendar
              views={['day']}
              defaultValue={dayjs()}
              value={dayjs(date)}
              variant="small"
              onChange={onChangeCalendar}
            />
          ) : (
            <Box>
              <Typography sx={{ fontWeight: 700 }} variant="body2">
                Visualizar por
              </Typography>
              <Divider sx={{ my: pxToRem(2) }} />
              <Stack direction="column" spacing={2} sx={{ my: 2 }}>
                <ToggleButton
                  size="small"
                  sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    border: 0,
                    gap: 1,
                  }}
                  onClick={() => {
                    onChangeView('timeGridWeek')
                  }}
                  selected={view === 'timeGridWeek'}
                >
                  <SVGIcon
                    name="weekFilter"
                    size={2}
                    color="backgroundNeutral"
                  />
                  Semana
                </ToggleButton>

                <ToggleButton
                  size="small"
                  sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    border: 0,
                    gap: 1,
                  }}
                  onClick={() => {
                    onChangeView('timeGridDay')
                  }}
                  selected={view === 'timeGridDay'}
                >
                  <SVGIcon
                    name="dayFilter"
                    size={2}
                    color="backgroundNeutral"
                  />
                  Dia
                </ToggleButton>
              </Stack>
            </Box>
          )}

          <Box>
            <Typography sx={{ fontWeight: 700 }} variant="body2">
              Filtrar por
            </Typography>
            <Divider sx={{ my: pxToRem(2) }} />
            <Typography sx={{ fontWeight: 700 }} variant="body2">
              Ano
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px 0px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    label="Todos"
                    value="Todos"
                    defaultChecked={true}
                    checked={filtersIdsGrade?.length == filtersGrade?.length}
                    disableRipple
                    color="secondary"
                    onChange={handleChangeAllGrade}
                  />
                }
                label="Todos"
              />
              {filtersGrade.map(({ name, id, checked }, index) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        label={name}
                        value={id}
                        checked={checked}
                        disableRipple
                        color="secondary"
                        onChange={() => handleChangeGrade(index)}
                      />
                    }
                    label={name}
                    key={index}
                  />
                )
              })}
            </Box>
          </Box>
        </Stack>
      </LocalizationProvider>
      {modalIsOpen}
      {entityId && modalIsOpen && (
        <CreateCurriculum entityId={entityId} setModalIsOpen={setModalIsOpen} />
      )}
    </>
  )
}

export default CalendarAside
