import { styled } from '@mui/material/styles'

export const PaginationUl = styled('ul')`
  display: flex;
  justify-content: space-around;
  margin: 10px auto 30px;
  width: max-content;

  li {
    font-size: 14px;

    a {
      padding: 4px 8px;
      color: ${({ theme }) => theme.palette.foco.backgroundPrimary};
      text-decoration: none;
      border-radius: 2px;

      &:hover,
      &:focus {
        background: ${({ theme, inverted }) =>
          inverted
            ? theme.palette.foco.white
            : theme.palette.foco.backgroundLight};
        color: ${({ theme }) => theme.palette.foco.linkColor};
      }
    }

    [aria-current] {
      background: ${({ theme, inverted }) =>
        inverted
          ? theme.palette.foco.white
          : theme.palette.foco.backgroundLight};
    }
  }
`
