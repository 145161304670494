import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/foco_exams'

export const useManageExamsBooklets = (params) => {
  const {
    data: dataBooklets,
    error,
    loading,
  } = useResource({
    key: 'useBookletsList',
    resource: getManageExamsBooklets,
    params,
  })

  return { loading, error, dataBooklets }
}

const getManageExamsBooklets = (params) => {
  const foco_exam_edition_id_eq =
    Number(params.foco_exam_edition_id) !== 0
      ? params.foco_exam_edition_id
      : undefined

  return fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      entity_type: 'school',
      entity_id: params?.entityId,
      include: [{ foco_exams: ['classrooms'] }],
      fields: [
        {
          foco_exams: [
            'id',
            'name',
            'status',
            'amount_of_student',
            'questions_count',
            'participation_rate',
            { classrooms: ['name'] },
          ],
        },
      ],
      q: {
        foco_exam_edition_id_eq,
      },
    },
  })
}
