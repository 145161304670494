import React from 'react'
import { Grid, Box, Typography } from '@mui/material'
import TitleUpdater from '~connected/TitleUpdater'
import useCustomization from '~hooks/useCustomization'

const ErrorRoute = () => {
  const customization = useCustomization()

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <TitleUpdater
          htmlTitle={`${customization.applicationName} | ${
            customization.clientName
          }`}
        />

        <Box mt={20}>
          <Typography align="center" component="h1" variant="h2">
            Erro de navegação
          </Typography>
          <Typography align="center" component="p" variant="subtitle">
            Ops! O endereço que você tentou acessar não existe.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ErrorRoute
