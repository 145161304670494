import React from 'react'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DistrictForm } from '~components/AcademicYear/Form/DistrictForm'
import Header from '~components/Common/Header'

const SchoolYearDistrictFormPage = () => {
  const params = useParams()
  const entityId = Number(params.entityId)
  const academicYearId = Number(params.academicYearId)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Header
          title="Adicionar ano letivo"
          breadcrumbs={[
            {
              text: 'Cadastro',
              pathname: `/gerenciar-calendario/district/${
                entityId ?? ''
              }/anos-letivos`,
            },
            {
              text: 'Adicionar ano letivo',
              pathname: `/gerenciar-calendario/district/${
                entityId ?? ''
              }/anos-letivos`,
            },
          ]}
        />

        {entityId && (
          <DistrictForm
            entityId={entityId}
            academicYearId={academicYearId}
            entityType="district"
          />
        )}
      </Container>
    </LocalizationProvider>
  )
}

export default SchoolYearDistrictFormPage
