import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

const ASSESSMENT_LEVEL_INFO = {
  easy: {
    text: 'Fácil',
    color: 'success',
  },
  medium: {
    text: 'Médio',
    color: 'warning',
  },
  hard: {
    text: 'Díficil',
    color: 'error',
  },
}

export const AssessmentLevelLabel = ({ level, typography = 'body1' }) => {
  const label = ASSESSMENT_LEVEL_INFO[level]
  return (
    <Stack direction="row" alignItems="baseline" gap={1}>
      <Box
        width={12}
        height={12}
        borderRadius="50%"
        bgcolor={`${label.color}.main`}
      />
      <Typography variant={typography}>{label.text}</Typography>
    </Stack>
  )
}
