import React from 'react'
import { useTheme } from '@mui/material'
import { ColumnChart } from '~primitives/Charts/Column'
import { convertHexToRGBA } from '~helpers/colors/convertHexToRGBA'

export const DifficultyLevelChart = ({
  hard,
  medium,
  easy,
  difficultyFilter,
  setDifficultyFilter,
}) => {
  const { palette } = useTheme()

  const totalOfQuestions = hard + medium + easy

  const series = makeSeries({
    hard,
    medium,
    easy,
    palette,
    difficultyFilter,
    setDifficultyFilter,
  })

  return (
    <ColumnChart
      series={series}
      width={undefined}
      height={270}
      alignLegend="left"
      insideLabels={false}
      maximum={totalOfQuestions > 40 ? totalOfQuestions + 10 : 40}
      tickInterval={10}
      tooltipDecimalPlaces={0}
      noLegend
      noDataLabel
      labelColumns
      gridLine
      enableTooltip
      noGrouping
      inactiveSeriesOnHover
      seriesAnimation
    />
  )
}

const makeSeries = ({
  hard,
  medium,
  easy,
  palette,
  difficultyFilter,
  setDifficultyFilter,
}) => [
  {
    cursor: 'pointer',
    color:
      difficultyFilter === 'easy' || !difficultyFilter
        ? palette.success.main
        : convertHexToRGBA({ hex: palette.success.main, opacity: 0.25 }),
    data: [
      {
        y: easy,
        name: 'Fácil',
      },
    ],
    point: {
      events: {
        click: () => setDifficultyFilter('easy'),
      },
    },
  },
  {
    cursor: 'pointer',
    color:
      difficultyFilter === 'medium' || !difficultyFilter
        ? palette.warning.main
        : convertHexToRGBA({ hex: palette.warning.main, opacity: 0.25 }),
    data: [
      {
        y: medium,
        name: 'Médio',
      },
    ],
    point: {
      events: {
        click: () => setDifficultyFilter('medium'),
      },
    },
  },
  {
    cursor: 'pointer',
    color:
      difficultyFilter === 'hard' || !difficultyFilter
        ? palette.error.main
        : convertHexToRGBA({ hex: palette.error.main, opacity: 0.25 }),
    data: [
      {
        y: hard,
        name: 'Difícil',
      },
    ],
    point: {
      events: {
        click: () => setDifficultyFilter('hard'),
      },
    },
  },
]
