export const getStyles = (theme) => ({
  container: {
    px: 2,
    pb: 2,
    zIndex: 10,
    [theme.breakpoints.up('sm')]: {
      px: 8,
      minHeight: 60,
    },
  },
  wrapper: {
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
    py: 1,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
      borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    },
  },
  user: {
    settings: {
      height: 1,
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        right: 0,
        px: 2,
        py: 1,
        width: 1,
        height: 54,
        background: 'transparent',
      },
    },
    data: {
      position: 'relative',
      cursor: 'pointer',
      mx: 0,
    },
    info: {
      pb: 1.5,
    },
    avatar: {
      minWidth: 0,
      ml: 2,
    },
    personable: {
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        width: 1,
        mt: 6,
        mb: 1,
        py: 1,
        px: 1,
        borderBottom: `solid 1px ${theme.palette.grey[200]}`,
        borderTop: `solid 1px ${theme.palette.grey[200]}`,
      },
    },
  },
  popover: {
    dropdown: {
      position: 'absolute',
      top: 48,
      right: 0,
      width: 250,
      px: 1.5,
      pt: 2,
      pb: 1,
      cursor: 'default',
      boxShadow: 3,
      '&:before': {
        content: "''",
        height: 10,
        width: 10,
        position: 'absolute',
        top: -5,
        right: 15,
        bgcolor: theme.palette.grey[0],
        transform: 'rotate(45deg)',
      },
      '&:after': {
        content: "''",
        height: 10,
        width: 10,
        position: 'absolute',
        top: -5,
        right: 15,
        boxShadow: 3,
        zIndex: -1,
        bgcolor: theme.palette.grey[0],
        transform: 'rotate(45deg)',
      },
    },
    profileSelect: {
      "&:[role='combobox']": {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
    },
    link: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 1,
      fontSize: 13,
      fontWeight: 400,
      py: 0.5,
      pl: 1,
      pr: 2,
      bgcolor: 'transparent',
      color: theme.palette.grey[800],
      '&:focus, &:hover': {
        bgcolor: 'transparent',
        color: theme.palette.secondary.main,
      },
      '&:focus i, &:hover i': {
        bgcolor: theme.palette.secondary.main,
      },
    },
  },
})
