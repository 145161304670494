import * as Highcharts from 'highcharts'
import Chart from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge'
import React from 'react'
import { styled } from '@mui/material/styles'
import { isNil } from 'ramda'
import {
  formatDecimalPercent,
  formatDecimalNumber,
} from '~helpers/formatting/numbers'
import useTheme from '~hooks/useTheme'

HighchartsMore(Highcharts)
HighchartsSolidGauge(Highcharts)

const Container = styled('div')`
  .highcharts-title {
    width: 100% !important;
    text-align: center;
    left: 0;
  }
  .gauge-value {
    font-family: 'Aribau Grotesk', sans-serif;
    position: relative;
    display: inline-block;
    width: 55px;
    text-align: center;
    top: ${({ noData }) => (noData ? '1px' : '3px')};
    font-weight: ${({ noData }) => (noData ? 700 : 400)};
    font-size: ${({ fontSize, noData }) => fontSize * (noData ? 1.2 : 1)}px;
    color: ${({ noData }) => (noData ? '#9e9e9e' : '#4a4a4a')};
  }
`

const getText = ({ value, percentage, titleColor }) => {
  const format = percentage ? formatDecimalPercent(0) : formatDecimalNumber(0)

  return `<span class="gauge-value" style="color: ${titleColor ?? 'inherit'}">
            ${isNil(value) ? '!' : format(value || 0)}
          </span>`
}

export const GaugeChart = ({
  name = 'gauge-chart',
  height = 150,
  width = 80,
  percentage,
  color,
  titleColor,
  backgroundColor,
  value,
  min = 0,
  max = 1,
  strokeWidth = 7,
  fontSize = height / 3.33,
}) => {
  const theme = useTheme()

  const options = {
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      type: 'solidgauge',
      height,
      width,
      spacing: [0, 0, 0, 0],
    },
    title: {
      text: getText({ value, percentage, titleColor }),
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
    },
    tooltip: { enabled: false },
    plotOptions: {
      solidgauge: {
        dataLabels: { enabled: false },
        linecap: 'round',
        stickyTracking: false,
        rounded: false,
      },
    },
    credits: { enabled: false },
    series: [
      {
        data: [
          {
            color: color || theme.resultables.result,
            radius: '100%',
            innerRadius: `${100 - strokeWidth}%`,
            y: value,
          },
        ],
      },
    ],
    yAxis: {
      min,
      max,
      lineWidth: 0,
      tickPositions: [],
    },
    pane: {
      size: '100%',
      background: [
        {
          outerRadius: '100%',
          innerRadius: `${100 - strokeWidth}%`,
          backgroundColor: backgroundColor || theme.border,
          borderWidth: 0,
        },
      ],
    },
  }

  return (
    <Container data-testid={name} noData={isNil(value)} fontSize={fontSize}>
      <Chart highcharts={Highcharts} options={options} />
    </Container>
  )
}
