import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/curriculum_skills'

export const useCurriculumSkills = (params) => {
  const { loading, error, data } = useResource({
    resource: getCurriculumSkills,
    key: 'useCurriculumSkills',
    params,
    enabled: !!params?.subjectId || !!params?.gradeId || !!params?.competencyId,
  })

  return { loading, error, data }
}

const getCurriculumSkills = (params) =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: ['curriculum_skills'],
      fields: [{ curriculum_skills: ['id', 'name', 'description'] }],
      q: {
        subject_id_eq: params?.subjectId,
        grades_id_eq: params?.gradeId,
        curriculum_competency_id_eq: params?.competencyId,
      },
    },
  })

export default useCurriculumSkills
