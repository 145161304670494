import React from 'react'
import { Box, Typography } from '@mui/material'
import ExamIcon from '~assets/svg/icon_exam_name.svg'

export const ListHeader = () => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: '55px 85px 1fr 1fr',
      alignItems: 'center',
      pr: 6.25,
      pl: 1,
    }}
  >
    <Box>
      <img
        src={ExamIcon}
        alt="Ícone de exames"
        style={{ margin: 'auto', display: 'block' }}
      />
    </Box>
    <Typography variant="body2" fontWeight={700} textAlign="center">
      Cod.
    </Typography>
    <Typography variant="body2" fontWeight={700}>
      Enunciado da questão
    </Typography>
    <Typography variant="body2" fontWeight={700}>
      Habilidade do currículo
    </Typography>
  </Box>
)
