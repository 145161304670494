import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { FileSelectGroup } from '../FileSelectGroup'

export const FileFields = () => {
  const { values, setValues } = useFormikContext()

  return (
    <FieldArray
      name="files"
      render={(arrayHelpers) =>
        values.files.map(({ name, file }, index) => {
          const handlePush = (index) => {
            arrayHelpers.insert(index + 1, {
              name: '',
              file: null,
            })
          }

          const handleRemove = (index) => {
            arrayHelpers.remove(index)
          }

          const setFile = ({ file, name }) => {
            const { files } = values

            files[index] = { file, name }
            setValues({ ...values, files })
          }

          return (
            <FileSelectGroup
              key={index}
              index={index}
              file={file}
              name={name}
              setFile={setFile}
              handlePush={handlePush}
              handleRemove={handleRemove}
              removeDisabled={values.files.length === 1}
            />
          )
        })
      }
    />
  )
}
