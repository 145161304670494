import React from 'react'
import { useSelector } from 'react-redux'
import { Stack, Typography, Link as MUILink } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { getSelectedProfile } from '~selectors/profile'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const ListHeader = ({ entityData, listLength }) => {
  const profile = useSelector(getSelectedProfile)
  const search = useLocation().search

  if (!entityData || !listLength) return null

  return (
    <Stack direction="row" sx={{ mt: 3, mb: 2 }}>
      {profile?.kind !== 'sector' && (
        <MUILink
          data-testid="header-button"
          to={`../district/${entityData.district?.id}${search}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: 2,
          }}
          component={Link}
        >
          <SVGIcon name="backArrow" color="backgroundNeutral" size={2} />
        </MUILink>
      )}
      <Typography
        data-testid="school-title"
        variant="span"
        sx={{
          fontWeight: 'bold',
        }}
      >
        {`${entityData?.name} (${listLength} ${
          listLength > 1 ? 'escolas' : 'escola'
        })`}
      </Typography>
    </Stack>
  )
}

export default ListHeader
