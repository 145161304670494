import { requestParamsConfig } from './params'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = (id) => `/api/v3/foco_exam_editions/${id ?? ''}`

export const useFocoExamEdition = (params) => {
  const { data, loading, error } = useResource({
    key: 'useFocoExamEdition',
    resource: getFocoExamEdition,
    params,
    enabled: !!params.id && !!params.entityId && !!params.entityType,
  })
  return { data, loading, error }
}

const getFocoExamEdition = ({ requestTemplate, ...params }) =>
  fetchWithRoot('get')(endpoint(params.id), {
    params: {
      ...requestParamsConfig[requestTemplate](params),
    },
  })
