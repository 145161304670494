export default function Input(theme) {
  return {
    MuiInputBase: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            '&.MuiInputLabel-root': {
              color: theme.palette.grey[800],
              '&.Mui-error': {
                color: theme.palette.grey[800],
              },
            },
            '&.MuiInputBase-root': {
              '& .MuiInputAdornment-root': {
                button: {
                  color: theme.palette.button.secondary,
                },
              },
              marginTop: '4px',
              '&:before': {
                display: 'none',
              },
              '&:after': {
                display: 'none',
              },

              'input, textarea': {
                background: theme.palette.common.white,
                border: 'none',
                borderRadius: theme.shape.borderRadius * 2,
                padding: '0 20px',
                height: '100%',
                '&::placeholder': {
                  color: theme.palette.grey[600],
                },
              },

              textarea: {
                padding: '20px',
                boxSizing: 'border-box',
              },

              '&.Mui-error': {
                color: theme.palette.grey[600],

                'input, textarea': {
                  border: `1px solid ${theme.palette.error.main}`,
                },
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          '&:not(.MuiInputBase-multiline)': {
            height: 45,
            lineHeight: '45px',
          },
          '& .MuiNativeSelect-select': {
            width: 'calc(100% - 36px)',
            marginLeft: theme.spacing(1.5),
            '&:focus': {
              background: 'transparent',
            },
          },
        },
        sizeSmall: {
          ...theme.typography.body2,

          '&:not(.MuiInputBase-multiline)': {
            height: 35,
            lineHeight: '35px',
          },
        },
      },
    },
  }
}
