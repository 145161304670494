import React from 'react'
import { Box, Divider, Stack, Typography, Button } from '@mui/material'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { ModalSuccess } from '../../ModalSuccess'
import { Loader } from './Loader'
import { YearFields } from './YearFields'
import { PeriodFields } from './PeriodFields'
import { validate } from './validate'

export const SchoolForm = ({
  schoolId,
  initialValues,
  success,
  type,
  parentAcademicYear,
  onSubmit,
  onCloseModal,
  loading,
  error,
}) => {
  if (error) {
    return <div>Erro ao carregar ano letivo: {error.message}</div>
  }

  if (loading || !initialValues) {
    return <Loader schoolId={schoolId} />
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <Stack
            variant="modalBox"
            p={4}
            direction="column"
            alignItems="flex-start"
          >
            <Typography component="h3" variant="h4">
              <strong>Dados gerais</strong>
            </Typography>

            <Divider sx={{ width: '100%', my: 2 }} />

            <Box width="100%">
              <YearFields type={type} parentAcademicYear={parentAcademicYear} />
            </Box>

            <Typography component="h3" variant="h4">
              <strong>Períodos do ano letivo</strong>
            </Typography>

            <Divider sx={{ width: '100%', my: 2 }} />

            <Box width="100%">
              {values.curriculumPeriods.length === 0 && (
                <Typography component="legend" variant="body2">
                  Selecione um ano para configurar períodos
                </Typography>
              )}

              {values.curriculumPeriods.map((period, i) => {
                return <PeriodFields key={i} index={i} />
              })}
            </Box>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
            <Button
              variant="neutral"
              size="small"
              sx={{ minWidth: 160 }}
              component={Link}
              to={`/gerenciar-calendario/school/${schoolId}/anos-letivos`}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="small"
              sx={{ minWidth: 160 }}
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Stack>

          {success && (
            <ModalSuccess onClose={onCloseModal} academicYear={values.year} />
          )}
        </>
      )}
    </Formik>
  )
}
