import React from 'react'
import { TableCell, TableRow, Link as MUILink } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { formatDecimalPercent } from '~helpers/formatting/numbers'

const format = formatDecimalPercent(0)

const Row = ({ id, name, foco_exam_completion_rate }) => {
  const search = useLocation().search

  return (
    <TableRow
      data-testid={`sector-row-${id}`}
      sx={{
        bgcolor: '#f8f8f8',
        mt: '2px',
        border: 'none',
      }}
    >
      <TableCell
        data-testid={`sector-cell-name-${id}`}
        sx={{
          pl: 4,
        }}
      >
        {name}
      </TableCell>
      <TableCell
        align="center"
        data-testid={`sector-cell-completion-rate-${id}`}
      >
        {format(foco_exam_completion_rate)}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          width: 150,
          i: {
            color: 'transparent',
          },
        }}
      >
        <MUILink
          data-testid={`sector-link-to-school-${id}`}
          to={`../school/${id}${search}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: 2,
          }}
          component={Link}
        >
          Ver alunos
        </MUILink>
      </TableCell>
    </TableRow>
  )
}

export default Row
