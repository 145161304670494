import React, { useState } from 'react'
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material'
import { Scribble } from '~primitives/Typography/Underline'

export const InsightContainer = ({
  title,
  children,
  component = 'div',
  ['data-testid']: testid,
  isPopover = false,
  contentPopover,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box
      data-testid={testid}
      component={component}
      sx={{
        px: { xs: 1.5, sm: 2, md: 4 },
        py: 2,
        my: 2,
        bgcolor: 'grey.200',
        borderRadius: 2,
      }}
    >
      <Stack justifyContent="space-between" direction="row">
        {title && (
          <Typography variant="h5" fontSize={18} mb={2}>
            <Scribble>{title}</Scribble>
          </Typography>
        )}

        {isPopover && (
          <>
            <IconButton
              variant="outlined"
              color="secondary"
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              sx={{
                height: '24px',
                width: '24px',
              }}
            >
              <Typography variant="body2" sx={{ transform: 'rotate(180deg)' }}>
                <strong>!</strong>
              </Typography>
            </IconButton>

            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {contentPopover}
            </Popover>
          </>
        )}
      </Stack>
      {children}
    </Box>
  )
}
