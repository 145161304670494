import React from 'react'
import { Link } from 'react-router-dom'
import {
  Stack,
  Typography,
  Divider,
  Box,
  Button,
  Skeleton,
} from '@mui/material'

export const Loader = ({ schoolId }) => (
  <>
    <Stack variant="modalBox" p={4} direction="column" alignItems="flex-start">
      <Typography component="h3" variant="h4">
        <strong>Dados gerais</strong>
      </Typography>

      <Divider sx={{ width: '100%', my: 2 }} />

      <Box width="100%" py={3}>
        <Skeleton variant="rounded" height={40} />
      </Box>

      <Typography component="h3" variant="h4">
        <strong>Períodos do ano letivo</strong>
      </Typography>

      <Divider sx={{ width: '100%', my: 2 }} />

      <Stack direction="column" spacing={3} width="100%" py={2}>
        <Skeleton variant="rounded" height={35} minWidth={100} />
        <Skeleton variant="rounded" height={35} />
      </Stack>
    </Stack>

    <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
      <Button
        variant="neutral"
        size="small"
        sx={{ minWidth: 160 }}
        component={Link}
        to={`/gerenciar-calendario/school/${schoolId}/anos-letivos`}
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        size="small"
        sx={{ minWidth: 160 }}
        disabled
      >
        Salvar
      </Button>
    </Stack>
  </>
)
