import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, Divider, Skeleton } from '@mui/material'
import { FiltersProvider } from '~contexts/Filters'
import useEntity from '~data/entity'
import filters from '~resources/filters'
import { pxToRem } from '~helpers/getFontValue'
import { Breadcrumbs } from '~primitives/Breadcrumbs'
import { Scribble } from '~primitives/Typography/Underline'
import { List } from '~components/Curriculum/List'

export default function CurriculumList() {
  const { entityType, entityId } = useParams()

  const { data } = useEntity(entityType ?? '', String(entityId))

  const breadcrumbs = [
    { id: 'curriculum', text: 'Currículo', pathname: '#' },
    {
      id: 'list-curriculums',
      text: 'Lista de Currículos',
      pathname: '#',
    },
  ]

  return (
    <FiltersProvider
      config={{
        academic_year_id: {
          filter: 'academic_years',
          label: 'Ano letivo',
        },
        curriculum_period_id: {
          filter: 'curriculum_periods',
          label: 'Período',
        },
        subject_id: { filter: 'subjects', label: 'Disciplina' },
        grade_id: { filter: 'grades', label: 'Série' },
      }}
      resource={filters.getBaseCurriculumsFilters}
      additionalParams={{
        entityId,
        entityType,
      }}
    >
      <Box
        sx={{
          px: {
            xs: 2,
            sm: 2,
            md: 2,
            lg: 8,
          },
        }}
      >
        <Breadcrumbs margin="0" items={breadcrumbs} />
        <Typography sx={{ fontSize: pxToRem(22), my: pxToRem(6) }}>
          {data && entityId ? (
            `${data?.name} (${entityId})`
          ) : (
            <Skeleton width={500} />
          )}
        </Typography>
        <Divider sx={{ my: pxToRem(8) }} />
        <Typography
          sx={{ fontSize: pxToRem(24), fontWeight: 700, my: pxToRem(6) }}
        >
          <Scribble>Lista de Currículos</Scribble>
        </Typography>
        <List entityType={entityType ?? ''} entityId={Number(entityId)} />
      </Box>
    </FiltersProvider>
  )
}
