export default function Snackbar(theme) {
  return {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('md')]: {
            top: 76,
          },
        },
      },
    },
  }
}
