import React from 'react'
import { Stack, Typography } from '@mui/material'
import IconExam from '~assets/svg/icon_exam_name.svg'

export const Header = ({ eventIndex, skillCode, date }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ mb: 2 }}
  >
    <Stack direction="row" alignItems="center" gap={1}>
      <img src={IconExam} alt="Ícone de exame" width={24} />
      <Typography variant="body2" fontWeight={700}>
        {eventIndex && `Aula ${eventIndex} - `}
        {skillCode}
      </Typography>
    </Stack>
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography variant="body2" fontWeight={700}>
        Data
      </Typography>
      <Typography variant="body2">{date}</Typography>
    </Stack>
  </Stack>
)
