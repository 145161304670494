const makeSkillMapPath = ({ feature, resource, examId, selection }) =>
  `/resultados/${feature}/${resource}?resultable_type=${
    selection.kind
  }&resultable_id=${selection[selection.kind + 'Id']}&exam_id=${examId}`

const makeAAPPath = ({ feature, selection, examId }) => {
  const resultableId = selection[selection.kind + 'Id']

  return `/diagnostica/${examId}/${feature}/${selection.kind}/${resultableId}`
}

const makeExamPath = (params) => {
  if (params.resource === 'aap') {
    return makeAAPPath(params)
  }

  return makeSkillMapPath(params)
}

const makeEnemPath = ({ resource, selection }) => {
  if (resource === 'map') {
    return `/enem/mapa?resultable_type=${selection.kind}&resultable_id=${
      selection[selection.kind + 'Id']
    }`
  }

  if (resource === 'question_analysis') {
    return `/enem/analise-questoes?school_id=${selection.schoolId}`
  }
}

const paths = {
  retrospective: makeExamPath,
  prospective: makeExamPath,
  enem: makeEnemPath,
}

export const makeFinalPath = (selection, { feature, resource, examId }) => {
  if (!paths[feature]) return null
  if (!selection[`${String(selection.kind)}Id`]) return null

  return paths[feature]({ feature, resource, examId, selection })
}
