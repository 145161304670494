import React, { createContext, useState } from 'react'

export const WalkthroughContext = createContext({
  state: { step: 1, run: false },
  setState: () => {},
})

export const WalkthroughProvider = ({ children }) => {
  const [state, setState] = useState({ step: 1, run: false })

  return (
    <WalkthroughContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </WalkthroughContext.Provider>
  )
}

export const WalkthroughConsumer = WalkthroughContext.Consumer

export default WalkthroughContext
