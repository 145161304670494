import * as React from 'react'
import { hideVisually } from 'polished'
import { styled } from '@mui/material/styles'

const RadioContainer = styled('label')`
  display: block;
  background-color: ${({ selected }) => (selected ? '#fafafa' : '#f3f3f3')};
  height: auto;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 20px 20px 20px 60px;
  position: relative;

  & > input {
    ${hideVisually()};
  }
`

const FakeInput = styled('span')`
  position: relative;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  width: 28px;
  height: 28px;
  border: 1px solid ${({ theme }) => theme.palette.foco.border};
  border-radius: 50%;
  top: 50%;
  left: 16px;
  margin-top: -14px;

  &:before {
    content: '';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    width: 16px;
    height: 16px;
    position: absolute;
    left: 5px;
    top: 5px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.foco.linkColor};
  }
`

export const RadioBlock = ({ selected, id, onClick, children }) => {
  const handleChange = () => {
    onClick(id)
  }

  return (
    <RadioContainer htmlFor={id} selected={selected}>
      <FakeInput checked={selected} />
      <input
        type="radio"
        name="profile"
        id={id}
        data-testid={`radio-block-${id}`}
        onChange={handleChange}
        checked={selected}
      />
      {children}
    </RadioContainer>
  )
}
