export const getStyles = (theme) => ({
  container: {
    maxWidth: 720,
    width: 1,
    px: 3,
    py: 4,
    bgcolor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius * 2.5,
  },

  instructions: {
    '& p': {
      py: 1,
      lineHeight: '1.25rem',
    },
    '& ul': {
      listStyle: 'disc',
      ml: 2,
      my: 1,
    },
    '& ul ul, & ol ul': {
      listStyle: 'circle',
    },
    '& ol': {
      listStyle: 'decimal',
      ml: 2,
      my: 1,
    },
    '& ul ol, & ol ol': {
      listStyle: 'upper-roman',
    },
    '& li': {
      my: 1,
    },
    '& ul p, & ol p': {
      py: 0,
    },
  },
})
