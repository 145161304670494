import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import TimeExpiredModal from './Modals/TimeExpired'
import Header from '~components/Common/Header'
import useExam from '~data/focoExams/student/exam'
import { Content } from '~components/FocoExams/Student/Exam'

export const StudentExamView = () => {
  const { id } = useParams()

  const [timeExpiredModalIsOpen, setTimeExpiredModalIsOpen] = useState(false)

  const { loading, error, data } = useExam({ id: Number(id) })

  useEffect(() => {
    if (data?.exam_end) {
      const examEnd = dayjs(data.exam_end)
      const now = dayjs()

      if (now.diff(examEnd) >= 0) setTimeExpiredModalIsOpen(true)
    }
  }, [data])

  if (error) {
    return (
      <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
        Erro ao carregar exame: {error.status} - {error.message}
      </Container>
    )
  }

  if (loading || !data) {
    return (
      <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
        Carregando ...
      </Container>
    )
  }

  return (
    <>
      <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
        <Header
          title="Minhas Provas"
          breadcrumbs={[
            {
              text: 'Avaliações',
              pathname: '/',
            },
            {
              text: 'Minhas Provas',
              pathname: `/responder-caderno/${id || ''}`,
            },
          ]}
        />

        <Content
          examEditionName={data.foco_exam_edition_name}
          subjectName={data.subject_name}
          gradeName={data.grade_name}
          examStart={data.exam_start}
          examEnd={data.exam_end}
          timeLimit={data.time_limit}
          instructions={data.instructions}
          answers={data.foco_answers}
          questions={data.foco_questions}
        />
      </Container>

      <TimeExpiredModal isOpen={timeExpiredModalIsOpen} />
    </>
  )
}

export default StudentExamView
