import React from 'react'
import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import dayjs from 'dayjs'
import { Link as RouterLink } from 'react-router-dom'
import ExamIcon from '~assets/svg/icon_exam_name.svg'
import { convertTimeToHour } from '~helpers/time'

export const ExamDetails = ({
  handleOpenForm,
  examEditionName,
  subjectName,
  gradeName,
  examStart,
  examEnd,
  timeLimit,
  instructions,
  amountOfQuestions,
}) => {
  const { typography } = useTheme()

  return (
    <Container maxWidth="sm">
      <Box
        component="article"
        sx={{
          mt: 15,
          px: 3,
          py: 4,
          bgcolor: 'grey.200',
          borderRadius: 5,
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Typography variant="h5" component="span">
            {examEditionName}
          </Typography>
          <Box>
            <Typography variant="h5" component="span">
              {subjectName} -{' '}
              <Typography
                variant="h5"
                component="span"
                sx={{ fontWeight: 400 }}
              >
                {gradeName}
              </Typography>
            </Typography>
          </Box>
        </Stack>

        <Divider sx={{ mt: 2.5, mb: 4 }} />

        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          justifyContent="space-between"
        >
          <Stack spacing={2}>
            <Typography variant="body2">
              <strong>Data de início:</strong>{' '}
              {dayjs(examStart).format('DD/MM/YYYY')} às{' '}
              {dayjs(examStart).format('HH:mm')}
            </Typography>
            <Typography variant="body2">
              <strong>Data de fim:</strong>{' '}
              {dayjs(examEnd).format('DD/MM/YYYY')} às{' '}
              {dayjs(examEnd).format('HH:mm')}
            </Typography>
          </Stack>
          <Stack spacing={2} alignItems="flex-end">
            <Typography variant="body2">
              <strong>Duração:</strong>{' '}
              {timeLimit ? convertTimeToHour(timeLimit) : 'Não prevista'}
            </Typography>
            <Typography variant="body2">
              <strong>Número de questões:</strong> {amountOfQuestions}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" mt={5}>
          <Box height={40} mr={1}>
            <img src={ExamIcon} alt="Ícone de exames" />
          </Box>
          <Typography component="h3" fontWeight={700} variant="h5">
            Regras e instruções para a prova:
          </Typography>
        </Stack>

        <Divider sx={{ mt: 2.5, mb: 4 }} />

        {instructions ? (
          <Box
            data-testid="exam-instructions"
            dangerouslySetInnerHTML={{ __html: instructions }}
            sx={{ ...instructionsStyles(typography) }}
          />
        ) : (
          'Nenhuma regra ou instrução disponível'
        )}
      </Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 6 }}
      >
        <Link to="/" variant="text" component={RouterLink}>
          Cancelar
        </Link>
        <Button variant="primary" onClick={handleOpenForm}>
          Iniciar avaliação
        </Button>
      </Stack>
    </Container>
  )
}

const instructionsStyles = (typography) => ({
  '& p': {
    py: 1,
    lineHeight: '1.25rem',
  },
  '& ul, & ol': {
    pl: 2,
    my: 1,
    ml: 1,
    ...typography.body2,
  },
  '& ul': {
    listStyle: 'disc',
  },
  '& ul ul, & ol ul': {
    listStyle: 'circle',
  },
  '& ol': {
    listStyle: 'decimal',
  },
  '& ul ol, & ol ol': {
    listStyle: 'upper-roman',
  },
  '& li': {
    my: 1,
  },
  '& ul p, & ol p': {
    py: 0,
  },
  '& h1': { ...typography.h1 },
  '& h2': { ...typography.h2 },
  '& h3': { ...typography.h3 },
  '& h4': { ...typography.h4 },
  '& h5': { ...typography.h5 },
  '& h6': { ...typography.h7 },
})
