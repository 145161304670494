import { useParams } from 'react-router-dom'

import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = (base_curriculum_id) =>
  `api/v3/base_curriculums/${base_curriculum_id}`

export const useBaseCurriculum = () => {
  const { baseCurriculumId, entityId, entityType } = useParams()

  const { loading, error, data } = useResource({
    resource: getBaseCurriculum,
    params: { baseCurriculumId, entityId, entityType },
    enabled: !!entityId,
    key: 'useBaseCurriculum',
  })

  return {
    loading,
    error,
    data,
  }
}

const getBaseCurriculum = ({ baseCurriculumId, entityId, entityType }) => {
  return fetchWithRoot('get')(endpoint(baseCurriculumId), {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      include: [
        'curriculum_period',
        'subject',
        'grade',
        {
          base_events: [
            {
              minimum_curriculum_unit: [
                { curriculum_skill: ['curriculum_competency'] },
              ],
            },
            'curriculum_activities',
          ],
        },
      ],
      fields: [
        'id',
        'events_per_week',
        {
          curriculum_period: ['name', 'id'],
          subject: ['name', 'id'],
          grade: ['name', 'id'],
          base_events: [
            'id',
            'activity_description',
            'event_order',
            'performed',
            {
              minimum_curriculum_unit: [
                'id',
                {
                  curriculum_skill: [
                    'name',
                    'description',
                    { curriculum_competency: ['name', 'id', 'description'] },
                  ],
                },
              ],
              curriculum_activities: ['name', 'id'],
            },
          ],
        },
      ],
    },
    bypassLoader: true,
  })
}
