import React, { useEffect, useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { SelectGroup } from './SelectGroup'
import { useManageBookletFilterClassrooms } from '~data/manageBooklets/form/filterClassrooms'
import usePrevious from '~hooks/usePrevious'

export const SelectGroupList = ({
  schoolId,
  gradeId,
  setFieldValue,
  studentsByClassroom,
}) => {
  const { data } = useManageBookletFilterClassrooms({ schoolId, gradeId })

  const classroomsOptions = normalizeClassrooms(
    data?.classrooms,
    studentsByClassroom
  )

  const prevClassroomsOptions = usePrevious(classroomsOptions)

  const setStudentsByClassroom = useCallback(
    (value) => {
      setFieldValue('studentsByClassroom', value)
    },
    [setFieldValue]
  )

  useEffect(() => {
    if (!isEqual(prevClassroomsOptions, classroomsOptions)) {
      if (studentsByClassroom.length > 0 && classroomsOptions.length !== 0) {
        setStudentsByClassroom(studentsByClassroom)
      }
      if (studentsByClassroom.length === 0 && classroomsOptions.length > 0) {
        const firstClassroomOption = classroomsOptions[0]
        const { name, id } = firstClassroomOption

        setStudentsByClassroom([
          {
            name,
            id,
            studentIds: [],
          },
        ])
      }
    }
  }, [
    classroomsOptions,
    prevClassroomsOptions,
    setStudentsByClassroom,
    studentsByClassroom,
  ])

  return (
    <>
      {studentsByClassroom.map((classroom, index) => (
        <SelectGroup
          key={index}
          index={index}
          classroom={classroom}
          classroomOptions={classroomsOptions}
          studentsByClassroom={studentsByClassroom}
          setStudentsByClassroom={setStudentsByClassroom}
          schoolId={schoolId}
        />
      ))}
    </>
  )
}

const normalizeClassrooms = (classrooms = [], studentsByClassroom) =>
  classrooms?.length > 0
    ? classrooms.map((classroom) => ({
        ...classroom,
        enabled: !studentsByClassroom.some(({ id }) => id === classroom.id),
      }))
    : []
