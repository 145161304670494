import React from 'react'
import { useSelector } from 'react-redux'
import ManagementRoutes from './ManagementRoutes'
import StudentRoutes from './StudentRoutes'

const LoggedInRoutes = () => {
  const user = useSelector(({ user }) => user)

  return user.student ? <StudentRoutes /> : <ManagementRoutes />
}
export default LoggedInRoutes
