import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Stack, Button } from '@mui/material'

import {
  Container,
  ContainerHeader,
  FormHeader,
  Form,
  FeatureIcon,
} from './styles'
import { Select } from '~primitives/Input/Select'
import { Heading } from '~primitives/Typography'
import { FlashMessage } from '~primitives/Messages/FlashMessage'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import useProfileData from '~hooks/useProfileData'

const SelectionForm = ({
  selected,
  onChange,
  pathname,
  error,
  filters,
  examId,
  feature,
  resource,
  loading,
}) => {
  const { getFeature, getResource } = useProfileData()

  const featureLabel = getFeature(feature).label
  const resourceLabel = getResource(feature, resource, examId)?.label

  const handleClickResend = () => {
    onChange({ name: 'resend', value: '' })
  }

  return (
    <>
      <Container>
        <ContainerHeader>
          <FeatureIcon feature={feature}>
            <SVGIcon
              name={_.camelCase(feature)}
              color="textNegative"
              size={7}
            />
          </FeatureIcon>

          <Heading as="h2" size={3}>
            {featureLabel} | <strong>{resourceLabel}</strong>
          </Heading>
        </ContainerHeader>
        <FormHeader>
          <Heading as="h3" size={3} center>
            Selecione a origem dos dados que deseja visualizar
          </Heading>
        </FormHeader>
        <Form>
          {error && (
            <FlashMessage
              color="danger"
              onClick={handleClickResend}
              buttonText="Tentar novamente"
            >
              Houve um erro ao fazer sua requisição: {error.message}
            </FlashMessage>
          )}

          {filters?.kinds && (
            <Select
              label="Nível"
              name="kind"
              background="white"
              options={filters.kinds}
              disabled={filters.kinds.length === 1 || loading}
              value={selected.kind}
              onChange={onChange}
            />
          )}

          {filters?.sectors && (
            <Select
              withSearch
              label="Regional"
              name="sectorId"
              background="white"
              options={filters.sectors}
              disabled={filters.sectors.length === 1 || loading}
              value={selected.sectorId}
              onChange={onChange}
            />
          )}

          {filters?.group_types && (
            <Select
              label="Tipo de ensino"
              name="groupType"
              background="white"
              options={filters.group_types}
              disabled={filters.group_types.length === 1 || loading}
              value={selected.groupType}
              onChange={onChange}
            />
          )}

          {filters?.schools && (
            <Select
              withSearch
              label="Escola"
              name="schoolId"
              background="white"
              options={filters.schools.map((school) => ({
                ...school,
                name: `${school.name} (${school.id})`,
              }))}
              disabled={filters.schools.length === 1 || loading}
              value={selected.schoolId}
              onChange={onChange}
            />
          )}

          <Stack direction="column" justifyContent="center">
            <Button
              component={Link}
              variant="primary"
              id="confirm-selection-button"
              data-testid="confirm-selection"
              to={pathname}
              disabled={loading}
            >
              {loading ? 'Carregando' : 'Continuar'}
            </Button>

            <Button component={Link} variant="transparent" to="/pagina-inicial">
              Voltar para o inicio
            </Button>
          </Stack>
        </Form>
      </Container>
    </>
  )
}

export default SelectionForm
