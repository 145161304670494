import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

export const endpoint = '/api/v3/foco_exam_results/performance_proficiency'

export const usePerformanceProficiency = ({ examId, classroomId }) => {
  const { loading, error, data } = useResource({
    resource: getPerformanceProficiency,
    params: { examId, classroomId },
    enabled: Boolean(examId && classroomId),
  })

  return { loading, error, data }
}

const getPerformanceProficiency = ({ examId, classroomId }) =>
  fetchWithRoot('get')(endpoint, {
    params: {
      foco_exam_id: examId,
      classroom_id: classroomId,
    },
    bypassLoader: true,
  })
