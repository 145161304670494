import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/curriculum_competencies'

export const useCurriculumCompetencies = (params) => {
  const { loading, error, data } = useResource({
    resource: getCurriculumCompetencies,
    key: 'useCurriculumCompetencies',
    params,
    enabled: !!params?.subjectId || !!params?.gradeId,
  })

  return { loading, error, data }
}

const getCurriculumCompetencies = (params) =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: ['curriculum_competencies'],
      fields: [{ curriculum_competencies: ['id', 'name', 'description'] }],
      q: {
        subject_id_eq: params?.subjectId,
        grade_id_eq: params?.gradeId,
      },
    },
  })

export default useCurriculumCompetencies
