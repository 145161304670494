import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Stack, Typography, Button } from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { Scribble } from '~primitives/Typography/Underline'
import { pxToRem } from '~helpers/getFontValue'

export const Header = ({ openImportModal }) => {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const handleOpenModal = () => {
    history.push({
      pathname: `${pathname}/add`,
      search,
    })
  }

  const handleOpenImportModal = () => {
    openImportModal(true)
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        sx={{ fontSize: pxToRem(24), fontWeight: 700, my: pxToRem(6) }}
      >
        <Scribble>Gerenciar alunos</Scribble>
      </Typography>
      <Stack direction="row" gap={2.5}>
        <Button
          onClick={handleOpenImportModal}
          variant="outlined"
          startIcon={<SVGIcon name="cloudUpload" color="red" size={1} />}
          size="small"
        >
          Importar alunos
        </Button>
        <Button variant="primary" onClick={handleOpenModal} size="small">
          Adicionar
        </Button>
      </Stack>
    </Stack>
  )
}
