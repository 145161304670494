import React from 'react'
import {
  Alert,
  Box,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import { InsightContainer } from '~primitives/InsightsContainer'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { useFocoExamPrioritarySkills } from '~data/manageExams/results/prioritarySkills'

export const PerformanceBySkill = ({ examId, classroomId, schoolId }) => {
  const { loading, error, data } = useFocoExamPrioritarySkills({
    examId,
    classroomId,
    schoolId,
  })

  const { palette } = useTheme()
  return (
    <InsightContainer title="Desempenho por habilidade">
      {error ? (
        <Box sx={{ p: 2 }}>
          <Alert variant="filled" severity="error">
            Ocorreu um erro ao carregar os dados.
          </Alert>
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{ p: 2, height: '220px', borderRadius: 2 }}
            >
              <Stack alignItems="center">
                <Typography variant="body2">
                  <strong>Habilidade mais prioritária</strong>
                </Typography>
                <Divider />

                <Box py={3}>
                  <Typography fontSize={30} color={palette.error.main}>
                    {loading ? (
                      <Skeleton height="100px" width="auto" />
                    ) : data?.highest_skill_priority ? (
                      data.highest_skill_priority.name
                    ) : (
                      '-'
                    )}
                  </Typography>
                </Box>

                <Typography variant="body2" textAlign="center">
                  É a habilidade com o menor percentual de acertos.
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: 2, height: '220px' }}>
              <Stack alignItems="center">
                <Typography variant="body2">
                  <strong>Habilidade menos prioritária</strong>
                </Typography>
                <Divider />

                <Box py={3}>
                  <Typography fontSize={30} color={palette.success.light}>
                    {(loading || !data) && (
                      <Skeleton height="100px" width="auto" />
                    )}

                    {data?.lowest_skill_priority &&
                      data.lowest_skill_priority.name}

                    {!data?.lowest_skill_priority && (
                      <SVGIcon name="info" color="warning" />
                    )}
                  </Typography>
                </Box>

                <Typography variant="body2" textAlign="center">
                  {data?.lowest_skill_priority &&
                    'É a habilidade com maior percentual de acertos.'}

                  {!data?.lowest_skill_priority &&
                    'Não há uma habilidade menos prioritária para essa avaliação'}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      )}
    </InsightContainer>
  )
}
