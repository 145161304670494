import React from 'react'
import {
  Grid,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { useGrades } from '~data/curriculum/grades'

export default function SelectGroup({
  handleChange,
  index,
  listLength,
  values,
  errors,
  arrayHelpers,
  setFieldValue,
  year,
}) {
  const { data } = useGrades({ year })

  const grades =
    data?.grades.filter(
      (grade) => grade.classrooms_with_subjects.length !== 0
    ) ?? []

  const selectedPeriodIndex = grades.findIndex(
    (item) => item.id === values.list[index].grade
  )
  const selectedClasroomIndex = grades?.[
    selectedPeriodIndex
  ]?.classrooms_with_subjects.findIndex(
    (item) => item.id === values.list[index].classroom_id
  )

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="select-grade" required>
              Série
            </InputLabel>
            <Select
              labelId="select-grade"
              name={`list.${index}.grade`}
              value={values.list[index].grade}
              error={Boolean(errors?.list?.[index]?.grade)}
              onChange={(e) => {
                setFieldValue(`list.${index}.classroom_id`, '')
                setFieldValue(`list.${index}.subject_id`, '')
                handleChange(e)
              }}
              disabled={!year}
              variant="standard"
              size="small"
              placeholder
              defaultValue=""
              displayEmpty
              required
              MenuProps={{
                style: { zIndex: 1000001 },
              }}
            >
              <MenuItem value="" disabled>
                Série
              </MenuItem>
              {grades?.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="select-classroom" required>
              Turma
            </InputLabel>
            <Select
              labelId="select-classroom"
              name={`list.${index}.classroom_id`}
              value={values.list[index].classroom_id}
              error={Boolean(errors?.list?.[index]?.classroom_id)}
              onChange={(e) => {
                setFieldValue(`list.${index}.subject_id`, '')
                handleChange(e)
              }}
              disabled={values.list[index].grade === ''}
              variant="standard"
              size="small"
              placeholder
              defaultValue=""
              displayEmpty
              required
              MenuProps={{
                style: { zIndex: 1000001 },
              }}
            >
              <MenuItem value="" disabled>
                Turma
              </MenuItem>
              {grades?.[selectedPeriodIndex]?.classrooms_with_subjects.map(
                ({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="select-subject" required>
              Disciplina
            </InputLabel>
            <Select
              labelId="select-subject"
              name={`list.${index}.subject_id`}
              value={values.list[index].subject_id}
              error={Boolean(errors?.list?.[index]?.subject_id)}
              onChange={handleChange}
              disabled={values.list[index].classroom_id === ''}
              variant="standard"
              size="small"
              placeholder
              defaultValue=""
              displayEmpty
              required
              MenuProps={{
                style: { zIndex: 1000001 },
              }}
            >
              <MenuItem value="" disabled>
                Disciplina
              </MenuItem>
              {grades?.[selectedPeriodIndex]?.classrooms_with_subjects?.[
                selectedClasroomIndex
              ]?.subjects.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          {index + 1 === listLength ? (
            <Stack direction="row" spacing={1}>
              <IconButton
                type="button"
                variant="secondary"
                disabled={false}
                onClick={() =>
                  arrayHelpers.push({
                    grade: '',
                    classroom_id: '',
                    subject_id: '',
                  })
                }
                sx={{
                  height: 36,
                  width: 36,
                }}
              >
                <SVGIcon name="add" color="white" size={6} />
              </IconButton>
              {index > 0 && (
                <IconButton
                  type="button"
                  variant="outlinedSecondary"
                  onClick={() => arrayHelpers.remove(index)}
                  sx={{
                    height: 36,
                    width: 36,
                  }}
                >
                  <SVGIcon name="delete" size={6} />
                </IconButton>
              )}
            </Stack>
          ) : (
            <Stack direction="row" spacing={1}>
              <IconButton
                type="button"
                variant="secondary"
                disabled={true}
                onClick={() => {}}
                sx={{
                  height: 36,
                  width: 36,
                }}
              >
                <SVGIcon name="add" color="white" size={6} />
              </IconButton>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  )
}
