import { requestTemplate } from './params'

import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/curriculum_skills'

export const useCurriculumSkills = (params) => {
  const { loading, error, data } = useResource({
    resource: getCurriculumSkills,
    key: 'useCurriculumSkills',
    params,
    enabled: !!params.curriculumSkillId,
  })

  return { loading, error, data }
}

const getCurriculumSkills = (params) =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      ...requestTemplate['modal-suggested-material'](params),
    },
  })

export default useCurriculumSkills
