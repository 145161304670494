import React from 'react'
import TitleUpdater from '~connected/TitleUpdater'
import BasicSelection from '~components/Selection'

import useAhoy from '~hooks/useAhoy'

const Selection = ({ match }) => {
  useAhoy('Selection', match.params)

  return (
    <>
      <TitleUpdater htmlTitle="Defina sua pesquisa" templateTitle={null} />

      <BasicSelection
        feature={match.params.feature}
        resource={match.params.resource}
        examId={Number(match.params.examId)}
      />
    </>
  )
}

export default Selection
