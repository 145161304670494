import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (id) => `/api/v3/foco_exams/${id}`

export const patchBooklet = async (data) =>
  fetchWithRoot('put')(data.endpoint, {
    data: {
      foco_exam: {
        name: data?.name,
        foco_exam_edition_id: data?.examId,
        subject_id: data?.subjectId,
        grade_id: data?.gradeId,
        student_profile_ids: data?.studentProfileIds,
        foco_exam_foco_questions_attributes: data?.questionIds,
      },
    },
  })
