import { useMutation, useQueryClient } from 'react-query'
import { config } from '../config'
import { fetchWithoutRoot } from '~helpers/fetch'

const { endpoint } = config

export const useUpdateJourneys = ({ onError, onSuccess }) => {
  const queryClient = useQueryClient()

  return useMutation((params) => updateJourneys(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(config.key)
      onSuccess(data)
    },
    onError,
  })
}

export const updateJourneys = async (params) => {
  const response = await fetchWithoutRoot('put')(
    `${endpoint(params?.entityType, params?.entityId)}/${params?.id}`,
    {
      data: {
        name: params?.name,
        hour_initial: params?.hour_initial.format('HH:mm:ss'),
        hour_end: params?.hour_end.format('HH:mm:ss'),
        active: params?.active,
      },
    }
  )

  if (response[0]?.message) {
    throw new Error(response[0]?.message)
  }
}
