import { fetchWithRoot } from '~helpers/fetch'

export const endpoint = 'api/v3/base_curriculums/create_entity_curriculum'

export const generateCurriculum = ({
  curriculumPeriodId,
  baseCurriculumIds,
  schoolId,
}) =>
  fetchWithRoot('post')(endpoint, {
    data: {
      entity_type: 'school',
      entity_id: schoolId,
      curriculum_period_id: curriculumPeriodId,
      base_curriculum_ids: baseCurriculumIds,
    },
  }).then(([error, response]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
    if (response) return response
  })
