import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import useFilters from '~hooks/useFilters'

export const endpoint = (entity_type, entity_id) =>
  `/api/v3/${entity_type}s/${entity_id}`

const useEntity = (entity_type, entity_id) => {
  const { params } = useFilters()

  const { loading, error, data } = useResource({
    resource: getEntity,
    params: { params, entity_type, entity_id },
    key: 'useEntity',
  })

  return { loading, error, data }
}

const getEntity = ({ params, entity_type, entity_id }) =>
  fetchWithRoot('get')(endpoint(entity_type, entity_id), {
    bypassLoader: true,
    params: {
      ...params,
      include: ['district', 'sector'],
      fields: [
        'id',
        'name',
        { district: ['id', 'name'], sector: ['id', 'name'] },
        'position_by_performance_rates',
        'sector_count',
      ],
    },
  })

export default useEntity
