import React from 'react'
import FeaturesGroup from '..'

const StudentFeaturesGroup = ({
  activeCollapse,
  setActiveCollapse,
  activeMenu,
  setActiveMenu,
}) => {
  const defaultProps = {
    activeCollapse,
    setActiveCollapse,
    activeMenu,
    setActiveMenu,
  }

  return (
    <>
      <FeaturesGroup
        title="Avaliação"
        icon="menuExams"
        name="exam_result"
        activeMatches={['pagina-inicial', 'meus-resultados']}
        {...defaultProps}
        groups={[
          {
            features: [
              {
                label: 'Minhas provas',
                href: '/pagina-inicial',
                name: 'my-exams',
                feature: 'exams',
              },
              {
                label: 'Meus resultados',
                href: '/meus-resultados',
                name: 'my-results',
                feature: 'results',
              },
            ],
          },
        ]}
      />
    </>
  )
}

export default StudentFeaturesGroup
