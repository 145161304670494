import { fetchWithRoot } from '~helpers/fetch'
import useFilters from '~hooks/useFilters'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/curriculum_events'

const useWeekCurriculumEvents = ({ starts, ends, grades = [] }) => {
  const { params } = useFilters()

  const { loading, error, data } = useResource({
    resource: getEvents,
    params: { ...params, starts, ends, grades },
    enabled: !!params,
    key: 'useWeekCurriculumEvents',
  })

  return {
    loading,
    error,
    data,
  }
}

const getEvents = ({ entityId, ...params }) =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_id: entityId,
      entity_type: 'school',
      classroom_id: params.classroom_id,
      grade_id: params.grades,
      q: { date_gteq: params.starts, date_lteq: params.ends },
      fields: [
        {
          curriculum_events: [
            'id',
            'date',
            'start_time',
            'end_time',
            'notes',
            'status',
            'performed',
            'performed_to_plan',
            { classroom: ['id', 'name'] },
            { grade: ['id', 'name', 'short_name'] },
            { subject: ['id', 'name'] },
          ],
        },
      ],
      include: [{ curriculum_events: ['grade', 'subject', 'classroom'] }],
    },
    bypassLoader: true,
  })

export default useWeekCurriculumEvents
