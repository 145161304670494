import * as React from 'react'
import { Formik } from 'formik'
import { styled } from '@mui/material/styles'
import { Stack, Typography, Button } from '@mui/material'
import { Form } from '~primitives/Input/Form'
import { TextInput } from '~primitives/Input/Text'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { NotificationBox } from '~primitives/Messages/NotificationBox'
import { track, trackView } from '~resources/ahoy'
import { validateEmail } from '~helpers/validators'
import Register from '~resources/register'

const validate = ({ email, confirm }) => {
  if (!validateEmail(email)) {
    return { email: 'E-mail inválido' }
  }
  if (email !== confirm) {
    return { confirm: 'Os e-mails informados não coincidem' }
  }
  return {}
}

const EmailSuccess = styled('section')`
  h3 {
    text-align: center;
    margin-bottom: 16px;
    line-height: 24px;
  }
  p {
    font-size: 14px;
    max-width: 240px;
    margin: 0 auto;
    text-align: center;
    line-height: 23px;
  }

  button {
    font-size: 14px;
    padding: 0;
    background: transparent;
    border: none;
    appearance: none;
    display: inline;
    color: ${({ theme }) => theme.palette.secondary.darker};
  }

  div {
    height: 150px;
    width: 100%;
    border-radius: 2px;
    background-color: #f3f3f3;
    margin-bottom: 20px;

    p {
      padding-top: 35px;
    }
  }
`

class FormEmail extends React.Component {
  state = { error: null, data: null }

  componentDidMount() {
    const { birthYear } = this.props
    trackView('Signup / Email', {
      birth_year: birthYear,
    })
  }

  handleSubmit = ({ email, confirm }, { setErrors }) => {
    const { cpf, birthYear } = this.props

    Register.createUser({
      cpf,
      birthYear,
      acceptTermsAndConditions: true,
      email,
      confirm,
    }).then(([error, data]) => {
      this.setState({ error, data })

      if (error) {
        track('Signup / Email / Error', {
          birth_year: birthYear,
          email,
          error_code: error.status,
          error_message: error.message,
        })

        setErrors({ email: 'E-mail inválido.', confirm: 'E-mail inválido.' })
      }

      if (data) {
        track('Signup / Email / Sent', {
          birth_year: birthYear,
          email,
        })
      }
    })
  }

  render() {
    const { error, data } = this.state

    return (
      <Formik
        initialValues={{ email: '', confirm: '' }}
        validate={validate}
        validateOnChange={true}
        onSubmit={this.handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            {!data && (
              <>
                <header>
                  <Typography component="h2" variant="h2">
                    Bem-vindo
                  </Typography>
                  <Typography component="span" variant="body1">
                    Informe um e-mail válido
                  </Typography>
                </header>

                <fieldset>
                  <ul>
                    <li>
                      <TextInput
                        type="email"
                        name="email"
                        id="email"
                        label="E-mail"
                        placeholder="Digite seu e-mail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email}
                        helperText={errors.email}
                        variant="outlined"
                        required
                      />
                    </li>

                    <li>
                      <TextInput
                        type="email"
                        name="confirm"
                        id="confirm"
                        label="Confirmação de e-mail"
                        placeholder="Confirme seu e-mail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm}
                        error={errors.confirm}
                        helperText={errors.confirm}
                        variant="outlined"
                        required
                      />
                    </li>
                  </ul>

                  <footer className="navigation-footer">
                    {error && (
                      <NotificationBox
                        danger
                        showSupportButton
                        title="Não foi possível finalizar seu cadastro"
                        text={error.message}
                      />
                    )}
                    <Stack
                      direction={{
                        sm: 'row-reverse',
                        xs: 'column',
                      }}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ flex: 1, mt: 2 }}
                    >
                      <Button
                        id="submit-form"
                        data-testid="submit-form"
                        type="submit"
                        disabled={!isValid}
                        variant="primary"
                        sx={{ width: { xs: 1, sm: 220 } }}
                      >
                        Criar minha conta
                      </Button>

                      <Button
                        variant="transparent"
                        data-testid="reset-form"
                        onClick={() => this.props.onClickReset('birthdate')}
                      >
                        <SVGIcon name="previous" color="neutral" size={2} />{' '}
                        Passo anterior
                      </Button>
                    </Stack>
                  </footer>
                </fieldset>
              </>
            )}

            {data && (
              <>
                <EmailSuccess>
                  <h3>
                    Você recebeu uma mensagem no e-mail{' '}
                    <strong>{values.email}</strong>
                  </h3>
                  <div>
                    <p>
                      Para finalizar seu cadastro, acesse a{' '}
                      <strong>caixa de entrada de seu e-mail</strong> e siga as
                      instruções da mensagem que enviamos.
                    </p>
                  </div>
                  <p>
                    Aguarde 2 minutos, por favor. Caso não receba a mensagem,
                    verifique sua <strong>caixa de spam</strong> ou{' '}
                    <strong>lixo eletrônico.</strong>
                  </p>
                </EmailSuccess>
              </>
            )}
          </Form>
        )}
      </Formik>
    )
  }
}

export default FormEmail
