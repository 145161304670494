export const getTabs = (state) =>
  state.map(({ id, status, endTime }) => ({
    id,
    status,
    endTime,
  }))

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_VALUES':
      return state.map((curriculumEvent) =>
        curriculumEvent.id === action.id
          ? { ...curriculumEvent, ...action.values }
          : curriculumEvent
      )
    default:
      return state
  }
}

export const getInitialValues = (curriculumEvents) =>
  curriculumEvents.map((event) => ({
    ...event,
    activityDescription: undefined,
    isFormBuffed: false,
  }))

export const getConfirmButtonText = (
  isSuccess,
  isEventSuccess,
  isSubmitting
) => {
  if (isSubmitting) return 'Salvando'

  if (isSuccess) return 'Continuar editando'

  if (isEventSuccess) return 'Voltar para o currículo'

  return 'Salvar'
}

export const getMaterialsData = (
  initialMaterials,

  selectedMaterialsId
) => {
  const initialMaterialsId = initialMaterials.map(
    ({ curriculum_material_id }) => curriculum_material_id
  )

  const materialsToAdd = selectedMaterialsId
    .filter(
      (selectedMaterialId) => !initialMaterialsId.includes(selectedMaterialId)
    )
    .map((materialId) => ({
      curriculum_material_id: materialId,
    }))

  const materialsToDelete = initialMaterials
    .filter(
      (initialMaterial) =>
        !selectedMaterialsId.includes(initialMaterial.curriculum_material_id)
    )
    .map((material) => ({
      curriculum_material_id: material.curriculum_material_id,
      id: material.id,
      _destroy: true,
    }))

  return [...materialsToAdd, ...materialsToDelete]
}
