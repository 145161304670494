import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = (id) =>
  `/api/v3/foco_exam_editions/${id}/foco_exam_student_profiles`

export const useFocoExamStudentProfileStatus = (params) => {
  const { loading, error, data } = useResource({
    key: 'useManageExamsList',
    resource: getFocoExamsStudentProfileStatus,
    params,
  })

  return { loading, error, data }
}

const getFocoExamsStudentProfileStatus = ({ editionId, examId, classroomId }) =>
  fetchWithRoot('get')(endpoint(editionId), {
    bypassLoader: true,
    params: {
      fields: [
        'answered_rate',
        'students_count',
        'answered_count',
        'in_progress_count',
        'not_started_count',
        'average_hit_rate',
      ],

      q: {
        foco_exam_id_eq: examId,
        student_profile_classroom_id_eq: classroomId,
      },
    },
  })
