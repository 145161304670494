import React from 'react'
import useExamResult from '~data/examResult'
import useFilters from '~hooks/useFilters'

const getAnalyticsTrack = (UTM) => {
  if (!UTM) return ''

  return `utm_source=${UTM.source}%26utm_medium=${UTM.medium}%26utm_campaign=${
    UTM.campaign
  }`
}

export const useInsightContent = ({ entityName, skill, UTM }) => {
  const { filters, params } = useFilters()

  const { data: examResult } = useExamResult({ id: Number(params?.exam_id) })

  if (!filters || !examResult || !skill || !entityName) return null

  const priorityColors = ['🟥', '🟧', '🟨', '🟩']

  const analyticsTrack = getAnalyticsTrack(UTM)

  return {
    message: `${priorityColors[Number(skill.priority)]} ${skill.name}* - "${
      skill.description || ''
    }" é o descritor *mais prioritário* da ${entityName} em ${
      filters.subject_id.selected.name
    }${
      examResult ? `(${filters.grade_id.selected.name})` : ''
    } no ${examResult?.label || 'ENEM'} ${filters.year.selected.name}.
      %0D%0A%0D%0A🔎 Aprofunde sua análise. Acesse o Módulo de Devolutivas Pedagógicas para o resultado completo: https://modulopedagogico.com.br/pagina-inicial?${analyticsTrack}`,
  }
}

export const useNotificationContent = ({ notification, internalLink, UTM }) => {
  if (!notification || !internalLink) return null

  const analyticsTrack = getAnalyticsTrack(UTM)

  const convertToPlain = (text) => {
    const tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = text
    return tempDivElement.textContent || tempDivElement.innerText || ''
  }

  return {
    message: `${convertToPlain(notification.message)}*
      %0D%0A%0D%0A🔎 ${
        notification.url
          ? `${notification.url}`
          : `https://focoescola.com.br${internalLink}?${analyticsTrack}`
      }
      %0D%0A%0D%0A👉 Ainda não se cadastrou na Foco? Acesse: https://focoescola.com.br/cadastro?${analyticsTrack}`,
  }
}

export const useInsightSubjectContent = ({
  UTM,
  entityName,
  subject,
  rate,
}) => {
  const { filters } = useFilters()
  if (!entityName || !UTM) return null

  const analyticsTrack = getAnalyticsTrack(UTM)

  return {
    message: `A nota* média da ${
      filters?.grade_id?.options[0]?.name || 'turma'
    } da ${entityName} na ${
      filters?.edition_id?.options[0]?.name || 'serie'
    } - *${subject || ''} foi ${rate || ''}*.

    %0D%0A%0D%0A🔎 Aprofunde sua análise. Acesse o Módulo de Devolutivas Pedagógicas para o resultado completo: https://modulopedagogico.com.br/pagina-inicial?${analyticsTrack}`,
  }
}

export const useInsightProficiencyContent = ({
  UTM,
  entityName,
  proficiency,
  yearProficiency,
  statistic,
  currentYearProficiency,
  points,
}) => {
  if (!entityName || !UTM) return null

  const analyticsTrack = getAnalyticsTrack(UTM)

  return {
    message: `*${proficiency || ''} foi a proficiência da ${entityName} em ${
      currentYearProficiency || ''
    }*. Foi ${statistic || ''} de ${
      points || ''
    } pontos em relação a ${yearProficiency || ''} .

    %0D%0A%0D%0A🔎 Aprofunde sua análise. Acesse o Módulo de Devolutivas Pedagógicas para o resultado completo: https://focoescola.com.br/pagina-inicial?${analyticsTrack}`,
  }
}
