import { Formik } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { Stack, Typography, Link, Divider, Button } from '@mui/material'
import validate from './validate'
import GoogleAuth from './GoogleAuth'
import { Form } from '~primitives/Input/Form'
import { PasswordInput } from '~primitives/Input/Password'
import { TextInput } from '~primitives/Input/Text'
import { Heading, Text } from '~primitives/Typography'
import { NotificationBox } from '~primitives/Messages/NotificationBox'
import { fetchAuth } from '~actions/User'
import useAhoy from '~hooks/useAhoy'
import { keyboardClick } from '~helpers/events'

const FormLogin = () => {
  const dispatch = useDispatch()
  const [authError, setAuthError] = useState(null)
  const [loading, setLoading] = useState('idle')

  const handleFormSubmit = (formValues, { setErrors }) => {
    setAuthError(null)
    setLoading('loading')
    dispatch(fetchAuth(formValues)).then(([error]) => {
      if (error) {
        setAuthError(error)
        setLoading('idle')
        setErrors({
          email: 'Verifique se seu email está correto',
          password: 'Verifique se sua senha está correta',
        })
      } else {
        setLoading('success')
      }
    })
  }

  useAhoy('Login')

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={validate}
      validateOnChange
      onSubmit={handleFormSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isValid,
      }) => (
        <Form onSubmit={handleSubmit} noValidate onKeyPress={keyboardClick}>
          <aside>
            <Text margin="0">
              É seu primeiro acesso?{' '}
              <Link
                component={RouterLink}
                to="/cadastro"
                id="register-link"
                color="secondary"
              >
                Cadastre-se aqui
              </Link>
            </Text>
          </aside>

          <header>
            <Typography component="h2" variant="h2">
              Bem-vindo
            </Typography>
            <Typography component="span" variant="body1">
              Faça seu login para continuar
            </Typography>
            {!!authError && (
              <Heading as="h3" size={2} color="danger">
                *Disponível para as redes estaduais de{' '}
                <strong>ES, SE e RS</strong>
              </Heading>
            )}
          </header>

          <fieldset>
            <ul>
              <li>
                <TextInput
                  type="email"
                  id="email"
                  variant="outlined"
                  name="email"
                  label="E-mail"
                  placeholder="Digite seu e-mail"
                  disabled={loading === 'loading'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  helperText={errors.email}
                />
              </li>

              <li>
                <PasswordInput
                  name="password"
                  label="Senha"
                  variant="outlined"
                  placeholder="Digite sua senha"
                  disabled={loading === 'loading'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={errors.password}
                  helperText={errors.password}
                />
              </li>

              {!!authError && (
                <li>
                  <NotificationBox danger {...getErrorText(authError)} />
                </li>
              )}
            </ul>

            <footer>
              <Stack
                direction={{
                  sm: 'row-reverse',
                  xs: 'column',
                }}
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  data-testid="login-submit"
                  onClick={() => loading === 'idle' && handleSubmit()}
                  variant="primary"
                  sx={{ width: { xs: 1, sm: 220 } }}
                  disabled={!isValid}
                >
                  {loading === 'loading' ? 'Acessando' : 'Acessar'}
                </Button>

                <Link component={RouterLink} to="/recuperar-senha">
                  Esqueci a senha
                </Link>
              </Stack>

              <Divider sx={{ my: 2 }} />

              <Stack direction="column" spacing={2} alignItems="center" mb={2}>
                {window.location.protocol === 'https:' && (
                  <GoogleAuth
                    setAuthError={setAuthError}
                    setLoading={setLoading}
                  />
                )}
              </Stack>
            </footer>
          </fieldset>
        </Form>
      )}
    </Formik>
  )
}

const getErrorText = ({ status, message }) => {
  if (status === 401 && message == 'Usuário não encontrado.') {
    return {
      children: (
        <p>
          Esta conta Google não está associada a nenhum cadastro no Sigae. Caso
          possua um cadastro na Foco com outro endereço de e-mail, utilize os
          campos E-mail e Senha para acessar.
        </p>
      ),
    }
  }
  if (status === 401) {
    return {
      children: (
        <p>
          Login ou senha incorretos. Tente novamente ou{' '}
          <strong>
            {' '}
            <Link to="/recuperar-senha" component={RouterLink}>
              clique aqui
            </Link>
          </strong>{' '}
          para redefini-los.
        </p>
      ),
    }
  }

  return { title: 'Houve um erro na autenticação', text: message }
}

export default FormLogin
