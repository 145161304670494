import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Stack, Typography } from '@mui/material'
import { deleteJourneys } from '~data/api/v3/journeys/mutation/delete'
import { config } from '~data/api/v3/journeys/config'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { useFeedback } from '~contexts/Feedback'
import SuccessCheck from '~assets/svg/success_check.svg'
import DeleteCheck from '~assets/svg/delete_check.svg'
import Modal from '~primitives/Modal'

export const ModalDeleteJourney = ({
  isOpen,
  setIsOpen,
  entityId,
  journeyId,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const { showMessage } = useFeedback()

  const { key } = config

  const queryClient = useQueryClient()
  const { mutate: deleteJourneyRequest } = useMutation(
    () =>
      deleteJourneys({
        journeyId: Number(journeyId),
        params: {
          entityId,
          entityType: 'school',
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key)
        setIsSuccess(true)
      },
      onError: (error) => {
        showMessage({
          type: 'error',
          text: `Erro ao tentar deletar o Turno: ${error?.message}`,
        })
      },
      onSettled: () => {},
    }
  )

  const handleClose = () => {
    setIsOpen(false)
    setIsSuccess(false)
  }

  const handleConfirm = () => {
    if (isSuccess) {
      handleClose()
    } else {
      deleteJourneyRequest()
    }
  }
  return (
    <Modal
      onClose={handleClose}
      onConfirm={handleConfirm}
      closeButton={!isSuccess}
      width="500px"
      confirmButtonText={isSuccess ? 'Voltar para a lista' : 'Confirmar'}
      isOpen={isOpen}
    >
      <>
        <Typography variant="h4" margin="15px 0">
          <UnderlinedHeading as="span">
            <strong>Deletar Turno</strong>
          </UnderlinedHeading>
        </Typography>

        <Stack variant="modalBox">
          {!isSuccess && (
            <React.Fragment>
              <img src={DeleteCheck} alt="Ícone de deletar" height="90px" />
              <Typography variant="h5" component="h3">
                Atenção!
              </Typography>
              <Typography variant="body2" textAlign="center">
                Deseja realmente deletar este turno ?
              </Typography>
            </React.Fragment>
          )}
          {isSuccess && (
            <React.Fragment>
              <img src={SuccessCheck} alt="Ícone de sucesso" height="90px" />
              <Typography variant="h5" component="h3">
                Turno excluído com sucesso!
              </Typography>
            </React.Fragment>
          )}
        </Stack>
      </>
    </Modal>
  )
}
