import React from 'react'
import {
  Box,
  Chip,
  Divider,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { useFocoExamStudentProfileStatus } from '~data/manageExams/examStatus'
import { formatDecimalPercent } from '~helpers/formatting/numbers'

const percentFormatter = formatDecimalPercent(0)

export const StatusModal = ({ isOpen, setIsOpen, selectedExam }) => {
  const { palette } = useTheme()

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleConfirm = () => {
    handleClose()
  }

  const { data } = useFocoExamStudentProfileStatus({
    editionId: selectedExam.id,
  })

  const initialValues = [
    {
      name: 'Conclusão da avaliação',
      component: (
        <Stack justifyContent="center" alignItems="center">
          <Stack alignItems="center" direction="row">
            <Stack width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                sx={{
                  width: 120,
                  '.MuiLinearProgress-barColorPrimary': {
                    backgroundColor: palette.success.main,
                  },
                }}
                value={(data?.answered_rate ?? 0) * 100}
              />
            </Stack>
            <Stack minWidth={35}>
              <Typography variant="body2">{`${percentFormatter(
                data?.answered_rate ?? 0
              )}`}</Typography>
            </Stack>
          </Stack>
        </Stack>
      ),
    },

    {
      name: 'Total de alunos previstos',
      component: (
        <Box>
          <Typography variant="body2" component="span" fontSize={30}>
            {data?.students_count}
          </Typography>
        </Box>
      ),
    },

    {
      name: 'Não iniciadas',
      component: (
        <Stack gap={2} flexDirection="row" alignItems="center">
          <SVGIcon name="warning" size={3} color="red" />
          <Typography variant="body2" component="span" fontSize={30}>
            {data?.not_started_count}
          </Typography>
        </Stack>
      ),
    },

    {
      name: 'Em andamento',
      component: (
        <Stack gap={2} flexDirection="row" alignItems="center">
          <SVGIcon name="warning" size={3} color="warning" />
          <Typography variant="body2" component="span" fontSize={30}>
            {data?.in_progress_count}
          </Typography>
        </Stack>
      ),
    },
    {
      name: 'Finalizadas',
      component: (
        <Stack gap={2} flexDirection="row" alignItems="center">
          <SVGIcon name="checkBage" size={3} color="success" />
          <Typography variant="body2" component="span" fontSize={30}>
            {data?.answered_count}
          </Typography>
        </Stack>
      ),
    },
  ]

  return (
    <Modal
      onClose={handleClose}
      onConfirm={handleConfirm}
      width="600px"
      closeButtonText=""
      confirmButtonText="Voltar"
      showFooterDivider={false}
      isOpen={isOpen}
    >
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Status da avaliação</strong>
        </UnderlinedHeading>
      </Typography>

      <Stack variant="modalBox" overflow="hidden">
        <Stack justifyContent="space-between" flexDirection="row" width="100%">
          <Typography component="span" variant="body1">
            <strong>{selectedExam?.name}</strong>
          </Typography>
          <Chip label="Em curso" variant="labelSuccess" />
        </Stack>
        <Divider sx={{ my: 2, width: '100%' }} />
        <Stack flexDirection="column" gap={1.5} width="100%">
          {initialValues.map((item, index) => (
            <Stack
              key={`item-modal-${index}`}
              bgcolor="white"
              width="100%"
              sx={{
                padding: '15px 30px',
              }}
              borderRadius={0.5}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              minHeight="70px"
            >
              <Typography component="span" variant="body2">
                <strong>{item.name}</strong>
              </Typography>
              {item.component}
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack />
    </Modal>
  )
}
