import store from '~store'
import { track } from '~resources/ahoy'

const storageKey = '_focobr_session'

export const handleSamlLogin = () => {
  const sessionData = sessionStorage.getItem(storageKey)

  if (sessionData) {
    store.dispatch({ type: 'LOGOUT' })
    store.dispatch({ type: 'UPDATE_LOGIN', payload: JSON.parse(sessionData) })
    track('SSO Login')
    sessionStorage.removeItem(storageKey)
  }
}
