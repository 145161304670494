import React from 'react'
import {
  Badge,
  Divider,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
} from '@mui/material'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { useManageExamsStudentProfiles } from '~data/manageExams/examListDetailsStudent'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const AnswerListModal = ({
  isOpen,
  setIsOpen,
  id,
  studentName,
  hitHate,
  subjectName,
}) => {
  const handleClose = () => {
    setIsOpen(false)
  }

  const { data, loading, error } = useManageExamsStudentProfiles({ id })

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleClose}
      closeButton={false}
      width="600px"
      confirmButtonText="Voltar"
      overflowContent={false}
      height="90vh"
    >
      <>
        <Typography variant="h4">
          <UnderlinedHeading as="span">
            <strong>{studentName}</strong>
          </UnderlinedHeading>
        </Typography>

        <Stack
          variant="modalBox"
          sx={{
            height: 'calc(100% - 51px)',
          }}
        >
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography fontWeight={700}>{subjectName}</Typography>
            <Typography>
              <strong>Taxa de acerto</strong> {hitHate * 100}%
            </Typography>
          </Stack>

          <Divider />

          {!error && loading && getPlaceholder()}

          {data && (
            <TableContainer>
              <Table aria-label="Table data" variant="striped">
                <TableBody>
                  {data?.foco_answers?.length > 0 && !loading
                    ? data?.foco_answers?.map((item, index) => (
                        <TableRow
                          key={`'item-table-${index}`}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell>
                            <Stack direction="row" gap={1} alignItems="center">
                              <Badge
                                variant="index"
                                badgeContent={item.position}
                                color="secondary"
                              />
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{ display: 'block', minWidth: 75 }}
                              >
                                Questão {item.position}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack
                              direction="row"
                              gap={1}
                              alignItems="center"
                              justifyContent="end"
                            >
                              {item.correct ? (
                                <SVGIcon
                                  name="check"
                                  size={3}
                                  color="success"
                                />
                              ) : item.correct === null ? (
                                <SVGIcon
                                  name="warning"
                                  size={3}
                                  color="warning"
                                />
                              ) : (
                                <SVGIcon name="close" size={3} color="danger" />
                              )}
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{ display: 'block', minWidth: 75 }}
                                textAlign="end"
                              >
                                {item.correct
                                  ? ' Acertou'
                                  : item.correct === null
                                    ? 'Não respondeu'
                                    : ' Errou'}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </>
    </Modal>
  )
}

const getPlaceholder = () =>
  [...Array(6).keys()].map((_, index) => (
    <Stack key={`a-${index}`} direction="row" width="100%" gap={2}>
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />
    </Stack>
  ))
