import React from 'react'
import { Stack, Box, Skeleton, Typography } from '@mui/material'
import { Insights } from './Insights'
import List from './List'
import useFilters from '~hooks/useFilters'

export const ManagementContent = () => {
  const { filters } = useFilters()

  return (
    <Box mb={4}>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="h4">Visão geral</Typography>
          {filters ? (
            <Typography
              variant="body1"
              sx={{
                my: 1,
              }}
            >
              {filters?.curriculum_period_id?.selected.name &&
                `${filters?.curriculum_period_id?.selected.name}  • `}

              {filters?.subject_id?.selected.name &&
                ` ${filters?.subject_id?.selected.name} • `}

              {filters?.grade_id?.selected.name &&
                `${filters?.grade_id?.selected.name}`}

              {filters?.classroom_id?.selected.name &&
                `• ${filters?.classroom_id?.selected.name}`}
            </Typography>
          ) : (
            <LegendPlaceholder />
          )}
        </Box>
      </Stack>
      <Insights />
      <List />
    </Box>
  )
}

const LegendPlaceholder = () => (
  <Stack direction="row" gap={2} alignItems="center" height={24} my={1}>
    <Skeleton variant="text" height={16} width="100px" />
    <Skeleton variant="text" height={16} width="100px" />
    <Skeleton variant="text" height={16} width="100px" />
    <Skeleton variant="text" height={16} width="100px" />
  </Stack>
)
