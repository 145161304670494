import React from 'react'
import { Container, Alert } from '@mui/material'
import { useParams } from 'react-router-dom'
import Header from '~components/Common/Header'
import { ExamEditionForm } from '~components/ManageExams/Forms/ExamEdition'
import { useFocoExamEdition } from '~data/api/focoExamEdition/findOne'
import { useProfileClassrooms } from '~data/manageExams/examCreate'

export const ExamEditionEditForm = () => {
  const { focoExamEditionId, entityType, entityId } = useParams()
  const { data, loading, error } = useFocoExamEdition({
    id: focoExamEditionId ?? '',
    entityId: entityId ?? '',
    entityType: entityType ?? '',
    requestTemplate: 'exam-details',
  })
  const { data: dataGrades } = useProfileClassrooms()

  if (!data) return 'carregando ...'

  return (
    <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
      <Header
        title="Editar avaliação"
        breadcrumbs={[
          {
            text: 'Avaliações',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Gerenciar avaliações',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Editar avaliação',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }/editar/${focoExamEditionId ?? ''}`,
          },
        ]}
      />

      {loading && <span>Carregando...</span>}

      {error && (
        <Alert variant="filled" severity="error">
          Ocorreu um erro ao carregar os dados da avaliação.
        </Alert>
      )}

      <ExamEditionForm
        grades={dataGrades?.grades}
        dataEdit={data}
        method="put"
      />
    </Container>
  )
}

export default ExamEditionEditForm
