import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpointProfileClassrom = '/api/v3/profiles/grades'

export const useGrades = (params) => {
  const { data, loading, error } = useResource({
    key: 'useGrades',
    resource: getGrades,
    params: params,
    enabled: !!params.year,
  })
  return { data, loading, error }
}

const getGrades = ({ year }) =>
  fetchWithRoot('get')(endpointProfileClassrom, {
    params: {
      year,
      fields: [{ grades: ['id', 'name', 'classrooms_with_subjects'] }],
      include: ['grades'],
    },
  })
