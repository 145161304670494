import React from 'react'
import {
  Skeleton,
  useTheme,
  Box,
  Stack,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { useQuestion } from '~data/questions/question'
import { pxToRem } from '~helpers/getFontValue'

export const QuestionListItem = ({
  selectedQuestionId,
  handleRemoveQuestion,
  handleOpenQuestionDetailsModal,
}) => {
  const { data, loading } = useQuestion({
    questionId: selectedQuestionId,
  })

  const { palette } = useTheme()
  const styles = getStyles()

  const handleDelete = (id) => {
    handleRemoveQuestion(id)
  }

  return (
    <React.Fragment>
      <Box style={{ width: 150 }}>
        {!data && loading && <Skeleton variant="text" />}
        {data && <Typography variant="body2">{data?.code}</Typography>}
      </Box>
      <Box sx={{ ...styles.listItem }}>
        {!data && loading && <Skeleton variant="text" />}
        {data && <Typography variant="body2">{data?.text}</Typography>}
      </Box>
      <Box sx={{ ...styles.listItem }}>
        {!data && loading && <Skeleton variant="text" />}
        {data && (
          <Typography variant="body2">
            <strong>({data?.curriculum_skill.name})</strong>{' '}
            {data?.curriculum_skill.description}
          </Typography>
        )}
      </Box>
      <Stack direction="row" gap={1}>
        <ListItemButton
          data-testid="button-action-edit"
          variant="outlinedSecondary"
          onClick={() => {
            handleOpenQuestionDetailsModal(selectedQuestionId)
          }}
        >
          <ListItemIcon>
            <SVGIcon name="eye" color={palette.secondary.main} size={3} />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton
          variant="outlinedSecondary"
          onClick={() => handleDelete(selectedQuestionId)}
        >
          <ListItemIcon>
            <SVGIcon name="delete" color={palette.secondary.main} size={3} />
          </ListItemIcon>
        </ListItemButton>
      </Stack>
    </React.Fragment>
  )
}

const getStyles = () => ({
  listItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    height: 44,
    lineHeight: pxToRem(22),
    padding: 0,
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
})
