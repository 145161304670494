import { createReducer } from 'reduxsauce'
import { merge } from 'ramda'

const beginRequest = (state) => merge(state, { fetching: true })

const endRequest = (state) => merge(state, { fetching: false })

const initialState = { fetching: false }

const handlers = { BEGIN_REQUEST: beginRequest, END_REQUEST: endRequest }

export default createReducer(initialState, handlers)
