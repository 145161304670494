import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { range } from 'ramda'
import qs from 'qs'
import { PaginationUl } from './styles'

export const Pagination = ({
  siblingCount = 4,
  totalPages,
  currentPage = 1,
  onClickPage = () => {},
  ...props
}) => {
  const { pathname, search } = useLocation()

  const query = qs.parse(search, { ignoreQueryPrefix: true })

  const endPage = range(Math.max(totalPages, 2), totalPages + 1)

  const siblingsStart = Math.max(
    3,
    Math.min(currentPage - siblingCount, totalPages - siblingCount * 2 - 2)
  )

  const siblingsEnd = Math.min(
    Math.max(currentPage + siblingCount, siblingCount * 2 + 2),
    totalPages - 2
  )

  const pages = [
    1,

    ...(siblingsStart > 3 ? ['start-ellipsis'] : 2 < totalPages - 1 ? [2] : []),

    ...range(siblingsStart, siblingsEnd + 1),

    ...(siblingsEnd < totalPages - 2
      ? ['end-ellipsis']
      : totalPages > 2
        ? [totalPages - 1]
        : []),

    ...endPage,
  ]

  if (!totalPages) return null

  return (
    <nav aria-label="Links de Paginação">
      <PaginationUl aria-label="Páginas" {...props}>
        {pages.map((page, index) => {
          const search = qs.stringify({ ...query, page }, { encode: false })
          if (page === 'start-ellipsis' || page === 'end-ellipsis')
            return (
              <li key={index} data-testid="ellipsis">
                <span aria-hidden="true">...</span>
              </li>
            )

          return (
            <li key={index}>
              <Link
                to={{ pathname, search }}
                aria-label={'Ir para a página' + ' ' + page}
                aria-current={page === currentPage ? true : undefined}
                data-testid={`list-item-${page}`}
                onClick={onClickPage}
              >
                {page}
              </Link>
            </li>
          )
        })}
      </PaginationUl>
    </nav>
  )
}
