import React from 'react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Typography } from '@mui/material'

export const TimePickerField = ({
  id,
  name,
  label,
  disabled,
  required,
  field,
  form,
  placeholder,
  fullWidth = true,
  ...props
}) => {
  const error = form.touched[field.name] && form.errors[field.name]

  return (
    <>
      <TimePicker
        name={field.name}
        defaultValue={field.value}
        value={field.value}
        label={label}
        onChange={(value) => form.setFieldValue(field.name, value)}
        disabled={disabled}
        sx={{ pb: '0px' }}
        slotProps={{
          label: { for: id ?? field.name },
          textField: {
            id: id ?? field.name,
            variant: 'standard',
            size: 'small',
            fullWidth,
            required,
          },
        }}
        {...props}
      />

      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </>
  )
}
