import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = (id) => `/api/v3/foco_exam_student_profiles/${id}`

export const useManageExamsStudentProfiles = (params) => {
  const { data, error, loading } = useResource({
    key: 'useManageExamsStudentProfiles',
    resource: getManageExamsStudentProfiles,
    params,
    enabled: true,
  })

  return { loading, error, data }
}

const getManageExamsStudentProfiles = ({ id }) =>
  fetchWithRoot('get')(endpoint(id), {
    params: {
      include: ['foco_answers'],
      fields: [
        {
          foco_answers: ['id', 'position', 'correct'],
        },
      ],
    },
  })
