import React from 'react'
import { Typography, Stack } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import Modal from '~primitives/Modal'
import { Scribble } from '~primitives/Typography/Underline'
import SuccessCheck from '~assets/svg/support-form-success.svg'

export const SuccessSubmitModal = ({
  isOpen,
  setIsOpen,
  basePath,
  resetInitialValuesOnSubmit,
}) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const handleClose = () => {
    window.scrollTo(0, 0)
    setIsOpen(false)
    if (resetInitialValuesOnSubmit) {
      queryClient.invalidateQueries('useBookletFormDetails')
    } else {
      history.push({
        pathname: basePath,
      })
    }
  }

  const handleConfirm = () => {
    setIsOpen(false)
    history.push({
      pathname: `${basePath}/criar`,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      closeButtonText="Voltar para a lista de cadernos"
      confirmButtonText="Criar novo"
      onClose={handleClose}
      onConfirm={handleConfirm}
      showFooterDivider={false}
      footerPadding="0 35px 25px"
      width="500px"
    >
      <Typography
        fontSize={24}
        fontWeight={700}
        my={0.75}
        mt={2}
        sx={{
          '& span': { pb: 0 },
        }}
      >
        <Scribble>Caderno salvo com sucesso!</Scribble>
      </Typography>
      <Stack
        alignItems="center"
        gap={1}
        sx={{ bgcolor: 'grey.200', py: 4, mt: 3 }}
      >
        <img src={SuccessCheck} alt="Sucesso" />
        <Typography variant="h5" fontWeight={700}>
          Parabéns!
        </Typography>
        <Typography variant="body2">
          Seu caderno foi salvo com sucesso!
        </Typography>
      </Stack>
    </Modal>
  )
}
