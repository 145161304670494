export const requestParamsConfig = {
  'exam-details': (params) => ({
    entity_id: params.entityId,
    entity_type: params.entityType,
    fields: [
      'id',
      'name',
      'active',
      'exam_start',
      'exam_end',
      'student_exams',
      'description',
      'publish_date',
      'instructions',
      'time_limit',
      { subjects: ['id', 'name'], grades: ['id', 'name'] },
    ],
    include: [
      'grades',
      'subjects',
      'foco_exam_edition',
      { grade_subjects: ['grade', 'subject'] },
    ],
  }),
  'exam-results': (params) => ({
    entity_id: params.entityId,
    entity_type: params.entityType,
    fields: ['name'],
  }),
}
