import { useEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

const exceptionPathname = [
  '/painel/escolas/:schoolId/ciclos/:cycleId/:indicator',
  '/painel/analises/ciclos/:cycleId',
  '/diagnostica/:examId/:feature/:resultableType/:resultableId/analise-questoes/:questionID',
  '/diagnostica/:examId/:feature/:entityType/:entityId/descritores/:skillId',
  '/enem/analise-questoes/',
]

const pathnameMatchesExceptions = (pathname = '') => {
  return !!exceptionPathname.find((exception) =>
    matchPath(pathname, { path: exception, strict: false, exact: false })
  )
}

const useScrollRestoration = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (!pathnameMatchesExceptions(pathname)) {
      window.scrollTo(0, 0)
    }
  }, [pathname])
}

export default useScrollRestoration
