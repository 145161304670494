import React, { useState } from 'react'
import { Button } from '@mui/material'

import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const DownloadCSV = ({ getCSV }) => {
  const [status, setStatus] = useState('waiting')

  const handleClick = () => {
    setStatus('loading')

    getCSV().then(([error]) => {
      if (error) {
        setStatus('error')
        return
      }

      setStatus('waiting')
    })
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<SVGIcon name="exportButton" color="linkColor" size={3} />}
      onClick={handleClick}
      disabled={status === 'loading'}
      size="small"
    >
      Exportar lista
    </Button>
  )
}

export default DownloadCSV
