import React, { createContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Notifications from '~components/Notifications'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

export const endpoint = '/api/v3/notifications'

const getNotifications = (params) =>
  fetchWithRoot('get')(endpoint, {
    params: {
      ...params,
    },
    bypassLoader: true,
  })

export const NotificationsContext = createContext({
  notifications: null,
  updateNotificationStatus: () => {},
  updateAllNotificationsStatus: () => {},
  popoverIsOpen: null,
  setPopoverIsOpen: () => {},
})

export const NotificationsProvider = ({ children }) => {
  const user = useSelector((state) => state.user)
  const profile = user.profiles.find(({ id }) => id === user.selectedProfile)
  const isStudent = user.student
  const [notifications, setNotifications] = useState([])
  const [popoverIsOpen, setPopoverIsOpen] = useState(null)

  const { loading, data } = useResource({
    key: 'getNotifications',
    resource: getNotifications,
    enabled: !isStudent,
    params: {
      include: ['notifications'],
      q: {
        notifications_districts_district_id_eq: profile.personable?.district_id,
        s: 'created_at desc',
      },
    },
  })

  useEffect(() => {
    if (!loading && data) {
      setNotifications(data.notifications)
    }
  }, [loading, data])

  const updateAllNotificationsStatus = () => {
    notifications
      .filter((notification) => !notification.read)
      .map(({ id }) => {
        fetchWithRoot('put')(`${endpoint}/${id}`, {
          data: { user_ids: user.id },
          bypassLoader: true,
        })
      })

    const mapped = notifications.map((notification) => {
      return !notification.read
        ? { ...notification, read: true }
        : { ...notification }
    })
    setNotifications(mapped)
  }

  const updateNotificationStatus = (id) => {
    fetchWithRoot('put')(`${endpoint}/${id}`, {
      data: { user_ids: user.id },
      bypassLoader: true,
    })

    const mapped = notifications.map((notification) => {
      return notification.id === id
        ? { ...notification, read: true }
        : { ...notification }
    })
    setNotifications(mapped)
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        updateNotificationStatus,
        updateAllNotificationsStatus,
        popoverIsOpen,
        setPopoverIsOpen,
      }}
    >
      <Notifications />
      {children}
    </NotificationsContext.Provider>
  )
}

export const NotificationsConsumer = NotificationsContext.Consumer

export default NotificationsContext
