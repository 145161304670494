import { useMutation, useQueryClient } from 'react-query'
import { config } from '../../config'
import { fetchWithRoot } from '~helpers/fetch'

const { endpoint } = config

export const useDeleteAcademicYear = ({ onError, onSuccess }) => {
  const queryClient = useQueryClient()

  return useMutation((params) => deleteAcademicYear(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(config.key)
      onSuccess(data)
    },
    onError,
  })
}

export const deleteAcademicYear = async (params) => {
  const [error, data] = await fetchWithRoot('delete')(
    `${endpoint(params.entityType, params.entityId)}/${params.id}`,
    {
      params: { entity_type: params.entityType, entity_id: params.entityId },
      bypassLoader: true,
    }
  )

  if (error) throw new Error(error.message)

  return data
}
