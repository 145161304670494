import React from 'react'
import Spinner from '../SVG/Spinner'
import { LoaderWrapper } from './styles'

const Loader = () => (
  <LoaderWrapper data-testid="global-loader">
    <Spinner />
  </LoaderWrapper>
)

export default Loader
