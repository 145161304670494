import { fetchWithoutRoot } from '~helpers/fetch'

export const endpoints = {
  validateDocument: (cpf) => `/api/v2/authentication/validate_document/${cpf}`,
  validateUserData: '/api/v2/authentication/validate_user_data',
  createUser: '/api/v2/authentication/users',
}

const validateDocument = (cpf) =>
  fetchWithoutRoot('get')(endpoints.validateDocument(cpf))

const validateUserData = (params) =>
  fetchWithoutRoot('get')(endpoints.validateUserData, {
    headers: { Document: params.cpf, 'Birth-year': params.birthYear },
  })

// send invite
const createUser = (params) =>
  fetchWithoutRoot('post')(endpoints.createUser, {
    headers: {
      Document: params.cpf,
      'Birth-year': params.birthYear,
      Email: params.email,
      'Email-confirmation': params.confirm,
    },
    data: {
      accept_terms_and_conditions: params.acceptTermsAndConditions,
    },
  })

export default {
  endpoints,
  validateDocument,
  validateUserData,
  createUser,
}
