const drawerWidth = {
  desktop: 190,
  mobile: 284,
}

export const getStyles = (theme) => ({
  buttonMobile: {
    position: 'fixed',
    top: 10,
    left: 0,
    zIndex: 11,
    py: 1,
  },
  logo: {
    width: 1,
    mt: 2,
    [theme.breakpoints.up('sm')]: {
      mt: 3,
    },
  },
  menu: {
    container: {
      width: drawerWidth.mobile,
      minHeight: 1,
      transition: 'width 0.2s ease-in, left 0.2s ease-in-out',
      zIndex: 100,
      overflowX: 'hidden',
      '@media print': {
        display: 'none',
      },
      '& .MuiDrawer-paper': {
        width: drawerWidth.mobile,
        bgcolor: theme.palette.grey[800],
        border: 'none',
        boxShadow: 'none',
        px: 1,
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth.desktop,
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth.desktop,
      },
    },
  },
})
