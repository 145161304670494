import { config } from '../config'
import { fetchWithoutRoot } from '~helpers/fetch'

const { endpoint } = config

const getEndpoint = (studentProfileId) => `${endpoint}/${studentProfileId}`

export const deleteStudentProfile = async ({ studentProfileId, params }) => {
  const [response] = await fetchWithoutRoot('delete')(
    getEndpoint(studentProfileId),
    {
      params: {
        entity_type: params.entityType,
        entity_id: params.entityId,
      },
    }
  )

  if (response?.message) {
    throw new Error(response)
  }
}
