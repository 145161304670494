import React, { useState } from 'react'
import { Container } from '@mui/material'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { SchoolForm } from '~components/AcademicYear/Form/SchoolForm'
import Header from '~components/Common/Header'
import { useFeedback } from '~contexts/Feedback'
import { useCreateAcademicYear } from '~data/api/v3/academicYears/mutation/create'

export const CreateSchoolAcademicYearPage = () => {
  const [success, setSuccess] = useState(false)
  const { showMessage } = useFeedback()

  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const entityId = Number(params.entityId)

  const initialValues = {
    parentAcademicYear: null,
    year: null,
    expectedTotalDays: null,
    initialDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    entityType: 'school',
    entityId: entityId,
    curriculumPeriods: [],
  }

  const { mutate: createAcademicYear } = useCreateAcademicYear({
    onSuccess: () => setSuccess(true),
    onError: (error) => {
      showMessage({ type: 'error', text: `Erro: ${error.message}` })
    },
  })

  const handleCloseModal = () => {
    setSuccess(false)
    history.push(`/gerenciar-calendario/school/${entityId}/anos-letivos/`)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Header
          title="Adicionar ano letivo"
          breadcrumbs={[
            {
              text: 'Cadastrar',
              pathname: `/gerenciar-calendario/school/${entityId}/anos-letivos`,
            },
            {
              text: 'Ano letivo',
              pathname: `/gerenciar-calendario/school/${entityId}/anos-letivos`,
            },
            {
              text: 'Adicionar',
              pathname: location.pathname,
            },
          ]}
        />

        <SchoolForm
          type="create"
          schoolId={entityId}
          initialValues={initialValues}
          onSubmit={createAcademicYear}
          success={success}
          onCloseModal={handleCloseModal}
        />
      </Container>
    </LocalizationProvider>
  )
}

export default CreateSchoolAcademicYearPage
