import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { track } from '~resources/ahoy'

const useSupportWidget = () => {
  const user = useSelector((state) => state.user)

  const open = useCallback(() => {
    if (window.zE) {
      track('Support widget / Open')

      if (user.session.token) {
        window.zE('webWidget', 'prefill', {
          name: { value: user.name },
          email: { value: user.session.uid },
        })
      }

      window.zE('webWidget', 'open')
    } else {
      alert(
        'Não foi possível encontrar o formulário de suporte. Se você usa algum tipo de bloqueador de conteúdo, desative-o e tente novamente.'
      )
    }
  }, [user])

  return { open }
}

export default useSupportWidget
