import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/foco_questions'

const useQuestions = (params) => {
  const { loading, error, data } = useResource({
    resource: getQuestions,
    params,
    key: 'useFocoQuestions',
    enabled: !!params.skillId,
  })

  return { loading, error, data }
}

const getQuestions = ({ skillId, gradeId }) =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: [{ foco_questions: ['skill'] }],
      fields: [
        {
          foco_questions: [
            'id',
            'code',
            'text',
            { curriculum_skill: ['id', 'name', 'description'] },
          ],
        },
      ],
      q: { curriculum_skill_id_eq: skillId, grade_id_eq: gradeId },
    },
  })

export default useQuestions
