import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (id) =>
  `/api/v3/foco_exam_student_profiles/${id}/update_exam_status`

export const updateFocoExamStart = async ({ id, data }) =>
  fetchWithRoot('patch')(getEndpoint(id), {
    data,
    bypassLoader: true,
  }).then(([error]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
  })
