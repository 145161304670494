import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

const useExam = (params) => {
  const { loading, error, data } = useResource({
    key: 'useTeacherFocoExam',
    resource: getExam,
    params,
  })

  return { loading, error, data }
}

export const endpoint = (id) => `/api/v3/foco_exams/${id}`

const getExam = ({ id, entityType, entityId, classroomId }) =>
  fetchWithRoot('get')(endpoint(id), {
    params: {
      entity_type: classroomId ? 'classroom' : entityType,
      entity_id: classroomId || entityId,
      fields: [
        'amount_of_created',
        'amount_of_displayed',
        'amount_of_answered',
        'completion_rate',
        'time_average',
      ],
      q: {
        student_profile_classroom_id_eq: classroomId,
      },
    },
    bypassLoader: true,
  })

export default useExam
