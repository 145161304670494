import React from 'react'
import { Link } from 'react-router-dom'
import { Stack, useTheme } from '@mui/material'
import { getStyles } from './styles'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const FeatureLink = ({ to, iconName, text, isActive, onMouseOver }) => {
  const theme = useTheme()
  const styles = getStyles(theme, isActive)

  return (
    <Stack component="li" sx={{ ...styles.general }} onMouseOver={onMouseOver}>
      <Link to={to}>
        <SVGIcon name={iconName} color={theme.palette.grey[200]} size="20px" />
        {text}
      </Link>
    </Stack>
  )
}
