export default function Button(theme) {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'transparent' },
          style: {
            padding: 0,
            margin: 0,
            '&:hover': {
              background: 'transparent',
              color: theme.palette.button.secondary,
            },
          },
        },
        {
          props: { variant: 'outlined-noborder' },
          style: {
            fontSize: '14px',
            lineHeight: '18px',
            padding: '10px 15px',
            color: theme.palette.secondary['main'],
            borderRadius: theme.shape.borderRadius * 2,
            margin: 0,
            '&:hover': {
              textDecoration: 'none',
              backgroundColor: 'transparent',
              border: `1px solid ${theme.palette.button.secondary}`,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontSize: '14px',
            lineHeight: '18px',
            padding: '10px 15px',
            color: theme.palette.button.secondary,
            borderRadius: theme.shape.borderRadius * 2,
            border: `1px solid ${theme.palette.button.secondary}`,
            '& > i': {
              backgroundColor: theme.palette.button.secondary,
              transition: 'background-color 250ms',
            },
            '&:hover > i': {
              backgroundColor: theme.palette.common.white,
            },
            '&:hover': {
              textDecoration: 'none',
              color: theme.palette.common.white,
              backgroundColor: theme.palette.button.secondarydark,
              borderColor: theme.palette.button.secondarydark,
              '& span > i': {
                backgroundColor: theme.palette.common.white,
              },
            },

            '&.Mui-disabled': {
              '& span > i': {
                backgroundColor: theme.palette.grey['500_80'],
              },
            },
          },
        },
        {
          props: { variant: 'outlined-icon' },
          style: {
            fontSize: '14px',
            lineHeight: '18px',
            padding: '10px 15px',
            color: theme.palette.button.secondary,
            borderRadius: theme.shape.borderRadius * 2,
            border: `1px solid ${theme.palette.button.secondary}`,
            minWidth: '0',
            '& > i': {
              backgroundColor: theme.palette.button.secondarydark,
              transition: 'background-color 250ms',
            },
            '&:hover > i': {
              backgroundColor: theme.palette.button.secondarydark,
            },
            '&:hover': {
              textDecoration: 'none',
              color: theme.palette.common.white,
              backgroundColor: theme.palette.button.secondarydark,
              borderColor: theme.palette.button.secondarydark,
              '& span > i': {
                backgroundColor: theme.palette.common.white,
              },
            },

            '&.Mui-disabled': {
              '& span > i': {
                backgroundColor: theme.palette.grey['500_80'],
              },
            },
          },
        },
        {
          props: { variant: 'rounded' },
          style: {
            ...theme.typography.body2,
            backgroundColor: theme.palette.secondary['main'],
            padding: '5px 25px',
            borderRadius: '32px',
            color: `${theme.palette.grey['100']} !important`,
          },
        },
        {
          props: { variant: 'primaryButtonPublicAarea' },
          style: {
            display: 'block',
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
            maxWidth: '100%',
            borderRadius: '8px',
            textAlign: 'center',
            paddingTop: 0,
            paddingBottom: 0,
            zIndex: 9,
            '&:hover': {
              background: theme.palette.secondary.light,
              color: theme.palette.common.white,
            },

            '&.Mui-disabled': {
              background: '#8C8C8C',
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: { variant: 'primaryButtonPublicAareaMedium' },
          style: {
            display: 'block',
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
            fontSize: '16px',
            maxWidth: '100%',
            borderRadius: '8px',
            textAlign: 'center',
            paddingTop: 0,
            paddingBottom: 0,
            zIndex: 9,
            '&:hover': {
              background: theme.palette.secondary.light,
              color: theme.palette.common.white,
            },

            '&.Mui-disabled': {
              background: theme.palette.grey[550],
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            display: 'block',
            background: theme.palette.button.secondary,
            color: theme.palette.common.white,
            maxWidth: '100%',
            borderRadius: theme.shape.borderRadius * 2,
            textAlign: 'center',
            paddingTop: 0,
            paddingBottom: 0,
            zIndex: 9,
            '&:hover': {
              background: theme.palette.button.secondarydark,
              color: theme.palette.common.white,
            },

            '&.Mui-disabled': {
              background: theme.palette.grey[550],
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: { variant: 'neutral' },
          style: {
            display: 'block',
            background: 'transparent',
            color: theme.palette.grey['650'],
            maxWidth: '100%',
            borderRadius: theme.shape.borderRadius * 2,
            textAlign: 'center',
            paddingTop: 0,
            paddingBottom: 0,
            zIndex: 9,
            '&:hover': {
              color: theme.palette.grey['650'],
              background: 'transparent',
            },
          },
        },
        {
          props: { variant: 'white' },
          style: {
            width: '100%',
            background: theme.palette.grey['0'],
            borderRadius: theme.shape.borderRadius * 2,
            justifyContent: 'space-between',
            boxShadow: 'none',
            '&:hover': {
              background: theme.palette.grey['0'],
              color: 'inherit',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { variant: 'skill' },
          style: {
            minWidth: '100px',
            height: '30px',
            fontWeight: 400,
            background: theme.palette.grey[250],
            borderRadius: theme.shape.borderRadius * 2,
            color: theme.palette.primary.main,
            boxShadow: 'none',
            transition: 'all .3s ease-in-out',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
            padding: '6px 0',
            '&:hover': {
              fontWeight: 700,
              background: theme.palette.grey[250],
              color: 'inherit',
              boxShadow: 'none',
              borderRadius: '4px 4px 0 0',
              zIndex: 9999999,
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            minHeight: '32px',
            height: 'auto',
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.grey[200],
            borderRadius: theme.shape.borderRadius * 2,
            minWidth: '0',
            padding: '4px 8px',
            lineHeight: 'inherit',
            '&:hover': {
              backgroundColor: theme.palette.grey[250],
            },
          },
        },
      ],
      defaultProps: { underline: 'none' },
      styleOverrides: {
        root: {
          height: 45,
          lineHeight: '45px',
          minWidth: 160,
          fontWeight: 400,
          textTransform: 'inherit',
          '&:hover': { color: theme.palette.secondary.main },
        },
        sizeSmall: {
          height: 35,
          lineHeight: '35px',
          minWidth: 0,
        },
      },
    },
  }
}
