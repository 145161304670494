import React from 'react'
import ReactQuill from 'react-quill'
import 'quill/dist/quill.snow.css'
import { Box } from '@mui/material'
import Label from '../Label'

const QuillInput = ({
  id,
  placeholder = '',
  formats,
  initialValue = '',
  label,
  onChange,
  value,
}) => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],

      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link'],
    ],
  }

  return (
    <Box width="100%" sx={{ '.ql-editor': { backgroundColor: 'grey.0' } }}>
      {label && <Label htmlFor={id}>{label}</Label>}

      <ReactQuill
        value={value}
        initialValue={initialValue}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        style={{ height: 200 }}
      />
    </Box>
  )
}

export default QuillInput
