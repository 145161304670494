import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { Buttons, Container, Description, IconWrapper, Title } from './styles'
import { SVGImage } from '~primitives/Media/SVG'
import { getPath } from '~helpers/paths'
import { getSelectedProfile } from '~selectors/profile'

const mapStateToProps = (state) => ({
  profile: getSelectedProfile(state),
  user: state.user,
})

const Thumb = ({ profile, id, title, text, icon, buttons, feature }) => (
  <Container id={id} data-testid="home-feature-thumb">
    <header>
      <Title>{title}</Title>

      <IconWrapper>
        <SVGImage alt={title} src={icon} />
      </IconWrapper>
    </header>

    <Description>{text}</Description>

    <Buttons>
      {buttons.map((item, i) => (
        <Button
          component={Link}
          variant="primary"
          key={i}
          to={getPath({
            feature,
            examType: item.exam_type,
            resource: item.resource,
          })(item.id, profile)}
          data-testid={`home-link-${feature}-${item.resource}`}
        >
          {item.label}
        </Button>
      ))}
    </Buttons>
  </Container>
)

export default connect(mapStateToProps)(Thumb)
