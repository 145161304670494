import React from 'react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import TableHeader from '~components/FocoExams/Teacher/TableHeader'

const Header = ({ setOrder }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <TableHeader label="Regionais" property="name" reorder={setOrder} />
        </TableCell>
        <TableCell>
          <TableHeader
            label="Taxa de conclusão"
            property="foco_exam_completion_rate"
            reorder={setOrder}
            sx={{
              justifyContent: 'center',
            }}
          />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

export default Header
