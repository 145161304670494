import { fetchWithRoot } from '~helpers/fetch'

const endpoint_event = (eventId) => `api/v3/curriculum_events/${eventId}`

export const updateEvent = ({ data, eventId, entityId }) => {
  return fetchWithRoot('patch')(endpoint_event(eventId), {
    data: {
      entity_id: entityId,
      entity_type: 'school',
      curriculum_event: {
        ...data,
      },
    },
  })
}
