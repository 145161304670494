export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        root: {
          boxShadow: theme.customShadows.dropdown,
        },
        paper: {
          width: 340,
          borderRadius: Number(theme.shape.borderRadius),
          border: `1px solid ${theme.palette.grey[200]}`,
        },
      },
    },
  }
}
