export default function FormGroup(theme) {
  return {
    MuiFormGroup: {
      variants: [],
      styleOverrides: {
        root: {
          position: 'relative',
          paddingBottom: '25px',

          '& .MuiFormHelperText-root': {
            position: 'absolute',
            bottom: 0,
            margin: 0,
            color: theme.palette.error.main,
          },
        },
      },
    },
  }
}
