import { IMaskInput } from 'react-imask'
import React from 'react'
import { TextField } from '@mui/material'
import { Wrapper } from './styles'

export const InputWrapper = Wrapper

const InputMask = React.forwardRef(function InputMask(
  { name, mask, definitions, onChange, ...inputProps },
  ref
) {
  return (
    <IMaskInput
      {...inputProps}
      name={name}
      mask={mask}
      definitions={definitions}
      inputRef={ref}
      onChange={onChange}
      onAccept={(value) => onChange({ target: { name: name, value } })}
      overwrite
    />
  )
})

export const TextInput = ({
  error,
  icon,
  type = 'text',
  name,
  id,
  placeholder,
  mask,
  definitions,
  label,
  required,
  value,
  onChange,
  onBlur,
  InputProps,
  inputRef,
  variant = 'standard',
  size = 'medium',
  ...props
}) => {
  const componentProps = {
    type,
    id: id ?? name,
    name,
    label,
    placeholder,
    value,
    error,
    variant,
    size,
    onChange,
    onBlur,
    inputRef,
    required,
    fullWidth: true,
    InputLabelProps: { shrink: true },
    ...props,
  }

  if (mask) {
    return (
      <TextField
        InputProps={{
          ...InputProps,
          inputComponent: InputMask,
          inputProps: { mask, definitions, 'data-testid': id || name },
        }}
        {...componentProps}
      />
    )
  }

  return (
    <TextField
      InputProps={{ ...InputProps, inputProps: { 'data-testid': id || name } }}
      {...componentProps}
    />
  )
}

export const FormikText = ({ field, form, ...props }) => {
  const { name, value = '' } = field
  const inputError = form.touched[name] && form.errors[name]

  return (
    <>
      <TextInput
        onChange={field.onChange}
        onBlur={field.onBlur}
        name={name}
        value={value}
        error={!!inputError}
        helperText={inputError}
        {...props}
      />
    </>
  )
}
