import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/foco_exam_editions'

export const useManageExamsList = (params) => {
  const validation = [
    'entityType',
    'entityId',
    'id',
    'subjectId',
    'gradeId',
    'status',
  ]
  const { data, error, loading } = useResource({
    key: 'useManageExamsList',
    resource: getManageExamsList,
    params,
    enabled: validation.every((key) => params[key] !== undefined),
  })

  return { loading, error, data }
}

const getManageExamsList = (params) =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_type: params?.entityType,
      entity_id: params?.entityId,
      page: params?.page || 1,
      per_page: 10,
      include: [
        {
          foco_exam_editions: ['subjects', 'grades'],
        },
      ],
      fields: [
        'not_started_count',
        'in_progress_count',
        'finished_count',
        {
          foco_exam_editions: [
            'id',
            'name',
            'status',
            'subjects',
            'grades',
            'exam_start',
            'exam_end',
            'foco_exam',
            'foco_exams_count',
            'participation_rate',
            'completed',
            { grades: ['id', 'name', 'short_name'] },
            { subjects: ['id', 'name'] },
          ],
        },
        'meta',
      ],
      q: {
        id_eq: params?.id ? params?.id : '',
        by_subject: params?.subjectId,
        by_grade: params?.gradeId,
        by_status: params?.status,
        s:
          params?.order &&
          params?.orderBy &&
          `${params?.orderBy} ${params?.order}`,
      },
    },
  })
