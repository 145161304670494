import { createReducer } from 'reduxsauce'

export const initialState = {
  name: '',
  session: { token: null, type: null, client: null, expiry: null, uid: null },
  selectedProfile: null,
  profiles: [],
  features: {
    enem: { label: null, description: null, resources: [] },
    indicators: { label: null, description: null, resources: [] },
    retrospective: { label: null, description: null, resources: [] },
    prospective: { label: null, description: null, resources: [] },
  },
  showNewHome: true,
  active: false,
  accept_terms_and_conditions: false,
  student: false,
}

const updateLogin = (state, { payload }) => ({
  ...state,
  session: payload,
})

const logout = () => initialState

const getProfileList = (state, { payload }) => ({
  ...state,
  profiles: payload,
})

const selectProfile = (state, { payload }) => ({
  ...state,
  selectedProfile: payload,
  features: initialState.features,
})

const getUserData = (state, { payload }) => ({
  ...state,
  ...payload,
})

const updateProfile = (state, { payload }) => ({
  ...state,
  profiles: state.profiles.map((p) =>
    p.id !== payload.id ? p : { ...p, ...payload }
  ),
})

const updateUser = (state, { payload }) => ({
  ...state,
  ...payload,
})

const getFeatures = (state, { payload }) => ({
  ...state,
  features: payload,
})

const getStudentFeatures = (state, { payload }) => ({
  ...state,
  studentFeatures: payload,
})

const toggleNewHome = (state) => ({
  ...state,
  showNewHome: !state.showNewHome,
})

const handlers = {
  UPDATE_LOGIN: updateLogin,
  GET_PROFILE_LIST: getProfileList,
  SELECT_PROFILE: selectProfile,
  GET_USER_DATA: getUserData,
  GET_FEATURES: getFeatures,
  GET_STUDENT_FEATURES: getStudentFeatures,
  LOGOUT: logout,
  TOGGLE_NEW_HOME: toggleNewHome,
  UPDATE_PROFILE: updateProfile,
  UPDATE_USER: updateUser,
}

export default createReducer(initialState, handlers)
