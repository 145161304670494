import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { DifficultyLevelChart } from './Chart'

export const DifficultyLevel = ({
  difficultyFilter,
  setDifficultyFilter,
  hard,
  medium,
  easy,
}) => {
  return (
    <Box
      sx={{ flexGrow: 1, borderRadius: 2, bgcolor: 'grey.0', px: 2.5, py: 1 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={45}
      >
        <Typography variant="body2" fontWeight={700}>
          Nível de dificuldade
        </Typography>
        {difficultyFilter && (
          <Button variant="link" onClick={() => setDifficultyFilter(null)}>
            ver todos
          </Button>
        )}
      </Stack>

      <DifficultyLevelChart
        hard={hard}
        medium={medium}
        easy={easy}
        difficultyFilter={difficultyFilter}
        setDifficultyFilter={setDifficultyFilter}
      />
    </Box>
  )
}
