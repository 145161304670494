import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

const useExam = (params) => {
  const { loading, error, data } = useResource({
    key: 'useStudentFocoExam',
    resource: getExam,
    params,
  })

  return { loading, error, data }
}

export const endpoint = (id) => `/api/v3/foco_exam_student_profiles/${id}`

const getExam = ({ id }) =>
  fetchWithRoot('get')(endpoint(id), {
    params: {
      include: [
        'foco_answers',
        {
          foco_questions: ['foco_question_options'],
        },
      ],
      fields: [
        'id',
        'foco_exam_edition_name',
        'subject_name',
        'grade_name',
        'exam_start',
        'exam_end',
        'time_limit',
        'question_number',
        'instructions',
        {
          foco_questions: [
            'id',
            'code',
            'statement',
            'command',
            'reference',
            'support_image_path',
            { foco_question_options: ['id', 'alternative', 'text'] },
          ],

          foco_answers: [
            'id',
            'selected_foco_question_option_id',
            'foco_question_id',
          ],
        },
      ],
    },
    bypassLoader: true,
  })

export default useExam
