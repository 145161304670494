import React, { useState } from 'react'
import {
  Badge,
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { ModalDeleteJourney } from '../ModalDelete'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const CELLS_HEADER = [
  'Nome do turno',
  'Hora inicial',
  'Hora final',
  'Status',
  '',
]

export const TableJourneys = ({ data, entityId }) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [journeyId, setJourneyId] = useState(null)

  return (
    <Box
      mt={1}
      bgcolor="white"
      my={1}
      borderRadius={2}
      padding={1}
      width="100%"
    >
      <TableContainer>
        <Table aria-label="Table data academic year" variant="striped">
          <TableHead>
            <TableRow>
              {CELLS_HEADER?.map((item, index) => (
                <TableCell
                  key={`key-x-${index}`}
                  align={index === 0 ? 'left' : 'center'}
                >
                  <strong>{item}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.journeys?.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Typography
                    component="span"
                    variant="body2"
                    align="left"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    <Stack alignItems="center" direction="row" gap={2}>
                      <strong>{row.name}</strong>
                    </Stack>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    align="center"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    {dayjs(row.hour_initial).format('HH:mm')}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    align="center"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    {dayjs(row.hour_end).format('HH:mm')}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    align="center"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    <Badge
                      variant="status"
                      color={row.active ? 'success' : 'inactive'}
                      badgeContent={row.active ? 'Ativo' : 'Inativo'}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="center"
                    spacing={1}
                    sx={{ maxWidth: '140px', float: 'right' }}
                  >
                    <IconButton
                      onClick={() => {}}
                      component={Link}
                      to={`/gerenciar-calendario/school/${entityId}/turnos/editar/${
                        row.id
                      }`}
                      variant="outlinedSecondary"
                      data-testid="button-action-edit"
                      title="Editar turno"
                    >
                      <SVGIcon name="edit" size={3} />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        setJourneyId(row.id)
                        setDeleteModalIsOpen(true)
                      }}
                      variant="outlinedSecondary"
                      data-testid="button-action-delete"
                      title="Deletar turno"
                      disabled={row.in_use}
                    >
                      <SVGIcon name="delete" size={3} />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {deleteModalIsOpen && entityId && journeyId && (
        <ModalDeleteJourney
          isOpen={deleteModalIsOpen}
          setIsOpen={setDeleteModalIsOpen}
          entityId={entityId}
          journeyId={journeyId}
        />
      )}
    </Box>
  )
}
