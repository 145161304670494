import Downshift from 'downshift'
import React, { useEffect, useRef } from 'react'
import { ErrorText } from '../styles'
import Label from '../Label'
import {
  SearchSelectClickable,
  SearchSelectInput,
  SearchSelectList,
  SearchSelectMenu,
  SearchSelectOption,
  SearchSelectValue,
  SearchSelectWrapper,
} from './styles'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { keyboardClick } from '~helpers/events'

const defaultProps = {
  onChange: () => {},
  options: [],
  placeholder: 'Selecione',
  noOptionsMessage: 'Nenhuma opção',
}

export const Select = (props) => {
  const {
    name,
    onChange,
    disabled,
    value,
    label,
    hideLabel,
    options,
    defaultToFirst,
    withSearch,
    invalid,
    disabledItemTooltip,
    placeholder,
    noOptionsMessage,
    ...styleProps
  } = { ...defaultProps, ...props }

  const containerRef = useRef()

  const formattedOptions = options.map(({ id, name, disabled }) => ({
    id,
    name: String(name),
    disabled,
  }))

  const selected = formattedOptions.find(
    (option) => String(option.id) === String(value)
  )

  const defaultValue = defaultToFirst ? options[0]?.name : placeholder
  const text = selected ? selected.name : defaultValue

  const handleChange = (data) => onChange({ name, value: data.id })

  const itemMatchesSearch = (inputValue) => (item) => {
    if (!inputValue) return true
    return normalizeString(item.name).includes(normalizeString(inputValue))
  }

  return (
    <Downshift
      onChange={handleChange}
      itemToString={(item) => (item ? item.name : '')}
      selectedItem={selected}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        setState,
      }) => (
        <div className="select__root">
          {label && (
            <Label
              {...getLabelProps()}
              {...styleProps}
              hide={hideLabel}
              className="select__label"
            >
              {label}
            </Label>
          )}

          <SearchSelectWrapper
            {...styleProps}
            disabled={disabled}
            id={`select-${name}`}
            data-testid={`select-${name}`}
            className="select__wrapper"
            onClick={() => {
              if (!disabled) setState({ inputValue: '', isOpen: !isOpen })
            }}
            onKeyPress={keyboardClick}
            tabIndex="0"
            ref={containerRef}
            isOpen={isOpen}
          >
            <SearchSelectClickable
              {...styleProps}
              className="select__clickable"
              data-testid={`select-${name}-clickable`}
              disabled={disabled}
              invalid={invalid}
              isOpen={isOpen}
            >
              <SearchSelectValue {...styleProps}>{text}</SearchSelectValue>
            </SearchSelectClickable>

            {isOpen && (
              <SearchSelectMenu {...getMenuProps()} {...styleProps}>
                {withSearch ? (
                  <SearchBar name={name} getInputProps={getInputProps} />
                ) : null}
                <SearchSelectList
                  {...styleProps}
                  className="select__options"
                  data-testid={`select-${name}-options`}
                >
                  {formattedOptions.length === 0 ? (
                    <SearchSelectOption disabled title={noOptionsMessage}>
                      {noOptionsMessage}
                    </SearchSelectOption>
                  ) : (
                    formattedOptions
                      .filter(itemMatchesSearch(inputValue))
                      .map((item, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <SearchSelectOption
                          {...getItemProps({
                            key: item.id,
                            index,
                            item,
                            disabled: item.disabled,
                          })}
                          {...styleProps}
                          title={item.disabled ? disabledItemTooltip : null}
                          tabIndex="0"
                          role="option"
                        >
                          {item.name}
                        </SearchSelectOption>
                      ))
                  )}
                </SearchSelectList>
              </SearchSelectMenu>
            )}
          </SearchSelectWrapper>
        </div>
      )}
    </Downshift>
  )
}

export const FormikSelect = ({ field, form, ...props }) => {
  const { name, value } = field
  const inputError = form.touched[name] && form.errors[name]

  return (
    <>
      <Select
        {...props}
        onChange={({ name, value }) => field.onChange(name)(value)}
        name={name}
        value={value}
        invalid={!!inputError}
      />
      {inputError && <ErrorText>{inputError}</ErrorText>}
    </>
  )
}

const SearchBar = ({ name, getInputProps }) => {
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <SearchSelectInput>
      <input
        ref={inputRef}
        data-testid={`select-${name}-search`}
        placeholder="Digite..."
        {...getInputProps()}
        onClick={(e) => e.stopPropagation()}
      />
      <SVGIcon name="search" color="neutralLight" size={3} />
    </SearchSelectInput>
  )
}

const normalizeString = (string) =>
  string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
