import { omit } from 'ramda'

import { fetchWithRoot, fetchWithoutRoot } from '~helpers/fetch'

export const endpoints = {
  getSkillMapFilters: (exam_id) =>
    `/api/v2/exam_results/${exam_id || ''}/ability_filters`,
  getEnemFilters: '/api/v2/enem/exam_results/0/ability_filters',
  getEnemQuestionFilters: '/api/v2/enem/questions/filters',
  getSelectionFilters: '/api/v2/selection',
  getTCTFilters: (entityType) => `/api/v3/filters/tct/${entityType}`,
  getFocoExamsFilters: (entityType) =>
    `api/v3/filters/foco_exams/${entityType}`,
  getManageStudentsFilters: '/api/v3/filters/student_profiles/',
  getCurriculumFilters: '/api/v3/curriculum_events/filters',
  getBaseCurriculumsFilters: (entityType, entityId) =>
    `api/v3/${entityType}s/${entityId}/base_curriculums/filters`,
  getJourneyResultsFilters: '/api/v3/filters/foco_exam_student_profiles',
  getManageExamsFilters: '/api/v3/filters/foco_exam_editions',
  getManageExamsResultsFilters: (examId) =>
    `/api/v3/foco_exam_editions/${examId}/results_filters`,
  getManageBookletsFilters: '/api/v3/foco_exams/filters',
}

const getEnemFilters = (params) =>
  fetchWithoutRoot('get')(endpoints.getEnemFilters, {
    params,
    bypassLoader: true,
  })

export const getSkillMapFilters = (params) => {
  const isClassroom = !!params.classroom_id && params.classroom_id !== '0'
  const resultable_type = isClassroom ? 'classroom' : params.resultable_type
  const resultable_id = isClassroom ? params.classroom_id : params.resultable_id

  return fetchWithoutRoot('get')(endpoints.getSkillMapFilters(params.exam_id), {
    params: { ...omit(['exam_id'], params), resultable_type, resultable_id },
    bypassLoader: true,
  })
}

const getEnemQuestionFilters = (params) =>
  fetchWithoutRoot('get')(endpoints.getEnemQuestionFilters, {
    params,
    bypassLoader: true,
  })

export const getTCTFilters = ({ entityType, entityId, ...params }) =>
  fetchWithRoot('get')(endpoints.getTCTFilters(entityType), {
    params: { ...params, entity_id: entityId },
    bypassLoader: true,
  })

export const getFocoExamsFilters = ({ entityType, entityId, ...params }) =>
  fetchWithRoot('get')(endpoints.getFocoExamsFilters(entityType), {
    params: { ...params, entity_id: entityId },
    bypassLoader: true,
  })

const getManageStudentsFilters = ({ grade_id, entityId }) =>
  fetchWithRoot('get')(endpoints.getManageStudentsFilters, {
    params: {
      grade_id,
      school_id: entityId,
    },
    bypassLoader: true,
  })

const getManageExamsFilters = ({
  entityType,
  entityId,
  foco_exam_edition_id,
  subject_id,
}) =>
  fetchWithRoot('get')(endpoints.getManageExamsFilters, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      foco_exam_edition_id,
      subject_id,
    },
    bypassLoader: true,
  })

const getManageExamsResultsFilters = ({
  entityType,
  entityId,
  examId,
  foco_exam_id,
  classroom_id,
}) =>
  fetchWithRoot('get')(endpoints.getManageExamsResultsFilters(examId), {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      foco_exam_id,
      classroom_id,
    },
    bypassLoader: true,
  })

export const getManageBookletsFilters = ({ entityType, entityId }) =>
  fetchWithRoot('get')(endpoints.getManageBookletsFilters, {
    params: { entity_type: entityType, entity_id: entityId },
    bypassLoader: true,
  })

const getSelectionFilters = ({
  feature,
  resource,
  examId,
  kind,
  groupType,
  sectorId,
  schoolId,
}) =>
  fetchWithRoot('get')(endpoints.getSelectionFilters, {
    params: {
      feature,
      resource,
      kind,
      group_type: groupType,
      sector_id: sectorId,
      school_id: schoolId,
      exam_id: examId,
    },
    bypassLoader: true,
  })

const getCurriculumFilters = ({
  entityType,
  entityId,
  curriculum_period_id,
  grade_id,
  subject_id,
  classroom_id,
}) =>
  fetchWithRoot('get')(endpoints.getCurriculumFilters, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      curriculum_period_id,
      grade_id,
      subject_id,
      classroom_id,
    },
    bypassLoader: true,
  })

const getCurriculumFiltersCalendar = ({ entityType, entityId }) =>
  fetchWithRoot('get')(endpoints.getCurriculumFilters, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      filter: 'calendar',
    },
    bypassLoader: true,
  })

const getJourneyFiltersResults = ({ foco_exam_edition_id, subject_id }) =>
  fetchWithRoot('get')(endpoints.getJourneyResultsFilters, {
    params: {
      foco_exam_edition_id: foco_exam_edition_id,
      subject_id: subject_id,
    },
    bypassLoader: true,
  })

export const getBaseCurriculumsFilters = ({ entityId, entityType }) =>
  fetchWithRoot('get')(
    endpoints.getBaseCurriculumsFilters(entityType, entityId),
    {
      params: { entity_id: entityId, entity_type: entityType },
      bypassLoader: true,
    }
  )

export default {
  endpoints,
  getEnemFilters,
  getSkillMapFilters,
  getEnemQuestionFilters,
  getSelectionFilters,
  getTCTFilters,
  getFocoExamsFilters,
  getCurriculumFilters,
  getManageStudentsFilters,
  getCurriculumFiltersCalendar,
  getJourneyFiltersResults,
  getManageExamsFilters,
  getManageBookletsFilters,
  getManageExamsResultsFilters,
  getBaseCurriculumsFilters,
}
