import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import TrackRouteChange from './TrackRouteChange'
import useScrollRestoration from '~hooks/useScrollRestoration'
import ErrorRoute from '~pages/ErrorRoute'
import LoggedInTemplate from '~templates/LoggedIn'
import AuthTemplate from '~templates/Auth'
import PrintTemplate from '~templates/Print'
import { routes as authRoutes } from '~routes/Auth'

const Routes = () => {
  useScrollRestoration()

  return (
    <TrackRouteChange>
      <Switch>
        <Route path={authRoutes} component={AuthTemplate} />
        <Route path={['/imprimir', '/print']} component={PrintTemplate} />
        <Route path="*" component={LoggedInTemplate} />
        <Route component={ErrorRoute} />
      </Switch>
    </TrackRouteChange>
  )
}

export default withRouter(Routes)
