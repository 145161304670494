import { useSelector } from 'react-redux'

const useProfileData = () => {
  const { features } = useSelector((state) => state.user)

  const getFeature = (featureName) => {
    return features[featureName]
  }

  const getResource = (featureName, resourceName, exam_id) => {
    if (!features[featureName]) return

    const resource = features[featureName].resources.find(({ resource, id }) =>
      exam_id
        ? id === Number(exam_id) && resource === resourceName
        : resource === resourceName
    )

    return resource
  }

  const profileHasFeature = (featureName, resourceName) =>
    !!getResource(featureName, resourceName)

  return { getFeature, getResource, profileHasFeature }
}

export default useProfileData
