import { List, ListItem, useTheme } from '@mui/material'
import React from 'react'
import { QuestionListItem } from './ListItem'
import { Placeholder } from './Placeholder'
import { ListHeader } from './ListHeader'
import { SortableList } from '~components/Common/Draggable/Sortable'

export const QuestionListWrapper = ({
  selectedQuestionIds = [],
  setSelectedQuestionIds,
  handleOpenQuestionDetailsModal,
}) => {
  const { shape, palette } = useTheme()
  const styles = getStyles(shape, palette)

  const handleRemoveQuestion = (id) => {
    setSelectedQuestionIds([
      ...selectedQuestionIds.filter((questionId) => questionId !== id),
    ])
  }

  return (
    <React.Fragment>
      <ListHeader />
      <List>
        {selectedQuestionIds.length > 0 && (
          <SortableList
            items={selectedQuestionIds}
            setItems={setSelectedQuestionIds}
            overlayStyle={{ ...styles.overlay }}
            renderItem={(item) => (
              <SortableList.Item
                id={item}
                key={item}
                sx={{ ...styles.ceil }}
                draggingStyle={{ opacity: 0.2 }}
                component={ListItem}
              >
                <SortableList.Handler
                  id={item}
                  color={palette.action.disabled}
                />
                <QuestionListItem
                  selectedQuestionId={item}
                  handleRemoveQuestion={handleRemoveQuestion}
                  handleOpenQuestionDetailsModal={
                    handleOpenQuestionDetailsModal
                  }
                />
              </SortableList.Item>
            )}
          />
        )}
        {selectedQuestionIds.length === 0 && <Placeholder />}
      </List>
    </React.Fragment>
  )
}

const getStyles = (shape, palette) => ({
  ceil: {
    display: 'grid',
    gridTemplateColumns: '40px 70px 1fr 1fr 80px',
    height: 105,
    width: 1,
    bgcolor: palette.common.white,
    borderRadius: shape.borderRadius * 2,
    gap: 1,
    p: 2,
    mb: 0.5,
  },
  overlay: {
    borderRadius: shape.borderRadius * 2,
    boxShadow: '0 4px  5px rgba(0,0,0,.2)',
  },
})
