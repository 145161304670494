import React, { useState } from 'react'
import { flatten } from 'ramda'
import {
  Box,
  Stack,
  Switch,
  FormControl,
  FormControlLabel,
  useTheme,
} from '@mui/material'
import { FilterExam } from '~components/FocoExams/Student/MyExam/Filter'
import { EditionCard } from '~components/FocoExams/Student/MyExam/EditionCard'
import { Placeholder } from '~components/FocoExams/Student/MyExam/Placeholder'
import useExamEditions from '~data/focoExams/student/examEditions'
import Header from '~components/Common/Header'

const StudentHome = () => {
  const [selectedFilter, setSelectedFilter] = useState('')
  const [showPastExams, setShowPastExams] = useState(false)
  const { data } = useExamEditions()
  const editions = data?.foco_exam_editions

  const theme = useTheme()

  const handleTogglePastExams = () => {
    setShowPastExams(!showPastExams)
  }

  if (!data) return null

  const filteredEditions = getFilteredEditions(
    showPastExams,
    selectedFilter,
    editions
  )

  const exams = flatten(
    editions?.map(({ student_exams, exam_start, exam_end }) =>
      student_exams.map((exam) => ({ ...exam, exam_start, exam_end }))
    )
  )

  const filterValues = {
    available: exams?.filter((exam) => validations['available'](exam))?.length,
    started: exams?.filter((exam) => validations['started'](exam))?.length,
    expiresSoon: exams?.filter((exam) => validations['expiresSoon'](exam))
      ?.length,
    answered: exams?.filter((exam) => validations['answered'](exam))?.length,
    unperformed: exams?.filter((exam) => validations['unperformed'](exam))
      ?.length,
  }

  return (
    <Stack
      sx={{
        px: 2,
        [theme.breakpoints.up('sm')]: {
          px: 8,
        },
      }}
    >
      <Header
        title="Minhas provas"
        breadcrumbs={[
          { id: '1', text: 'Avaliação', pathname: '#' },
          { id: '2', text: 'Minhas provas', pathname: '' },
        ]}
      />

      <Box mt={5} mb={3}>
        <FilterExam
          selected={selectedFilter}
          setSelected={setSelectedFilter}
          {...filterValues}
        />
      </Box>

      <Stack>
        <FormControl component="fieldset" variant="standard">
          <FormControlLabel
            value={showPastExams}
            onClick={handleTogglePastExams}
            control={<Switch color="primary" />}
            label="Mostrar avaliações passadas:"
            labelPlacement="start"
            sx={{ '.MuiTypography-root': { fontSize: 14 } }}
          />
        </FormControl>
      </Stack>

      <Box mt={3}>
        {filteredEditions?.length === 0 ? (
          <Placeholder text="Não há avaliações disponíveis" />
        ) : (
          <>
            {filteredEditions?.map((edition) => (
              <EditionCard
                key={edition.id}
                id={edition.id}
                title={edition.name}
                exams={edition.student_exams}
                startsAt={edition.exam_start}
                endsAt={edition.exam_end}
              />
            ))}
          </>
        )}
      </Box>
    </Stack>
  )
}

export default StudentHome

const validations = {
  available: ({ available, answered }) => available && !answered,
  started: ({ started, answered }) => started && !answered,
  answered: ({ answered, showPastExams }) => answered || showPastExams,
  expiresSoon: ({ expires_soon, answered }) => expires_soon && !answered,
  unperformed: ({ exam_end, answered }) =>
    new Date(exam_end) < new Date(Date.now()) && !answered,
}

export const getFilteredEditions = (
  showPastExams,
  selectedFilter,
  editions
) => {
  let filteredEditions = editions

  if (showPastExams || selectedFilter == 'unperformed') {
    filteredEditions = filterEditionsByDateRange(editions)
  }

  if (selectedFilter) {
    return filteredEditions
      .filter(({ student_exams, exam_start, exam_end }) =>
        student_exams?.some((exam) =>
          validations[selectedFilter]({
            ...exam,
            exam_start,
            exam_end,
          })
        )
      )
      .map((edition) => ({
        ...edition,
        student_exams: edition.student_exams.filter((exam) =>
          validations[selectedFilter]({
            ...exam,
            exam_end: edition.exam_end,
            exam_start: edition.exam_start,
          })
        ),
      }))
  }

  return filteredEditions
}

const filterEditionsByDateRange = (editions) =>
  editions.filter(
    ({ exam_end, exam_start }) =>
      new Date(exam_end) > new Date(Date.now()) &&
      new Date(exam_start) < new Date(Date.now())
  )
