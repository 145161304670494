import React from 'react'
import { Button, Grid, useTheme } from '@mui/material'
import { getStatusIcons } from '../../../helpers'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const STYLES_BUTTON = {
  width: 1,
  minWidth: 0,
  height: 30,
  mx: 0,
  '& i': { fontSize: '14px !important' },
  '&:hover': { color: 'inherit' },
}

export const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  const { palette } = useTheme()

  const handleSetActiveTab = (tab) => {
    setActiveTab(tab)
  }

  if (tabs?.length === 0) return null

  return (
    <Grid container spacing={1.5}>
      {tabs?.map(({ endTime, status }, index) => {
        const isActive = activeTab === index
        const icon = getStatusIcons(status, endTime)

        return (
          <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
            <Button
              onClick={() => handleSetActiveTab(index)}
              disabled={isActive}
              sx={{
                ...STYLES_BUTTON,
                bgcolor: isActive ? palette.grey[250] : palette.common.white,
              }}
              endIcon={
                icon && (
                  <SVGIcon
                    name={icon?.name}
                    color={palette?.[icon?.color]?.main}
                  />
                )
              }
            >
              Aula {index + 1}
            </Button>
          </Grid>
        )
      })}
    </Grid>
  )
}
