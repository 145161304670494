import React from 'react'
import dayjs from 'dayjs'
import {
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
  Button,
} from '@mui/material'
import { getStatusIcons } from '../../../../helpers'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const STATUS_LABEL = {
  unplanned: 'não planejada',
  planned: 'planejada',
  unregistered: 'não registrada',
  registered: 'registrada',
}

export const Header = ({
  classroom = '',
  subject = '',
  date,
  startTime,
  endTime,
  status,
  performed,
  onClickPresences,
  setFormBuffer,
  values,
}) => {
  const icon = getStatusIcons(status, endTime)
  const { palette } = useTheme()

  return (
    <React.Fragment>
      <Stack
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Chip size="medium" color="info" label={`${classroom} - ${subject}`} />

        <Button
          variant="outlined"
          sx={{
            py: 1,
            height: '30px',
          }}
          disabled={Number(performed) !== 1}
          onClick={() => {
            onClickPresences()
            setFormBuffer({
              ...values,
              materials_id: values?.materials_id,
            })
          }}
        >
          Registrar frequência
        </Button>
      </Stack>

      <Divider sx={{ my: 1.5 }} />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography component="span" variant="body2">
          <strong>Data: </strong>
          {getFormattedDate(date)}
        </Typography>
        <Typography component="span" variant="body2">
          <strong>Horário: </strong>
          {`${getFormattedHour(startTime)}`}
          {' - '}
          {getFormattedHour(endTime)}
        </Typography>

        {status && (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="body2">Aula {STATUS_LABEL[status]}</Typography>
            <SVGIcon
              name={icon?.name}
              color={palette?.[icon?.color]?.main}
              size={3}
            />
          </Stack>
        )}
      </Stack>
    </React.Fragment>
  )
}

const getFormattedHour = (time) => {
  const date = dayjs(time)
  if (date.minute() === 0) {
    return date.format('H[h]')
  }
  return date.format('H:mm[h]')
}

const getFormattedDate = (date) => dayjs(date).format('DD/MM/YYYY')
