import { filterStepsByFeatures } from './helpers'

export const getHomeWalkthrough = ({ applicationName }) =>
  filterStepsByFeatures([
    {
      target: '#home-banner',
      content: `Confira nossas dicas para fazer melhor uso da ${applicationName}! Clique em "Próximo" para ver nossas sugestões para cada uma das seções.`,
    },
    {
      target: '#students-thumb',
      content:
        'Aqui você pode fazer um diagnóstico de seus alunos atuais. Analise uma das avaliações e direcione seu planejamento pedagógico. :)',
      requiredResources: [
        { feature: 'prospective', requiredResource: 'estadual' },
        { feature: 'prospective', requiredResource: 'ana' },
        { feature: 'prospective', requiredResource: 'anresc' },
      ],
    },
    {
      target: '#school-thumb',
      content:
        'Quais são as dificuldades recorrentes em sua escola? Conheça os resultados históricos para saber mais!',
      requiredResources: [
        { feature: 'retrospective', requiredResource: 'estadual' },
        { feature: 'retrospective', requiredResource: 'ana' },
        { feature: 'retrospective', requiredResource: 'anresc' },
        { feature: 'retrospective', requiredResource: 'aap' },
      ],
    },
    {
      target: '#indicators-thumb',
      content:
        'Você sabe se sua escola está cumprindo as metas pactuadas? Confira seu desempenho e os índices de abandono e reprovação para desenhar um plano de ação.',
      requiredResources: [
        { feature: 'indicators', requiredResource: 'schools' },
      ],
    },
    {
      target: '#enem-thumb',
      content:
        'Quer incentivar seus alunos a entrar no Ensino Superior? Identifique as dificuldades recorrentes e planeje reforços com ajuda de questões de edições anteriores.',
      requiredResources: [{ feature: 'enem', requiredResource: 'map' }],
    },
    {
      target: '#main-nav',
      content:
        'Clicando nos ícones do menu lateral você pode navegar entre as seções.',
      placement: 'right-start',
    },
  ])
