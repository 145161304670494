import axios from 'axios'

import { fetchWithRoot } from '~helpers/fetch'

export const endpoints = {
  login: '/api/v2/login',
  logout: '/api/v2/logout',
  requestPasswordReset: '/api/v2/users/reset_password_token',
  resetPassword: (token) => `/api/v2/users/change_password/${token}`,
}

const processResponse = (response) => [null, response.data]

const login = (params) =>
  axios
    .post(endpoints.login, {}, { headers: params })
    .then(processResponse)
    .catch((error) => {
      const message = error.response?.data
        ? error.response.data.errors[0]?.details
        : 'Erro no sistema, tente novamente.'

      return [{ status: error.response?.status, logout: false, message }, null]
    })

const logout = () =>
  axios
    .delete(endpoints.logout, {})
    .then(processResponse)
    .catch((error) => {
      const message = error.response?.data
        ? error.response?.data.errors[0]
        : 'Erro no sistema, tente novamente.'

      return [{ status: error.response?.status, logout: false, message }, null]
    })

const requestPasswordReset = (params) =>
  fetchWithRoot('post')(endpoints.requestPasswordReset, {
    headers: { Email: params.email },
    data: { identifier: params.identifier },
  })

const resetPassword = (params) =>
  fetchWithRoot('post')(endpoints.resetPassword(params.token), {
    headers: {
      Password: params.password,
      'Password-confirmation': params.confirm,
    },
  })

export default {
  endpoints,
  login,
  logout,
  requestPasswordReset,
  resetPassword,
}
