import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Checkbox,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import IconWarning from '~assets/svg/icon_warning.svg'

const DIVIDER_STYLES = {
  borderTopStyle: 'solid',
  borderTopWidth: 1,
  borderTopColor: 'grey.300',
}

export const MaterialList = ({
  selectedMaterialsId,
  setSelectedMaterialsId,
  materialList,
  loading = true,
}) => {
  const handleToggleMaterial = (id) => {
    const isActive = selectedMaterialsId.includes(id)
    if (isActive) {
      setSelectedMaterialsId(
        selectedMaterialsId.filter((materialId) => materialId !== id)
      )
    } else {
      setSelectedMaterialsId([...selectedMaterialsId, id])
    }
  }

  if (loading) {
    return <Placeholder />
  }

  return (
    <List>
      {materialList?.length === 0 && (
        <Stack direction="row" gap={1} px={1}>
          <img src={IconWarning} alt="Ícone de alerta" width="18px" />
          <Typography variant="body2">Nenhum material disponível</Typography>
        </Stack>
      )}
      {materialList?.length > 0 &&
        materialList?.map(
          ({ id, name, file_type, filename, file_path = '' }, index) => {
            const checked = selectedMaterialsId.includes(id)

            return (
              <ListItem
                key={id}
                sx={index === 0 ? {} : { ...DIVIDER_STYLES }}
                secondaryAction={
                  file_type === 'file' ? (
                    <IconButton
                      edge="end"
                      aria-label="download"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      target="_blank"
                      download={filename}
                      rel="noreferrer"
                      href={`${ENV_VARS.FOCO_API_HOST}${file_path}`}
                      component="a"
                    >
                      <SVGIcon name="iconDownload" />
                    </IconButton>
                  ) : null
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={() => handleToggleMaterial(id)}
                  dense
                  sx={{
                    '&:hover': { bgcolor: 'transparent' },
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="secondary"
                      edge="start"
                      checked={checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': `checkbox-list-label-${id}`,
                      }}
                    />
                  </ListItemIcon>
                  {file_type === 'file' && (
                    <ListItemText
                      id={`checkbox-list-label-${id}`}
                      primary={filename}
                    />
                  )}

                  {file_type === 'url' && (
                    <Link
                      variant="body2"
                      color="secondary"
                      href={file_path}
                      target="_blank"
                    >
                      {name}
                    </Link>
                  )}
                </ListItemButton>
              </ListItem>
            )
          }
        )}
    </List>
  )
}

const Placeholder = () => (
  <List>
    <ListItem sx={{ height: 50 }} disablePadding>
      <Skeleton variant="text" width="100%" />
    </ListItem>
    <ListItem
      secondaryAction={<Skeleton variant="circular" width={34} height={34} />}
      sx={{ ...DIVIDER_STYLES, height: 50, pr: 8 }}
      disablePadding
    >
      <Skeleton variant="text" width="100%" />
    </ListItem>
    <ListItem sx={{ ...DIVIDER_STYLES, height: 50 }} disablePadding>
      <Skeleton variant="text" width="100%" />
    </ListItem>
    <ListItem
      secondaryAction={<Skeleton variant="circular" width={34} height={34} />}
      sx={{ ...DIVIDER_STYLES, height: 50, pr: 8 }}
      disablePadding
    >
      <Skeleton variant="text" width="100%" />
    </ListItem>
  </List>
)
