export const getDashboardLink = (profile, resource) => {
  switch (resource) {
    case 'dashboard':
      return !!profile && profile.kind === 'school'
        ? `/painel/escolas/${profile.personable.id}`
        : '/painel/escolas/'
    case 'panel':
      return `/painel/escolas/${profile.personable.id}/ciclos/3/desempenho`
    case 'skills_panel':
      return `/painel/descritores/${profile.personable.id}`
    case 'schools':
      return '/painel/escolas/'
    case 'formation':
      return '/painel/formacao/'
    case 'analysis':
      return '/painel/analises/'
    default:
      return '/pagina-inicial'
  }
}
