export default function Switch(theme) {
  return {
    MuiSwitch: {
      variants: [
        {
          props: { variant: 'default' },
          style: {
            width: 62,
            height: 30,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 3,
              transitionDuration: '300ms',
              '&.Mui-checked': {
                transform: 'translateX(33px)',
                color: theme.palette.grey[0],
                '& + .MuiSwitch-track': {
                  backgroundColor: theme.palette.secondary.main,
                  opacity: 1,
                  border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 24,
              height: 24,
            },
            '& .MuiSwitch-track': {
              borderRadius: 24,
              backgroundColor: theme.palette.grey[300],
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          },
        },
      ],
    },
  }
}
