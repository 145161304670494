import { useState } from 'react'
import { useQuery } from 'react-query'
import useUser from '~data/user'

const useResource = ({
  resource,
  params = {},
  key = resource.name,
  enabled = true,
  staleTime = Infinity,
  keepPreviousData = true,
}) => {
  const { selectedProfile } = useUser()
  const [loading, setLoading] = useState(false)

  const queryKey = [key, selectedProfile, params]

  const fetchData = () =>
    new Promise((resolve) => {
      setLoading(true)
      resource(params).then((data) => {
        setLoading(false)
        resolve(data)
      })
    })

  const queryOptions = { enabled, staleTime, keepPreviousData }

  const query = useQuery(queryKey, fetchData, queryOptions)

  const [error, data] = query.data ?? []

  return { loading, error, data }
}

export default useResource
