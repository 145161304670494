import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = (id) => `/api/v3/foco_exams/${id || 0}/foco_exam_result`

export const useFocoExamPrioritarySkills = (params) => {
  const { data, loading, error } = useResource({
    key: 'useFocoExamsPrioritarySkills',
    resource: getFocoExamPrioritarySkills,
    params,
    enabled: Boolean(params.examId && params.classroomId),
  })

  return { data, loading, error }
}

const getFocoExamPrioritarySkills = (params) =>
  fetchWithRoot('get')(endpoint(params.examId), {
    params: {
      entity_type: 'school',
      entity_id: params.schoolId,
      classroom_id: params.classroomId,
      include: ['highest_skill_priority', 'lowest_skill_priority'],
      fields: [
        { highest_skill_priority: ['id', 'name', 'description'] },
        { lowest_skill_priority: ['id', 'name', 'description'] },
      ],
    },
  })
