import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/student_profiles'

export const useManageBookletFilterStudents = (params) => {
  const { data, error, loading } = useResource({
    key: 'StudentsList',
    resource: getManageBookletFilterStudents,
    params,
    enabled: !!params?.schoolId && !!params?.classroomId,
  })

  return { loading, error, data }
}

const getManageBookletFilterStudents = (params) =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_type: 'school',
      entity_id: params?.schoolId,
      q: {
        classroom_id_eq: params?.classroomId,
      },
      fields: [
        {
          student_profiles: ['id', { user: ['id', 'name'] }],
        },
      ],
      include: [{ student_profiles: ['user'] }],
    },
  })
