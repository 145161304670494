import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (questionId) =>
  `/api/v3/foco_questions/${questionId}`

export const useFocoQuestion = (params) => {
  const { data, loading, error } = useResource({
    key: 'useFocoQuestion',
    resource: getQuestionItemDetails,
    params,
  })

  return { data, loading, error }
}

const getQuestionItemDetails = ({ questionId }) =>
  fetchWithRoot('get')(getEndpoint(questionId), {
    params: {
      include: [
        'curriculum_skill',
        'foco_question_options',
        { skill: ['competence'] },
      ],
      fields: [
        [
          'id',
          'code',
          'text',
          'statement',
          'support_image_path',
          'reference',
          'command',
        ],
        {
          curriculum_skill: ['name', 'description'],
          foco_question_options: ['id', 'alternative', 'text', 'correct'],
        },
      ],
    },
  })
