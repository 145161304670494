import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

const useExamResult = ({ id }) => {
  const { loading, error, data } = useResource({
    resource: getExamResult,
    params: { id },
    key: 'useExamResult',
  })

  return { loading, error, data }
}

export const endpoint = (id) => `/api/v3/exam_results/${id}`

const getExamResult = ({ id }) =>
  fetchWithRoot('get')(endpoint(id), {
    params: { fields: ['label'] },
  })

export default useExamResult
