import React from 'react'
import {
  FormControl,
  InputLabel,
  Stack,
  IconButton,
  Input,
} from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const LinkSelectGroup = ({
  index,
  name,
  link,
  setLink,
  removeDisabled,
  handlePush,
  handleRemove,
}) => {
  const handleChangeName = (event) => {
    const { value } = event.target
    setLink({ link, name: value })
  }

  const handleChangeLink = (event) => {
    const { value } = event.target
    setLink({ link: value, name })
  }

  const isPushButtonDisabled = checkPushButtonIsDisabled()

  return (
    <Stack direction="row" alignItems="flex-end" pb={2}>
      <FormControl fullWidth sx={{ pb: 0, mr: 3 }} variant="standard">
        <InputLabel id="name-input-label" required>
          Nome do link
        </InputLabel>
        <Input
          variant="standard"
          labelId="name-input-label"
          id="name-input"
          name="name-input"
          value={name}
          label="Nome do link"
          size="small"
          onChange={handleChangeName}
        />
      </FormControl>

      <FormControl fullWidth sx={{ pb: 0, mr: 3 }} variant="standard">
        <InputLabel id="link-input-label" required>
          Link
        </InputLabel>
        <Input
          variant="standard"
          labelId="link-input-label"
          id="link-input"
          name="subject_ids"
          value={link}
          label="Link"
          size="small"
          onChange={handleChangeLink}
        />
      </FormControl>

      <Stack direction="row" gap={1}>
        <IconButton
          type="button"
          variant="secondary"
          size="small"
          onClick={() => handlePush(index)}
          disabled={isPushButtonDisabled}
        >
          <SVGIcon name="add" color="white" size={6} />
        </IconButton>

        <IconButton
          type="button"
          variant="secondary"
          size="small"
          onClick={() => handleRemove(index)}
          disabled={removeDisabled}
        >
          <SVGIcon name="delete" color="white" size={6} />
        </IconButton>
      </Stack>
    </Stack>
  )
}

const checkPushButtonIsDisabled = () => {
  return false
}
