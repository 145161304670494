import React, { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

const ManageClassrooms = lazy(() => import('~pages/ManageClassrooms'))
const ViewClassroom = lazy(
  () => import('~pages/ManageClassrooms/ViewClassroom')
)
const TimeTablePage = lazy(() => import('~pages/ManageClassrooms/TimeTable'))

const ManageClassroomsRoutes = () => (
  <Suspense fallback={null}>
    <Route
      exact
      path="/gerenciar-turmas/:entityType/:entityId/"
      component={ManageClassrooms}
    />

    <Route
      exact
      path="/gerenciar-turmas/:entityType/:entityId/quadro-de-horario/:classroomId"
      component={TimeTablePage}
    />

    <Route
      exact
      path="/gerenciar-turmas/:entityType/:entityId/turma/:classroomId"
      component={ViewClassroom}
    />
  </Suspense>
)

export default ManageClassroomsRoutes
