import React from 'react'
import { Box, Divider } from '@mui/material'
import { QuestionListWrapper } from './List'
import { Header } from './Header'

export const QuestionList = ({
  selectedQuestionIds,
  setSelectedQuestionIds,
  handleOpenQuestionDetailsModal,
}) => (
  <Box sx={{ mb: 2, mt: 4 }}>
    <Header amountOfQuestions={selectedQuestionIds?.length} />
    <Divider sx={{ my: 2 }} />
    <QuestionListWrapper
      selectedQuestionIds={selectedQuestionIds}
      setSelectedQuestionIds={setSelectedQuestionIds}
      handleOpenQuestionDetailsModal={handleOpenQuestionDetailsModal}
    />
  </Box>
)
