import { triangle } from 'polished'
import { styled } from '@mui/material/styles'

const defaultProps = {
  borderWidth: '1px',
  background: 'white',
  borderColor: 'backgroundLight',
  height: '45px',
  width: '100%',
  textColor: 'text',
  hoverColor: 'backgroundLight',
  menuDirection: 'bottom',
  fontSize: '14px',
  borderRadius: '4px',
  padding: '0 42px 0 14px',
}

export const SearchSelectWrapper = styled('div')`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  vertical-align: middle;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  position: relative;

  :focus {
    outline: none;
  }

  &:focus .select__clickable {
    box-shadow: ${({ theme, isOpen }) =>
      isOpen ? '0' : `0 0 1px 1px ${theme.linkColor}`};
  }
`

SearchSelectWrapper.defaultProps = defaultProps

const getArrowDirection = ({ isOpen, menuDirection }) => {
  if (menuDirection === 'top') return isOpen ? 'bottom' : 'top'
  return isOpen ? 'top' : 'bottom'
}

export const SearchSelectClickable = styled('div')`
  color: ${({ theme, textColor }) => theme.palette.foco[textColor]};
  border-radius: ${({ borderRadius }) => borderRadius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  text-transform: ${({ textTransform }) =>
    textTransform ? textTransform : 'unset'};

  background: ${({ theme, background }) => theme.palette.foco[background]};
  border: ${({ borderWidth }) => borderWidth} solid
    ${({ theme, invalid }) =>
      invalid ? theme.palette.foco.danger : 'rgba(51, 68, 73, 0.50)'};

  @media print {
    border: none;
  }

  &:after {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -4px;
    width: 10px;
    height: 10px;
    border-radius: 3px;

    @media print {
      display: none;
    }

    ${({ theme, isOpen, invalid, disabled, menuDirection }) =>
      triangle({
        pointingDirection: getArrowDirection({ isOpen, menuDirection }),
        width: '12px',
        height: '8px',
        foregroundColor: disabled
          ? theme.palette.foco.neutral
          : invalid
            ? theme.palette.foco.danger
            : '#707070',
      })}
  }

  &:hover:after {
    ${({ theme, isOpen, invalid, disabled, menuDirection }) =>
      triangle({
        pointingDirection: getArrowDirection({ isOpen, menuDirection }),
        width: '12px',
        height: '8px',
        foregroundColor: disabled
          ? theme.palette.foco.neutral
          : invalid
            ? theme.palette.foco.danger
            : '#707070',
      })}
  }
`
SearchSelectClickable.defaultProps = defaultProps

export const SearchSelectValue = styled('span')`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  text-overflow: ellipsis;
  ${({ bold }) => bold && 'font-weight: 700;'}

  img {
    display: inline-block;
    margin: -4px 8px 0 0;
    vertical-align: middle;
  }
`
SearchSelectValue.defaultProps = defaultProps

export const SearchSelectInput = styled('div')`
  position: relative;
  padding-right: 42px;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.palette.foco.border};
  border-radius: 2px;
  height: 42px;
  width: 90%;
  margin: 0 auto 14px;

  i {
    width: 42px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 30px;
    line-height: 42px;
    color: ${({ theme }) => theme.palette.foco.neutral};
  }

  input {
    border: 0;
    background: transparent;
    font-size: 16px;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 0 0 8px;

    &:focus {
      outline: none;
    }
  }
`
SearchSelectInput.defaultProps = defaultProps

export const SearchSelectMenu = styled('div')`
  background: ${({ theme, background }) =>
    background
      ? theme.palette.foco[background]
      : theme.palette.foco.backgroundLight};
  border: ${({ borderWidth }) => borderWidth} solid
    ${({ theme, borderColor }) => theme.palette.foco[borderColor]};
  ${({ menuDirection }) =>
    menuDirection === 'bottom' ? 'top' : 'bottom'}: calc(100% + 10px);
  border-radius: ${({ menuDirection }) =>
    menuDirection === 'bottom' ? '0 0 2px 2px' : '2px 2px 0 0'};
  ${({ menuDirection }) => menuDirection === 'bottom' && 'border-top: 0'};
  ${({ menuDirection }) => menuDirection === 'top' && 'border-bottom: 0'};
  font-size: 16px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 90;
  padding: 0 0 5px 0;
  overflow: hidden;
`
SearchSelectMenu.defaultProps = defaultProps

export const SearchSelectList = styled('ul')`
  max-height: 200px;
  overflow: auto;
  line-height: 22px;
  font-size: ${({ fontSize }) => fontSize};
  width: 100%;
`
SearchSelectList.defaultProps = defaultProps

export const SearchSelectOption = styled('li')`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.foco.textLight : theme.palette.button.secondary};
  padding: 0.5em 14px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ disabled, theme, hoverColor }) =>
    !disabled &&
    `&:hover, &:focus {
      background: ${theme.palette.foco[hoverColor]};
      color: ${theme.palette.button.secondarydark};
    }`};
`
SearchSelectOption.defaultProps = defaultProps
