import React, { lazy, Suspense, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Form from '~components/Register/CPF/Form'

const PreRegister = lazy(() => import('~components/Register/PreRegister'))

const RegNumber = () => {
  const [preRegister, setPreRegister] = useState(false)
  const history = useHistory()

  const handleSuccess = ({ cpf, name }) => {
    history.push({
      pathname: `cadastro/${cpf}`,
      search: `name=${name}`,
    })
  }

  return (
    <>
      {preRegister ? (
        <Suspense fallback={null}>
          <PreRegister onClose={() => setPreRegister(false)} />
        </Suspense>
      ) : (
        <Form
          onSuccess={handleSuccess}
          onOpenModal={() => setPreRegister(true)}
        />
      )}
    </>
  )
}

export default RegNumber
