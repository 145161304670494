import React from 'react'

export const SVGImage = ({
  src,
  className,
  groupName = '',
  height = 'auto',
  width = 'auto',
  alt,
}) => {
  return (
    <img
      alt={alt}
      className={className}
      src={`${src}${groupName !== '' ? '#' + groupName : ''}`}
      height={height}
      width={width}
    />
  )
}
