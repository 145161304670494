import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { Header } from '../Header'
import { FileFields } from './FileFields'
import { LinkFields } from './LinkFields'
import { AddedMaterials } from './AddedMaterials'
import { keyboardClick } from '~helpers/events'
import { useFeedback } from '~contexts/Feedback'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { useCreateCurriculumMaterials } from '~data/api/v3/curriculumMaterials/mutation/create'

export const AddMaterialsModal = ({
  isOpen,
  setIsOpen,
  eventIndex,
  curriculumEventId,
  skillCode,
  date,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { entityType, entityId } = useParams()
  const { showMessage } = useFeedback()
  const queryClient = useQueryClient()

  const { mutate: createCurriculumMaterials } = useCreateCurriculumMaterials({
    onSuccess: () => {
      setIsOpen(false)
      queryClient.invalidateQueries('useWeekCurriculumEvents')
      queryClient.invalidateQueries('useCurriculumEvents')
      queryClient.invalidateQueries('useCurriculumEvent')
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        text: `Erro: ${error?.message}`,
      })
    },
    onSettled: () => {
      setIsSubmitting(false)
    },
  })

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleFormSubmit = ({ values }) => {
    if (!entityId || !entityType) return null

    setIsSubmitting(true)

    const materials = normalizeMaterialsToSubmit(values)

    createCurriculumMaterials({
      entityType,
      entityId: Number(entityId),
      materials,
      curriculumEventId,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      showFooter={false}
      width="650px"
      contentPadding="10px 20px"
    >
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Adicionar materiais</strong>
        </UnderlinedHeading>
      </Typography>

      <Formik
        initialValues={{
          files: [{ name: '', file: null }],
          links: [{ name: '', link: '' }],
        }}
      >
        {({ values, setSubmitting }) => (
          <Form
            noValidate
            onSubmit={(event) => {
              event.preventDefault()
              handleFormSubmit({ values, setSubmitting })
            }}
            onKeyPress={keyboardClick}
            headingSize="24px"
          >
            <Stack variant="modalBox" style={{ display: 'block' }}>
              <Header
                eventIndex={eventIndex}
                skillCode={skillCode}
                date={date}
              />

              <FileFields />

              <LinkFields />

              <AddedMaterials
                curriculumEventId={curriculumEventId}
                entityType={String(entityType)}
                entityId={Number(entityId)}
              />
            </Stack>

            <Stack direction="row" justifyContent="flex-end" py={2.5}>
              <Button
                size="small"
                variant="neutral"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                Voltar
              </Button>
              <Button
                size="small"
                variant="primary"
                type="submit"
                disabled={isSubmitting || validateSubmitValues(values)}
              >
                {isSubmitting ? 'Enviando...' : 'Salvar'}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

const validateSubmitValues = ({ files, links }) => {
  const filesIsValid = files.some(({ name, file }) => name && file)
  const linksIsValid = links.some(({ name, link }) => name && link)

  return !(filesIsValid || linksIsValid)
}

const normalizeMaterialsToSubmit = ({ files, links }) => {
  const materialFiles = files
    .map(({ name, file }) => ({
      name,
      file,
    }))
    .filter(({ name, file }) => !!name || !!file)

  const materialLinks = links
    .map(({ name, link }) => ({
      name,
      url: link,
    }))
    .filter(({ name, url }) => !!name || !!url)

  return [...materialFiles, ...materialLinks]
}
