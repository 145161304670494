import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (id) => `/api/v3/foco_exams/${id}`

export const useBookletFormDetails = (params) => {
  const { data, loading, error } = useResource({
    key: 'useBookletFormDetails',
    resource: getBookletFormDetails,
    params,
    enabled: !!params.id || !!params.entityId || !!params.entityType,
  })

  return { data, loading, error }
}

const getBookletFormDetails = ({ id, entityId, entityType }) =>
  fetchWithRoot('get')(getEndpoint(id), {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      include: [
        'foco_exam',
        'foco_exam_edition',
        'subject',
        'grade',
        'classrooms',
        'foco_exam_foco_questions',
        { foco_exam_student_profiles: ['student_profile'] },
      ],
      fields: [
        ['name'],
        {
          foco_exam_edition: ['id', 'name'],
          subject: ['id', 'name'],
          grade: ['id', 'name'],
          classrooms: ['id', 'name'],
          foco_exam_foco_questions: ['foco_question_id', 'id', 'position'],
          foco_exam_student_profiles: [{ student_profile: ['id'] }],
        },
      ],
    },
  })
