import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (id) => `/api/v3/foco_exams/${id}`

export const useBookletDetails = (params) => {
  const { data, loading, error } = useResource({
    key: 'useBookletDetails',
    resource: getBookletDetails,
    params,
    enabled: !!params.id && !!params.entityId && !!params.entityType,
  })

  return { data, loading, error }
}

const getBookletDetails = ({ id, entityId, entityType }) =>
  fetchWithRoot('get')(getEndpoint(Number(id)), {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      include: [
        'foco_exam',
        'foco_exam_edition',
        'subject',
        'grade',
        {
          foco_exam_foco_questions: [
            { foco_question: ['foco_question_options', 'curriculum_skill'] },
          ],
        },
      ],
      fields: [
        ['name'],
        {
          foco_exam_edition: ['id', 'name'],
          subject: ['id', 'name'],
          grade: ['id', 'name'],
          foco_exam_foco_questions: [
            'id',
            'position',
            {
              foco_question: [
                'id',
                'code',
                'statement',
                'command',
                'reference',
                'support_image_path',
                'curriculum_skill',
                {
                  foco_question_options: [
                    'id',
                    'alternative',
                    'correct',
                    'text',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  })
