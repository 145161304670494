import React, { useState, useReducer } from 'react'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { Header } from './Header'
import { Tabs } from './Tabs'
import { Content } from './Content'
import {
  reducer,
  getTabs,
  getInitialValues,
  getConfirmButtonText,
  getMaterialsData,
} from './helpers'
import { registerCurriculumEvent } from './fetch'
import { FormFeedback } from '~primitives/molecules/FormFeedback'
import { useFeedback } from '~contexts/Feedback'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { useCurriculumEvent } from '~data/curriculum/event'

const SUCCESS_STATUS = ['registered', 'planned']

export const PlanEventModal = ({
  isOpen,
  setIsOpen,
  week,
  weekDays,
  entityId,
  curriculumEvents,
  handleOpenSuggestedMaterialsModal,
  handleOpenAddMaterialsModal,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const initialValues = getInitialValues(curriculumEvents)
  const [state, dispatch] = useReducer(reducer, initialValues)

  const { showMessage } = useFeedback()

  const currentCurriculumEvent = state?.[activeTab] ?? []

  const isEventPlanned = SUCCESS_STATUS.includes(currentCurriculumEvent?.status)

  const { data, loading, error } = useCurriculumEvent(currentCurriculumEvent.id)

  const confirmButtonText = getConfirmButtonText(
    isSuccess,
    isEventPlanned,
    isSubmitting
  )

  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    () =>
      registerCurriculumEvent({
        data: {
          objective: currentCurriculumEvent?.objective,
          activityDescription: currentCurriculumEvent?.activityDescription,
          curriculumMaterials: getMaterialsData(
            currentCurriculumEvent?.initialMaterialsId ?? [],
            currentCurriculumEvent?.materialsId ?? []
          ),
        },
        skillId:
          currentCurriculumEvent.skillId ||
          data?.curriculum_events_curriculum_skills[0].curriculum_skill.id,
        attributesId: data?.curriculum_events_curriculum_skills[0].id,
        activityIds: currentCurriculumEvent.activityIds,
        eventId: currentCurriculumEvent.id,
        entityId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useCurriculumEvents')
        queryClient.invalidateQueries('useCurriculumEvent')
        handleSetCurriculumEventValues(currentCurriculumEvent.id, {
          isFormBuffed: false,
        })
        setIsSuccess(true)
      },
      onError: (error) => {
        showMessage({ type: 'error', text: error.message })
      },
      onSettled: () => {
        setIsSubmitting(false)
      },
    }
  )

  const handleSetCurriculumEventValues = (id, values) => {
    dispatch({ type: 'SET_VALUES', id, values })
  }

  const handleConfirmForm = () => {
    if (isSuccess) {
      setIsSuccess(false)
      return
    }
    if (isEventPlanned) {
      setIsOpen(false)
    } else {
      setIsSubmitting(true)
      mutate()
    }
  }

  const handleCloseForm = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      width="700px"
      height="98vh"
      closeOnDismiss={false}
      contentPadding="0 35px"
      showFooter={false}
      footerPadding="0 0 20px"
      showFooterDivider={false}
      onClose={handleCloseForm}
    >
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Plano de aulas</strong>
        </UnderlinedHeading>
      </Typography>

      <Stack variant="modalBox" style={{ display: 'block' }}>
        {isSuccess ? (
          <FormFeedback title="Planejamento de aula semanal alterado com sucesso!" />
        ) : (
          <React.Fragment>
            <Header week={week} weekDays={weekDays} />

            <Divider sx={{ my: 1.5 }} />

            <Tabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={getTabs(state)}
            />

            <Divider sx={{ my: 1.5 }} />

            <Content
              id={currentCurriculumEvent?.id}
              data={data}
              loading={loading}
              error={error || null}
              status={currentCurriculumEvent?.status}
              plannedCompetencyId={currentCurriculumEvent?.competencyId}
              plannedSkillId={currentCurriculumEvent?.skillId}
              objective={currentCurriculumEvent?.objective}
              activityDescription={currentCurriculumEvent?.activityDescription}
              eventIndex={activeTab + 1}
              materialsId={currentCurriculumEvent?.materialsId}
              handleSetValues={handleSetCurriculumEventValues}
              activityIds={currentCurriculumEvent?.activityIds}
              handleOpenSuggestedMaterialsModal={
                handleOpenSuggestedMaterialsModal
              }
              handleOpenAddMaterialsModal={handleOpenAddMaterialsModal}
              isFormBuffed={currentCurriculumEvent?.isFormBuffed}
            />
          </React.Fragment>
        )}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" my={2}>
        {!isEventPlanned && (
          <Button
            variant="neutral"
            size="small"
            onClick={() => {
              handleCloseForm()
            }}
          >
            Voltar para o currículo
          </Button>
        )}
        <Button
          type="submit"
          variant="primary"
          size="small"
          disabled={isSubmitting}
          onClick={() => {
            handleConfirmForm()
          }}
        >
          {confirmButtonText}
        </Button>
      </Stack>
    </Modal>
  )
}
