import React, { useState } from 'react'
import { equals } from 'ramda'
import { Box, Divider, Typography } from '@mui/material'
import Filters from './Filters'
import Questions from './Questions'
import Legend from './Legend'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'

export const AddQuestionsModal = ({
  subject,
  bookletName,
  isOpen,
  setIsOpen,
  selectedQuestionIds,
  setSelectedQuestionIds,
  handleOpenQuestionDetailsModal,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    gradeId: undefined,
    skillId: undefined,
  })
  const initialSelectedQuestions = [...selectedQuestionIds]
  const [newSelectedQuestionIds, setNewSelectedQuestionIds] = useState([
    ...selectedQuestionIds,
  ])

  const handleConfirm = () => {
    setSelectedQuestionIds(newSelectedQuestionIds)
    setIsOpen(false)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleChangeFilters = ({ gradeId, skillId }) => {
    setSelectedFilters({ gradeId, skillId })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      disableConfirm={equals(initialSelectedQuestions, newSelectedQuestionIds)}
      onConfirm={handleConfirm}
      confirmButtonText="Salvar"
      closeButtonText="Cancelar"
      contentPadding="16px"
    >
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Banco de questões</strong>
        </UnderlinedHeading>
      </Typography>

      <Box sx={{ bgcolor: 'grey.200', p: 3, mt: 2.5, borderRadius: 4 }}>
        <Box component="header">
          <Typography variant="subtitle1" component="h4">
            {bookletName && (
              <>
                <strong>{bookletName} </strong> •
              </>
            )}{' '}
            {subject?.name}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Filters onChange={handleChangeFilters} subjectId={subject?.id} />

        <Divider sx={{ my: 2 }} />

        <Legend amountOfQuestion={newSelectedQuestionIds?.length} />

        <Divider sx={{ my: 2 }} />

        <Questions
          gradeId={selectedFilters.gradeId}
          skillId={selectedFilters.skillId}
          selectedQuestionIds={newSelectedQuestionIds}
          setSelectedQuestionIds={setNewSelectedQuestionIds}
          handleOpenQuestionDetailsModal={handleOpenQuestionDetailsModal}
        />
      </Box>
    </Modal>
  )
}

export default AddQuestionsModal
