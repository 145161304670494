import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const StudentRow = ({ id, name, classroom, answered }) => (
  <TableRow
    data-testid={`student-${id}`}
    sx={{
      bgcolor: '#f8f8f8',
      mt: '2px',
      border: 'none',
    }}
  >
    <TableCell
      sx={{
        pl: 4,
      }}
    >
      {name}
    </TableCell>
    <TableCell align="center">{classroom}</TableCell>
    {answered ? (
      <TableCell
        align="right"
        sx={{
          width: 150,
          i: {
            color: 'transparent',
          },
        }}
      >
        Concluída <SVGIcon name="checkCircle" color="success" size={3} />
      </TableCell>
    ) : (
      <TableCell
        align="right"
        sx={{
          width: 150,
          i: {
            color: 'transparent',
          },
        }}
      >
        Não concluída <SVGIcon name="clockCircle" color="#979797" size={3} />
      </TableCell>
    )}
  </TableRow>
)

export default StudentRow
