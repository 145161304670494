import { useMutation, useQueryClient } from 'react-query'
import { config } from '../../config'
import { fetchWithRoot } from '~helpers/fetch'

const { endpoint } = config

export const useUpdateAcademicYear = ({ onError, onSuccess }) => {
  const queryClient = useQueryClient()

  return useMutation((params) => updateAcademicYear(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(config.key)
      onSuccess(data)
    },
    onError,
  })
}

export const updateAcademicYear = async (params) => {
  const updateParams =
    params.entityType === 'school'
      ? {
          initial_date: params.initialDate.format('YYYY-MM-DD'),
          end_date: params.endDate.format('YYYY-MM-DD'),
          curriculum_periods_attributes: params.curriculumPeriods.map(
            ({ id, startDate, endDate }) => ({
              id,
              start_date: startDate.format('YYYY-MM-DD'),
              end_date: endDate.format('YYYY-MM-DD'),
            })
          ),
        }
      : {
          initial_date: params.initialDate.format('YYYY-MM-DD'),
          end_date: params.endDate.format('YYYY-MM-DD'),
          expected_total_days: params.expectedTotalDays,
        }

  const [error, data] = await fetchWithRoot('patch')(
    `${endpoint(params.entityType, params.entityId)}/${params.id}`,
    {
      data: updateParams,
      bypassLoader: true,
    }
  )

  if (error) throw new Error(error.message)

  return data
}
