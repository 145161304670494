import { useEffect } from 'react'
import { prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const useLoggedOutGuards = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(prop('user'))
  const { pathname, search } = useSelector(prop('loginRedirect'))

  useEffect(() => {
    const subroute = location.pathname && location.pathname.split('/')[2]

    if (user.selectedProfile) {
      history.push({ pathname, search })
      history.push(user.active ? { pathname, search } : '/user')
      dispatch({ type: 'CLEAR_LOGIN_REDIRECT' })
      return
    }

    if (subroute === 'perfil' && !user.session.token) {
      history.push('/login')
    }

    if (subroute !== 'perfil' && user.session.token) {
      history.push('/login/perfil')
    }
  }, [history, dispatch, user, pathname, search])
}

export default useLoggedOutGuards
