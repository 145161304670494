import React, { useState } from 'react'
import { Typography, Stack, Button, Box } from '@mui/material'
import { useQueryClient } from 'react-query'
import Modal from '~primitives/Modal'
import { Form } from '~primitives/Input/Form'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { FormFeedback } from '~primitives/molecules/FormFeedback'
import { useDeleteCurriculumMaterial } from '~data/api/v3/curriculumMaterials/mutation/delete'
import { useFeedback } from '~contexts/Feedback'

export const DeleteMaterial = ({ id, name, onClose }) => {
  const [success, setSuccess] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const queryClient = useQueryClient()
  const { showMessage } = useFeedback()

  const { mutate: deleteCurriculumMaterial } = useDeleteCurriculumMaterial({
    onSuccess: () => {
      setSuccess(true)
      setDeleting(false)
      queryClient.invalidateQueries('useWeekCurriculumEvents')
      queryClient.invalidateQueries('useCurriculumEvents')
      queryClient.invalidateQueries('useCurriculumEvent')
    },
    onError: (error) => {
      showMessage({ type: 'error', text: `Erro: ${error.message}` })
      setDeleting(false)
    },
  })

  const handleConfirm = () => {
    setDeleting(true)
    deleteCurriculumMaterial({ id })
  }

  return (
    <Modal
      width="500px"
      height="98vh"
      overflowContent={true}
      contentPadding="5px 30px 20px"
      showFooter={false}
      onClose={onClose}
    >
      <Form onSubmit={(e) => e.preventDefault()} noValidate headingSize="24px">
        <header>
          <UnderlinedHeading as="h2" size={7}>
            <Typography component="strong" variant="h4">
              Deletar material
            </Typography>
          </UnderlinedHeading>
        </header>

        <Box backgroundColor="grey.200" borderRadius={3} p={3}>
          {success ? (
            <FormFeedback
              title="Material deletado!"
              text="O material foi excluído com sucesso."
            />
          ) : (
            <FormFeedback
              type="warning"
              title="Atenção!"
              text={
                <>
                  Tem certeza que você deseja deletar <strong>{name}</strong> da
                  sua lista?
                </>
              }
            />
          )}
        </Box>

        <Stack direction="row-reverse" component="footer">
          {!success && (
            <Button
              variant="primary"
              onClick={handleConfirm}
              disabled={deleting}
            >
              Confirmar
            </Button>
          )}

          <Button
            variant={success ? 'primary' : 'transparent'}
            onClick={onClose}
          >
            Voltar
          </Button>
        </Stack>
      </Form>
    </Modal>
  )
}
