import React from 'react'
import { Stack, Typography } from '@mui/material'

const ListHeader = ({ entityData, listLength }) => {
  if (!entityData || !listLength) return null

  return (
    <Stack direction="row" sx={{ mt: 3, mb: 2 }}>
      <Typography
        data-testid="district-title"
        variant="span"
        sx={{
          fontWeight: 'bold',
        }}
      >
        {`${entityData?.name} (${listLength} ${
          listLength > 1 ? 'regionais' : 'regional'
        })`}
      </Typography>
    </Stack>
  )
}

export default ListHeader
