import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/grades'

const useGrades = () => {
  const { loading, error, data } = useResource({
    resource: getGrades,
    key: 'useQuestionGrades',
  })

  return { loading, error, data }
}

const getGrades = () =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: ['grades'],
      fields: [{ grades: ['id', 'name'] }],
      q: { foco_questions_id_not_null: true },
    },
  })

export default useGrades
