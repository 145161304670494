import React, { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { deleteExam } from './fetch'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import SuccessCheck from '~assets/svg/success_check.svg'
import DeleteCheck from '~assets/svg/delete_check.svg'
import { useFeedback } from '~contexts/Feedback'

function ModalDelete({ handleCloseModal, examId }) {
  const [isSuccess, setIsSuccess] = useState(false)
  const queryClient = useQueryClient()
  const { showMessage } = useFeedback()

  const { mutate } = useMutation(() => deleteExam({ examId }), {
    onSuccess: () => {
      queryClient.invalidateQueries('useBookletsList')
      queryClient.invalidateQueries('useManageExamsList')
      setIsSuccess(true)
    },
    onError: (error) => {
      setIsSuccess(false)
      showMessage({ type: 'error', text: error.message })
    },
  })

  const handleConfirm = () => {
    if (isSuccess) {
      handleCloseModal()
    } else {
      mutate()
    }
  }

  return (
    <Modal
      width="500px"
      onConfirm={handleConfirm}
      closeButton={!isSuccess}
      onClose={handleCloseModal}
      confirmButtonText={isSuccess ? 'Voltar para a lista' : 'Confirmar'}
    >
      <>
        <Typography variant="h4" margin="15px 0">
          <UnderlinedHeading as="span">
            <strong>Deletar caderno de questões</strong>
          </UnderlinedHeading>
        </Typography>

        <Stack variant="modalBox">
          {isSuccess ? (
            <>
              <img src={SuccessCheck} alt="" width="90px" />
              <Typography variant="h5">
                Caderno de questões excluído com sucesso!
              </Typography>
            </>
          ) : (
            <>
              <img src={DeleteCheck} alt="" width="90px" />
              <Typography variant="h3">Atenção!</Typography>
              <Typography variant="span">
                Deseja realmente deletar o caderno de questões?
              </Typography>
            </>
          )}
        </Stack>
      </>
    </Modal>
  )
}

export default ModalDelete
