import React from 'react'
import qs from 'qs'
import _ from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { TableCell, TableHead, TableRow, Stack, Button } from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const Header = ({ cells }) => {
  const history = useHistory()
  const query = qs.parse(useLocation().search, { ignoreQueryPrefix: true })

  const setOrder = (property) => {
    const order =
      !query?.order ||
      !query?.orderBy ||
      (query?.orderBy === property && query?.order == 'desc')
        ? 'asc'
        : 'desc'

    history.push({
      search: qs.stringify({ ...query, order, orderBy: property }),
    })
  }

  return (
    <TableHead>
      <TableRow>
        {cells.map(({ label, property, align, width = 'initial' }) => (
          <TableCell key={property} sx={{ width: width }}>
            <Stack
              direction="row"
              justifyContent={align || 'inherit'}
              alignItems="center"
              gap={1}
              key={property}
              sx={{
                fontSize: 14,
                fontWeight: 700,
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              {label && label}
              {property && (
                <Button
                  data-testid={_.kebabCase(`button-toggle-${property}`)}
                  disableRipple
                  variant="transparent"
                  onClick={() => setOrder(property)}
                  sx={{
                    minWidth: 24,
                    transition: 'transform .2s ease-in',
                    transform: `rotateX(${
                      query?.orderBy === property && query?.order === 'desc'
                        ? '180deg'
                        : '0'
                    })`,
                  }}
                >
                  <SVGIcon name="openDropdown" color="linkColor" size={0} />
                </Button>
              )}
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default Header
