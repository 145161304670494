export default function DateCalendar(theme) {
  return {
    MuiDateCalendar: {
      variants: [
        {
          props: { variant: 'small' },
          style: {
            width: 210,
            maxHeight: 300,
            '& .MuiPickersCalendarHeader-root ': {
              paddingLeft: 10,
              paddingRight: 0,
              '& .MuiPickersFadeTransitionGroup-root': {
                display: 'flex',
                alignItems: 'center',
                '& .MuiPickersCalendarHeader-label': {
                  ...theme.typography.subtitle,
                  fontWeight: 700,
                },
              },
            },
            '& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel': {
              width: 26,
              height: 26,
            },
            '& .MuiDayCalendar-monthContainer': {
              '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                  backgroundColor: theme.palette.secondary.main,
                },
              },
            },

            '& .MuiPickersCalendarHeader-root  .MuiPickersFadeTransitionGroup-root, & .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-label':
              {
                minHeight: '16px',
                maxHeight: '16px',
              },
          },
        },
        {
          props: { variant: 'fullWidthCustom' },
          style: {
            width: '100%',
            maxHeight: 300,
            padding: theme.spacing(0, 1, 0, 5),
            '& .MuiPickersCalendarHeader-root ': {
              paddingLeft: 10,
              paddingRight: 0,
              justifyContent: 'space-between',
              '& .MuiPickersFadeTransitionGroup-root': {
                display: 'flex',
                alignItems: 'center',
                '& .MuiPickersCalendarHeader-label': {
                  ...theme.typography.subtitle,
                  fontWeight: 700,
                },
              },
            },
            '& .MuiPickersSlideTransition-root': {
              minHeight: 150,
            },
            '& .MuiDayCalendar-header': {
              justifyContent: 'space-between',
            },
            '& .MuiDayCalendar-weekContainer': {
              justifyContent: 'space-between',
            },
            '& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel': {
              width: 26,
              height: 26,
            },
            '& .MuiDayCalendar-monthContainer': {
              '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                  backgroundColor: theme.palette.secondary.main,
                },
              },
            },

            '& .MuiPickersCalendarHeader-root  .MuiPickersFadeTransitionGroup-root, & .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-label':
              {
                minHeight: '16px',
                maxHeight: '16px',
              },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& .MuiPickersCalendarHeader-root  .MuiPickersFadeTransitionGroup-root':
            {
              minHeight: '30px',
              maxHeight: '30px',
            },
          '& .MuiDayCalendar-monthContainer': {
            '& .MuiPickersDay-root': {
              '&.Mui-selected': {
                backgroundColor: theme.palette.secondary.main,
              },
            },
          },
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            '& .MuiMenuItem-root': {
              '&.Mui-selected': {
                backgroundColor: theme.palette.secondary.main,
              },
            },
          },
        },
      },
    },
  }
}
