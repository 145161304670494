import React, { createContext, useEffect, useState } from 'react'
import { omit, mapObjIndexed } from 'ramda'
import queryString from 'qs'
import { useLocation, useHistory } from 'react-router-dom'
import isEqual from 'react-fast-compare'
import usePrevious from '~hooks/usePrevious'

export const initialFiltersData = {
  filters: null,
  error: null,
  loading: false,
  params: {},
  updateParams: () => {},
  pushParam: () => {},
  requestFilters: () => Promise.resolve([null, {}]),
}

export const FiltersContext = createContext(initialFiltersData)

export const FiltersProvider = ({
  children,
  resource,
  config,
  additionalParams,
}) => {
  const [state, setState] = useState({
    error: undefined,
    filters: undefined,
  })
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { pathname, search } = useLocation()
  const params = queryString.parse(search, { ignoreQueryPrefix: true })
  const defaultParams = omit(Object.keys(config), params)

  const deps = { resource, search, pathname, additionalParams }
  const prevDeps = usePrevious(deps)

  useEffect(() => {
    if (!isEqual(deps, prevDeps)) {
      setLoading(true)
      deps
        .resource({
          ...queryString.parse(deps.search, { ignoreQueryPrefix: true }),
          ...deps.additionalParams,
        })
        .then(([error, filters]) => {
          setState({ error, filters })
          setLoading(false)
        })
    }
  }, [deps, prevDeps])

  const updateParams = (newParams) => {
    history.push({
      pathname,
      search: queryString.stringify(
        omit(['page'], { ...defaultParams, ...newParams })
      ),
    })
  }

  const pushParam = (name, id) => {
    updateParams({ ...params, [name]: id })
  }

  const normalizeFilters =
    (state, params) =>
    (normalizedFilters, [key, value]) => {
      const label = value.label
      const options = state.filters?.[value.filter]

      if (!options) return normalizedFilters

      const firstAvailableOption = options.find(({ disabled }) => !disabled)
      const userSelectedOption = options.find(
        ({ id }) => String(id) === params[key]
      )

      if (!firstAvailableOption) return normalizedFilters

      const { id, name } = userSelectedOption || firstAvailableOption

      return {
        ...normalizedFilters,
        [key]: { label, selected: { id, name }, options },
      }
    }

  const filters =
    state.filters &&
    Object.entries(config).reduce(normalizeFilters(state, params), {})

  const getParams = () => {
    if (!state.filters) return null
    const selected = mapObjIndexed((filter) => filter.selected.id, filters)
    return { ...params, ...additionalParams, ...selected }
  }

  const requestFilters = (params) => {
    return resource({ ...defaultParams, ...additionalParams, ...params }).then(
      ([error, data]) => {
        if (error) return [error, null]
        return [null, renameFilterKeys(config, data)]
      }
    )
  }

  const renameFilterKeys = (config, options) => {
    if (!options) return {}
    return mapObjIndexed(({ filter }) => options[filter])(config)
  }

  return (
    <FiltersContext.Provider
      value={{
        filters,
        error: state.error,
        loading,
        params: getParams(),
        updateParams,
        pushParam,
        requestFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export const FiltersConsumer = FiltersContext.Consumer

export default FiltersContext
