import React from 'react'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import Header from '~components/Common/Header'
import { Content } from '~components/Journeys/Content'

export default function JourneysListPage() {
  const { entityId } = useParams()

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ px: { xs: 2, sm: 4, md: 8 } }}
    >
      <Header
        title="Cadastrar turno"
        breadcrumbs={[
          {
            text: 'Cadastrar',
            pathname: `/gerenciar-calendario/school/${entityId ?? ''}/turnos`,
          },
          {
            text: 'Turno',
            pathname: `/gerenciar-calendario/school/${entityId ?? ''}/turnos`,
          },
        ]}
      />

      {entityId && <Content entityId={Number(entityId)} />}
    </Container>
  )
}
