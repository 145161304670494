import { fetchWithRoot } from '~helpers/fetch'
import useFilters from '~hooks/useFilters'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/curriculum_events'

const useCurriculumEvents = () => {
  const { params } = useFilters()

  const { loading, error, data } = useResource({
    resource: getEvents,
    params: params || {},
    enabled: !!params,
    key: 'useCurriculumEvents',
  })

  return {
    loading,
    error,
    data,
  }
}

export const useCurriculumEventsInsights = (params) => {
  const { loading, error, data } = useResource({
    resource: getEventsInsights,
    params: params || {},
    enabled: !!params,
    key: 'useCurriculumEventsInsights',
  })

  return {
    loading,
    error,
    data,
  }
}

const getEvents = ({ entityId, ...params }) => {
  return fetchWithRoot('get')(endpoint, {
    params: {
      ...params,
      entity_id: entityId,
      entity_type: 'school',
      classroom_id: params.classroom_id,
      period_id: params.curriculum_period_id,
      include: [
        {
          curriculum_events: [
            {
              curriculum_events_curriculum_skills: [
                { curriculum_skill: ['curriculum_competency'] },
              ],
            },
            'curriculum_activities',
          ],
        },
      ],
    },
    bypassLoader: true,
  })
}

const getEventsInsights = ({ entityId, ...params }) => {
  return fetchWithRoot('get')(endpoint, {
    params: {
      ...params,
      entity_id: entityId,
      entity_type: 'school',
      classroom_id: params.classroom_id,
      period_id: params.curriculum_period_id,
      fields: [
        'performed_count',
        'performed_rate',
        'planned_curriculum_skills_count',
        'performed_curriculum_skills_count',
        'events_count',
      ],
    },
    bypassLoader: true,
  })
}

export default useCurriculumEvents
