import React, { useState } from 'react'
import { Box, Divider, Stack, Typography, Button } from '@mui/material'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import { ModalSuccess } from '../../ModalSuccess'
import { Fields } from './Fields'
import { useAcademicYear } from '~data/api/v3/academicYears/query/findUnique'
import { useFeedback } from '~contexts/Feedback'
import { useCreateAcademicYear } from '~data/api/v3/academicYears/mutation/create'

export const DistrictForm = ({ entityId, entityType, academicYearId }) => {
  const [success, setSuccess] = useState(false)
  const [createYear, setCreateYear] = useState(0)
  const { showMessage } = useFeedback()
  const history = useHistory()
  const { data } = useAcademicYear({
    entityType,
    entityId,
    academicYearId,
  })

  const { mutate: createAcademicYear } = useCreateAcademicYear({
    onSuccess: () => setSuccess(true),
    onError: (error) => {
      showMessage({ type: 'error', text: `Erro: ${error.message}` })
    },
  })

  const handleSubmit = (values) => {
    setCreateYear(values.year)
    createAcademicYear(values)
  }

  const onCloseModal = () => {
    setSuccess(false)
    history.push(
      `/gerenciar-calendario/${entityType}/${entityId}/anos-letivos/`
    )
  }

  const valueDateStart = data?.initial_date
    ? dayjs(data.initial_date)
    : dayjs().startOf('year')

  const valueDateEnd = data?.end_date
    ? dayjs(data.end_date)
    : dayjs().endOf('year')

  const initialValues = {
    year: new Date().getFullYear(),
    initialDate: valueDateStart,
    endDate: valueDateEnd,
    periodType: 'bimester',
    expectedTotalDays: 200,
    entityType: entityType,
    entityId: entityId,
  }

  const validate = (values) =>
    Object.fromEntries(
      ['year', 'initialDate', 'endDate', 'expectedTotalDays', 'periodType']
        .filter((field) => !values[field])
        .map((field) => [field, 'Obrigatório'])
    )

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <Stack
              variant="modalBox"
              p={4}
              direction="column"
              alignItems="flex-start"
            >
              <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                Dados gerais
              </Typography>

              <Divider sx={{ width: '100%', my: 2 }} />

              <Box width="100%">
                <Fields />
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <Button
                variant="neutral"
                size="small"
                sx={{ minWidth: 160 }}
                component={Link}
                to={`/gerenciar-calendario/district/${entityId}/anos-letivos`}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="small"
                sx={{ minWidth: 160 }}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </Stack>
          </>
        )}
      </Formik>

      {success && (
        <ModalSuccess onClose={onCloseModal} academicYear={createYear} />
      )}
    </>
  )
}
