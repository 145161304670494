/* eslint-disable no-unused-vars */
import { getAAPLink, getEnemQuestionsLink } from './links/aap'
import { getDashboardLink } from './links/indicators'
import { getFocoExamsLink } from './links/focoExams'
import { getCurriculumLink } from './links/curriculum'
import { getManagementLink } from './links/management'
import { getEnemMapLink, getSkillMapLink } from './links/skillMap'

export const paths = {
  retrospective: (examType, resource) => {
    if (examType === 'tct') {
      return (id, profile) => getAAPLink('retrospective', id, profile)
    }

    return (id, profile) =>
      getSkillMapLink('retrospective', resource, id, profile)
  },
  prospective: (examType, resource) => {
    if (examType === 'tct') {
      return (id, profile) => getAAPLink('prospective', id, profile)
    }

    return (id, profile) =>
      getSkillMapLink('prospective', resource, id, profile)
  },
  enem: (examType, resource) => {
    if (examType === 'tri') {
      return (id, profile) => getEnemMapLink(profile)
    }

    return (id, profile) => getEnemQuestionsLink(profile)
  },
  indicators: (examType, resource) => {
    return (id, profile) => getDashboardLink(profile, resource)
  },
  foco_exam: (examType, resource) => {
    return (id, profile) => getFocoExamsLink(profile, resource)
  },
  curriculum: (examType, resource) => {
    return (id, profile) => getCurriculumLink(profile, resource)
  },
  management: (examType, resource) => {
    return (id, profile) => getManagementLink(profile, resource)
  },
  foco_bi: (examType, resource) => (id, profile) => '/foco-bi',
}

export const getPath = ({ feature, examType, resource }) =>
  paths[feature](examType, resource) || ((id, profile) => '')
