export const getStyles = (theme, isActive) => ({
  general: {
    bgcolor: isActive
      ? theme.palette.secondary.darker
      : theme.palette.grey[800],
    my: 1,
    borderRadius: theme.shape.borderRadius * 2,
    cursor: 'pointer',
    '& > a i, & > button i': {
      fontSize: 24,
    },
    '& > a': {
      color: theme.palette.grey[0],
      display: 'flex',
      alignItems: 'center',
    },
    '& > button': {
      justifyContent: 'flex-start',
    },
    '& > a, & > button': {
      fontSize: 13,
      gap: 1.5,
      p: 1.125,
    },
    '& > button:focus': {
      color: 'inherit',
    },
    '&:hover': {
      bgcolor: theme.palette.grey[200],
    },
    '&:hover > a': {
      color: theme.palette.grey[800],
      fontWeight: 700,
    },
    '&:hover i': {
      bgcolor: theme.palette.grey[550],
    },
  },
})
