import React from 'react'
import { Grid } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import dayjs from 'dayjs'
import { SelectField } from '~primitives/Input/SelectField'
import { DesktopDatePickerField } from '~primitives/Input/DesktopDatePickerField'
import { usePendingAcademicYears } from '~data/api/v3/academicYears/query/findPending'

export const YearFields = ({ type, parentAcademicYear }) => {
  const { values, setFieldValue } = useFormikContext()

  const { loading, data } = usePendingAcademicYears({
    entityType: 'school',
    entityId: values.entityId,
  })

  const academicYears = data?.academic_years ?? []

  const handleChangeParentYear = ({ target }) => {
    setFieldValue('parentAcademicYear', target.value)

    const selectedYear = academicYears.find(({ id }) => id === target.value)

    setFieldValue('year', selectedYear?.year)
    setFieldValue('expectedTotalDays', selectedYear?.expected_total_days)
    setFieldValue('initialDate', dayjs(selectedYear?.initial_date))
    setFieldValue('endDate', dayjs(selectedYear?.end_date))

    setFieldValue(
      'curriculumPeriods',
      selectedYear?.curriculum_periods.map(
        ({ id, name, start_date, end_date }) => ({
          parentCurriculumPeriod: id,
          name,
          startDate: dayjs(start_date),
          endDate: dayjs(end_date),
        })
      )
    )
  }

  const academicYearOptions =
    type === 'update'
      ? [{ value: parentAcademicYear?.id, label: parentAcademicYear?.year }]
      : academicYears.map(({ id, year }) => ({
          label: year,
          value: id,
        }))

  const minDate = dayjs().year(values.year).startOf('year')
  const maxDate = dayjs().year(values.year).endOf('year')

  return (
    <Grid component="fieldset" title="Ano" container spacing={1}>
      <Grid item xs={12} md={4} lg={2} pr={4}>
        <Field
          component={SelectField}
          name="parentAcademicYear"
          label="Ano"
          required
          onChange={handleChangeParentYear}
          options={academicYearOptions}
          placeholder={loading ? 'Carregando...' : 'Selecione o ano'}
          disabled={type === 'update'}
        />
      </Grid>

      <Grid item xs={12} md={8} lg={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} pr={9}>
            <Field
              component={DesktopDatePickerField}
              variant="standard"
              name="initialDate"
              label="Data de início"
              minDate={minDate}
              maxDate={values.endDate}
              required
              fullWidth
              placeholder="Selecione"
              disabled={values.parentAcademicYear === null}
            />
          </Grid>
          <Grid item xs={12} md={6} pr={9}>
            <Field
              component={DesktopDatePickerField}
              variant="standard"
              name="endDate"
              label="Data fim"
              minDate={values.initialDate}
              maxDate={maxDate}
              required
              fullWidth
              placeholder="Selecione"
              disabled={values.parentAcademicYear === null}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
