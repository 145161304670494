import { createReducer } from 'reduxsauce'

export const initialState = {
  pathname: '/pagina-inicial',
  search: '',
}

const setRedirect = (state, { payload }) => ({
  ...state,
  ...payload,
})

const clearRedirect = () => initialState

const handlers = {
  SET_LOGIN_REDIRECT: setRedirect,
  CLEAR_LOGIN_REDIRECT: clearRedirect,
}

export default createReducer(initialState, handlers)
