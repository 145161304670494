import { styled } from '@mui/material/styles'
import UnderlineSVG from '~assets/svg/heading_underline.svg'
import DoubleUnderlineSVG from '~assets/svg/double_heading_underline.svg'
import DoubleCaptionUnderline from '~assets/svg/double_caption_underline.svg'
import RoundSVG from '~assets/svg/round_underline.svg'

export const Scribble = styled('span')`
  display: inline-block;
  padding-bottom: 5px;
  background-image: ${({ double }) =>
    double ? `url(${DoubleUnderlineSVG})` : `url(${UnderlineSVG})`};
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover 100%;
  font-weight: inherit;
`
export const ScribbleRound = styled('span')`
  display: inline-block;
  font-weight: inherit;
  position: relative;
  z-index: 1;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 120%;
    height: 120%;
    background: url(${RoundSVG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`

export const CaptionUnderline = styled('span')`
  font-weight: 700;
  display: inline;
  position: relative;

  &:after {
    content: ' ';
    background-image: url(${DoubleCaptionUnderline});
    background-repeat: no-repeat;
    background-position: left bottom;
    width: 40.88px;
    height: 7.11px;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
`
