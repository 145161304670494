import React from 'react'
import { Grid, Skeleton, Stack, Typography } from '@mui/material'

export const Header = ({ examName, classroomName, loading }) => (
  <Grid container component="header" spacing={{ xs: 0, md: 2 }} maxWidth={1190}>
    <Grid item xs={5} minWidth={210}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h4" fontSize={{ xl: 20, md: 16, xs: 18 }}>
          Caderno:
        </Typography>

        <Typography fontSize={{ xl: 20, md: 16, xs: 18 }}>
          {loading ? <Skeleton width={200} /> : examName}
        </Typography>
      </Stack>
    </Grid>
    <Grid item xs={7}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h4" fontSize={{ xl: 20, md: 16, xs: 18 }}>
          Turma:
        </Typography>
        <Typography fontSize={{ xl: 20, md: 16, xs: 18 }}>
          {loading ? <Skeleton width={200} /> : classroomName}
        </Typography>
      </Stack>
    </Grid>
  </Grid>
)
