import React, { useState } from 'react'
import { TextField, IconButton, InputAdornment } from '@mui/material'
import { SVGIcon } from '../../Utilities/SVGIcon'

export const PasswordInput = ({
  error,
  icon,
  name,
  id,
  placeholder,
  mask,
  definitions,
  label,
  required,
  value,
  onChange,
  onBlur,
  InputProps,
  inputRef,
  variant = 'standard',
  size = 'medium',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <TextField
      label={label}
      type={showPassword ? 'text' : 'password'}
      id={id || name}
      name={name}
      placeholder={placeholder}
      value={value}
      error={error}
      variant={variant}
      size={size}
      onChange={onChange}
      onBlur={onBlur}
      inputRef={inputRef}
      fullWidth
      InputProps={{
        ...InputProps,
        inputProps: {
          mask,
          definitions,
          'data-testid': id || name,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="alternar visibilidade da senha"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              disableRipple
            >
              <SVGIcon
                name={showPassword ? 'hidePassword' : 'showPassword'}
                color="linkColor"
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  )
}
