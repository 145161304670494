import Accordion from './Accordion'
import Link from './Link'
import Stack from './Stack'
import Button from './Button'
import Snackbar from './Snackbar'
import Paper from './Paper'
import Popover from './Popover'
import List from './List'
import ListItemButton from './ListItemButton'
import Card from './Card'
import IconButton from './IconButton'
import Select from './Select'
import InputLabel from './InputLabel'
import Input from './Input'
import MenuItem from './MenuItem'
import Table from './Table'
import TextField from './TextField'
import Checkbox from './Checkbox'
import Switch from './Switch'
import FormGroup from './FormGroup'
import DateCalendar from './DateCalendar'
import Chip from './Chip'
import Badge from './Badge'
import LinearProgress from './LinearProgress'
import Dialog from './Dialog'
import FormControl from './FormControl'
import FormControlLabel from './FormControlLabel'
import Radio from './Radio'
import HelperText from './HelperText'

export default function ComponentsOverrides(theme) {
  return {
    ...Accordion(theme),
    ...Snackbar(theme),
    ...Stack(theme),
    ...Link(theme),
    ...Paper(theme),
    ...Popover(theme),
    ...List(theme),
    ...ListItemButton(theme),
    ...Card(theme),
    ...IconButton(theme),
    ...Select(theme),
    ...Checkbox(theme),
    ...Switch(theme),
    ...Input(theme),
    ...InputLabel(theme),
    ...MenuItem(theme),
    ...Table(theme),
    ...Button(theme),
    ...TextField(theme),
    ...Checkbox(theme),
    ...FormGroup(theme),
    ...DateCalendar(theme),
    ...Chip(theme),
    ...Badge(theme),
    ...LinearProgress(theme),
    ...Dialog(),
    ...FormControl(theme),
    ...FormControlLabel(theme),
    ...Radio(theme),
    ...HelperText(),
  }
}
