export const getStyles = (theme, color, isActive) => ({
  container: {
    bgcolor: isActive ? color : 'transparent',
    cursor: 'pointer',
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 130,
    minHeight: 54,
    border: 1,
    borderColor: color,
    borderRadius: theme.shape.borderRadius,
    transition: 'background ease-in .2s',
    '&:hover': {
      bgcolor: isActive ? color : 'transparent',
    },
    '&:hover > span': {
      color: isActive ? theme.palette.grey[0] : color,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 8,
      height: 35,
      px: 2.5,
      width: '100%',
    },
  },
  title: {
    color: isActive ? theme.palette.grey[0] : color,
    transition: 'color ease-in .2s',
    cursor: 'inherit',
  },
  value: {
    color: isActive ? theme.palette.grey[0] : color,
    transition: 'color ease-in .2s',
    fontSize: 24,
    lineHeight: 1,
  },
})
