import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

const useSectorList = (params) => {
  const { data: examData } = useResource({
    key: 'useFocoExamData',
    resource: getFocoExamData,
    params,
  })

  const selectedExamId = examData?.foco_exams[0]?.id

  const { loading, error, data } = useResource({
    key: 'useSectorList',
    resource: getSectorList,
    params: {
      id: Number(selectedExamId),
      entityId: Number(params.entityId),
      page: Number(params.page),
      order: params?.order,
      orderBy: params?.orderBy,
    },
    enabled: !!selectedExamId,
  })

  return { loading, error, data, examData }
}

export const focoExamEndpoint = '/api/v3/foco_exams/'

const getFocoExamData = (params) =>
  fetchWithRoot('get')(focoExamEndpoint, {
    params: {
      entity_type: 'district',
      entity_id: params?.entityId,
      q: {
        district_id_eq: params?.districtId,
        grade_id_eq: params?.gradeId,
        subject_id_eq: params?.subjectId,
      },
      fields: [{ foco_exams: ['id'] }],
      include: ['foco_exams'],
    },
    bypassLoader: true,
  })

export const endpoint = '/api/v3/sectors'

const getSectorList = ({
  id,
  entityId,
  page,
  order = 'asc',
  orderBy = 'name',
}) =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_type: 'district',
      entity_id: entityId,
      foco_exam_id: id,
      page: page || 1,
      per_page: 10,
      q: {
        schools_classrooms_student_profiles_foco_exam_student_profiles_foco_exam_id_eq:
          id,
        s: `${orderBy} ${order}`,
      },
      fields: [
        'meta',
        { sectors: ['id', 'name', 'foco_exam_completion_rate'] },
      ],
      include: ['sectors'],
    },
    bypassLoader: true,
  })

export default useSectorList
