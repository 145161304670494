import { alpha } from '@mui/material/styles'

export default function TextField(theme) {
  return {
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            position: 'relative',
            paddingBottom: '20px',
            'input, textarea': {
              borderRadius: theme.shape.borderRadius * 2,
              border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
              padding: '0 20px',
              height: '100%',
              '&::placeholder': {
                color: theme.palette.grey[600],
              },
            },
            fieldset: {
              display: 'none',
            },
            '& .MuiInputLabel-outlined': {
              '&.Mui-error': {
                color: theme.palette.grey[800],
                '& + .MuiOutlinedInput-root > input': {
                  border: `1px solid ${theme.palette.error.main}`,
                },
              },
            },
            '& .MuiFormHelperText-root': {
              '&.Mui-error': {
                position: 'absolute',
                bottom: '2px',
              },
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            '& .MuiInputLabel-root': {
              color: theme.palette.grey[800],
              '&.Mui-error': {
                color: theme.palette.grey[800],
              },
            },
            '& .MuiInputBase-root': {
              '& .MuiInputAdornment-root': {
                button: {
                  color: theme.palette.secondary.main,
                },
              },
              marginTop: '4px',
              '&:before': {
                display: 'none',
              },
              '&:after': {
                display: 'none',
              },

              'input, textarea': {
                background: theme.palette.common.white,
                border: 'none',
                borderRadius: theme.shape.borderRadius * 2,
                padding: '0 20px',
                height: '100%',
                '&::placeholder': {
                  color: theme.palette.grey[600],
                },
              },

              textarea: {
                padding: '20px',
                boxSizing: 'border-box',
              },

              '&.Mui-error': {
                color: theme.palette.grey[600],

                'input, textarea': {
                  border: `1px solid ${theme.palette.error.main}`,
                },
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& .MuiInputBase-adornedStart': {
            paddingLeft: 0,
          },
          '& .MuiInputBase-adornedEnd': {
            paddingRight: 0,
          },

          '& .MuiInputAdornment-root': {
            position: 'absolute',
          },
          '& .MuiInputAdornment-positionEnd': {
            right: '15px',
          },
          '& .MuiInputAdornment-positionStart': {
            left: '15px',
          },
          '& .MuiFormHelperText-root': {
            bottom: 0,
            margin: theme.spacing(1, 0, 0, 0),
          },
        },
      },
    },
  }
}
