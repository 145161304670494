import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = (id) =>
  `/api/v3/foco_exam_editions/${id}/foco_exam_student_profiles`

export const useManageExamsStudentList = (params) => {
  const { data, error, loading } = useResource({
    key: 'useManageExamsStudentList',
    resource: getManageExamsStudentList,
    params,
    enabled: true,
  })

  return { loading, error, data }
}

const getManageExamsStudentList = ({ editionId, examId }) =>
  fetchWithRoot('get')(endpoint(editionId), {
    params: {
      include: [
        { foco_exam_student_profiles: [{ student_profile: ['user'] }] },
      ],
      fields: [
        {
          foco_exam_student_profiles: [
            'id',
            'hit_rate',
            'answered_at',
            'started_at',
            {
              student_profile: [{ user: ['id', 'name'] }],
            },
          ],
        },
      ],
      q: { foco_exam_id_eq: examId },
    },
  })
