import { styled } from '@mui/material/styles'
import { Heading } from '../'
import UnderlineSVG from '~assets/svg/heading_underline.svg'

export const UnderlinedHeading = styled(Heading)`
  strong,
  span {
    display: inline-block;
    padding-bottom: 5px;
    margin-bottom: 10px;
    background-image: url(${UnderlineSVG});
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover 100%;
  }
`
