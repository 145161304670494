import React from 'react'
import { Alert, Box, Container, Stack, Link } from '@mui/material'
import { useParams, Link as RouterLink } from 'react-router-dom'
import Header from '~components/Common/Header'
import { useFocoExamEdition } from '~data/api/focoExamEdition/findOne'
import { Content } from '~components/ManageExams/ViewDetails/ExamEdition'

export const ManageExamsViewDetails = () => {
  const { focoExamEditionId, entityType, entityId } = useParams()
  const { data, loading, error } = useFocoExamEdition({
    id: focoExamEditionId ?? '',
    entityId: entityId ?? '',
    entityType: entityType ?? '',
    requestTemplate: 'exam-details',
  })

  return (
    <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
      <Header
        title="Visualizar avaliação"
        breadcrumbs={[
          {
            text: 'Avaliações',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Gerenciar avaliações',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Visualizar avaliação',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }/visualizar/${focoExamEditionId ?? ''}`,
          },
        ]}
      />

      {loading && <span>Carregando...</span>}

      {error && (
        <Box mt={5}>
          <Alert variant="filled" severity="error">
            Ocorreu um erro ao carregar os dados da avaliação.
          </Alert>
        </Box>
      )}

      {data && !loading && (
        <>
          <Box mb={6}>
            <Content
              examStart={data?.exam_start}
              examEnd={data?.exam_end}
              timeLimit={data?.time_limit}
              name={data?.name}
              subjects={data?.subjects}
              grades={data?.grades}
              instructions={data?.instructions}
            />
          </Box>
          <Stack
            alignItems="flex-end"
            sx={{ maxWidth: 720, width: 1, margin: '0 auto' }}
          >
            <Link
              to={`/gerenciar-avaliacoes/${entityType ?? ''}/${entityId ?? ''}`}
              variant="secondaryOutlined"
              component={RouterLink}
            >
              Voltar
            </Link>
          </Stack>
        </>
      )}
    </Container>
  )
}

export default ManageExamsViewDetails
