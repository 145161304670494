import { any, filter, map, omit, pipe } from 'ramda'
import store from '~store'

const profileHasFeature = (feature, resource_name) => {
  const features = store.getState().user.features
  if (!features[feature]) return false
  return !!features[feature].resources.find(
    ({ resource }) => resource === resource_name
  )
}

const profileHasAnyFeature = any(({ feature, requiredResource }) =>
  profileHasFeature(feature, requiredResource)
)

export const filterStepsByFeatures = (steps) =>
  pipe(
    filter(({ requiredResources }) => {
      if (!requiredResources) return true
      return profileHasAnyFeature(requiredResources)
    }),
    map(omit(['requiredResources']))
  )(steps)
