import { config } from '../config'
import { fetchWithoutRoot } from '~helpers/fetch'

const { endpoint } = config

export const deleteJourneys = async ({ journeyId, params }) => {
  const [response] = await fetchWithoutRoot('delete')(
    `${endpoint(params.entityType, params.entityId)}/${journeyId}`
  )

  if (response?.message) {
    throw new Error(response)
  }
}
