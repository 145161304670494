import React from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import { Table, TableBody, TableContainer } from '@mui/material'
import Row from '~components/FocoExams/Teacher/DistrictContent/List/Table/Row'
import Header from '~components/FocoExams/Teacher/DistrictContent/List/Table/Header'

const ListTable = ({ list }) => {
  const history = useHistory()
  const query = qs.parse(useLocation().search, { ignoreQueryPrefix: true })

  const setOrder = (orderBy, order) => {
    history.push({
      search: qs.stringify({ ...query, order, orderBy }),
    })
  }

  return (
    <TableContainer>
      <Table
        variant="borderless"
        data-testid="foco-exam-district-table"
        sx={{
          minWidth: 650,
          color: 'grey.800',
          borderSpacing: '0 2px',
          borderCollapse: 'separate',
        }}
      >
        <Header setOrder={setOrder} />
        <TableBody>
          {list.map(({ id, name, foco_exam_completion_rate }) => (
            <Row
              key={id}
              id={id}
              name={name}
              foco_exam_completion_rate={foco_exam_completion_rate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ListTable
