import React from 'react'
import { Chip, Stack, Typography } from '@mui/material'
import useFilters from '~hooks/useFilters'

export const Header = ({ week, weekDays }) => {
  const { filters } = useFilters()

  const label = `${filters?.grade_id?.selected?.name} - ${
    filters?.subject_id?.selected?.name
  }`

  return (
    <Stack
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      direction={{ xs: 'column', sm: 'row' }}
    >
      <Chip size="medium" color="info" label={label} />
      <Stack
        alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
        mt={{ xs: 1, sm: 0 }}
      >
        <Stack direction="row" gap={1}>
          <Typography variant="body2" fontWeight={700}>
            Período
          </Typography>
          <Typography variant="body2">
            {filters?.curriculum_period_id?.selected?.name}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Typography variant="body2" fontWeight={700}>
            Semana {week}
          </Typography>
          <Typography variant="body2">{weekDays}</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
