import React, { useState } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { Header } from '../Header'
import { MaterialList } from './List'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import useCurriculumSkills from '~data/api/curriculumSkills/findAll'

export const SuggestedMaterialsModal = ({
  isOpen,
  setIsOpen,
  closeButtonText,
  eventIndex,
  skillId,
  skillCode,
  date,
  initialValues = [],
  setValuesOnConfirm = () => {},
}) => {
  const { data, loading } = useCurriculumSkills({
    requestTemplate: 'modal-suggested-material',
    curriculumSkillId: skillId,
  })

  const [newSelectedMaterialsId, setNewSelectedMaterialsId] =
    useState(initialValues)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleConfirm = () => {
    setValuesOnConfirm(newSelectedMaterialsId)
    setIsOpen(false)
  }

  return (
    <Modal
      width="800px"
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmButtonText="Salvar"
      closeButtonText={closeButtonText}
    >
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Materiais sugeridos</strong>
        </UnderlinedHeading>
      </Typography>

      <Stack variant="modalBox" style={{ display: 'block' }}>
        <Header eventIndex={eventIndex} skillCode={skillCode} date={date} />

        <Divider sx={{ my: 1 }} />

        {data?.curriculum_skills?.[0]?.curriculum_materials?.length !== 0 && (
          <Typography variant="caption">
            Selecione os materiais que deseja adicionar à aula.
          </Typography>
        )}

        <MaterialList
          selectedMaterialsId={newSelectedMaterialsId}
          setSelectedMaterialsId={setNewSelectedMaterialsId}
          materialList={
            data?.curriculum_skills?.[0]?.curriculum_materials ?? []
          }
          loading={loading}
        />
      </Stack>
    </Modal>
  )
}
