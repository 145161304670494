import { fetchWithRoot } from '~helpers/fetch'

export const curriculum_events_endpoint = '/api/v3/curriculum_events/generate'

export const generateCurriculum = ({ data }) => {
  return fetchWithRoot('post')(curriculum_events_endpoint, {
    data,
  }).then(([error, response]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
    if (response) return response
  })
}
