import React from 'react'
import { Container, ShareIcon } from '../styles.js'
import { Text } from '~primitives/Typography'

import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const ShareButtonContent = ({ content, onClick, customization }) => {
  if (!content) return null

  return (
    <Container customization={customization}>
      <a
        onClick={onClick}
        data-testid="share-button-anchor"
        rel="noopener noreferrer"
        target="_blank"
        href={`https://api.whatsapp.com/send?text=*${content.message}`}
      >
        <Text width="" color="backgroundNeutral">
          Compartilhe
        </Text>
        <ShareIcon className="share-icon">
          <SVGIcon name="shareIcon" color="#008190" />
        </ShareIcon>
      </a>
    </Container>
  )
}

export default ShareButtonContent
