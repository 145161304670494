import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import _ from 'lodash'
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListSubheader,
  ListItem,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { getStyles } from './styles'
import { getActiveLink } from './helpers'
import { track } from '~resources/ahoy'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const FeaturesGroup = ({
  title,
  icon,
  name,
  activeMatches,
  activeCollapse,
  setActiveCollapse,
  activeMenu,
  setActiveMenu,
  groups = [],
}) => {
  const { pathname, search } = useLocation()
  const collapseExpanded = activeCollapse === name
  const isHoverExpanded = activeMenu === name
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isGroupActive = activeMatches.some((match) => pathname.includes(match))

  const styles = getStyles(
    theme,
    collapseExpanded,
    isHoverExpanded,
    isGroupActive
  )

  const handleClick = () => {
    if (!isMobile) return
    setActiveCollapse(collapseExpanded ? '' : name)
  }

  const handleMouseOver = () => {
    if (isMobile || isHoverExpanded) return
    setActiveMenu(name)
    track(`Menu / Desktop / mouse over - ${name}`)
  }

  return (
    <Stack
      data-testid={`asidemenu-${_.kebabCase(name)}`}
      sx={{ ...styles.container }}
      component="li"
      onMouseOver={handleMouseOver}
    >
      <Button
        disableTouchRipple
        sx={{ ...styles.button }}
        onClick={handleClick}
        data-test={`asidemenu-button-${_.kebabCase(name)}`}
      >
        <SVGIcon
          name={icon}
          color={theme.palette.grey[collapseExpanded && isMobile ? 550 : 200]}
        />

        <Typography component="span" sx={{ ...styles.title }}>
          {title}
        </Typography>
      </Button>
      {collapseExpanded && isMobile && <Divider sx={{ ...styles.divider }} />}
      <Collapse
        in={collapseExpanded || !isMobile}
        sx={{ ...styles.collapse.container }}
      >
        {!isMobile && (
          <Box sx={{ mt: 3, ml: 1 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
              {title}
            </Typography>
            <Divider sx={{ ...styles.divider }} />
          </Box>
        )}
        {groups &&
          groups.map((group, index) => (
            <List
              key={index}
              sx={{
                ...styles.collapse.list,
                borderBottom:
                  index + 1 !== groups.length ? 'solid 1px #d9d9d9' : 'none',
              }}
              subheader={
                group.title &&
                group.features.length > 0 && (
                  <ListSubheader sx={{ ...styles.collapse.subheader }}>
                    {group.title}
                  </ListSubheader>
                )
              }
            >
              {group.features.map(
                ({ label, href, name, feature, resource, id }, index) => (
                  <ListItem
                    key={index}
                    data-testid={`asidemenu-item-${_.kebabCase(name)}`}
                    selected={getActiveLink({
                      feature,
                      id,
                      resource,
                      pathname,
                      search,
                      href,
                    })}
                    sx={{ ...styles.collapse.item }}
                  >
                    <Link to={href}>{label}</Link>
                  </ListItem>
                )
              )}
            </List>
          ))}
      </Collapse>
    </Stack>
  )
}

export default FeaturesGroup
