import React from 'react'
import {
  Badge,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AssessmentLevelLabel } from '../AssessmentLevel/Label'

import { ElevationTooltip } from '~components/Common/Tooltips'
import { formatDecimalPercent } from '~helpers/formatting/numbers'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const format = formatDecimalPercent(0)

export const QuestionsTable = ({
  loading,
  difficultyFilter,
  questions,
  handleOpenQuestionDetailsModal,
}) => {
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('sm'))

  return (
    <TableContainer
      sx={{ borderRadius: 2, bgcolor: 'grey.0', px: 2.5, height: '525px' }}
    >
      <Table variant="striped" stickyHeader>
        <TableHead>
          <TableRow sx={{ bgcolor: 'grey.0' }}>
            <TableCell sx={{ minWidth: 180 }}>Questão</TableCell>
            {!isMobile && (
              <TableCell align="center" sx={{ minWidth: 100 }}>
                Habilidade
              </TableCell>
            )}
            <TableCell align="center">Taxa de acerto</TableCell>
            <TableCell align="center">Grau de dificuldade</TableCell>
            {!isMobile && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || !questions ? (
            <Placeholder />
          ) : (
            questions.map(({ id, skill, hitRate, difficulty }, index) => {
              if (difficulty === difficultyFilter || !difficultyFilter)
                return (
                  <TableRow
                    key={id}
                    onClick={() =>
                      isMobile ? handleOpenQuestionDetailsModal(id) : null
                    }
                    style={{ cursor: isMobile ? 'pointer' : 'inherit' }}
                  >
                    <TableCell>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Badge
                          variant="index"
                          badgeContent={index + 1}
                          color="secondary"
                        />
                        <Typography variant="body2">
                          Questão {index + 1}
                        </Typography>
                      </Stack>
                    </TableCell>
                    {!isMobile && (
                      <TableCell>
                        <Stack direction="row" justifyContent="center">
                          <ElevationTooltip
                            TransitionComponent={Zoom}
                            title={skill.description}
                            placement="bottom-start"
                          >
                            <Button
                              variant="skill"
                              maxWidth="100px"
                              width="100px"
                            >
                              {skill.name}
                            </Button>
                          </ElevationTooltip>
                        </Stack>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {format(hitRate)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack alignItems="center">
                        <AssessmentLevelLabel
                          typography="body2"
                          level={difficulty}
                        />
                      </Stack>
                    </TableCell>
                    {!isMobile && (
                      <TableCell>
                        <Tooltip title="Ver questão" placement="bottom" arrow>
                          <IconButton
                            color="secondary"
                            variant="outlined"
                            onClick={() => handleOpenQuestionDetailsModal(id)}
                          >
                            <SVGIcon size={3} name="eye" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                )
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Placeholder = () =>
  Array(10)
    .fill(null)
    .map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton variant="text" height={14} />
        </TableCell>
        <TableCell>
          <Skeleton
            variant="rectangular"
            height={30}
            sx={{ borderRadius: 2 }}
          />
        </TableCell>
        <TableCell align="center">
          <Stack alignItems="center">
            <Skeleton variant="text" height={14} width={30} />
          </Stack>
        </TableCell>
        <TableCell>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Skeleton variant="circular" height={12} width={12} />
            <Skeleton variant="text" width={60} height={14} />
          </Stack>
        </TableCell>
        <TableCell />
      </TableRow>
    ))
