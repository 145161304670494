import { createTypes } from 'reduxsauce'

export const types = createTypes(`
  API_GENERIC_ERROR
  API_INVALID_SESSION
`)

export const handleApiError = (error) => ({
  type: types.API_GENERIC_ERROR,
  payload: error,
})
