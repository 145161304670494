import React from 'react'
import { Stack, Box } from '@mui/material'

export const ProgressBar = ({ percent }) => {
  return (
    <Stack
      direction="row"
      sx={{ borderRadius: 2, height: 20, width: 88, overflow: 'hidden' }}
    >
      <Box sx={{ bgcolor: '#3BB4B7', height: 1, width: `${percent}%` }} />
      <Box
        sx={{ bgcolor: 'grey.300', height: 1, width: `${100 - percent}%` }}
      />
    </Stack>
  )
}
