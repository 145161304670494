import { getThemeValue } from './helpers'

export const focoEscolaTheme = (parentTheme) => ({
  ...parentTheme,

  borderRadius: '2px',

  text: '#334449',
  textPlaceholder: '#859092',
  textMedium: '#717171',
  textLight: '#2C2E3E',
  textDark: '#1d3a3b',
  textNegative: '#FFF',

  linkColor: '#0087ca',
  buttonLinkColor: '#00cef4',
  hoverColor: '#0087ca',

  primary: '#2C2E3E',
  primaryLight: '#618586',
  primaryMedium: '#2E6162',
  primaryDark: '#1D3A3B',
  primaryBlack: '#2E6162',
  secondary: '#C6D92D',

  backgroundPrimary: '#2C2E3E',
  backgroundPrimaryDarker: '#283A3E',
  backgroundSecondary: '#C6D92D',
  backgroundTertiary: '#00CEF4',
  backgroundLight: '#F3F3F3',
  backgroundMedium: '#EBEFF4',
  backgroundDark: '#D8DFE7',
  backgroundNeutral: '#979797',
  backgroundLightWhite: '#F1F1F1',
  backgroundGradient:
    'linear-gradient(335.75deg, #7AA4E2 0%, #547BC5 40%, #4268B7 100%)',

  backgroundMenuOpen: '#F4F4F4',
  backgroundMenu: '#F4F4F4',
  backgroundMenuActive: '#DDDDDD',
  backgroundMenuDark: '#0E2946',
  backgroundDanger: '#FEEDEF',
  menuHover: '#47596E',

  border: '#D8DFE7',
  borderLight: '#E5E5E5',
  borderDark: '#979797',

  warning: '#FFCD11',
  success: '#66CB97',
  danger: '#D62A2A',
  info: '#0B486B',
  neutral: '#C2C2C2',
  neutralLight: '#ABB7CA',
  disabled: '#D8DFE7',
  question: '#e8e39a',

  white: '#ffffff',
  black: '#000000',
  blue: '#2FA3DA',
  darkBlue: '#0B486B',
  green: '#C1C722',
  purple: '#9B4AD0',
  transparent: 'transparent',

  chartColors: {
    mixed: ['#709CBE', '#2E6162', '#61387A'],
    heat: ['#D62A2A', '#FF8A00', '#FFCD11', '#66CB97'],
    grading: ['#1E479C', '#4268B7', '#7EA9E0', '#B4D1F7'],
  },

  resultables: {
    classroom: '#1C4F8D',
    school: '#7D69CC',
    sector: '#54BCC0',
    district: '#EE7786',
    result: '#66CB97',
    target: '#B5C1D0',
    group: '#5E98EF',
  },

  breakpoints: {
    small: '320px',
    medium: '960px',
    mediumLarge: '980px',
    large: '1200px',
    wide: '1600px',

    number: {
      small: 320,
      medium: 800,
      mediumLarge: 1000,
      large: 1200,
      wide: 1600,
    },
  },

  get: function (colorName) {
    return getThemeValue(this, colorName)
  },
})
