import React from 'react'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import Header from '~components/Common/Header'
import { useFocoExamEdition } from '~data/api/focoExamEdition/findOne'
import filters from '~resources/filters'
import { FiltersProvider } from '~contexts/Filters'
import { Content } from '~components/ManageExams/Results/Content'

const ManageExamsResult = () => {
  const { focoExamEditionId, entityType, entityId } = useParams()
  const { data } = useFocoExamEdition({
    id: focoExamEditionId ?? '',
    entityId: entityId ?? '',
    entityType: entityType ?? '',
    requestTemplate: 'exam-results',
  })

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ px: { xs: 2, sm: 4, md: 8 } }}
    >
      <Header
        title={`Resultados - ${data?.name ?? 'Carregando...'}`}
        breadcrumbs={[
          {
            text: 'Avaliações',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Gerenciar avaliações',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Resultados',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }/avaliacao/${focoExamEditionId ?? ''}/resultados`,
          },
        ]}
      />

      <FiltersProvider
        resource={filters.getManageExamsResultsFilters}
        config={{
          foco_exam_id: {
            filter: 'foco_exams',
            label: 'Nome do caderno',
          },
          classroom_id: {
            filter: 'classrooms',
            label: 'Turma',
          },
        }}
        additionalParams={{
          entityId,
          entityType,
          examId: focoExamEditionId,
        }}
      >
        <Content editionId={Number(focoExamEditionId)} />
      </FiltersProvider>
    </Container>
  )
}

export default ManageExamsResult
