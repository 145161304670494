import React from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  IconButton,
  Badge,
  List,
  Typography,
  ListItemText,
  ListItem,
  Divider,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ShareButton from '~components/Common/ShareButton'
import { getSelectedProfile } from '~selectors/profile'
import MenuPopover from '~components/MenuPopover'
import useNotifications from '~hooks/useNotifications'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

import { getPath } from '~helpers/paths'
import { track } from '~resources/ahoy'

export default function NotificationsPopover() {
  const {
    notifications,
    updateAllNotificationsStatus,
    popoverIsOpen,
    setPopoverIsOpen,
  } = useNotifications()
  const theme = useTheme()

  if (!notifications) return null

  const totalUnRead = notifications.filter(
    (notification) => !notification.read
  ).length

  const handleOpen = (event) => {
    setPopoverIsOpen(event.currentTarget)
    updateAllNotificationsStatus()
    track('Notifications / Popover open')
  }

  const handleClose = () => {
    setPopoverIsOpen(null)
  }

  return (
    <>
      <IconButton
        disableRipple
        onClick={handleOpen}
        data-testid="notification-popover-button"
      >
        {totalUnRead === 0 ? (
          <SVGIcon name="iconNotification" color={theme.palette.grey[800]} />
        ) : (
          <Badge
            color="secondary"
            badgeContent=" "
            variant="dot"
            sx={{ '.BaseBadge-badge': { top: 3, right: 3 } }}
          >
            <SVGIcon name="iconNotification" color={theme.palette.grey[800]} />
          </Badge>
        )}
        <Badge
          badgeContent={totalUnRead}
          color="error"
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(popoverIsOpen)}
        anchorEl={popoverIsOpen}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 3, ml: '-5px' }}
        arrow="top-left"
      >
        <IconButton
          onClick={handleClose}
          variant="grey"
          sx={{
            position: 'absolute',
            zIndex: 99,
            top: '10px',
            right: '10px',
          }}
        >
          <SVGIcon name="close" color={theme.palette.text.primary} size={3} />
        </IconButton>
        <List
          data-testid="notifications-list"
          disablePadding
          sx={{
            px: 2,
          }}
        >
          {notifications.length ? (
            notifications.map((notification, index) => (
              <React.Fragment key={index}>
                {index > totalUnRead && <Divider />}
                <NotificationItem notification={notification} />
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body2" sx={{ p: 2 }}>
              Você não possui novas notificações
            </Typography>
          )}
        </List>
      </MenuPopover>
    </>
  )
}

const useLinkHref = ({ url, exam_info }) => {
  const profile = useSelector(getSelectedProfile)
  if (url) return url

  if (exam_info) {
    return getPath({
      feature: exam_info.feature,
      resource: exam_info.resource,
      examType: exam_info.exam_type,
    })(exam_info.exam_result_id, profile)
  }

  return ''
}

function NotificationItem({ notification }) {
  const notificationDate = dayjs().to(dayjs(notification.created_at))
  const { setPopoverIsOpen } = useNotifications()

  const linkHref = useLinkHref(notification)

  const ListLink = ({ children, url, target }) =>
    url ? (
      <a
        data-testid={`notification-link-${notification.id}`}
        href={linkHref}
        target={target}
        onClick={() => {
          track('Notifications / Popover click notification')
          setPopoverIsOpen(null)
        }}
      >
        {children}
      </a>
    ) : (
      <Link
        data-testid={`notification-link-${notification.id}`}
        to={linkHref}
        onClick={() => {
          track('Notifications / Popover click notification')
          setPopoverIsOpen(null)
        }}
      >
        {children}
      </Link>
    )

  return (
    <ListItem
      sx={{ pt: 3, pb: 1.2, px: 0 }}
      data-testid={`notification-${notification.id}`}
    >
      <ListItemText
        primary={
          <ListLink
            url={notification?.url}
            target={notification.new_tab ? '_blank' : '_self'}
          >
            <Typography
              variant="subtitle2"
              sx={{
                pr: 5,
                color: 'text.primary',
                '&:hover': {
                  color: '#F05E09',
                },
              }}
            >
              <div
                data-testid={`notification-popover-message-${notification.id}`}
                dangerouslySetInnerHTML={{ __html: notification.message }}
              />
            </Typography>
          </ListLink>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              pt: 1.7,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: 'text.disabled',
            }}
          >
            {notificationDate}
            <ShareButton
              content="notification"
              notification={notification}
              internalLink={linkHref}
              UTM={{
                source: 'Whatsapp',
                medium: 'Social',
                campaign: `notification_${notification.id}`,
              }}
            />
          </Typography>
        }
      />
    </ListItem>
  )
}
