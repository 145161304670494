import React from 'react'
import { FormControl, InputLabel, Select } from '@mui/material'

export const SelectForm = ({
  id,
  label,
  value,
  onChange,
  disabled,
  children,
  ...props
}) => (
  <FormControl fullWidth>
    <InputLabel id={`${id}-label`} required>
      {label}
    </InputLabel>
    <Select
      id={`select-${id}`}
      name={id}
      label={`${id}-label`}
      value={value}
      variant="standard"
      size="small"
      disabled={disabled}
      onChange={onChange}
      {...props}
    >
      {children}
    </Select>
  </FormControl>
)
