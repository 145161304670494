import { config } from '../../config'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import { getFieldsAndIncludes } from '~data/helpers'

const { endpoint, key } = config

export const useJourney = (params = {}) => {
  const { loading, error, data } = useResource({
    key,
    resource: getJourney,
    params,
  })

  return { loading, error, data }
}

const extensionsConfig = {
  default: {
    include: [],
    fields: ['id', 'name', 'hour_initial', 'hour_end', 'active'],
  },
}

const getJourney = ({ entityType, entityId, journeyId, extensions = [] }) => {
  return fetchWithRoot('get')(
    `${endpoint(entityType, entityId)}/${journeyId || 0}`,
    {
      params: { ...getFieldsAndIncludes(extensionsConfig, extensions) },
      bypassLoader: true,
    }
  )
}
