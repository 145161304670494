import { Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const CardGeneralView = ({
  icon = false,
  heading = '',
  content,
  suffix,
  backgroundColor,
  loading,
}) => {
  const { palette, shape } = useTheme()
  return loading ? (
    <Skeleton
      animation="wave"
      sx={{
        height: 1,
        minHeight: { xs: 1, md: 105 },
        transform: 'none',
      }}
    />
  ) : (
    <Stack
      component="article"
      gap={2.5}
      alignItems="center"
      justifyContent="space-between"
      bgcolor={backgroundColor}
      sx={{
        px: 4,
        py: 1,
        color: palette.text.white,
        borderRadius: shape.borderRadius,
        height: 1,
        textAlign: 'center',
      }}
    >
      <Typography variant="body2">{heading}</Typography>
      <Typography variant="h2">
        {content}
        {suffix && <React.Fragment>{suffix}</React.Fragment>}
        {icon && <SVGIcon name="medal" color={palette.text.white} size={7} />}
      </Typography>
    </Stack>
  )
}

export default CardGeneralView
