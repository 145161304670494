import React from 'react'
import Notification from './Notification'
import useNotifications from '~hooks/useNotifications'

export default function StoreNotifications() {
  const { notifications, updateNotificationStatus } = useNotifications()

  if (!notifications) return null

  return (
    <>
      {notifications
        .filter((notification) => !notification.read)
        .map((notification, index) => (
          <Notification
            index={index}
            notification={notification}
            key={notification.id}
            handleUpdate={updateNotificationStatus}
          />
        ))}
    </>
  )
}
