import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (examId) => `/api/v3/foco_exam_editions/${examId}`

export const deleteExam = async (examId) =>
  fetchWithRoot('delete')(getEndpoint(String(examId))).then(([error]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
  })
