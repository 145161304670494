import React from 'react'
import { Skeleton, Stack } from '@mui/material'

export const TableLoadingPlaceholder = () =>
  Array(5)
    .fill(null)
    .map((_, index) => ({
      fields: [
        <Skeleton key="week" variant="text" height={14} width="33%" />,
        <Stack key="skill" direction="row" justifyContent="center">
          <Skeleton
            variant="rounded"
            height={20}
            sx={{ minWidth: 175, borderRadius: 30 }}
          />
        </Stack>,
        <Stack
          key="minimum-curriculum-unit"
          direction="row"
          justifyContent="flex-end"
        >
          <Skeleton
            variant="rounded"
            height={35}
            sx={{ minWidth: 160, borderRadius: 4 }}
          />
        </Stack>,
      ],
      align: 'justify',
      key: `loading-${index}`,
    }))
