import { Alert, Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { TextPlaceholder } from './Placeholders/Text'
import { OptionsPlaceholder } from './Placeholders/Options'
import Modal from '~primitives/Modal'
import { Scribble } from '~primitives/Typography/Underline'
import { pxToRem } from '~helpers/getFontValue'
import { useFocoQuestion } from '~data/manageBooklets/ListItemQuestion'
import ExamIcon from '~assets/svg/icon_exam_name.svg'

const IMG_STYLES = {
  width: '100%',
  height: 'auto',
}

const AVATAR_STYLES = {
  bgcolor: 'primary.main',
  width: 28,
  height: 28,
  fontSize: 14,
  fontWeight: 700,
}

export const QuestionDetailsModal = ({ isOpen, setIsOpen, questionId }) => {
  const { data, error, loading } = useFocoQuestion({ questionId })

  const correctOption = data?.foco_question_options?.find(
    ({ correct }) => correct
  )

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      width="800px"
      height="98vh"
      isOpen={isOpen}
      overflowContent={true}
      contentPadding="20px"
      showFooter={false}
      onClose={handleClose}
    >
      <Typography sx={{ fontSize: pxToRem(24), fontWeight: 700, my: 2, px: 2 }}>
        <Scribble>Detalhe da questão</Scribble>
      </Typography>

      {error ? (
        <Box sx={{ p: 2 }}>
          <Alert variant="filled" severity="error">
            Ocorreu um erro ao carregar os dados da questão.
          </Alert>
        </Box>
      ) : (
        <Stack variant="modalBox">
          <Box sx={{ width: 1 }}>
            <Stack direction="row" spacing={2}>
              <img src={ExamIcon} alt="Ícone de exames" />
              {loading && <TextPlaceholder />}
              {!loading && (
                <Typography variant="body2">
                  Habilidade: <strong>{data?.curriculum_skill.name} </strong>
                  {data?.curriculum_skill.description}
                </Typography>
              )}
            </Stack>

            <Divider sx={{ my: 2 }} />

            <Stack spacing={2}>
              {loading && <TextPlaceholder />}
              {!loading && (
                <React.Fragment>
                  {data?.statement && (
                    <Typography variant="body2">{data?.statement}</Typography>
                  )}
                  {data?.support_image_path && (
                    <img
                      src={`${ENV_VARS.FOCO_API_HOST}/${
                        data.support_image_path
                      }`}
                      alt="Imagem representativa da questão"
                      style={IMG_STYLES}
                    />
                  )}
                  {data?.reference && (
                    <Typography variant="body2">{data.reference}</Typography>
                  )}
                  {data?.command && (
                    <Typography variant="body2">{data.command}</Typography>
                  )}
                </React.Fragment>
              )}

              {!loading &&
                data?.foco_question_options?.map(
                  ({ id, alternative, text }) => {
                    return (
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        key={id}
                      >
                        <Avatar
                          sx={{
                            ...AVATAR_STYLES,
                          }}
                        >
                          {alternative}
                        </Avatar>
                        <Typography variant="body2">{text}</Typography>
                      </Stack>
                    )
                  }
                )}
              {loading && <OptionsPlaceholder />}
            </Stack>

            <Divider sx={{ my: 2 }} />

            {correctOption && (
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                  Resposta correta
                </Typography>
                <Avatar
                  sx={{
                    ...AVATAR_STYLES,
                  }}
                >
                  {correctOption.alternative}
                </Avatar>
              </Stack>
            )}
          </Box>
        </Stack>
      )}
    </Modal>
  )
}
