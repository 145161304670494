import { Formik } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import React, { Component } from 'react'

import { Typography, Link, Button, Stack } from '@mui/material'
import { Form } from '~primitives/Input/Form'
import { TextInput } from '~primitives/Input/Text'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { NotificationBox } from '~primitives/Messages/NotificationBox'
import { track, trackView } from '~resources/ahoy'
import Register from '~resources/register'

const validate = (values) =>
  values['birth-year'].length === 4
    ? {}
    : { 'birth-year': 'Data de nascimento inválida' }

class FormBirthdate extends Component {
  state = { error: null }

  componentDidMount() {
    trackView('Signup / Personal', { url: undefined, page: undefined })
  }

  handleSubmit = ({ 'birth-year': birthYear }, { setErrors }) => {
    const { cpf, onSuccess } = this.props

    Register.validateUserData({ cpf, birthYear }).then(([error, data]) => {
      if (error) {
        track('Signup / Personal / Error', {
          birth_year: birthYear,
          error_code: error.status,
          error_message: error.message,
        })

        this.setState({ error })
        setErrors({
          'birth-year': 'Ano informado incompatível com o cadastrado.',
        })
      }

      if (data) {
        onSuccess(birthYear)
      }
    })
  }

  render() {
    const { error } = this.state
    const { returnPath, name } = this.props

    return (
      <Formik
        initialValues={{ birthdate: '' }}
        validate={validate}
        onSubmit={this.handleSubmit}
        validateOnChange
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <header>
              <Typography component="h2" variant="h2">
                Bem-vindo
              </Typography>
              <Typography component="span" variant="body1">
                Verifique o seu perfil e informe seu{' '}
                <strong>ano de nascimento</strong>
              </Typography>
            </header>

            <fieldset>
              <ul>
                <li>
                  {name && (
                    <Typography align="left" mb={2.5}>
                      {name}
                    </Typography>
                  )}
                  <TextInput
                    name="birth-year"
                    id="birth-year"
                    placeholder="Digite o seu ano de nascimento"
                    label="Ano de nascimento"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values['birth-year']}
                    error={errors['birth-year']}
                    helperText={errors['birth-year']}
                    mask="0000"
                  />
                </li>

                {error && (
                  <li>
                    <NotificationBox
                      danger
                      showSupportButton
                      title="Houve um erro ao validar os seus dados"
                      text={error.message}
                    />
                  </li>
                )}
              </ul>

              <footer className="navigation-footer">
                <Stack
                  direction={{
                    sm: 'row-reverse',
                    xs: 'column',
                  }}
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ flex: 1, mt: 2 }}
                >
                  <Button
                    type="submit"
                    id="submit-form"
                    data-testid="submit-form"
                    variant="primary"
                    sx={{ width: { xs: 1, sm: 220 } }}
                    disabled={!isValid}
                  >
                    Avançar
                  </Button>
                  <Link component={RouterLink} to={returnPath}>
                    <SVGIcon name="previous" color="neutral" /> Voltar
                  </Link>
                </Stack>
              </footer>
            </fieldset>
          </Form>
        )}
      </Formik>
    )
  }
}

export default FormBirthdate
