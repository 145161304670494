import React from 'react'
import { Alert, Container } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useParams } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Header from '~components/Common/Header'
import { useProfileClassrooms } from '~data/manageExams/examCreate'
import { ExamEditionForm } from '~components/ManageExams/Forms/ExamEdition'

export const ExamEditionCreateForm = () => {
  const { entityType, entityId } = useParams()
  const { data, loading, error } = useProfileClassrooms()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
        <Header
          title="Criar avaliação"
          breadcrumbs={[
            {
              text: 'Avaliações',
              pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
                entityId ?? ''
              }`,
            },
            {
              text: 'Gerenciar avaliações',
              pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
                entityId ?? ''
              }`,
            },
            {
              text: 'Criar avaliação',
              pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
                entityId ?? ''
              }/criar`,
            },
          ]}
        />
        {loading && <span>Carregando...</span>}

        {error && (
          <Alert variant="filled" severity="error">
            Ocorreu um erro ao carregar os dados da avaliação.
          </Alert>
        )}
        {data && !loading && (
          <ExamEditionForm grades={data?.grades} method="post" />
        )}
      </Container>
    </LocalizationProvider>
  )
}

export default ExamEditionCreateForm
