import { connect } from 'react-redux'
import React from 'react'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { Stack, Button, Typography } from '@mui/material'
import { Form } from '~primitives/Input/Form'
import { NotificationBox } from '~primitives/Messages/NotificationBox'
import { RadioBlock } from '~primitives/Input/RadioBlock'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { trackView } from '~resources/ahoy'
import userActions from '~actions/User'

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = userActions

const ProfileOptions = styled('li')`
  max-height: 275px;
  overflow-y: auto;
  margin-top: 16px;
`

export class ProfileSelection extends React.Component {
  state = { error: null, selected: 0 }

  componentDidMount() {
    this.props.fetchProfiles()
    trackView('Login / Profile Selection')
  }

  handleClickItem = (selected) => {
    this.setState({ selected })
  }

  handleClickLogout = (e) => {
    e.preventDefault()
    this.props.removeToken()
    this.props.history.push('/login')
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { selectProfile, fetchFeatures, user } = this.props
    const { selected } = this.state
    const selectedProfile = selected || user.profiles[0].id

    selectProfile(selectedProfile)
    fetchFeatures()
  }

  render() {
    const { user } = this.props
    const { error, selected } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <header>
          <h2>
            Olá, <strong>{user.name}</strong>
          </h2>

          <p>
            Você tem mais de um perfil cadastrado. Qual você gostaria de
            acessar?
          </p>
        </header>

        <fieldset>
          <ul>
            <ProfileOptions id="profile-options" data-testid="profile-options">
              {user.profiles.map((profile, i) => {
                const isSelected =
                  selected !== 0 ? profile.id === selected : i === 0

                return (
                  <RadioBlock
                    key={profile.id}
                    id={profile.id}
                    selected={isSelected}
                    onClick={this.handleClickItem}
                  >
                    {profile.personable?.name}{' '}
                    {profile.role && `(${profile.role})`}
                  </RadioBlock>
                )
              })}
            </ProfileOptions>
          </ul>

          <footer className="navigation-footer">
            <Stack
              direction={{
                sm: 'row-reverse',
                xs: 'column',
              }}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ flex: 1 }}
            >
              <Button
                variant="primary"
                type="submit"
                id="submit-form"
                data-testid="submit-form"
                disabled={user.profiles.length === 0}
              >
                Avançar
              </Button>

              <Link to="#" onClick={this.handleClickLogout}>
                <SVGIcon name="previous" color="neutral" size={1} />
                <Typography variant="body2" component="span" color="#008190">
                  Voltar
                </Typography>
              </Link>
            </Stack>
          </footer>
        </fieldset>

        {error && (
          <NotificationBox
            danger
            title="Houve um erro ao buscar seus dados"
            text={error.message}
          />
        )}
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSelection)
