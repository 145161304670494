import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { Summary } from '../Summary'
import { QuestionAnalysis } from './QuestionAnalysis'
import { StudentList } from './StudentsList'
import { PerformanceByProficiency } from './PerformanceByProficiency'
import { PerformanceBySkill } from './PerformanceBySkill'
import useFilters from '~hooks/useFilters'
import { useManageExamsStudentList } from '~data/manageExams/examStudentList'
import FilterBar from '~components/Common/FilterBar'

export const Content = () => {
  const { filters } = useFilters()

  const editionId = Number(useParams().focoExamEditionId)
  const schoolId = Number(useParams().entityId)

  const selectedExam = filters?.foco_exam_id.selected
  const selectedClassroom = filters?.classroom_id.selected

  const { data, loading, error } = useManageExamsStudentList({
    editionId,
    examId: selectedExam?.id,
  })

  return (
    <>
      <Box bgcolor="grey.200" mb={0.5}>
        <FilterBar margin="0" contained wrapperProps={{ maxWidth: '160px' }} />
        <Box
          sx={{
            px: 3.5,
            pb: 2,
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Typography variant="caption">
            *Existe(m) caderno(s) e/ou turma(s) associado(s) a essa avaliação.
            Selecione os campos acima para visualizar os demais resultados.
          </Typography>
        </Box>
      </Box>

      <Summary
        editionId={editionId}
        exam={selectedExam}
        classroom={selectedClassroom}
      />

      <QuestionAnalysis
        examId={selectedExam?.id}
        classroomId={selectedClassroom?.id}
        schoolId={schoolId}
      />

      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <PerformanceByProficiency
            examId={selectedExam?.id}
            classroomId={selectedClassroom?.id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PerformanceBySkill
            examId={selectedExam?.id}
            classroomId={selectedClassroom?.id}
            schoolId={schoolId}
          />
        </Grid>
      </Grid>

      <StudentList
        list={data}
        error={error}
        loading={loading}
        focoExamName={filters?.foco_exam_id?.selected?.name ?? ''}
      />
    </>
  )
}
