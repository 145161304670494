import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (entityType, entityId, curriculumId) =>
  `/api/v3/${entityType}s/${entityId}/base_curriculums/${curriculumId}`

export const deleteCurriculum = async ({
  entityType,
  entityId,
  curriculumId,
}) => {
  if (!curriculumId) return false

  return fetchWithRoot('delete')(
    getEndpoint(entityType, entityId, curriculumId)
  ).then(([error]) => {
    if (error) throw new Error(error?.message ?? error?.status)
  })
}
