import React, { useState } from 'react'
import { Container } from '@mui/material'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { SchoolForm } from '~components/AcademicYear/Form/SchoolForm'
import Header from '~components/Common/Header'
import { useFeedback } from '~contexts/Feedback'
import { useAcademicYear } from '~data/api/v3/academicYears/query/findUnique'
import { useUpdateAcademicYear } from '~data/api/v3/academicYears/mutation/update'

export const UpdateSchoolAcademicYearPage = () => {
  const [success, setSuccess] = useState(false)
  const { showMessage } = useFeedback()

  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const entityId = Number(params.entityId)
  const academicYearId = Number(params.academicYearId)

  const { error, loading, data } = useAcademicYear({
    entityType: 'school',
    entityId,
    academicYearId,
    extensions: [
      'schoolDetails',
      'curriculumPeriods',
      'curriculumPeriodDays',
      'parent',
    ],
  })

  const initialValues = data && {
    parentAcademicYear: data.parent_academic_year.id,
    year: data.year,
    expectedTotalDays: data.expected_total_days,
    initialDate: dayjs(data.initial_date),
    endDate: dayjs(data.end_date),
    entityType: 'school',
    entityId: entityId,
    curriculumPeriods: data.curriculum_periods.map(
      ({ id, name, start_date, end_date, total_days }) => ({
        id,
        name,
        totalDays: total_days,
        startDate: dayjs(start_date),
        endDate: dayjs(end_date),
      })
    ),
  }

  const { mutate: updateAcademicYear } = useUpdateAcademicYear({
    onSuccess: () => setSuccess(true),
    onError: (error) => {
      showMessage({ type: 'error', text: `Erro: ${error.message}` })
    },
  })

  const handleCloseModal = () => {
    setSuccess(false)
    history.push(`/gerenciar-calendario/school/${entityId}/anos-letivos/`)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Header
          title="Editar ano letivo"
          breadcrumbs={[
            {
              text: 'Cadastrar',
              pathname: `/gerenciar-calendario/school/${entityId}/anos-letivos`,
            },
            {
              text: 'Ano letivo',
              pathname: `/gerenciar-calendario/school/${entityId}/anos-letivos`,
            },
            {
              text: 'Editar',
              pathname: location.pathname,
            },
          ]}
        />

        <SchoolForm
          schoolId={entityId}
          initialValues={initialValues}
          onSubmit={(values) =>
            updateAcademicYear({ id: academicYearId, ...values })
          }
          success={success}
          onCloseModal={handleCloseModal}
          loading={loading}
          error={error}
          type="update"
          parentAcademicYear={data?.parent_academic_year}
        />
      </Container>
    </LocalizationProvider>
  )
}

export default UpdateSchoolAcademicYearPage
