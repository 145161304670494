import { createReducer } from 'reduxsauce'

const initialState = {
  title: '',
  subtitle: '',
  hideTitle: false,
}

const setHeader = (state, { payload }) => ({
  ...state,
  hideTitle: false,
  subtitle: '',
  ...payload,
})

const handlers = {
  SET_HEADER: setHeader,
}

export default createReducer(initialState, handlers)
