import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { IconButton } from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const SortableHandler = ({
  id,
  color,
  component = 'button',
  sx = {},
  ...rest
}) => {
  const { attributes, listeners } = useSortable({
    id,
  })

  return (
    <IconButton
      disableRipple
      data-testid={`sortable-drag-handle-${id}`}
      {...attributes}
      {...listeners}
      component={component}
      sx={{ cursor: 'grab', ...sx }}
      {...rest}
    >
      <SVGIcon name="dragDotHandle" color={color ?? 'primary'} size={3} />
    </IconButton>
  )
}
