import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

const getEndpoint = (schoolId) =>
  `api/v3/schools/${schoolId}/base_curriculums/district_curriculums`

export const useBaseCurriculumsByDistrict = ({
  schoolId,
  gradeId,
  curriculumPeriodId,
}) => {
  const { data, error, loading } = useResource({
    key: 'useBaseCurriculumsByDistrict',
    params: { schoolId, gradeId, curriculumPeriodId },
    enabled: !!gradeId && !!curriculumPeriodId,
    resource: getBaseCurriculumsByDistrict,
  })

  return { data, error, loading }
}

const getBaseCurriculumsByDistrict = ({
  schoolId,
  gradeId,
  curriculumPeriodId,
}) =>
  fetchWithRoot('get')(getEndpoint(String(schoolId)), {
    params: {
      curriculum_period_id: curriculumPeriodId,
      grade_id: gradeId,
      include: [{ base_curriculums: ['subject'] }],
      fields: ['id', { subject: ['id', 'name'] }],
    },
  })
