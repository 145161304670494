import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Skeleton, Stack, Typography, Tooltip } from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import SortTable from '~primitives/molecules/Table/SortTable'

const CELLS_HEADER = [
  {
    label: 'Ano letivo',
  },
  {
    label: 'Série',
    property: 'grade_id',
  },
  {
    label: 'Disciplina',
    property: 'subject_name',
    align: 'center',
  },
  {
    label: 'Período',
    property: 'curriculum_period_id',
    align: 'center',
  },
  {
    label: 'Aulas na semana',
    align: 'center',
  },
  {
    align: 'center',
  },
]

const BaseCurriculumsTable = ({
  baseCurriculums,
  isLoading,
  currentPage,
  totalPages,
  entityType,
  entityId,
  setCurriculumData,
  handleOpenModalDelete,
}) => {
  const placeholder = getPlaceholder()
  const rows = normalizeTableRow({
    baseCurriculums,
    entityType,
    entityId,
    setCurriculumData,
    handleOpenModalDelete,
  })

  return (
    <SortTable
      variant="simple"
      rows={isLoading || !baseCurriculums ? placeholder : rows}
      rowsAlign={['left', 'left', 'left', 'left', 'left', 'center']}
      cellsHeader={CELLS_HEADER}
      totalPages={totalPages}
      currentPage={currentPage}
      emptyContentText="Não há currículos cadastrados"
      lengthText="Total de currículos"
    />
  )
}

export default BaseCurriculumsTable

const normalizeTableRow = ({
  baseCurriculums,
  entityId,
  entityType,
  setCurriculumData,
  handleOpenModalDelete,
}) => {
  if (!baseCurriculums) return []
  return baseCurriculums.map(
    ({
      id,
      grade,
      subject,
      events_per_week,
      events_count,
      child_base_curriculums_count,
      curriculum_period,
    }) => {
      const hasEvents = events_count > 0
      const hasChildBaseCurriculumsCount = child_base_curriculums_count > 0

      return {
        fields: [
          <React.Fragment key="academic_year">
            <Typography component="span" variant="body2">
              {curriculum_period.academic_year.year}
            </Typography>
          </React.Fragment>,
          <React.Fragment key="week">
            <Typography component="span" variant="body2">
              {grade.name}
            </Typography>
          </React.Fragment>,
          <React.Fragment key="skill">
            <Typography
              component="span"
              variant="body2"
              align="center"
              sx={{ display: 'block', width: '100%' }}
            >
              {subject.name}
            </Typography>
          </React.Fragment>,
          <React.Fragment key="minimum_curriculum_unit">
            <Typography
              component="span"
              variant="body2"
              align="center"
              sx={{ display: 'block', width: '100%' }}
            >
              {curriculum_period.name}
            </Typography>
          </React.Fragment>,
          <React.Fragment key="activities">
            <Typography
              component="span"
              variant="body2"
              align="center"
              sx={{ display: 'block', width: '100%' }}
            >
              {events_per_week}
            </Typography>
          </React.Fragment>,
          <React.Fragment key="activities">
            <Stack
              direction="row"
              justifyContent="right"
              alignItems="center"
              spacing={1}
              sx={{
                maxWidth: '140px',
                float: 'right',
              }}
            >
              <Tooltip title="Ver plano de aulas" placement="bottom">
                <IconButton
                  to={`/curriculo/${entityType}/${entityId}/plano-de-aulas/${id}`}
                  variant="outlined"
                  color="secondary"
                  data-testid={`button-action-view-${id}`}
                  component={Link}
                >
                  <SVGIcon name="eye" size={3} />
                </IconButton>
              </Tooltip>

              {entityType === 'district' && (
                <Tooltip title="Editar currículo" placement="bottom">
                  <IconButton
                    onClick={() => {
                      setCurriculumData({
                        curriculumId: id,
                        grade_id: grade.id,
                        subject_id: subject.id,
                        curriculum_period_id: curriculum_period.id,
                        events_per_week,
                        academic_year_id: curriculum_period.academic_year.id,
                      })
                    }}
                    disabled={hasEvents || hasChildBaseCurriculumsCount}
                    variant="outlined"
                    color="secondary"
                    data-testid={`button-action-edit-${id}`}
                  >
                    <SVGIcon name="edit" size={3} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Excluir currículo" placement="bottom">
                <IconButton
                  onClick={() => {
                    handleOpenModalDelete(Number(id))
                  }}
                  disabled={hasEvents || hasChildBaseCurriculumsCount}
                  variant="outlined"
                  color="secondary"
                  data-testid={`button-action-cancel-${id}`}
                >
                  <SVGIcon name="delete" size={3} />
                </IconButton>
              </Tooltip>
            </Stack>
          </React.Fragment>,
        ],
        disabled: false,
        key: id,
      }
    }
  )
}

const getPlaceholder = () =>
  [...Array(6).keys()].map((_, index) => ({
    fields: [
      <Skeleton key={`subject-${index}`} variant="text" />,
      <Skeleton key={`grade-${index}`} variant="text" />,
      <Skeleton key={`curriculum-period-${index}`} variant="text" />,
      <Skeleton key={`events-per-week-${index}`} variant="text" />,
      <Stack key={'d'} direction="row" gap={1} justifyContent="flex-end">
        <Skeleton variant="circular" width={34} height={34} />
        <Skeleton variant="circular" width={34} height={34} />
        <Skeleton variant="circular" width={34} height={34} />
      </Stack>,
    ],
    key: index,
  }))
