import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import useFilters from '~hooks/useFilters'

export const endpoint = (entityType, entityId) =>
  `api/v3/${entityType}s/${entityId}/base_curriculums`

export const useBaseCurriculums = ({ entityType, entityId }) => {
  const { params } = useFilters()

  const { loading, error, data } = useResource({
    resource: getBaseCurriculums,
    params: { ...params, entityType, entityId },
    enabled: !!params,
    key: 'useManagementBaseCurriculums',
  })

  return {
    loading,
    error,
    data,
  }
}

const getBaseCurriculums = ({
  entityType,
  entityId,
  order = 'desc',
  orderBy = 'created_at',
  page,
  ...params
}) => {
  const grade_id_eq =
    Number(params.grade_id) !== 0 ? params.grade_id : undefined
  const subject_id_eq =
    Number(params.subject_id) !== 0 ? params.subject_id : undefined
  const curriculum_period_id_eq =
    Number(params.curriculum_period_id) !== 0
      ? params.curriculum_period_id
      : undefined

  return fetchWithRoot('get')(endpoint(entityType, entityId), {
    params: {
      entity_type: entityType,
      per_page: 10,
      page: page || 1,
      fields: [
        {
          base_curriculums: [
            'id',
            'events_per_week',
            'events_count',
            'child_base_curriculums_count',
            {
              curriculum_period: [
                'id',
                'name',
                { academic_year: ['id', 'year'] },
              ],
              subject: ['id', 'name'],
              grade: ['id', 'name'],
            },
          ],
        },
        'meta',
      ],
      include: [
        {
          base_curriculums: [
            {
              curriculum_period: [
                'id',
                'name',
                { academic_year: ['id', 'year'] },
              ],
            },
            'subject',
            'grade',
          ],
        },
      ],
      q: {
        grade_id_eq,
        subject_id_eq,
        curriculum_period_id_eq,
        s: `${orderBy} ${order}`,
      },
    },
    bypassLoader: true,
  })
}
