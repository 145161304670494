import { fetchWithRoot } from '~helpers/fetch'
import useFilters from '~hooks/useFilters'
import useResource from '~hooks/useResource'

export const endpoint = (id) => `/api/v3/curriculum_events/${id}`

export const useCurriculumEvent = (id) => {
  const { params } = useFilters()

  const { loading, error, data } = useResource({
    resource: getEvent,
    params: { ...params, id },
    key: 'useCurriculumEvent',
  })

  return { loading, error, data }
}

const getEvent = ({ entityId, id }) => {
  return fetchWithRoot('get')(endpoint(id), {
    params: {
      entity_id: entityId,
      entity_type: 'school',
      fields: [
        'id',
        'date',
        'week',
        'activity_description',
        'start_time',
        'end_time',
        'notes',
        'performed',
        'status',
        'objective',
        'performed_to_plan',
        'unperformed_justification',
        'unplanned_justification',
        'content_description',
        'activity_description',
        'entity_id',
        {
          curriculum_events_student_profiles: [
            'presence',
            'id',
            { student_profile: ['id', { user: ['name', 'id', 'active'] }] },
          ],
        },
        {
          base_event: [
            'id',
            {
              curriculum_skill: ['id', 'name', 'code', 'description'],
            },
            { curriculum_competency: ['id', 'name', 'description'] },
          ],
        },
        { classroom: ['id', 'name'] },
        { grade: ['id', 'name'] },
        { subject: ['id', 'name'] },
        { curriculum_period: ['id', 'name'] },
        { curriculum_activities: ['id', 'name'] },
        {
          curriculum_events_curriculum_skills: [
            'id',
            'status',
            {
              curriculum_skill: [
                'id',
                'name',
                'description',
                'code',
                { curriculum_competency: ['id', 'name'] },
              ],
            },
          ],
        },
      ],
      include: [
        'classroom',
        'curriculum_period',
        'curriculum_events_curriculum_materials',
        'grade',
        'subject',
        'curriculum_activities',
        {
          curriculum_events_student_profiles: [{ student_profile: ['user'] }],
        },

        {
          base_event: [
            'curriculum_skill',
            'curriculum_competency',
            'curriculum_skill',
          ],
        },
        {
          curriculum_events_curriculum_skills: [
            { curriculum_skill: ['curriculum_competency'] },
          ],
        },
      ],
    },
    bypassLoader: true,
  })
}
