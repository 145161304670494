import React from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import {
  Badge,
  Box,
  IconButton,
  Stack,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const CELLS_HEADER = {
  school: [
    'Ano letivo',
    'Data inicial',
    'Data final',
    'Dias letivos indicados ',
    'Dias letivos registrados',
    'Status',
    '',
  ],
  district: [
    'Ano letivo',
    'Data inicial',
    'Data final',
    'Dias letivos',
    'Total de escolas',
    'Status',
    '',
  ],
}

export const Table = ({ entityType, entityId, data, onClickDelete }) => {
  return (
    <Box
      mt={1}
      bgcolor="white"
      my={1}
      borderRadius={2}
      padding={1}
      width="100%"
    >
      <TableContainer>
        <MUITable aria-label="Lista de anos letivos" variant="striped">
          <TableHead>
            <TableRow>
              {CELLS_HEADER[entityType]?.map((item, index) => (
                <TableCell
                  key={`key-x-${index}`}
                  align={index === 0 ? 'left' : 'center'}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.academic_years?.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Typography
                    component="span"
                    variant="body2"
                    align="left"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    <Stack alignItems="center" direction="row" gap={2}>
                      <SVGIcon name="date" size={3} color="primary" />{' '}
                      <strong>{row?.year}</strong>
                    </Stack>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    align="center"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    {dayjs(row?.initial_date).format('DD/MM/YYYY')}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    align="center"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    {dayjs(row?.end_date).format('DD/MM/YYYY')}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    align="center"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    <strong>{row?.expected_total_days}</strong>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    align="center"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    <strong>{row?.school_number ?? row?.total_days}</strong>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    align="center"
                    sx={{ display: 'block', width: '100%' }}
                  >
                    <Badge
                      variant="status"
                      color={row.active ? 'success' : 'inactive'}
                      badgeContent={row.active ? 'Ativo' : 'Inativo'}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="center"
                    spacing={1}
                    sx={{ maxWidth: '140px', float: 'right' }}
                  >
                    <IconButton
                      component={Link}
                      variant="outlinedSecondary"
                      to={`/gerenciar-calendario/${entityType}/${entityId}/anos-letivos/editar/${
                        row.id
                      }`}
                      title="Editar ano letivo"
                    >
                      <SVGIcon name="eye" size={3} />
                    </IconButton>

                    <IconButton
                      variant="outlinedSecondary"
                      title="Excluir ano letivo"
                      onClick={() => onClickDelete(row.id)}
                      disabled={row.in_use}
                    >
                      <SVGIcon name="delete" size={3} />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MUITable>
      </TableContainer>
    </Box>
  )
}
