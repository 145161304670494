import React, { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import ModalDeleteLessonPlan from './DeleteEvent'
import CreateEventModal from './CreateEvent'
import BaseEventsTable from './BaseEventsTable'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { useBaseCurriculum } from '~data/curriculum/baseCurriculum'
import { pxToRem } from '~helpers/getFontValue'

export default function LessonsPlanContent() {
  const [isModalCreateEventOpen, setIsModalCreateEventOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  const [planIdSelect, setPlanIdSelect] = useState(0)

  const { entityId, baseCurriculumId, entityType } = useParams()

  const isSchool = entityType !== 'school'

  const [lessonPlanData, setLessonPlanData] = useState(null)

  const handleOpenModalDelete = (id) => {
    setPlanIdSelect(id)
    setIsModalDeleteOpen(true)
  }

  const handleCloseModalDelete = () => {
    setIsModalDeleteOpen(false)
  }

  const handleOpenModalCreateEvent = () => {
    setIsModalCreateEventOpen(true)
  }

  const handleCloseModalCreateEvent = () => {
    setIsModalCreateEventOpen(false)
    setLessonPlanData(null)
  }

  const { data, error, loading } = useBaseCurriculum()

  if (error) {
    return `Erro: ${error.status} - ${error.message || ''}`
  }

  const lastEventAmount = data?.base_events?.reduce(
    (accumulator, current) =>
      accumulator > current.event_order ? accumulator : current.event_order,
    0
  )

  return (
    <>
      <Box mb={4} mt={4}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: 3.5,
            mb: 2.5,
          }}
        >
          <Typography
            sx={{
              fontSize: pxToRem(16),
            }}
          >
            {data &&
              `${data.curriculum_period.name}  •  ${data.subject.name}  •  ${
                data.grade.name
              }  •  ${data.events_per_week} aula(s) por semana`}
          </Typography>
          {isSchool && (
            <Button
              onClick={handleOpenModalCreateEvent}
              variant="primary"
              startIcon={<SVGIcon name="addCircle" color="white" />}
              sx={{
                minWidth: '200px',
                display: 'flex',
                float: { xs: 'none', sm: 'right' },
              }}
            >
              Adicionar aula
            </Button>
          )}
        </Stack>

        <BaseEventsTable
          isLoading={loading}
          isSchool={isSchool}
          baseEvents={data?.base_events}
          handleOpenModalDelete={handleOpenModalDelete}
          setLessonPlanData={setLessonPlanData}
        />
      </Box>
      {(isModalCreateEventOpen || lessonPlanData) &&
        entityId &&
        data?.subject.id &&
        baseCurriculumId && (
          <CreateEventModal
            handleCloseModal={handleCloseModalCreateEvent}
            lastEventAmount={Number(lastEventAmount) + 1}
            baseEvent={lessonPlanData || null}
            subjectId={data.subject.id}
            gradeId={data.grade.id}
          />
        )}

      {isModalDeleteOpen && !isModalCreateEventOpen && (
        <ModalDeleteLessonPlan
          handleCloseModal={handleCloseModalDelete}
          baseCurriculumId={baseCurriculumId || ''}
          planId={planIdSelect}
        />
      )}
    </>
  )
}
