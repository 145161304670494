import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

const endpoint = 'api/v3/profiles/grades'

export const useGradesByProfile = () => {
  const { error, data, loading } = useResource({
    resource: getGradesByProfile,
    params: {},
    key: 'useGradesByProfile',
  })

  return { error, data, loading }
}

const getGradesByProfile = () =>
  fetchWithRoot('get')(endpoint, {
    params: {
      include: ['grades'],
      fields: ['id', 'name'],
    },
  })
