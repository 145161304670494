import React, { useState, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { equals } from 'ramda'
import isEqual from 'react-fast-compare'
import {
  FormControl,
  Box,
  MenuItem,
  InputLabel,
  Select,
  Divider,
  Grid,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import { Formik } from 'formik'
import { usePrevious } from '@dnd-kit/utilities'
import {
  useAcademicYearsOptions,
  useCurriculumOptions,
  createCurriculum,
  editCurriculum,
} from './fetch'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { Form } from '~primitives/Input/Form'
import useResponsive from '~hooks/useResponsive'
import SuccessCheck from '~assets/svg/success_check.svg'

const classesPerWeek = [1, 2, 3, 4, 5, 6]

function FormModal({ handleCloseModal, entityId, curriculumData }) {
  const isDesktop = useResponsive({ query: 'up', key: 'lg' }) || false

  const isEditMode = Boolean(curriculumData)

  const { data: academicYearsOptions } = useAcademicYearsOptions(entityId)

  const prevAcademicYearOptions = usePrevious(academicYearsOptions)
  const [selectedAcademicYearId, setSelectedAcademicYearId] = useState(null)

  useEffect(() => {
    if (
      !isEqual(academicYearsOptions, prevAcademicYearOptions) &&
      !selectedAcademicYearId &&
      academicYearsOptions?.length
    ) {
      setSelectedAcademicYearId(academicYearsOptions[0]?.id)
    }
  }, [academicYearsOptions, prevAcademicYearOptions, selectedAcademicYearId])

  const [error, setError] = useState(false)

  const [isSuccess, setIsSuccess] = useState(false)

  const {
    data,
    loading,
    error: useCurriculumOptionsError,
  } = useCurriculumOptions(entityId, selectedAcademicYearId)

  const title = isEditMode ? 'Editar Currículo' : 'Adicionar Currículo'

  const successMessage = isEditMode
    ? 'Currículo editado com sucesso!'
    : 'Currículo adicionado com sucesso!'

  const queryClient = useQueryClient()

  const { mutate } = useMutation(
    ({ data, entityId }) =>
      isEditMode
        ? editCurriculum({
            data,
            entityId,
            curriculumId: Number(curriculumData?.curriculumId),
          })
        : createCurriculum({ data, entityId }),
    {
      onSuccess: (response) => {
        if (response[0] !== null && response[0].status === 422) {
          setError(
            'Ops! Parece que já existe um currículo criado com os mesmos valores.'
          )
        } else {
          setIsSuccess(true)
          queryClient.invalidateQueries('useManagementBaseCurriculums')
        }
      },
      onError: () => {
        setError('Erro ao enviar ao salvar informações')
      },
    }
  )

  if (useCurriculumOptionsError)
    return 'Ops! Tivemos um problema com a requisição.'

  if (
    loading ||
    !data.grades.length ||
    !data.subjects.length ||
    !data.curriculumPeriods.length ||
    !academicYearsOptions?.length
  )
    return null

  const handleFormValidate = (values) => {
    const errors = {}
    for (const [key] of Object.entries(values)) {
      if (values[key] === '') {
        errors[key] = 'Requerido'
      }
    }

    return errors
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    mutate({
      data: values,
      entityId: entityId,
    })
    setSubmitting(false)
  }

  const handleFormReset = (resetForm) => {
    setIsSuccess(false)
    resetForm()
  }

  const handleChangeSelectedAcademicYearId = (event) => {
    setSelectedAcademicYearId(event.target.value)
  }

  const initialValues = {
    grade_id: isEditMode ? curriculumData?.grade_id : '',
    subject_id: isEditMode ? curriculumData?.subject_id : '',
    curriculum_period_id: isEditMode
      ? curriculumData?.curriculum_period_id
      : '',
    events_per_week: isEditMode ? curriculumData?.events_per_week : '',
  }

  return (
    <Modal
      width="600px"
      height="98vh"
      overflowContent={true}
      contentPadding="5px 0"
      showFooter={false}
      footerPadding="20px 35px 30px"
      onClose={handleCloseModal}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={handleFormValidate}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          resetForm,
          values,
          errors,
          touched,
        }) => (
          <Form
            onSubmit={handleSubmit}
            headingSize="24px"
            style={{ padding: '0 19px' }}
          >
            <Typography variant="h4" margin="15px 0">
              <UnderlinedHeading as="span">
                <strong>{title}</strong>
              </UnderlinedHeading>
            </Typography>
            {isSuccess ? (
              <>
                <Box>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                    sx={{ bgcolor: 'grey.200', px: 4, height: 350 }}
                  >
                    <img src={SuccessCheck} alt="" width="90px" />
                    <Typography variant="h5">{successMessage}</Typography>
                  </Stack>
                </Box>
                <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
                  {isEditMode ? (
                    <Button
                      variant="primary"
                      onClick={handleCloseModal}
                      sx={{ minWidth: 160 }}
                    >
                      Ver lista de currículos
                    </Button>
                  ) : (
                    <>
                      <Button variant="neutral" onClick={handleCloseModal}>
                        Voltar para a lista de currículos
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          handleFormReset(resetForm)
                        }}
                        sx={{ minWidth: 160 }}
                      >
                        Adicionar novo
                      </Button>
                    </>
                  )}
                </Stack>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    backgroundColor: 'grey.200',
                    padding: 3,
                    borderRadius: 4,
                  }}
                >
                  <Typography as="span">
                    Inclua os dados do currículo
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Stack spacing={2}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="academic_year_id">
                        Ano letivo
                      </InputLabel>
                      <Select
                        name="academic_year_id"
                        id="academic_year_id"
                        value={selectedAcademicYearId}
                        defaultValue=""
                        disabled={false}
                        placeholder
                        onChange={handleChangeSelectedAcademicYearId}
                        variant="standard"
                        displayEmpty
                        MenuProps={{
                          style: { zIndex: 9999999 },
                        }}
                        native={!isDesktop}
                      >
                        {getPlaceholder({
                          placeholder: 'Selecione o ano eletivo',
                          isDesktop: isDesktop,
                        })}
                        {academicYearsOptions?.map(({ id, year }) =>
                          isDesktop ? (
                            <MenuItem value={id} key={id}>
                              {year}
                            </MenuItem>
                          ) : (
                            <option value={id} key={id}>
                              {year}
                            </option>
                          )
                        )}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel htmlFor="grade">Série</InputLabel>
                      <Select
                        name="grade_id"
                        id="grade"
                        value={values.grade_id}
                        error={Boolean(touched.grade_id && errors.grade_id)}
                        defaultValue=""
                        disabled={!data.grades}
                        placeholder
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="standard"
                        displayEmpty
                        MenuProps={{
                          style: { zIndex: 9999999 },
                        }}
                        native={!isDesktop}
                      >
                        {getPlaceholder({
                          placeholder: 'Selecione uma Série',
                          isDesktop: isDesktop,
                        })}
                        {data.grades.map((grade) =>
                          isDesktop ? (
                            <MenuItem value={grade.id} key={grade.id}>
                              {grade.name}
                            </MenuItem>
                          ) : (
                            <option value={grade.id} key={grade.id}>
                              {grade.name}
                            </option>
                          )
                        )}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel htmlFor="subject">Disciplina</InputLabel>
                      <Select
                        name="subject_id"
                        id="subject"
                        value={values.subject_id}
                        error={Boolean(touched.subject_id && errors.subject_id)}
                        defaultValue=""
                        disabled={!data.subjects}
                        placeholder
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="standard"
                        displayEmpty
                        MenuProps={{
                          style: { zIndex: 9999999 },
                        }}
                        native={!isDesktop}
                      >
                        {getPlaceholder({
                          placeholder: 'Selecione uma Disciplina',
                          isDesktop: isDesktop,
                        })}
                        {data.subjects.map((subject) =>
                          isDesktop ? (
                            <MenuItem value={subject.id} key={subject.id}>
                              {subject.name}
                            </MenuItem>
                          ) : (
                            <option value={subject.id} key={subject.id}>
                              {subject.name}
                            </option>
                          )
                        )}
                      </Select>
                    </FormControl>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8} lg={8}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="curriculum_period">
                              Período
                            </InputLabel>
                            <Select
                              name="curriculum_period_id"
                              id="curriculum_period"
                              value={values.curriculum_period_id}
                              error={Boolean(
                                touched.curriculum_period_id &&
                                  errors.curriculum_period_id
                              )}
                              defaultValue=""
                              disabled={!data.curriculumPeriods}
                              placeholder
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="standard"
                              displayEmpty
                              MenuProps={{
                                style: { zIndex: 9999999 },
                              }}
                              native={!isDesktop}
                            >
                              {getPlaceholder({
                                placeholder: 'Selecione um Período',
                                isDesktop: isDesktop,
                              })}
                              {data.curriculumPeriods.map(
                                (curriculum_period) =>
                                  isDesktop ? (
                                    <MenuItem
                                      value={curriculum_period.id}
                                      key={curriculum_period.id}
                                    >
                                      {curriculum_period.name}
                                    </MenuItem>
                                  ) : (
                                    <option
                                      value={curriculum_period.id}
                                      key={curriculum_period.id}
                                    >
                                      {curriculum_period.name}
                                    </option>
                                  )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="events_per_week">
                              Aulas na semana
                            </InputLabel>
                            <Select
                              id="events_per_week"
                              name="events_per_week"
                              value={values.events_per_week}
                              error={Boolean(
                                touched.events_per_week &&
                                  errors.events_per_week
                              )}
                              defaultValue=""
                              disabled={!data?.grades}
                              placeholder
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="standard"
                              displayEmpty
                              MenuProps={{
                                style: { zIndex: 9999999 },
                              }}
                              native={!isDesktop}
                            >
                              {getPlaceholder({
                                placeholder: '0',
                                isDesktop: isDesktop,
                              })}
                              {classesPerWeek.map((value) =>
                                isDesktop ? (
                                  <MenuItem value={value} key={value}>
                                    {value}
                                  </MenuItem>
                                ) : (
                                  <option value={value} key={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </Stack>
                  {error && (
                    <Typography
                      variant="body2"
                      sx={{ color: 'error.main', mt: 2 }}
                    >
                      {error}
                    </Typography>
                  )}
                </Box>
                <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
                  <Button variant="neutral" onClick={handleCloseModal}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    sx={{ minWidth: 160 }}
                    disabled={
                      isSubmitting ||
                      equals(values, initialValues) ||
                      Object.keys(errors).length > 0
                    }
                  >
                    Salvar
                  </Button>
                </Stack>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

function getPlaceholder({ placeholder, isDesktop }) {
  if (isDesktop) {
    return (
      <MenuItem value="" disabled="true">
        {placeholder}
      </MenuItem>
    )
  }
  return (
    <option value="" disabled>
      {placeholder}
    </option>
  )
}

export default FormModal
