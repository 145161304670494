import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { FooterWrapper, FooterContainer, FooterColumn } from './styles'
import LogoSVG from '~assets/svg/logo.svg'

import useCustomization from '~hooks/useCustomization'

const Footer = () => {
  const customization = useCustomization()
  const theme = useTheme()

  return (
    <FooterWrapper theme={theme}>
      <FooterContainer>
        <FooterColumn>
          {customization.smallText && (
            <Typography variant="caption" sx={{ color: 'text.white' }}>
              {customization.smallText}
            </Typography>
          )}

          {customization.stateLogo && (
            <img src={LogoSVG} alt={customization.departmentName} />
          )}
        </FooterColumn>
        {/* <FooterColumn>
          <Typography variant="caption" sx={{ color: 'text.white' }}>
            Siga a Foco nas redes sociais
          </Typography>
          <Stack direction="row" spacing={2}>
            <Item>
              <a
                href="https://www.instagram.com/focoescola/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SVGIcon name="icnInstagram" color="white" size={6} />
              </a>
            </Item>
            <Item>
              <a
                href="https://www.facebook.com/focoesc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SVGIcon name="icnFacebook" color="white" size={6} />
              </a>
            </Item>
            <Item>
              <a
                href="https://www.youtube.com/focoesc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SVGIcon name="icnYoutube" color="white" size={6} />
              </a>
            </Item>
          </Stack>
        </FooterColumn> */}
        {/* <FooterColumn>
          <Typography variant="caption" sx={{ color: 'text.white' }}>
            Entre no grupo da Foco
          </Typography>
          <a
            href="https://chat.whatsapp.com/CzW12CVurDX2tt8ILIHw6u"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVGIcon name="icnWhatsappGroup" color="white" size={7} />
          </a>
        </FooterColumn> */}
        {/* <FooterColumn>
          <Typography variant="caption" sx={{ color: 'text.white' }}>
            Precisa de ajuda?
          </Typography>
          <a
            href="https://api.whatsapp.com/send?phone=5511942338191&amp;utm_source=sendinblue&amp;utm_campaign=Onboarding__Reset_senha&amp;utm_medium=emailhttps://api.whatsapp.com/send?phone=5511942338191&amp;utm_source=sendinblue&amp;utm_campaign=Onboarding__Reset_senha&amp;utm_medium=email"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVGIcon name="icnWhatsapp" color="white" size={6} />
          </a>
        </FooterColumn> */}
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer
