import { Link, useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import React, { useState } from 'react'

import {
  Box,
  Stack,
  Avatar,
  Button,
  Typography,
  Paper,
  Divider,
  useTheme,
} from '@mui/material'

import { getStyles } from './styles'
import NotificationsPopover from './NotificationsPopover'
import useClickOutside from '~hooks/useClickOutside'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { Select } from '~primitives/Input/Select'
import { removeToken, selectProfile } from '~actions/User'

import { getSelectedProfile } from '~selectors/profile'

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeToken, selectProfile }, dispatch)

const getProfileName = (profile) => {
  if (!profile.role) return profile.personable.name

  return `${profile.personable.name} (${profile.role})`
}

const Header = ({ removeToken, selectProfile, user }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const menuRef = useClickOutside(() => setMenuIsOpen(false))
  const history = useHistory()
  const profile = useSelector(getSelectedProfile)

  const theme = useTheme()
  const styles = getStyles(theme)

  const handleChangeProfile = ({ value }) => {
    selectProfile(parseInt(value, 10))
    history.push('/pagina-inicial')
    setMenuIsOpen(false)
  }

  const handleToggleMenu = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  return (
    <Stack sx={{ ...styles.container }}>
      <Stack sx={{ ...styles.wrapper }}>
        {user.name && (
          <Stack sx={{ ...styles.user.settings }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              menuIsOpen={menuIsOpen}
              ref={menuRef}
              sx={{ ...styles.user.data }}
            >
              <Stack direction="row">
                {!user.student && <NotificationsPopover />}
                <Button
                  variant="transparent"
                  disableTouchRipple
                  onClick={handleToggleMenu}
                  data-testid="user-data"
                  sx={{ ...styles.user.avatar }}
                >
                  <Avatar
                    sx={{
                      bgcolor: '#2C2E3E',
                      fontSize: 13,
                    }}
                  >
                    {user?.name.slice(0, 1)}
                  </Avatar>
                </Button>
              </Stack>
              {menuIsOpen && (
                <Paper
                  data-testid="dropdown-menu"
                  sx={{ ...styles.popover.dropdown }}
                >
                  <Box sx={{ ...styles.user.info }}>
                    <Typography sx={{ fontSize: 13, color: 'grey.800' }}>
                      {user?.name}
                    </Typography>
                    <Typography sx={{ fontSize: 13, color: 'grey.550', mb: 2 }}>
                      {user?.email}
                    </Typography>
                    <Divider />
                  </Box>
                  <ul>
                    <li>
                      {!user.student && (
                        <Box sx={{ ...styles.popover.profileSelect }}>
                          {user.profiles.length > 1 && (
                            <Select
                              height="35px"
                              width="100%"
                              fontSize="13px"
                              padding="0 42px 0 8px"
                              name="profile_id"
                              options={user.profiles.map((profile) => ({
                                id: profile.id,
                                name: getProfileName(profile),
                              }))}
                              value={user?.selectedProfile || 0}
                              onChange={handleChangeProfile}
                            />
                          )}
                        </Box>
                      )}
                    </li>
                    <li>
                      <Link to="/perfil" data-testid="edit-profile">
                        <Typography
                          sx={{ ...styles.popover.link, pt: 1, mt: 1 }}
                        >
                          Editar perfil <SVGIcon name="edit" color="primary" />
                        </Typography>
                      </Link>
                    </li>
                    <Divider sx={{ my: 1 }} />
                    <li>
                      <Button
                        onClick={removeToken}
                        data-testid="logout"
                        sx={{
                          ...styles.popover.link,
                        }}
                      >
                        Sair <SVGIcon name="logout" color="primary" />
                      </Button>
                    </li>
                  </ul>
                </Paper>
              )}
            </Stack>
          </Stack>
        )}
        {profile?.personable && (
          <Stack sx={{ ...styles.user.personable }}>
            <Typography>{getProfileName(profile)}</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
