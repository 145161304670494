import React, { useState } from 'react'
import {
  Alert,
  Badge,
  Box,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { AnswerListModal } from './AnswerListModal'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { InsightContainer } from '~primitives/InsightsContainer'

const CELLS_HEADER = [
  { label: 'Nome', align: 'left' },
  { label: 'Status', align: 'left' },
  { label: 'Desempenho por proficiência', align: 'center' },
  { label: 'Taxa de acerto', align: 'center' },
  { label: '', align: 'center' },
]

export const StudentList = ({ list, error, loading, focoExamName }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [idAnswerd, setIdAnswerd] = useState(0)
  const [studantName, setNameStudant] = useState('')
  const [hitRate, setHitRate] = useState(0)

  const handleOpenModal = (id, studentName, hit_rate) => {
    setIdAnswerd(id)
    setNameStudant(studentName)
    setModalIsOpen(true)
    setHitRate(hit_rate)
  }

  const statusProficiecy = (hit_rate) => {
    const percenst = Number(hit_rate) * 100

    if (percenst <= 25) {
      return 'Abaixo do básico'
    } else if (percenst > 25 && percenst <= 50) {
      return 'Básico'
    } else if (percenst > 50 && percenst <= 75) {
      return 'Proficiente'
    } else if (percenst > 75) {
      return 'Avançado'
    }

    return ' Erro '
  }

  return (
    <InsightContainer title="Lista de alunos">
      {error && (
        <Box mt={5}>
          <Alert variant="filled" severity="error">
            Ocorreu um erro ao carregar os dados da avaliação.
          </Alert>
        </Box>
      )}

      {!error && loading && getPlaceholder()}

      <Box mt={1} bgcolor="white" my={1} borderRadius={2} padding={1}>
        <TableContainer>
          <Table aria-label="Table data student" variant="striped">
            <TableHead>
              <TableRow>
                {CELLS_HEADER.map((item, index) => (
                  <TableCell key={`key-a-${index}`} align={item.align}>
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.foco_exam_student_profiles?.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Badge
                        variant="index"
                        badgeContent={index + 1}
                        color="secondary"
                      />
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ display: 'block', minWidth: 75 }}
                      >
                        {row.student_profile.user.name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      component="span"
                      variant="body2"
                      align="left"
                      sx={{ display: 'block', width: '100%' }}
                    >
                      {row.answered_at ? (
                        <>
                          <SVGIcon name="checkBage" size={3} color="success" />{' '}
                          {' Realizado'}
                        </>
                      ) : (
                        <>
                          <SVGIcon name="warning" size={3} color="danger" />{' '}
                          {' Não realizado'}
                        </>
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      component="span"
                      variant="body2"
                      align="center"
                      sx={{ display: 'block', width: '100%' }}
                    >
                      {row.answered_at ? statusProficiecy(row.hit_rate) : ' - '}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      component="span"
                      variant="body2"
                      align="center"
                      sx={{ display: 'block', width: '100%' }}
                    >
                      {row.answered_at
                        ? `${row.hit_rate !== null ? row.hit_rate * 100 : 0}%`
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      justifyContent="right"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        maxWidth: '140px',
                        float: 'right',
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          handleOpenModal(
                            row.id,
                            row.student_profile.user.name || '',
                            row.hit_rate || 0
                          )
                        }}
                        variant="outlinedSecondary"
                        data-testid="button-action-cancel"
                      >
                        <SVGIcon name="eye" size={3} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {modalIsOpen && (
        <AnswerListModal
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          id={idAnswerd}
          studentName={studantName}
          hitHate={hitRate}
          subjectName={focoExamName}
        />
      )}
    </InsightContainer>
  )
}

const getPlaceholder = () =>
  [...Array(6).keys()].map((_, index) => (
    <Stack key={`a-${index}`} direction="row" gap={1} width="100%">
      <Skeleton variant="text" />
      <Stack>
        <Skeleton variant="text" />
      </Stack>
      <Stack key={`c-${index}`}>
        <Skeleton variant="text" />
      </Stack>
      <Stack key={'d'} direction="row" gap={1} justifyContent="flex-end">
        <Skeleton variant="circular" width={34} height={34} />
      </Stack>
    </Stack>
  ))
