import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/profiles/classrooms'

export const useManageBookletFilterClassrooms = (params) => {
  const { data, error, loading } = useResource({
    key: 'useManageBookletFilterClassrooms',
    resource: getManageBookletFilterClassrooms,
    params,
    enabled: !!params?.schoolId && !!params?.gradeId,
  })

  return { loading, error, data }
}

const getManageBookletFilterClassrooms = (params) =>
  fetchWithRoot('get')(endpoint, {
    params: {
      school_id: params?.schoolId,
      grade_id: params?.gradeId,
      include: ['classrooms'],
      fields: [{ classrooms: ['id', 'name'] }],
      q: { year_eq: new Date().getFullYear() },
    },
  })
