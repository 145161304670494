import React from 'react'
import { Typography } from '@mui/material'
import { Breadcrumbs } from '~primitives/Breadcrumbs'
import { Scribble } from '~primitives/Typography/Underline'

const Header = ({ title, breadcrumbs = [] }) => (
  <>
    {breadcrumbs?.length > 0 && <Breadcrumbs margin="0" items={breadcrumbs} />}
    <Typography
      fontSize={24}
      fontWeight={700}
      my={2}
      sx={{
        '& span': { pb: 0 },
      }}
    >
      <Scribble>{title}</Scribble>
    </Typography>
  </>
)

export default Header
