import * as React from 'react'
import { Route } from 'react-router-dom'

const RouteWithProps = ({
  component: Component,
  props,
  children,
  path,
  exact,
  strict,
  location,
  sensitive,
}) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    sensitive={sensitive}
    render={(childProps) => <Component {...props} {...childProps} />}
  >
    {children}
  </Route>
)

export default RouteWithProps
