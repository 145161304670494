import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/curriculum_skills'

const useSkills = (params) => {
  const { loading, error, data } = useResource({
    resource: getSkills,
    key: 'useQuestionSkills',
    params,
    enabled: !!params?.subjectId,
  })

  return { loading, error, data }
}

const getSkills = (params) =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: ['curriculum_skills'],
      fields: [{ curriculum_skills: ['id', 'name', 'description'] }],
      q: {
        foco_questions_id_not_null: true,
        subject_id_eq: params?.subjectId,
        foco_questions_grade_id_eq: params?.gradeId,
      },
    },
  })

export default useSkills
