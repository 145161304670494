import AribauGroteskRegularWoff from '~assets/webfonts/AribauGroteskRegular.woff'
import AribauGroteskRegularWoff2 from '~assets/webfonts/AribauGroteskRegular.woff2'
import AribauGroteskMediumWoff from '~assets/webfonts/AribauGroteskMedium.woff'
import AribauGroteskMediumWoff2 from '~assets/webfonts/AribauGroteskMedium.woff2'
import AribauGroteskBoldWoff from '~assets/webfonts/AribauGroteskBold.woff'
import AribauGroteskBoldWoff2 from '~assets/webfonts/AribauGroteskBold.woff2'

export const fontFace = `
  @font-face {
    font-family: 'Aribau Grotesk';
    src: url(${AribauGroteskRegularWoff2}) format('woff2'), url(${AribauGroteskRegularWoff}) format('woff');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Aribau Grotesk';
    src: url(${AribauGroteskMediumWoff2}) format('woff2'), url(${AribauGroteskMediumWoff}) format('woff');
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Aribau Grotesk';
    src: url(${AribauGroteskBoldWoff2}) format('woff2'), url(${AribauGroteskBoldWoff}) format('woff');
    font-weight: 700;
    font-display: swap;
  }
`
