import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (answerId) => `/api/v3/foco_answers/${answerId}`

export const patchAnswer = (params) =>
  fetchWithRoot('put')(getEndpoint(String(params.answerId)), {
    params: {
      foco_answer: {
        foco_question_option_id: params.optionId,
      },
    },
    bypassLoader: true,
  }).then(([error]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
  })
