import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

const getEndpoint = (schoolId) => `api/v3/schools/${schoolId}/academic_years`

export const useAcademicYearsBySchool = ({ schoolId }) => {
  const { error, data, loading } = useResource({
    resource: getAcademicYearsBySchool,
    params: { schoolId },
    key: 'useAcademicYearsBySchool',
  })

  return { error, data, loading }
}

const getAcademicYearsBySchool = ({ schoolId }) =>
  fetchWithRoot('get')(getEndpoint(String(schoolId)), {
    params: {
      include: ['academic_years'],
      fields: ['id', 'name'],
    },
  })
