import React, { useState } from 'react'
import { IconButton, Stack, useTheme } from '@mui/material'
import { SelectStudents } from './SelectStudents'
import { SelectClassroom } from './SelectClassroom'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const SelectGroup = ({
  classroom,
  classroomOptions,
  studentsByClassroom,
  setStudentsByClassroom,
  index,
  schoolId,
}) => {
  const theme = useTheme()

  const [selectedStudents, setSelectedStudents] = useState([
    ...classroom.studentIds,
  ])
  const [selectedClassroom, setSelectedClassroom] = useState(
    Number(classroom.id)
  )
  const amountOfClassroomsInUse = studentsByClassroom.length

  const handleAddSelectGroup = () => {
    const firstAvailableClassroom = classroomOptions.find(
      ({ enabled }) => enabled
    )

    if (!firstAvailableClassroom) return

    setStudentsByClassroom([
      ...studentsByClassroom,
      {
        id: firstAvailableClassroom.id,
        name: firstAvailableClassroom.name,
        studentIds: [],
      },
    ])
  }

  const handleDeleteSelectGroup = () => {
    setStudentsByClassroom(
      studentsByClassroom.slice(0, studentsByClassroom.length - 1)
    )
  }

  const handleChangeStudents = (value) => {
    setSelectedStudents(value)
    setStudentsByClassroom(
      studentsByClassroom.map((classroom, currentIndex) =>
        currentIndex === index
          ? {
              ...classroom,
              studentIds: [...value],
            }
          : { ...classroom }
      )
    )
  }

  const handleChangeClassroom = (event) => {
    const value = event.target.value
    const newSelectedClassroom = classroomOptions.find(({ id }) => id === value)

    if (!newSelectedClassroom) return

    setStudentsByClassroom(
      studentsByClassroom.map((classroom, currentIndex) =>
        currentIndex === index
          ? {
              id: newSelectedClassroom?.id,
              name: newSelectedClassroom?.name,
              studentIds: [],
            }
          : { ...classroom }
      )
    )

    setSelectedClassroom(Number(value))
    setSelectedStudents([])
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ position: 'relative' }}>
      <SelectClassroom
        classroomOptions={classroomOptions}
        selectedClassroom={selectedClassroom}
        handleChangeClassroom={handleChangeClassroom}
      />

      <SelectStudents
        selectedStudents={selectedStudents}
        selectedClassroom={selectedClassroom}
        handleChangeStudents={handleChangeStudents}
        schoolId={schoolId}
      />

      {
        <IconButton
          variant="secondary"
          disabled={
            index + 1 !== amountOfClassroomsInUse ||
            classroomOptions.length === amountOfClassroomsInUse
          }
          onClick={handleAddSelectGroup}
          size="small"
        >
          <SVGIcon name="add" color="white" size={6} />
        </IconButton>
      }

      {amountOfClassroomsInUse > 1 && index + 1 === amountOfClassroomsInUse && (
        <IconButton
          type="button"
          variant="outlined"
          color="secondary"
          onClick={handleDeleteSelectGroup}
          size="small"
          sx={{
            position: 'absolute',
            right: '-45px',
          }}
        >
          <SVGIcon
            name="delete"
            color={theme.palette.secondary.main}
            size={6}
          />
        </IconButton>
      )}
    </Stack>
  )
}
