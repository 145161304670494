import React, { useState } from 'react'
import { Alert, Box, Button, Skeleton, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { DeleteAcademicYear } from '../DeleteAcademicYear'
import { Table } from '../Table'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { useAcademicYears } from '~data/api/v3/academicYears/query/findMany'

export const Content = ({ entityType, entityId }) => {
  const extension = {
    school: 'school',
    district: 'district',
  }

  const { data, error, loading } = useAcademicYears({
    entityId,
    entityType,
    extensions: ['in_use', extension[entityType]],
  })

  const [deleteYearId, setDeleteYearId] = useState(undefined)

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Typography variant="h4">Visão geral</Typography>
        <Button
          variant="primary"
          component={Link}
          to={`/gerenciar-calendario/${entityType}/${entityId}/anos-letivos/novo`}
          startIcon={<SVGIcon name="addCircle" color="white" />}
          sx={{ display: 'flex', pr: 5, gap: 3 }}
          size="small"
        >
          Adicionar ano letivo
        </Button>
      </Stack>

      {error && (
        <Alert variant="filled" severity="error">
          Ocorreu um erro ao carregar os dados do ano letivo
        </Alert>
      )}

      {(loading || !data) && (
        <Skeleton
          sx={{ mt: 3 }}
          variant="rounded"
          minWidth="100%"
          height={200}
        />
      )}

      {!error && !loading && data && (
        <Stack variant="modalBox" mt={3}>
          {data && (
            <Table
              data={data}
              entityType={entityType}
              entityId={entityId}
              onClickDelete={setDeleteYearId}
            />
          )}
        </Stack>
      )}

      {deleteYearId && (
        <DeleteAcademicYear
          id={deleteYearId}
          onClose={() => setDeleteYearId(undefined)}
          entityType={entityType}
          entityId={entityId}
        />
      )}
    </Box>
  )
}
