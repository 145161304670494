import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const ActionButton = (props) => {
  const { tooltipTitle, tooltipPlacement, iconName, ...rest } = props

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
      <IconButton {...rest} variant="outlined" color="secondary">
        <SVGIcon name={iconName} size={3} />
      </IconButton>
    </Tooltip>
  )
}
