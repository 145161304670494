import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

const useExamsEditions = () => {
  const { loading, error, data } = useResource({
    key: 'useStudentFocoExamsEditions',
    resource: getExamsEditions,
  })

  return { loading, error, data }
}

export const endpoint = '/api/v3/foco_exam_editions'

const getExamsEditions = () =>
  fetchWithRoot('get')(endpoint, {
    params: {
      fields: [
        'name',
        'id',
        'foco_exam_student_profile_id',
        'exam_start',
        'exam_end',
        'student_exams',
      ],
      include: ['foco_exam_editions'],
    },
    bypassLoader: true,
  })

export default useExamsEditions
