import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const endpoint = '/api/v3/foco_exam_editions'

export const useManageBookletFilterExam = (params) => {
  const { data, error, loading } = useResource({
    key: 'useManageBookletFilterExam',
    resource: getManageBookletFilterExam,
    params,
    enabled:
      !!params?.entityType && !!params?.entityId && !!params?.focoExamEditionId,
  })

  return { loading, error, data }
}

const getManageBookletFilterExam = ({
  entityType,
  entityId,
  focoExamEditionId,
}) =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      include: [
        {
          foco_exam_editions: [{ grade_subjects: ['grade', 'subject'] }],
        },
      ],
      fields: [
        {
          foco_exam_editions: [
            'id',
            'name',
            {
              grade_subjects: [
                { grade: ['id', 'name'] },
                { subject: ['id', 'name'] },
              ],
            },
          ],
        },
      ],
      q: {
        id_eq: focoExamEditionId,
      },
    },
  })
