export const getSubjectOptions = (examEdition) =>
  examEdition ? examEdition?.grade_subjects.map(({ subject }) => subject) : []

export const getGradeOptions = (examEdition, subjectId) =>
  examEdition
    ? examEdition?.grade_subjects
        .filter((exam) => exam.subject.id === subjectId)
        .map(({ grade }) => grade)
    : []

export const getSubjectFirstOption = (examEdition) =>
  getSubjectOptions(examEdition)[0]

export const getGradeFirstOption = (examEdition, subjectId) =>
  getGradeOptions(examEdition, subjectId)[0]

export const getInitialValues = ({
  examEdition,
  name,
  subjectId,
  gradeId,
  studentsByClassroom,
}) => {
  const subject = getSubjectFirstOption(examEdition)
  const grade = getGradeFirstOption(examEdition, subject.id)

  return {
    name: name ?? '',
    subject: subjectId ?? subject.id,
    grade: gradeId ?? grade.id,
    studentsByClassroom: studentsByClassroom ?? [],
  }
}

export const getQuestionsToDelete = (initialQuestions, newQuestions) =>
  initialQuestions
    .filter(
      (initialQuestion) =>
        !newQuestions.some(
          (newQuestion) =>
            newQuestion.foco_question_id === initialQuestion.foco_question_id
        )
    )
    .map(({ id, foco_question_id }) => ({
      id,
      foco_question_id,
      _destroy: true,
    }))

export const getQuestionsToChangePosition = (initialQuestions, newQuestions) =>
  initialQuestions.filter((initialQuestion) =>
    newQuestions.some(
      (newQuestion) =>
        newQuestion.foco_question_id === initialQuestion.foco_question_id &&
        newQuestion.position !== initialQuestion.position
    )
  )

export const getQuestionsToAdd = (initialQuestions, newQuestions) =>
  newQuestions
    .filter((newQuestion) =>
      initialQuestions.every(
        (initialQuestion) =>
          newQuestion.foco_question_id !== initialQuestion.foco_question_id
      )
    )
    .map(({ foco_question_id, position }) => ({
      foco_question_id,
      position,
    }))

export const getQuestionsPositions = (questionIds) =>
  questionIds.map((questionId, index) => ({
    foco_question_id: questionId,
    position: index + 1,
  }))
