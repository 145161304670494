import { Stack, useTheme } from '@mui/material'
import React from 'react'
import { Option } from './Option'
import { getStyles } from './styles'

export const FilterExam = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const optionKeys = [
    'expiresSoon',
    'available',
    'started',
    'answered',
    'unperformed',
  ]

  return (
    <Stack sx={{ ...styles.container }}>
      {optionKeys.map((option, index) => (
        <Option
          key={index}
          selected={props.selected}
          setSelected={props.setSelected}
          name={option}
          value={props[option]}
        />
      ))}
    </Stack>
  )
}
