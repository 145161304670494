import React from 'react'
import { useHistory } from 'react-router-dom'
import { StudentModalDelete } from '~components/ManageStudents/ModalDelete'
import { deleteStudentProfile } from '~data/api/v3/studentProfiles/mutation/delete.js'

export const DeleteModal = (props) => {
  const history = useHistory()

  const handleClose = () => {
    history.push({
      pathname: `/gerenciar-alunos/school/${props.schoolId}`,
    })
  }

  return (
    <StudentModalDelete
      requestOnSubmit={deleteStudentProfile}
      handleClose={handleClose}
      {...props}
    />
  )
}
