import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import CalendarComponent from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export const FullCalendar = ({
  events,
  initialView = 'timeGridWeek',
  initialDate = new Date(),
  ...props
}) => {
  return (
    <CalendarComponent
      weekend
      selectable
      editable={false}
      rerenderDelay={10}
      dayMaxEventRows={3}
      eventDisplay="block"
      headerToolbar={false}
      locale={'pt-br'}
      height="auto"
      stickyHeaderDates={false}
      slotDuration="00:60:00"
      hiddenDays={[0, 7]}
      allDaySlot={false}
      plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
      views={{
        week: { columnFormat: 'ddd d/M' },
        day: { columnFormat: 'dddd' },
      }}
      slotLabelFormat={(data) =>
        `${data.date.hour}:${String(data.date.minute).padStart(2, '0')}`
      }
      initialDate={initialDate}
      initialView={initialView}
      events={{ events }}
      {...props}
    />
  )
}

export const CalendarStyles = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
    '& .fc': {
      '--fc-list-event-dot-width': '8px',
      '--fc-border-color': theme.palette.divider,
      '--fc-event-border-color': theme.palette.info.light,
      '--fc-now-indicator-color': theme.palette.error.main,
      '--fc-today-bg-color': theme.palette.action.selected,
      '--fc-page-bg-color': theme.palette.background.default,
      '--fc-neutral-bg-color': theme.palette.background.neutral,
      '--fc-list-event-hover-bg-color': theme.palette.action.hover,
      '--fc-highlight-color': alpha(theme.palette.primary.main, 0.08),
    },

    '& .fc .fc-license-message': {
      display: 'none',
    },
    '& .fc a': {
      color: theme.palette.text.secondary,
    },

    // Table Head
    '& .fc .fc-col-header ': {
      '& th:not(:first-of-type)': {
        '& > div:after': {
          display: 'block',
          content: "''",
          borderColor: theme.palette.divider,
        },
      },

      '& .fc-col-header-cell-cushion': {
        ...theme.typography.subtitle2,
        padding: '13px 0',
        textAlign: 'center',
      },
    },

    // Event
    '& .fc .fc-event': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[230],
      borderColor: theme.palette.grey[230],
    },
    '& .fc-timegrid-event-harness': {
      '&:hover': {
        zIndex: '2 !important',
        boxShadow: '0px 4px 14px 0px rgba(16, 16, 16, 0.15)',
      },
    },
    '& .fc .fc-event .fc-event-main': {
      padding: '5px',
      color: theme.palette.grey[850],
      borderRadius: 4,
      transition: theme.transitions.create('filter'),
      overflow: 'hidden',

      '&:before,&:after': {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        content: "''",
        borderRadius: 4,
        position: 'absolute',
        boxSizing: 'border-box',
        transition: theme.transitions.create('all'),
      },
      '&:before': {
        zIndex: 8,
        opacity: 0,
      },
      '&:after': {
        zIndex: 7,
        opacity: 0,
        backgroundColor: 'currentColor',
      },
      '&:hover': {
        boxShadow: theme.shadows[5],
        backgroundColor: theme.palette.grey[230],
        '&:before': {
          zIndex: 8,
        },
        '&:after': {
          zIndex: 7,
        },
      },
    },
    '& .fc .fc-event .fc-event-main-frame': {
      fontSize: 13,
      lineHeight: '20px',
    },
    '& .fc .fc-daygrid-event .fc-event-title': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& .fc .fc-event .fc-event-time': {
      padding: 0,
      overflow: 'unset',
      fontWeight: theme.typography.bold,
    },

    // Background events
    '.fc .fc-bg-event.opaque': {
      opacity: 1,
    },

    // Popover
    '& .fc .fc-popover': {
      border: 0,
      overflow: 'hidden',
      boxShadow: theme.customShadows.z20,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
    '& .fc .fc-popover-header': {
      ...theme.typography.subtitle2,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[500],
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
    '& .fc .fc-popover-close': {
      opacity: 0.48,
      transition: theme.transitions.create('opacity'),
      '&:hover': {
        opacity: 1,
      },
    },
    '& .fc .fc-more-popover .fc-popover-body': {
      padding: theme.spacing(1.5),
    },
    '& .fc .fc-popover-body': {
      '& .fc-daygrid-event.fc-event-start, & .fc-daygrid-event.fc-event-end': {
        margin: '2px 0',
      },
    },

    // Month View
    '& .fc .fc-day-other .fc-daygrid-day-top': {
      opacity: 1,
      '& .fc-daygrid-day-number': {
        color: theme.palette.text.disabled,
      },
    },
    '& .fc .fc-daygrid-day-number': {
      ...theme.typography.body2,
      padding: theme.spacing(1, 1, 0),
    },
    '& .fc .fc-daygrid-event': {
      marginTop: 4,
    },
    '& .fc .fc-daygrid-event.fc-event-start, & .fc .fc-daygrid-event.fc-event-end':
      {
        marginLeft: 4,
        marginRight: 4,
      },
    '& .fc .fc-daygrid-more-link': {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
    },

    // Week & Day View
    '& .fc .fc-timegrid-axis-cushion': {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
    '& .fc .fc-timegrid-slot-label-cushion': {
      ...theme.typography.body2,
      padding: '0 10px',
      fontSize: '11px', // deveria usar uma variável, mas ...
    },

    // Agenda View
    '& .fc-direction-ltr .fc-list-day-text, .fc-direction-rtl .fc-list-day-side-text, .fc-direction-ltr .fc-list-day-side-text, .fc-direction-rtl .fc-list-day-text':
      {
        ...theme.typography.subtitle2,
      },
    '& .fc .fc-list-event': {
      ...theme.typography.body2,
      '& .fc-list-event-time': {
        color: theme.palette.text.secondary,
      },
    },
    '& .fc .fc-list-table': {
      '& th, td': {
        borderColor: 'transparent',
      },
    },
    '& .fc .fc-scrollgrid': {
      border: 'none',
    },
    '& .fc .fc-daygrid-body table tbody tr:fist-of-type': {
      display: 'none',
    },

    '& .fc-theme-standard td, .fc-theme-standard th': {
      borderRight: '0',
    },

    '& .fc table tbody > tr > td:first-of-type, & .fc table thead > tr > th:first-of-type':
      {
        border: 'none',
      },
    '& .fc .fc-timegrid-slot': {
      height: '60px',
      borderLeft: 0,
    },
    '& .fc .fc-col-header': {
      boxShadow: 'none',
    },

    '& .fc .fc-timegrid-col.fc-day-today': {
      backgroundColor: 'transparent',
    },
    '& .fc-theme-standard th': {
      [theme.breakpoints.up('lg')]: {
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          height: 'calc(100% - 15px)',
          width: '100%',
          background: theme.palette.background.paper,
        },
      },

      '& > div': {
        position: 'relative',
        zIndex: 9,
      },
    },
    '& .fc .fc-timeGridDay-view .fc-col-header': {
      '& .fc-scrollgrid-sync-inner': {
        textAlign: 'left',
      },
    },
    '& .fc-timegrid-col-events': {
      width: '100%',
    },
  }
})
